// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";

function make(md5, persistedState) {
  var basicResponse = function (json) {
    return {
            id: Json_decode.field("id", Json_decode.string, json),
            name: Json_decode.field("name", Json_decode.string, json),
            questionIndex: Json_decode.field("questionIndex", Json_decode.$$int, json),
            answers: Json_decode.field("answers", Json_decode.string, json),
            correct: Json_decode.field("correct", Json_decode.bool, json)
          };
  };
  var batmanResponse = function (json) {
    return {
            answer: Json_decode.field("answer", Json_decode.string, json),
            questionIndex: Json_decode.field("questionIndex", Json_decode.$$int, json),
            correct: Json_decode.field("correct", Json_decode.bool, json)
          };
  };
  if (md5 === undefined) {
    return /* NotSet */0;
  }
  if (persistedState === undefined) {
    return /* NotSet */0;
  }
  var json;
  try {
    json = Json.parseOrRaise(persistedState);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    console.log("deserialize error CobraPersistedState decodeCobraQuestions");
    console.log(e);
    return /* NotSet */0;
  }
  var match = Json_decode.field("responseType", Json_decode.string, json);
  switch (match) {
    case "BasicResponse" :
        return {
                TAG: /* BasicResponse */0,
                _0: {
                  responses: Json_decode.field("responses", (function (param) {
                          return Json_decode.array(basicResponse, param);
                        }), json)
                }
              };
    case "BatmanResponse" :
        return {
                TAG: /* BatmanResponse */1,
                _0: {
                  responses: Json_decode.field("responses", (function (param) {
                          return Json_decode.array(batmanResponse, param);
                        }), json)
                }
              };
    default:
      console.log("Uknown CobraPersistedState responseType");
      return /* NotSet */0;
  }
}

var Decode = {
  make: make
};

function encodeBasicResponse(br) {
  var dict = {};
  dict["id"] = br.id;
  dict["name"] = br.name;
  dict["questionIndex"] = br.questionIndex;
  dict["answers"] = br.answers;
  dict["correct"] = br.correct;
  return dict;
}

function encodeBatmanResponse(br) {
  var dict = {};
  dict["answer"] = br.answer;
  dict["questionIndex"] = br.questionIndex;
  dict["correct"] = br.correct;
  return dict;
}

function make$1(responseStates) {
  if (typeof responseStates === "number") {
    return ;
  }
  if (responseStates.TAG === /* BasicResponse */0) {
    var dict = {};
    return dict["responseType"] = "BasicResponse", dict["responses"] = Belt_Array.map(responseStates._0.responses, encodeBasicResponse), Json.stringify(dict);
  }
  var dict$1 = {};
  return dict$1["responseType"] = "BatmanResponse", dict$1["responses"] = Belt_Array.map(responseStates._0.responses, encodeBatmanResponse), Json.stringify(dict$1);
}

var Encode = {
  encodeBasicResponse: encodeBasicResponse,
  encodeBatmanResponse: encodeBatmanResponse,
  make: make$1
};

function findId(responseStateData, id) {
  if (typeof responseStateData === "number" || responseStateData.TAG !== /* BasicResponse */0) {
    return ;
  } else {
    return Caml_option.undefined_to_opt(responseStateData._0.responses.find(function (r) {
                    return r.id === id;
                  }));
  }
}

var responseStateData = {
  contents: /* NotSet */0
};

function clearResponses(param) {
  responseStateData.contents = /* NotSet */0;
  
}

function initResponses(md5, stringData) {
  responseStateData.contents = make(stringData, md5);
  
}

function stringifyResponses(param) {
  return make$1(responseStateData.contents);
}

function collectResponses(t) {
  var tmp;
  if (typeof t === "number" || t.TAG !== /* Orig */0) {
    tmp = responseStateData.contents;
  } else {
    var match = t._0;
    var r_id = match.id;
    var r_name = match.name;
    var r_questionIndex = match.questionIndex;
    var r_answers = match.answers;
    var r_correct = match.correct;
    var r = {
      id: r_id,
      name: r_name,
      questionIndex: r_questionIndex,
      answers: r_answers,
      correct: r_correct
    };
    var match$1 = responseStateData.contents;
    tmp = typeof match$1 === "number" ? ({
          TAG: /* BasicResponse */0,
          _0: {
            responses: [r]
          }
        }) : (
        match$1.TAG === /* BasicResponse */0 ? ({
              TAG: /* BasicResponse */0,
              _0: {
                responses: Belt_Array.concat(match$1._0.responses, [r])
              }
            }) : responseStateData.contents
      );
  }
  responseStateData.contents = tmp;
  return make$1(responseStateData.contents);
}

function collectBatchResponses(responses) {
  responseStateData.contents = responses ? ({
        TAG: /* BatmanResponse */1,
        _0: {
          responses: Belt_Array.map(responses._0, (function (param) {
                  return {
                          answer: param.answer,
                          questionIndex: param.questionIndex,
                          correct: param.correct
                        };
                }))
        }
      }) : responseStateData.contents;
  return make$1(responseStateData.contents);
}

function use(md5, persistedState) {
  React.useEffect((function () {
          initResponses(md5, persistedState);
          return (function (param) {
                    responseStateData.contents = /* NotSet */0;
                    
                  });
        }), []);
  
}

export {
  Decode ,
  Encode ,
  findId ,
  responseStateData ,
  clearResponses ,
  initResponses ,
  stringifyResponses ,
  collectResponses ,
  collectBatchResponses ,
  use ,
  
}
/* react Not a pure module */
