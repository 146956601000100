// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_SetString from "../../../node_modules/rescript/lib/es6/belt_SetString.js";
import * as FlagsTypes$LiteracyplanetClientFlags from "./FlagsTypes.bs.js";
import * as FlagsContext$LiteracyplanetClientFlags from "./FlagsContext.bs.js";
import * as FlagsReducer$LiteracyplanetClientFlags from "./FlagsReducer.bs.js";
import * as FeaturesQuery$LiteracyplanetClientFlags from "./FeaturesQuery.bs.js";

var products = Belt_SetString.fromArray([
      "teacher-product",
      "school-product",
      "home-product",
      "library-product"
    ]);

function getProduct(classicFlags) {
  return Belt_List.head(Belt_SetString.toList(Belt_SetString.intersect(Belt_SetString.fromArray(classicFlags), products)));
}

function FlagsProvider(Props) {
  var userId = Props.userId;
  var classicFlags = Props.classicFlags;
  var systemFlags = Props.systemFlags;
  var userRoles = Props.userRoles;
  var idp = Props.idp;
  var idpAccountId = Props.idpAccountId;
  var yearLevel = Props.yearLevel;
  var loadingComponent = Props.loadingComponent;
  var customUnleashFeaturesOpt = Props.customUnleashFeatures;
  var children = Props.children;
  var customUnleashFeatures = customUnleashFeaturesOpt !== undefined ? customUnleashFeaturesOpt : [];
  var product = getProduct(classicFlags);
  var state = FeaturesQuery$LiteracyplanetClientFlags.use(userRoles, userId, idp, idpAccountId, yearLevel, product, Belt_Array.concat(FlagsTypes$LiteracyplanetClientFlags.unleashFeatures, customUnleashFeatures));
  if (state) {
    return React.createElement(FlagsContext$LiteracyplanetClientFlags.Provider.make, {
                hasFlagInState: FlagsReducer$LiteracyplanetClientFlags.hasFlagInState,
                features: state._0,
                classicFlags: classicFlags,
                userRoles: userRoles,
                systemFlags: systemFlags,
                children: children
              });
  } else {
    return loadingComponent;
  }
}

var unleashFeatures = FlagsTypes$LiteracyplanetClientFlags.unleashFeatures;

var hasFlagInState = FlagsReducer$LiteracyplanetClientFlags.hasFlagInState;

var make = FlagsProvider;

export {
  unleashFeatures ,
  hasFlagInState ,
  products ,
  getProduct ,
  make ,
  
}
/* products Not a pure module */
