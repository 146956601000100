import React from 'react';
import PropTypes from 'prop-types';

class CallbackComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onRedirectSuccess = this.onRedirectSuccess.bind(this);
    this.onRedirectError = this.onRedirectError.bind(this);
  };

  componentDidMount() {
    this.props.userManager.signinRedirectCallback(process.env.MOBILE ? {
      response_mode: 'query'
    } : null)
      .then((user) => this.onRedirectSuccess(user))
      .catch((error) => this.onRedirectError(error));
  };

  onRedirectSuccess(user) {
    this.props.successCallback(user);
  };

  onRedirectError(error) {
    if (this.props.errorCallback) {
      this.props.errorCallback(error);
    } else {
      throw new Error(`Error handling redirect callback: ${error.message}`);
    }
  };

  render() {
    return React.Children.only(this.props.children);
  }
}


CallbackComponent.propTypes = {
  // the content to render
  children: PropTypes.element.isRequired,

  // the userManager
  userManager: PropTypes.object.isRequired,

  // a function invoked when the callback succeeds
  successCallback: PropTypes.func.isRequired,

  // a function invoked when the callback fails
  errorCallback: PropTypes.func
};


export default CallbackComponent;
