// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Client from "@apollo/client";

var Raw = {};

var query = Client.gql(["fragment MissionFragment on Mission   {\n__typename  \nid  \nrefId  \nstrand  \ndescription  \n}\n"]);

function parse(value) {
  var value$1 = value.description;
  return {
          __typename: value.__typename,
          id: value.id,
          refId: value.refId,
          strand: value.strand,
          description: !(value$1 == null) ? value$1 : undefined
        };
}

function serialize(value) {
  var value$1 = value.description;
  var description = value$1 !== undefined ? value$1 : null;
  var value$2 = value.strand;
  var value$3 = value.refId;
  var value$4 = value.id;
  var value$5 = value.__typename;
  return {
          __typename: value$5,
          id: value$4,
          refId: value$3,
          strand: value$2,
          description: description
        };
}

function verifyArgsAndParse(_MissionFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var MissionFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = Client.gql(["fragment ActivityFragment on Activity   {\n__typename  \nid  \nrefId  \nkind  \ntitle  \ndescription  \n}\n"]);

function parse$1(value) {
  var value$1 = value.kind;
  var value$2 = value.title;
  var value$3 = value.description;
  return {
          __typename: value.__typename,
          id: value.id,
          refId: value.refId,
          kind: !(value$1 == null) ? value$1 : undefined,
          title: !(value$2 == null) ? value$2 : undefined,
          description: !(value$3 == null) ? value$3 : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.description;
  var description = value$1 !== undefined ? value$1 : null;
  var value$2 = value.title;
  var title = value$2 !== undefined ? value$2 : null;
  var value$3 = value.kind;
  var kind = value$3 !== undefined ? value$3 : null;
  var value$4 = value.refId;
  var value$5 = value.id;
  var value$6 = value.__typename;
  return {
          __typename: value$6,
          id: value$5,
          refId: value$4,
          kind: kind,
          title: title,
          description: description
        };
}

function verifyArgsAndParse$1(_ActivityFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var ActivityFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

var query$2 = Client.gql(["fragment StudentAttemptAggregateFragment on StudentAttemptAggregate   {\n__typename  \nattempts  \nbestScore  \n}\n"]);

function parse$2(value) {
  return {
          __typename: value.__typename,
          attempts: value.attempts,
          bestScore: value.bestScore
        };
}

function serialize$2(value) {
  var value$1 = value.bestScore;
  var value$2 = value.attempts;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          attempts: value$2,
          bestScore: value$1
        };
}

function verifyArgsAndParse$2(_StudentAttemptAggregateFragment, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var StudentAttemptAggregateFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

var Raw$3 = {};

var query$3 = Client.gql(["fragment StudentActivityAttemptFragment on StudentActivityAttempt   {\n__typename  \nid  \nstate  \ncontentMd5  \nquestionIndex  \nnumComplete  \nnumCorrect  \naccuracy  \n}\n"]);

function parse$3(value) {
  var value$1 = value.state;
  var value$2 = value.contentMd5;
  return {
          __typename: value.__typename,
          id: value.id,
          state: !(value$1 == null) ? value$1 : undefined,
          contentMd5: !(value$2 == null) ? value$2 : undefined,
          questionIndex: value.questionIndex,
          numComplete: value.numComplete,
          numCorrect: value.numCorrect,
          accuracy: value.accuracy
        };
}

function serialize$3(value) {
  var value$1 = value.accuracy;
  var value$2 = value.numCorrect;
  var value$3 = value.numComplete;
  var value$4 = value.questionIndex;
  var value$5 = value.contentMd5;
  var contentMd5 = value$5 !== undefined ? value$5 : null;
  var value$6 = value.state;
  var state = value$6 !== undefined ? value$6 : null;
  var value$7 = value.id;
  var value$8 = value.__typename;
  return {
          __typename: value$8,
          id: value$7,
          state: state,
          contentMd5: contentMd5,
          questionIndex: value$4,
          numComplete: value$3,
          numCorrect: value$2,
          accuracy: value$1
        };
}

function verifyArgsAndParse$3(_StudentActivityAttemptFragment, value) {
  return parse$3(value);
}

function verifyName$3(param) {
  
}

var StudentActivityAttemptFragment = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  verifyArgsAndParse: verifyArgsAndParse$3,
  verifyName: verifyName$3
};

var Raw$4 = {};

var query$4 = Client.gql([
      "fragment StudentActivityFragment on NonOptionalStudentActivity   {\n__typename  \nid  \nassessment  \ncomplete  \nactivity  {\n...ActivityFragment   \n}\n\nattemptAggregates  {\n...StudentAttemptAggregateFragment   \n}\n\ncurrentAttempt  {\n...StudentActivityAttemptFragment   \n}\n\n}\n",
      "",
      "",
      ""
    ], query$1, query$3, query$2);

function parse$4(value) {
  var value$1 = value.attemptAggregates;
  return {
          __typename: value.__typename,
          id: value.id,
          assessment: value.assessment,
          complete: value.complete,
          activity: parse$1(value.activity),
          attemptAggregates: !(value$1 == null) ? parse$2(value$1) : undefined,
          currentAttempt: parse$3(value.currentAttempt)
        };
}

function serialize$4(value) {
  var value$1 = value.currentAttempt;
  var currentAttempt = serialize$3(value$1);
  var value$2 = value.attemptAggregates;
  var attemptAggregates = value$2 !== undefined ? serialize$2(value$2) : null;
  var value$3 = value.activity;
  var activity = serialize$1(value$3);
  var value$4 = value.complete;
  var value$5 = value.assessment;
  var value$6 = value.id;
  var value$7 = value.__typename;
  return {
          __typename: value$7,
          id: value$6,
          assessment: value$5,
          complete: value$4,
          activity: activity,
          attemptAggregates: attemptAggregates,
          currentAttempt: currentAttempt
        };
}

function verifyArgsAndParse$4(_StudentActivityFragment, value) {
  return parse$4(value);
}

function verifyName$4(param) {
  
}

var StudentActivityFragment = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  verifyArgsAndParse: verifyArgsAndParse$4,
  verifyName: verifyName$4
};

var Raw$5 = {};

var query$5 = Client.gql([
      "fragment StudentMissionFragment on NonOptionalStudentMission   {\n__typename  \nid  \nchest  \naverageAccuracy  \ncomplete  \nteacherAssignmentId  \ntitle  \nusersLocale  \nmission  {\n...MissionFragment   \n}\n\nactivities  {\n...StudentActivityFragment   \n}\n\n}\n",
      "",
      ""
    ], query, query$4);

function parse$5(value) {
  var value$1 = value.chest;
  var tmp;
  switch (value$1) {
    case "BLANK" :
        tmp = "BLANK";
        break;
    case "COMMON" :
        tmp = "COMMON";
        break;
    case "GEM" :
        tmp = "GEM";
        break;
    case "MYTHIC" :
        tmp = "MYTHIC";
        break;
    case "RARE" :
        tmp = "RARE";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  var value$2 = value.averageAccuracy;
  var value$3 = value.teacherAssignmentId;
  var value$4 = value.title;
  var value$5 = value.activities;
  return {
          __typename: value.__typename,
          id: value.id,
          chest: tmp,
          averageAccuracy: !(value$2 == null) ? value$2 : undefined,
          complete: value.complete,
          teacherAssignmentId: !(value$3 == null) ? value$3 : undefined,
          title: !(value$4 == null) ? value$4 : undefined,
          usersLocale: value.usersLocale,
          mission: parse(value.mission),
          activities: value$5.map(parse$4)
        };
}

function serialize$5(value) {
  var value$1 = value.activities;
  var activities = value$1.map(serialize$4);
  var value$2 = value.mission;
  var mission = serialize(value$2);
  var value$3 = value.usersLocale;
  var value$4 = value.title;
  var title = value$4 !== undefined ? value$4 : null;
  var value$5 = value.teacherAssignmentId;
  var teacherAssignmentId = value$5 !== undefined ? value$5 : null;
  var value$6 = value.complete;
  var value$7 = value.averageAccuracy;
  var averageAccuracy = value$7 !== undefined ? value$7 : null;
  var value$8 = value.chest;
  var chest = typeof value$8 === "object" ? value$8.VAL : (
      value$8 === "GEM" ? "GEM" : (
          value$8 === "BLANK" ? "BLANK" : (
              value$8 === "RARE" ? "RARE" : (
                  value$8 === "MYTHIC" ? "MYTHIC" : "COMMON"
                )
            )
        )
    );
  var value$9 = value.id;
  var value$10 = value.__typename;
  return {
          __typename: value$10,
          id: value$9,
          chest: chest,
          averageAccuracy: averageAccuracy,
          complete: value$6,
          teacherAssignmentId: teacherAssignmentId,
          title: title,
          usersLocale: value$3,
          mission: mission,
          activities: activities
        };
}

function verifyArgsAndParse$5(_StudentMissionFragment, value) {
  return parse$5(value);
}

function verifyName$5(param) {
  
}

var StudentMissionFragment = {
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  verifyArgsAndParse: verifyArgsAndParse$5,
  verifyName: verifyName$5
};

function activities(mission) {
  return mission.activities;
}

function findMissionByActivityId(missions, studentActivityId) {
  return Belt_Array.getBy(missions, (function (m) {
                return Belt_Array.some(m.activities, (function (a) {
                              return a.id === studentActivityId;
                            }));
              }));
}

function findMissionAndActivity(res, studentActivityId) {
  return Belt_Option.flatMap(findMissionByActivityId(res, studentActivityId), (function (m) {
                return Belt_Option.map(Belt_Array.getBy(m.activities, (function (a) {
                                  return a.id === studentActivityId;
                                })), (function (a) {
                              return [
                                      m,
                                      a
                                    ];
                            }));
              }));
}

function findActivityById(activities, id) {
  return Belt_Array.getBy(activities, (function (a) {
                return a.id === id;
              }));
}

function currentAttempt(activity) {
  return activity.currentAttempt;
}

export {
  MissionFragment ,
  ActivityFragment ,
  StudentAttemptAggregateFragment ,
  StudentActivityAttemptFragment ,
  StudentActivityFragment ,
  StudentMissionFragment ,
  activities ,
  findMissionByActivityId ,
  findMissionAndActivity ,
  findActivityById ,
  currentAttempt ,
  
}
/* query Not a pure module */
