// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as MemoPlay$LiteracyplanetClientFlags from "./MemoPlay.bs.js";

function AudioButtonApi(Props) {
  var isPlaying = Props.isPlaying;
  var src = Props.src;
  var onEnd = Props.onEnd;
  var onTogglePlay = Props.onTogglePlay;
  var children = Props.children;
  var match = React.useState(function () {
        if (isPlaying) {
          return /* Loading */0;
        } else {
          return /* Stopped */1;
        }
      });
  var setState = match[1];
  var match$1 = React.useState(function () {
        return /* NothingCalled */0;
      });
  var setEvent = match$1[1];
  var $$event = match$1[0];
  React.useEffect((function () {
          var exit = 0;
          if (isPlaying) {
            switch ($$event) {
              case /* NothingCalled */0 :
                  Curry._1(setState, (function (param) {
                          return /* Loading */0;
                        }));
                  break;
              case /* ToggleCalled */2 :
                  Curry._1(setState, (function (param) {
                          return /* Loading */0;
                        }));
                  break;
              case /* PlayingCalled */1 :
              case /* LoadedCalled */3 :
                  exit = 2;
                  break;
              case /* EndedCalled */4 :
                  exit = 1;
                  break;
              
            }
          } else if ($$event >= 2) {
            switch ($$event) {
              case /* ToggleCalled */2 :
                  Curry._1(setState, (function (param) {
                          return /* Stopped */1;
                        }));
                  break;
              case /* LoadedCalled */3 :
                  exit = 2;
                  break;
              case /* EndedCalled */4 :
                  exit = 1;
                  break;
              
            }
          } else {
            exit = 2;
          }
          switch (exit) {
            case 1 :
                Curry._1(setState, (function (param) {
                        return /* Stopped */1;
                      }));
                break;
            case 2 :
                if (isPlaying) {
                  Curry._1(setState, (function (param) {
                          return /* Playing */2;
                        }));
                } else {
                  Curry._1(setState, (function (param) {
                          return /* Stopped */1;
                        }));
                }
                break;
            
          }
          
        }), [
        isPlaying,
        $$event
      ]);
  var onPlaying = function (param) {
    return Curry._1(setEvent, (function (param) {
                  return /* PlayingCalled */1;
                }));
  };
  var onLoaded = function (param) {
    return Curry._1(setEvent, (function (param) {
                  return /* LoadedCalled */3;
                }));
  };
  var toggle = function (param) {
    Curry._1(onTogglePlay, undefined);
    return Curry._1(setEvent, (function (param) {
                  return /* ToggleCalled */2;
                }));
  };
  var ended = function (param) {
    Curry._1(onEnd, undefined);
    return Curry._1(setEvent, (function (param) {
                  return /* EndedCalled */4;
                }));
  };
  return React.createElement(React.Fragment, undefined, Curry._2(children, match[0], toggle), isPlaying && src !== undefined ? React.createElement(MemoPlay$LiteracyplanetClientFlags.make, {
                    src: src,
                    onEnd: ended,
                    onLoaded: onLoaded,
                    onPlaying: onPlaying
                  }) : null);
}

var make = AudioButtonApi;

export {
  make ,
  
}
/* react Not a pure module */
