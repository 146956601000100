// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as IconsJsx from "./icons.jsx";
import * as Audio_buttonJsx from "./audio_button.jsx";
import * as Library_componentsJsx from "./library_components.jsx";

var make = Library_componentsJsx.LeftArrowButton;

var LeftArrowButton = {
  make: make
};

var make$1 = Library_componentsJsx.RightArrowButton;

var RightArrowButton = {
  make: make$1
};

var make$2 = Library_componentsJsx.LeftBlankButton;

var LeftBlankButton = {
  make: make$2
};

var make$3 = Library_componentsJsx.RightBlankButton;

var RightBlankButton = {
  make: make$3
};

var make$4 = Library_componentsJsx.NavWrapper;

var NavWrapper = {
  make: make$4
};

var make$5 = Library_componentsJsx.StyledDocument;

var StyledDocument = {
  make: make$5
};

var make$6 = Library_componentsJsx.BackButton;

var BackButton = {
  make: make$6
};

var make$7 = Library_componentsJsx.BackButtonIcon;

var BackButtonIcon = {
  make: make$7
};

var make$8 = Library_componentsJsx.Grid;

var Grid = {
  make: make$8
};

var make$9 = Library_componentsJsx.BookThumbnail;

var BookThumbnail = {
  make: make$9
};

var make$10 = Library_componentsJsx.BookThumb;

var BookThumb = {
  make: make$10
};

var make$11 = Library_componentsJsx.BookLevel;

var BookLevel = {
  make: make$11
};

var make$12 = Audio_buttonJsx.AudioButton;

var AudioButton = {
  make: make$12
};

function LibraryComponents$NoAudioButton(Props) {
  return React.createElement("div", undefined);
}

var NoAudioButton = {
  make: LibraryComponents$NoAudioButton
};

var make$13 = IconsJsx.LeftArrowIcon;

var LeftArrowIcon = {
  make: make$13
};

var make$14 = IconsJsx.BookIcon;

var BookIcon = {
  make: make$14
};

export {
  LeftArrowButton ,
  RightArrowButton ,
  LeftBlankButton ,
  RightBlankButton ,
  NavWrapper ,
  StyledDocument ,
  BackButton ,
  BackButtonIcon ,
  Grid ,
  BookThumbnail ,
  BookThumb ,
  BookLevel ,
  AudioButton ,
  NoAudioButton ,
  LeftArrowIcon ,
  BookIcon ,
  
}
/* make Not a pure module */
