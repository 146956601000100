// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as BehaviourQuestionVisible$LiteracyplanetTupperware from "./BehaviourQuestionVisible.bs.js";
import * as BehaviourMultiChoiceContainer$LiteracyplanetTupperware from "./BehaviourMultiChoiceContainer.bs.js";

function V2MultiChoiceContainer(Props) {
  var correctAnswer = Props.correctAnswer;
  var fuzzyMarkOpt = Props.fuzzyMark;
  var questionIndex = Props.questionIndex;
  var showCorrectAnswerBehaviourOpt = Props.showCorrectAnswerBehaviour;
  var children = Props.children;
  var fuzzyMark = fuzzyMarkOpt !== undefined ? fuzzyMarkOpt : false;
  var showCorrectAnswerBehaviour = showCorrectAnswerBehaviourOpt !== undefined ? showCorrectAnswerBehaviourOpt : false;
  var answer = Belt_List.fromArray(correctAnswer);
  return React.createElement(BehaviourMultiChoiceContainer$LiteracyplanetTupperware.make, {
              correctAnswer: answer,
              fuzzyMark: fuzzyMark,
              questionIndex: questionIndex,
              showCorrectAnswerBehaviour: showCorrectAnswerBehaviour,
              children: React.createElement(BehaviourQuestionVisible$LiteracyplanetTupperware.make, {
                    questionIndex: questionIndex,
                    children: children
                  })
            });
}

var make = V2MultiChoiceContainer;

var $$default = V2MultiChoiceContainer;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
