// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function NextIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 921.7 921.3",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("g", undefined, React.createElement("path", {
                          d: "M711.2,444.3L497.7,231.5c-3.1-3-7.6-3.9-11.6-2.3c-4,1.7-6.6,5.5-6.6,9.9v157.3H212.9\n\t\t\tc-5.9,0-10.7,4.8-10.7,10.7v90.7c0,5.9,4.8,10.7,10.7,10.7h266.7v156.9c0,4.3,2.6,8.2,6.6,9.9c1.3,0.6,2.7,0.8,4.1,0.8\n\t\t\tc2.8,0,5.5-1.1,7.6-3.1l213.5-213.4c2-2,3.1-4.7,3.1-7.6C714.4,449,713.2,446.3,711.2,444.3z"
                        }))));
}

var make = NextIcon;

export {
  make ,
  
}
/* react Not a pure module */
