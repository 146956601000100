// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as React from "react";
import * as Caml_obj from "../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "./UserContext.bs.js";

var partial_arg = localStorage;

function setItem(param, param$1) {
  partial_arg.setItem(param, param$1);
  
}

var partial_arg$1 = localStorage;

function getItem(param) {
  return Caml_option.null_to_opt(partial_arg$1.getItem(param));
}

var latestKey = "latest";

var lastAssociationKey = "lastAssociation";

function makeKey(nameSpace, key) {
  return nameSpace + (":" + key);
}

function readLast(namespace) {
  return getItem(makeKey(namespace, latestKey));
}

function setLatest(namespace, latestId) {
  return setItem(makeKey(namespace, latestKey), latestId);
}

function seialiseAssociations(associations) {
  return JSON.stringify(Json_encode.object_({
                  hd: [
                    "associations",
                    Json_encode.array((function (param) {
                            return Json_encode.tuple2((function (prim) {
                                          return prim;
                                        }), (function (prim) {
                                          return prim;
                                        }), param);
                          }), associations)
                  ],
                  tl: /* [] */0
                }));
}

function deserialiseAssociations(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("associations", (function (param) {
                              return Json_decode.array((function (param) {
                                            return Json_decode.tuple2(Json_decode.string, Json_decode.string, param);
                                          }), param);
                            }), param);
              }), json);
}

function readAssociations(namespace) {
  return Belt_Option.flatMap(Belt_Option.flatMap(getItem(makeKey(namespace, lastAssociationKey)), Json.parse), deserialiseAssociations);
}

function setAssociations(associationsCache, namespace) {
  return setItem(makeKey(namespace, lastAssociationKey), seialiseAssociations(associationsCache));
}

function inCache(cache, latestId) {
  if (cache !== undefined) {
    if (cache === latestId) {
      return /* CacheMatch */2;
    } else {
      return /* NoCacheMatch */1;
    }
  } else {
    return /* NoCacheSet */0;
  }
}

function matches(a, f) {
  return Belt_Option.isSome(Belt_Array.getBy(a, f));
}

function latestMatches(associations, latestId) {
  return Belt_Option.isSome(Belt_Array.getBy(associations, (function (param) {
                    return Caml_obj.caml_equal(param[0], latestId);
                  })));
}

function associationMatches(associations, associationId) {
  return Belt_Option.isSome(Belt_Array.getBy(associations, (function (param) {
                    return Caml_obj.caml_equal(param[1], associationId);
                  })));
}

function associationInCache(cache, latestId, associationId) {
  if (cache === undefined) {
    return /* NoCacheSet */0;
  }
  var match = latestMatches(cache, latestId);
  var match$1 = associationMatches(cache, associationId);
  if (match) {
    if (match$1) {
      return /* CacheMatch */2;
    } else {
      return /* NoCacheMatch */1;
    }
  } else {
    return /* NoCacheSet */0;
  }
}

function updateAssociations(associationsCache, latestId, associationId) {
  if (associationsCache === undefined) {
    return [[
              latestId,
              associationId
            ]];
  }
  var param = Belt_Array.reduce(associationsCache, [
        [],
        false
      ], (function (param, param$1) {
          var savedlatestId = param$1[0];
          var matched = savedlatestId === latestId;
          var pair = Belt_Array.concat(param[0], [matched ? [
                    latestId,
                    associationId
                  ] : [
                    savedlatestId,
                    param$1[1]
                  ]]);
          return [
                  pair,
                  matched ? true : param[1]
                ];
        }));
  var associations = param[0];
  if (param[1]) {
    return associations;
  } else {
    return Belt_Array.concat(associations, [[
                  latestId,
                  associationId
                ]]);
  }
}

function useLatest(userId, namespace) {
  var ns = userId + namespace;
  var latestCache = getItem(makeKey(ns, latestKey));
  var saveLatest = function (id) {
    return setLatest(ns, id);
  };
  var inLatestCache = function (id) {
    return inCache(latestCache, id);
  };
  return [
          inLatestCache,
          saveLatest
        ];
}

function useAssociation(userId, namespace) {
  var ns = userId + namespace;
  var associationsCache = readAssociations(ns);
  var saveAssociation = function (id, associationId) {
    return setAssociations(updateAssociations(associationsCache, id, associationId), ns);
  };
  var inAssociationCache = function (id, associationId) {
    return associationInCache(associationsCache, id, associationId);
  };
  return [
          inAssociationCache,
          saveAssociation
        ];
}

function useUserContext(namespace) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var match = useLatest(userId, namespace);
  var match$1 = useAssociation(userId, namespace);
  return [
          match[0],
          match[1],
          match$1[0],
          match$1[1]
        ];
}

function useValue(userId, namespace) {
  var ns = userId + namespace;
  var optValue = React.useMemo((function () {
          return getItem(makeKey(ns, latestKey));
        }), []);
  var setValue = function (value) {
    return setLatest(ns, value);
  };
  return [
          optValue,
          setValue
        ];
}

export {
  setItem ,
  getItem ,
  latestKey ,
  lastAssociationKey ,
  makeKey ,
  readLast ,
  setLatest ,
  seialiseAssociations ,
  deserialiseAssociations ,
  readAssociations ,
  setAssociations ,
  inCache ,
  matches ,
  latestMatches ,
  associationMatches ,
  associationInCache ,
  updateAssociations ,
  useLatest ,
  useAssociation ,
  useUserContext ,
  useValue ,
  
}
/* partial_arg Not a pure module */
