// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as CobraQuestionResponse$LiteracyplanetClientLibsActivity from "./CobraQuestionResponse.bs.js";

var CurrentStudentEmpty = {};

var CurrentStudent = {};

var ResultsApi = {};

var ResultsLauncherProps = {};

var $$Audio = {};

var Asset = {};

var Vocal = {};

var WordGameContent = {};

var WordGameContent_WordGameData = {};

var WordGameContent_SoundTrainData = {};

var WordGameContent_WhackAMunkData = {};

var WordGameContent_MonsterChefData = {};

var WordGameContent_InternalStaticData = {};

var WordGameContent_UnsafeData = {};

var ActivityLauncherProps = {
  $$Audio: $$Audio,
  Asset: Asset,
  Vocal: Vocal,
  WordGameContent: WordGameContent,
  WordGameContent_WordGameData: WordGameContent_WordGameData,
  WordGameContent_SoundTrainData: WordGameContent_SoundTrainData,
  WordGameContent_WhackAMunkData: WordGameContent_WhackAMunkData,
  WordGameContent_MonsterChefData: WordGameContent_MonsterChefData,
  WordGameContent_InternalStaticData: WordGameContent_InternalStaticData,
  WordGameContent_UnsafeData: WordGameContent_UnsafeData
};

var ActivityLauncherApi = {};

function api(onActivityCompleted, onActivityPaused, onActivityReachedEnd, onActivityReady, onActivityResumed, onActivityStarted, onExit, onFailed, onQuestionFailed, onQuestionPassed, onQuestionStarted, onBatchQuestionsAnswered, onPreloaderReady) {
  return {
          onActivityCompleted: onActivityCompleted,
          onActivityPaused: onActivityPaused,
          onActivityReachedEnd: onActivityReachedEnd,
          onActivityReady: onActivityReady,
          onActivityResumed: onActivityResumed,
          onActivityStarted: onActivityStarted,
          onExit: onExit,
          onFailed: onFailed,
          onQuestionFailed: (function (input) {
              return Curry._1(onQuestionFailed, Curry._2(CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.Decode.make, input, false));
            }),
          onQuestionPassed: (function (input) {
              return Curry._1(onQuestionPassed, Curry._2(CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.Decode.make, input, true));
            }),
          onQuestionStarted: onQuestionStarted,
          onBatchQuestionsAnswered: (function (input) {
              return Curry._1(onBatchQuestionsAnswered, Curry._1(CobraQuestionResponse$LiteracyplanetClientLibsActivity.Batch.Decode.make, input));
            }),
          onPreloaderReady: onPreloaderReady
        };
}

var CobraKindDoesntMatchFlipperTypes = /* @__PURE__ */Caml_exceptions.create("FlipperTypes-LiteracyplanetClientLibsActivity.Cobra.CobraKindDoesntMatchFlipperTypes");

function validate(param, props) {
  switch (param.cobraActivityProps) {
    case /* WordGameContent_WordGameData */0 :
        if (props.TAG === /* WordListData */0) {
          return {
                  TAG: /* Ok */0,
                  _0: props
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case /* WordGameContent_SoundTrainData */1 :
        if (props.TAG === /* SoundTrainData */1) {
          return {
                  TAG: /* Ok */0,
                  _0: props
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case /* WordGameContent_WhackAMunkData */2 :
        if (props.TAG === /* WhackAMunkData */2) {
          return {
                  TAG: /* Ok */0,
                  _0: props
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case /* WordGameContent_MonsterChefData */3 :
        if (props.TAG === /* MonsterChefData */3) {
          return {
                  TAG: /* Ok */0,
                  _0: props
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case /* WordGameContent_InternalStaticData */4 :
        if (props.TAG === /* InernalStaticData */4) {
          return {
                  TAG: /* Ok */0,
                  _0: props
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    case /* WordGameContent_UnsafeData */5 :
        if (props.TAG === /* UnsafeData */5) {
          return {
                  TAG: /* Ok */0,
                  _0: props
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: {
                    RE_EXN_ID: CobraKindDoesntMatchFlipperTypes
                  }
                };
        }
    
  }
}

var Cobra = {
  CurrentStudentEmpty: CurrentStudentEmpty,
  CurrentStudent: CurrentStudent,
  ResultsApi: ResultsApi,
  ResultsLauncherProps: ResultsLauncherProps,
  ActivityLauncherProps: ActivityLauncherProps,
  ActivityLauncherApi: ActivityLauncherApi,
  api: api,
  CobraKindDoesntMatchFlipperTypes: CobraKindDoesntMatchFlipperTypes,
  validate: validate
};

export {
  Cobra ,
  
}
/* No side effect */
