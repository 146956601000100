// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mobile_utils from "./mobile_utils";

function isAndroid(prim) {
  return Mobile_utils.isAndroid();
}

function signOut(prim) {
  Mobile_utils.signOut();
  
}

function openWordMania(prim) {
  Mobile_utils.openWordMania();
  
}

export {
  isAndroid ,
  signOut ,
  openWordMania ,
  
}
/* ./mobile_utils Not a pure module */
