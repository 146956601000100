// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Luxon from "luxon";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphQL_PPX from "../../../../node_modules/@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as FlagsContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/FlagsContext.bs.js";
import * as Storyverse$LiteracyplanetClientSxEntry from "./Storyverse.bs.js";
import * as AvatarFragments$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/AvatarFragments.bs.js";
import * as CobraAvatarType$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/CobraAvatarType.bs.js";
import * as JourneyCurrentStudent$LiteracyplanetClientSxEntry from "./JourneyCurrentStudent.bs.js";
import * as RequestMissionsMutation$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/RequestMissionsMutation.bs.js";
import * as JourneyMissionsSubscription$LiteracyplanetClientSxEntry from "./JourneyMissionsSubscription.bs.js";

var Raw = {};

var query = Client.gql([
      "fragment FriendsAvatarFragment on FriendsAvatar   {\n__typename  \nuserId  \navatar  {\n...PlayerEquippedFragment   \n}\n\n}\n",
      ""
    ], AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.query);

function parse(value) {
  var value$1 = value.avatar;
  return {
          __typename: value.__typename,
          userId: value.userId,
          avatar: !(value$1 == null) ? AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.verifyArgsAndParse("PlayerEquippedFragment", value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.avatar;
  var avatar = value$1 !== undefined ? AvatarFragments$LiteracyplanetClientFlags.PlayerEquippedFragment.serialize(value$1) : null;
  var value$2 = value.userId;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          userId: value$2,
          avatar: avatar
        };
}

function verifyArgsAndParse(_FriendsAvatarFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var FriendsAvatarFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = Client.gql(["fragment StudentTeacherFragment on StudentTeacher   {\n__typename  \nnextUserId  \ntitle  \nfamilyName  \n}\n"]);

function parse$1(value) {
  var value$1 = value.nextUserId;
  var value$2 = value.title;
  var value$3 = value.familyName;
  return {
          __typename: value.__typename,
          nextUserId: !(value$1 == null) ? value$1 : undefined,
          title: !(value$2 == null) ? value$2 : undefined,
          familyName: !(value$3 == null) ? value$3 : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.familyName;
  var familyName = value$1 !== undefined ? value$1 : null;
  var value$2 = value.title;
  var title = value$2 !== undefined ? value$2 : null;
  var value$3 = value.nextUserId;
  var nextUserId = value$3 !== undefined ? value$3 : null;
  var value$4 = value.__typename;
  return {
          __typename: value$4,
          nextUserId: nextUserId,
          title: title,
          familyName: familyName
        };
}

function verifyArgsAndParse$1(_StudentTeacherFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var StudentTeacherFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

var query$2 = Client.gql([
      "fragment EnrolleeFragment on Enrollee   {\n__typename  \nteachers  {\n...StudentTeacherFragment   \n}\n\n}\n",
      ""
    ], query$1);

function parse$2(value) {
  var value$1 = value.teachers;
  return {
          __typename: value.__typename,
          teachers: value$1.map(parse$1)
        };
}

function serialize$2(value) {
  var value$1 = value.teachers;
  var teachers = value$1.map(serialize$1);
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          teachers: teachers
        };
}

function verifyArgsAndParse$2(_EnrolleeFragment, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var EnrolleeFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

var Raw$3 = {};

var query$3 = Client.gql([
      "query JourneyMissionsQuery($userId: String!, $avatarUserIds: [String!]!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \n...JourneyCurrentStudentFragment   \nenrollee  {\n...EnrolleeFragment   \n}\n\nfriendsAvatars(userIds: $avatarUserIds)  {\n...FriendsAvatarFragment   \n}\n\nincompleteMissions: incompleteVisibleMissionsNonOptional  {\n...JourneyStudentMissionFragment   \n}\n\ncompletedMissions: pagedCompletedMissionsNonOptional(page: 1)  {\n...JourneyStudentMissionFragment   \n}\n\n}\n\n}\n",
      "",
      "",
      "",
      ""
    ], query$2, query, JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.query, JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.query);

function parse$3(value) {
  var value$1 = value.currentStudent;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["enrollee"];
    var value$3 = value$1["friendsAvatars"];
    var value$4 = value$1["incompleteMissions"];
    var value$5 = value$1["completedMissions"];
    tmp = {
      __typename: value$1["__typename"],
      journeyCurrentStudentFragment: JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.verifyArgsAndParse("JourneyCurrentStudentFragment", value$1),
      enrollee: !(value$2 == null) ? parse$2(value$2) : undefined,
      friendsAvatars: value$3.map(parse),
      incompleteMissions: value$4.map(function (value) {
            return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.verifyArgsAndParse("JourneyStudentMissionFragment", value);
          }),
      completedMissions: value$5.map(function (value) {
            return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.verifyArgsAndParse("JourneyStudentMissionFragment", value);
          })
    };
  }
  return {
          currentStudent: tmp
        };
}

function serialize$3(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.completedMissions;
    var completedMissions = value$2.map(function (value) {
          return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.serialize(value);
        });
    var value$3 = value$1.incompleteMissions;
    var incompleteMissions = value$3.map(function (value) {
          return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.serialize(value);
        });
    var value$4 = value$1.friendsAvatars;
    var friendsAvatars = value$4.map(serialize);
    var value$5 = value$1.enrollee;
    var enrollee = value$5 !== undefined ? serialize$2(value$5) : null;
    var value$6 = value$1.__typename;
    currentStudent = [JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.serialize(value$1.journeyCurrentStudentFragment)].reduce(GraphQL_PPX.deepMerge, {
          __typename: value$6,
          enrollee: enrollee,
          friendsAvatars: friendsAvatars,
          incompleteMissions: incompleteMissions,
          completedMissions: completedMissions
        });
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  var a = inp.avatarUserIds;
  return {
          userId: inp.userId,
          avatarUserIds: a.map(function (b) {
                return b;
              })
        };
}

function makeVariables(userId, avatarUserIds, param) {
  return {
          userId: userId,
          avatarUserIds: avatarUserIds
        };
}

var GetMissions_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetMissions_refetchQueryDescription = include.refetchQueryDescription;

var GetMissions_useLazy = include.useLazy;

var GetMissions_useLazyWithVariables = include.useLazyWithVariables;

var GetMissions = {
  GetMissions_inner: GetMissions_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: GetMissions_refetchQueryDescription,
  use: use,
  useLazy: GetMissions_useLazy,
  useLazyWithVariables: GetMissions_useLazyWithVariables
};

var defaultMissionTitle = "";

function makeDueDate(scalarDate) {
  return Caml_option.nullable_to_opt(Luxon.DateTime.fromISO(Json_decode.date(scalarDate).toISOString()).toRelative());
}

function makeTeacherName(enrollee, userId) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.map(enrollee, (function (enrollee) {
                        return enrollee.teachers;
                      })), (function (teachers) {
                    return Belt_Array.getBy(teachers, (function (t) {
                                  return t.nextUserId === userId;
                                }));
                  })), (function (t) {
                var match = t.title;
                var match$1 = t.familyName;
                if (match !== undefined) {
                  if (match$1 !== undefined) {
                    return match + " " + match$1;
                  } else {
                    return ;
                  }
                } else if (match$1 !== undefined) {
                  return match$1;
                } else {
                  return ;
                }
              }));
}

function makeMission(hasAssigned, makeTeacherName, m) {
  return {
          missionId: String(m.id),
          missionTitle: Belt_Option.getWithDefault(m.title, defaultMissionTitle),
          strand: m.mission.strand,
          chestRarity: JourneyCurrentStudent$LiteracyplanetClientSxEntry.chestToString(m.chest),
          locked: hasAssigned ? Belt_Option.mapWithDefault(m.teacherAssignmentId, true, (function (param) {
                    return false;
                  })) : false,
          averageAccuracy: m.averageAccuracy,
          teacherAssigned: Belt_Option.isSome(m.teacherAssignmentId),
          teacherName: Curry._1(makeTeacherName, m.teacherAssignmentId),
          dueDate: Belt_Option.flatMap(m.expireAt, makeDueDate)
        };
}

function filter(prim0, prim1) {
  return prim0.filter(Curry.__1(prim1));
}

function pushLockedToFront(missions) {
  var locked = missions.filter(function (m) {
        return m.locked;
      });
  var unlocked = missions.filter(function (m) {
        return !m.locked;
      });
  return Belt_Array.concat(unlocked, locked);
}

function makeMissions(missions, makeTeacherName, hasAssigned) {
  return Belt_Array.map(missions, (function (param) {
                return makeMission(hasAssigned, makeTeacherName, param);
              }));
}

function makeOptionalExtraUser(avatar, userId) {
  return Belt_Option.map(avatar, (function (avatar) {
                return {
                        userId: userId,
                        avatar: CobraAvatarType$LiteracyplanetClientFlags.makeAvatar(avatar),
                        country: ""
                      };
              }));
}

function makeFriendsAvatars(avatars) {
  return Belt_Array.keepMap(Belt_Array.map(avatars, (function (friend) {
                    return makeOptionalExtraUser(friend.avatar, friend.userId);
                  })), (function (i) {
                return i;
              }));
}

function hasAssigned(studentMissions) {
  return Belt_Array.some(studentMissions, (function (sm) {
                return Belt_Option.isSome(sm.teacherAssignmentId);
              }));
}

function makeProps(response) {
  return Belt_Option.map(response.currentStudent, (function (s) {
                var partial_arg = s.enrollee;
                var partial_arg$1 = s.enrollee;
                return {
                        currentStudent: JourneyCurrentStudent$LiteracyplanetClientSxEntry.makeCurrentStudent(s.journeyCurrentStudentFragment),
                        extraUsers: makeFriendsAvatars(s.friendsAvatars),
                        completedMissions: makeMissions(s.completedMissions, (function (param) {
                                return makeTeacherName(partial_arg, param);
                              }), false),
                        incompleteMissions: pushLockedToFront(makeMissions(s.incompleteMissions, (function (param) {
                                    return makeTeacherName(partial_arg$1, param);
                                  }), hasAssigned(s.incompleteMissions))),
                        hasDiag: false,
                        progressData: undefined
                      };
              }));
}

var avatarUserIds = [
  "1b6211cd-021b-4393-b0a8-82d6c9609e81",
  "eece34bb-2aa4-4754-aae6-2237d56e421f",
  "681cdd96-d9e8-4ba7-9c15-2209911da92c"
];

function onLoading(param) {
  return /* LoadingMissions */0;
}

function onResult(query) {
  return Belt_Result.flatMap(query, (function (response) {
                var props = makeProps(response);
                if (props !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: /* MissionProps */{
                            _0: props
                          }
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* ErrorWithMessage */4,
                            _0: "JourneyMissionsError",
                            _1: "error in makeProps"
                          }
                        };
                }
              }));
}

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw$3,
      use: use
    });

function useMissionQuery(userId) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              userId: userId,
              avatarUserIds: avatarUserIds
            });
}

function hasLocked(missions) {
  return Belt_Array.some(missions, (function (m) {
                return m.locked;
              }));
}

function appendMissions(previous, newest) {
  var newesetHasBeenAssigned = hasAssigned(newest);
  var wasLocked = Belt_Array.some(previous, (function (m) {
          return m.locked;
        }));
  var old = newesetHasBeenAssigned ? Belt_Array.map(previous, (function (m) {
            return {
                    missionId: m.missionId,
                    missionTitle: m.missionTitle,
                    strand: m.strand,
                    chestRarity: m.chestRarity,
                    locked: wasLocked ? m.locked : true,
                    averageAccuracy: m.averageAccuracy,
                    teacherAssigned: m.teacherAssigned,
                    teacherName: m.teacherName,
                    dueDate: m.dueDate
                  };
          })) : previous;
  return pushLockedToFront(Belt_Array.concat(old, Belt_Array.keepMap(makeMissions(newest, (function (param) {
                            
                          }), newesetHasBeenAssigned), (function (cur) {
                        if (Belt_Array.getBy(old, (function (old) {
                                  return old.missionId === cur.missionId;
                                })) === undefined) {
                          return Caml_option.some(cur);
                        }
                        
                      }))));
}

function mergeAsyncMissions(studentHasStoryverse, progressData, missionStates, subscription) {
  if (studentHasStoryverse) {
    if (progressData === undefined) {
      return /* LoadingMissions */0;
    }
    if (!missionStates) {
      return /* LoadingMissions */0;
    }
    var props = missionStates._0;
    if (subscription !== undefined) {
      return /* MissionProps */{
              _0: {
                currentStudent: props.currentStudent,
                extraUsers: props.extraUsers,
                completedMissions: props.completedMissions,
                incompleteMissions: appendMissions(props.incompleteMissions, subscription),
                hasDiag: props.hasDiag,
                progressData: progressData
              }
            };
    } else {
      return /* MissionProps */{
              _0: {
                currentStudent: props.currentStudent,
                extraUsers: props.extraUsers,
                completedMissions: props.completedMissions,
                incompleteMissions: props.incompleteMissions,
                hasDiag: props.hasDiag,
                progressData: progressData
              }
            };
    }
  }
  if (!missionStates) {
    return /* LoadingMissions */0;
  }
  var props$1 = missionStates._0;
  if (subscription !== undefined) {
    return /* MissionProps */{
            _0: {
              currentStudent: props$1.currentStudent,
              extraUsers: props$1.extraUsers,
              completedMissions: props$1.completedMissions,
              incompleteMissions: appendMissions(props$1.incompleteMissions, subscription),
              hasDiag: props$1.hasDiag,
              progressData: props$1.progressData
            }
          };
  } else {
    return /* MissionProps */{
            _0: props$1
          };
  }
}

function useAsyncMissions(userId) {
  var progressData = Curry._1(Storyverse$LiteracyplanetClientSxEntry.use, userId);
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var studentHasStoryverse = Curry._1(hasFlag, "StudentHasStoryverse");
  var queriedMissionStates = useMissionQuery(userId);
  var subscription = JourneyMissionsSubscription$LiteracyplanetClientSxEntry.useSubscription(userId);
  return mergeAsyncMissions(studentHasStoryverse, progressData, queriedMissionStates, subscription);
}

function hasMissions(x) {
  if (x) {
    return [
            false,
            x._0.incompleteMissions.length === 0
          ];
  } else {
    return [
            true,
            false
          ];
  }
}

function useCurrentUser(param) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var startingPoint = user.startingPoint;
  var missionStates = useAsyncMissions(userId);
  var match = hasMissions(missionStates);
  RequestMissionsMutation$LiteracyplanetClientFlags.useRequestMissionsHook(userId, startingPoint, match[0], match[1]);
  return missionStates;
}

var JourneyCurrentStudentFragment;

var JourneyStudentMissionFragment;

var PlayerEquippedFragment;

export {
  JourneyCurrentStudentFragment ,
  JourneyStudentMissionFragment ,
  PlayerEquippedFragment ,
  FriendsAvatarFragment ,
  StudentTeacherFragment ,
  EnrolleeFragment ,
  GetMissions ,
  defaultMissionTitle ,
  makeDueDate ,
  makeTeacherName ,
  makeMission ,
  filter ,
  pushLockedToFront ,
  makeMissions ,
  makeOptionalExtraUser ,
  makeFriendsAvatars ,
  hasAssigned ,
  makeProps ,
  avatarUserIds ,
  onLoading ,
  onResult ,
  Q ,
  useMissionQuery ,
  hasLocked ,
  appendMissions ,
  mergeAsyncMissions ,
  useAsyncMissions ,
  hasMissions ,
  useCurrentUser ,
  
}
/* query Not a pure module */
