// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.bs.js";
import * as BubbleStyles$LiteracyplanetClientSxEntry from "./BubbleStyles.bs.js";
import * as JourneyHeaderIcons$LiteracyplanetClientSxEntry from "./JourneyHeaderIcons.bs.js";
import * as JourneyHeaderStyles$LiteracyplanetClientSxEntry from "./JourneyHeaderStyles.bs.js";

function BubbleComponents$HeaderPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderPanel.className
            }, children);
}

var HeaderPanel = {
  make: BubbleComponents$HeaderPanel
};

function BubbleComponents$HeaderSubPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderSubPanel.className
            }, children);
}

var HeaderSubPanel = {
  make: BubbleComponents$HeaderSubPanel
};

function BubbleComponents$HeaderSubSubPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderSubSubPanel.className
            }, children);
}

var HeaderSubSubPanel = {
  make: BubbleComponents$HeaderSubSubPanel
};

function BubbleComponents$HeaderBar(Props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderBar.className
            });
}

var HeaderBar = {
  make: BubbleComponents$HeaderBar
};

function BubbleComponents$HeaderList(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderList.className
            }, children);
}

var HeaderList = {
  make: BubbleComponents$HeaderList
};

function BubbleComponents$HeaderItem(Props) {
  var children = Props.children;
  var large = Props.large;
  var highlightColor = Props.highlightColor;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderItem.className(large, highlightColor),
              onClick: onClick
            }, children);
}

var HeaderItem = {
  make: BubbleComponents$HeaderItem
};

function BubbleComponents$MissionText(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionText.className
            }, children);
}

var MissionText = {
  make: BubbleComponents$MissionText
};

function BubbleComponents$PlanetIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Planet.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var PlanetIcon = {
  make: BubbleComponents$PlanetIcon
};

function BubbleComponents$ChestIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Chest.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var ChestIcon = {
  make: BubbleComponents$ChestIcon
};

function BubbleComponents$AvatarIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Avatar.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var AvatarIcon = {
  make: BubbleComponents$AvatarIcon
};

function BubbleComponents$LetterAIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Lettera.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var LetterAIcon = {
  make: BubbleComponents$LetterAIcon
};

function BubbleComponents$ArcadeIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Arcade.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var ArcadeIcon = {
  make: BubbleComponents$ArcadeIcon
};

function BubbleComponents$BookIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Book.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var BookIcon = {
  make: BubbleComponents$BookIcon
};

function BubbleComponents$MyWordsIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.MyWords.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var MyWordsIcon = {
  make: BubbleComponents$MyWordsIcon
};

function BubbleComponents$MagazineIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Magazine.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var MagazineIcon = {
  make: BubbleComponents$MagazineIcon
};

function BubbleComponents$LibraryIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Library.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.MissionIcon.className
            });
}

var LibraryIcon = {
  make: BubbleComponents$LibraryIcon
};

function BubbleComponents$LockSmallIcon(Props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.LockSmallIcon.make, {
              className: BubbleStyles$LiteracyplanetClientSxEntry.LockSmallIcon.className
            });
}

var LockSmallIcon = {
  make: BubbleComponents$LockSmallIcon
};

function BubbleComponents$HeaderLockOverlay(Props) {
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.HeaderLockOverlay.className
            }, React.createElement(BubbleComponents$LockSmallIcon, {}));
}

var HeaderLockOverlay = {
  make: BubbleComponents$HeaderLockOverlay
};

function BubbleComponents$JourneyHeaderItem(Props) {
  var large = Props.large;
  var onClick = Props.onClick;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.planetColor,
              onClick: onClick
            }, React.createElement(BubbleComponents$PlanetIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Journey")
                }));
}

var JourneyHeaderItem = {
  make: BubbleComponents$JourneyHeaderItem
};

function BubbleComponents$CollectionsHeaderItem(Props) {
  var large = Props.large;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.chestColor,
              onClick: onClick
            }, React.createElement(BubbleComponents$ChestIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Collections")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var CollectionsHeaderItem = {
  make: BubbleComponents$CollectionsHeaderItem
};

function BubbleComponents$AvatarHeaderItem(Props) {
  var large = Props.large;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.avatarColor,
              onClick: onClick
            }, React.createElement(BubbleComponents$AvatarIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Shop")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var AvatarHeaderItem = {
  make: BubbleComponents$AvatarHeaderItem
};

function BubbleComponents$IntrepizineHeaderItem(Props) {
  var large = Props.large;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.avatarColor,
              onClick: onClick
            }, React.createElement(BubbleComponents$MagazineIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Intrepizine")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var IntrepizineHeaderItem = {
  make: BubbleComponents$IntrepizineHeaderItem
};

function BubbleComponents$ArcadeHeaderItem(Props) {
  var large = Props.large;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.letteraColor,
              onClick: onClick
            }, React.createElement(BubbleComponents$ArcadeIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Arcade")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var ArcadeHeaderItem = {
  make: BubbleComponents$ArcadeHeaderItem
};

function BubbleComponents$MyWordsHeaderItem(Props) {
  var large = Props.large;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.chestColor,
              onClick: onClick
            }, React.createElement(BubbleComponents$MyWordsIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("My Words")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var MyWordsHeaderItem = {
  make: BubbleComponents$MyWordsHeaderItem
};

function BubbleComponents$DropdownHeaderPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.DropdownHeaderPanel.className
            }, children);
}

var DropdownHeaderPanel = {
  make: BubbleComponents$DropdownHeaderPanel
};

function BubbleComponents$DropdownHeaderItem(Props) {
  var children = Props.children;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: BubbleStyles$LiteracyplanetClientSxEntry.DropdownHeaderItem.className,
              onClick: onClick
            }, children);
}

var DropdownHeaderItem = {
  make: BubbleComponents$DropdownHeaderItem
};

function BubbleComponents$BooksDropdownItem(Props) {
  var onClick = Props.onClick;
  return React.createElement(BubbleComponents$DropdownHeaderItem, {
              children: null,
              onClick: onClick
            }, React.createElement(BubbleComponents$BookIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Books")
                }));
}

var BooksDropdownItem = {
  make: BubbleComponents$BooksDropdownItem
};

function BubbleComponents$IntrepizineDropdownItem(Props) {
  var onClick = Props.onClick;
  return React.createElement(BubbleComponents$DropdownHeaderItem, {
              children: null,
              onClick: onClick
            }, React.createElement(BubbleComponents$MagazineIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Intrepizine")
                }));
}

var IntrepizineDropdownItem = {
  make: BubbleComponents$IntrepizineDropdownItem
};

function BubbleComponents$LibraryClosedHeaderItem(Props) {
  var large = Props.large;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(BubbleComponents$HeaderItem, {
              children: null,
              large: large,
              highlightColor: JourneyHeaderStyles$LiteracyplanetClientSxEntry.bookColor,
              onClick: onClick
            }, React.createElement(BubbleComponents$LibraryIcon, {}), React.createElement(BubbleComponents$MissionText, {
                  children: BubbleStyles$LiteracyplanetClientSxEntry.s("Library")
                }), lock ? React.createElement(BubbleComponents$HeaderLockOverlay, {}) : null);
}

var LibraryClosedHeaderItem = {
  make: BubbleComponents$LibraryClosedHeaderItem
};

function BubbleComponents$LibraryOpenDropdown(Props) {
  var onClickLibrary = Props.onClickLibrary;
  var onClickIntrepizine = Props.onClickIntrepizine;
  return React.createElement(BubbleComponents$DropdownHeaderPanel, {
              children: null
            }, React.createElement("hr", {
                  className: BubbleStyles$LiteracyplanetClientSxEntry.divider
                }), React.createElement(BubbleComponents$BooksDropdownItem, {
                  onClick: onClickLibrary
                }), React.createElement("hr", {
                  className: BubbleStyles$LiteracyplanetClientSxEntry.divider
                }), React.createElement(BubbleComponents$IntrepizineDropdownItem, {
                  onClick: onClickIntrepizine
                }));
}

var LibraryOpenDropdown = {
  make: BubbleComponents$LibraryOpenDropdown
};

function BubbleComponents$LibraryHeaderItem(Props) {
  var lockLibrary = Props.lockLibrary;
  var highlightLibrary = Props.highlightLibrary;
  var onClickIntrepizine = Props.onClickIntrepizine;
  var onClickLibrary = Props.onClickLibrary;
  var subnavOpen = Props.subnavOpen;
  var closeSubnav = Props.closeSubnav;
  var openSubnav = Props.openSubnav;
  if (lockLibrary) {
    return React.createElement(BubbleComponents$LibraryClosedHeaderItem, {
                large: highlightLibrary,
                onClick: (function (param) {
                    return Curry._1(onClickIntrepizine, undefined);
                  }),
                lock: lockLibrary
              });
  } else if (subnavOpen) {
    return React.createElement("div", undefined, React.createElement(BubbleComponents$LibraryClosedHeaderItem, {
                    large: highlightLibrary,
                    onClick: (function (param) {
                        return Curry._1(closeSubnav, undefined);
                      }),
                    lock: lockLibrary
                  }), React.createElement(BubbleComponents$LibraryOpenDropdown, {
                    onClickLibrary: (function (param) {
                        Curry._1(onClickLibrary, undefined);
                        return Curry._1(closeSubnav, undefined);
                      }),
                    onClickIntrepizine: (function (param) {
                        Curry._1(onClickIntrepizine, undefined);
                        return Curry._1(closeSubnav, undefined);
                      })
                  }));
  } else {
    return React.createElement(BubbleComponents$LibraryClosedHeaderItem, {
                large: highlightLibrary,
                onClick: (function (param) {
                    return Curry._1(openSubnav, undefined);
                  }),
                lock: lockLibrary
              });
  }
}

var LibraryHeaderItem = {
  make: BubbleComponents$LibraryHeaderItem
};

export {
  HeaderPanel ,
  HeaderSubPanel ,
  HeaderSubSubPanel ,
  HeaderBar ,
  HeaderList ,
  HeaderItem ,
  MissionText ,
  PlanetIcon ,
  ChestIcon ,
  AvatarIcon ,
  LetterAIcon ,
  ArcadeIcon ,
  BookIcon ,
  MyWordsIcon ,
  MagazineIcon ,
  LibraryIcon ,
  LockSmallIcon ,
  HeaderLockOverlay ,
  JourneyHeaderItem ,
  CollectionsHeaderItem ,
  AvatarHeaderItem ,
  IntrepizineHeaderItem ,
  ArcadeHeaderItem ,
  MyWordsHeaderItem ,
  DropdownHeaderPanel ,
  DropdownHeaderItem ,
  BooksDropdownItem ,
  IntrepizineDropdownItem ,
  LibraryClosedHeaderItem ,
  LibraryOpenDropdown ,
  LibraryHeaderItem ,
  
}
/* react Not a pure module */
