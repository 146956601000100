import {openIdUserManager} from '@literacyplanet/client_core';

export const signOut = () => {
  if (process.env.MOBILE) {
    openIdUserManager.removeUser().then(() =>
      signIn()
    );
  } else {
    openIdUserManager.storeUser().then(() =>
      window.location.href = process.env.CLASSIC_LOGOUT_LINK
    );
  }
};

export const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return false;
  }

  if (/android/i.test(userAgent)) {
    return true;
  }

  return false;
};

export const openWordMania = () => {
  window.location.href = getWordManiaUrl();
};

const getWordManiaUrl = () => {
  if (process.env.MOBILE) {
    return isAndroid()
      ? process.env.WORD_MANIA_MOBILE_ANDROID_URL
      : process.env.WORD_MANIA_MOBILE_IOS_URL;
  } else {
    return `${process.env.LP_URL}/wordmania_competition`;
  }
};

/**
 * Sign in using iOS or Android native app
 */
const signIn = () => {
  // iOS
  if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.signInMessageHandler.postMessage({
      param1: 'Signing in',
      param2: '1000'
    });
  }

  // Android
  if (typeof bridge !== 'undefined') {
    bridge.signInMessageHandler('Signing in');
  }
};
