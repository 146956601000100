// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";

function useDimensionsWithUpdate(param) {
  var ref = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setDimensions = match[1];
  var updateDimensions = function (param) {
    Belt_Option.map(Caml_option.nullable_to_opt(ref.current), (function (el) {
            return Curry._1(setDimensions, (function (param) {
                          return el.getBoundingClientRect();
                        }));
          }));
    
  };
  React.useLayoutEffect((function () {
          window.addEventListener("resize", updateDimensions);
          updateDimensions(undefined);
          return (function (param) {
                    window.removeEventListener("resize", updateDimensions);
                    
                  });
        }), [ref.current]);
  return [
          ref,
          match[0],
          updateDimensions
        ];
}

function useDimensions(param) {
  var match = useDimensionsWithUpdate(undefined);
  return [
          match[0],
          match[1]
        ];
}

function useWidth(param) {
  var match = useDimensions(undefined);
  return [
          match[0],
          Belt_Option.map(match[1], (function (d) {
                  return d.width;
                }))
        ];
}

function useHeight(param) {
  var match = useDimensions(undefined);
  return [
          match[0],
          Belt_Option.map(match[1], (function (d) {
                  return d.height;
                }))
        ];
}

function useDimsWithContent(content) {
  var match = useDimensionsWithUpdate(undefined);
  var updateDimensions = match[2];
  React.useLayoutEffect((function () {
          Curry._1(updateDimensions, undefined);
          
        }), [content]);
  return [
          match[0],
          match[1],
          updateDimensions
        ];
}

export {
  useDimensionsWithUpdate ,
  useDimensions ,
  useWidth ,
  useHeight ,
  useDimsWithContent ,
  
}
/* react Not a pure module */
