// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.bs.js";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.bs.js";
import * as NextArrow$LiteracyplanetTupperware from "./NextArrow.bs.js";
import * as PrevArrow$LiteracyplanetTupperware from "./PrevArrow.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as MarkButtonStatesHook$LiteracyplanetTupperware from "./MarkButtonStatesHook.bs.js";
import * as SecondarySummaryIcon$LiteracyplanetTupperware from "./SecondarySummaryIcon.bs.js";
import * as SkilfulEndScreenContainer$LiteracyplanetTupperware from "./SkilfulEndScreenContainer.bs.js";
import * as BehaviourActivityMarkClassNames$LiteracyplanetTupperware from "./BehaviourActivityMarkClassNames.bs.js";

function SkilfulQuestionContent$SecondarySummaryButton(Props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var onGotoEndScreen = match.onGotoEndScreen;
  return React.createElement(SecondarySummaryIcon$LiteracyplanetTupperware.make, {
              onClick: (function (param) {
                  return Curry._1(onGotoEndScreen, undefined);
                }),
              className: Skillet$LiteracyplanetTupperware.SummaryIcon.className
            });
}

var SecondarySummaryButton = {
  make: SkilfulQuestionContent$SecondarySummaryButton
};

function SkilfulQuestionContent$ActivityProgressBar(Props) {
  var question = Props.question;
  var questionCount = Props.questionCount;
  var percent = question / questionCount * 100.0;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ActivityProgressBar.className(percent)
            });
}

var ActivityProgressBar = {
  make: SkilfulQuestionContent$ActivityProgressBar
};

function SkilfulQuestionContent$ActivityProgressMeter(Props) {
  var question = Props.question;
  var questionCount = Props.questionCount;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ActivityProgressMeter.className
            }, React.createElement(SkilfulQuestionContent$ActivityProgressBar, {
                  question: question,
                  questionCount: questionCount
                }));
}

var ActivityProgressMeter = {
  make: SkilfulQuestionContent$ActivityProgressMeter
};

function SkilfulQuestionContent$ActivityProgress(Props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var questionCount = match.questionCount;
  var question = match.currentQuestionIndex + 1 | 0;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ActivityProgress.className
            }, React.createElement("div", undefined, String(question) + " of " + String(questionCount)), React.createElement(SkilfulQuestionContent$ActivityProgressMeter, {
                  question: question,
                  questionCount: questionCount
                }));
}

var ActivityProgress = {
  make: SkilfulQuestionContent$ActivityProgress
};

function SkilfulQuestionContent$CorrectCount(Props) {
  var questionsCorrect = Props.questionsCorrect;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.CorrectCount.className
            }, React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.CorrectCountIcon.className
                }), React.createElement("div", undefined, String(questionsCorrect)));
}

var CorrectCount = {
  make: SkilfulQuestionContent$CorrectCount
};

function SkilfulQuestionContent$IncorrectCount(Props) {
  var questionsIncorrect = Props.questionsIncorrect;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.InorrectCount.className
            }, React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.IncorrectCountIcon.className
                }), React.createElement("div", undefined, String(questionsIncorrect)));
}

var IncorrectCount = {
  make: SkilfulQuestionContent$IncorrectCount
};

function SkilfulQuestionContent$MiddleContainer(Props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.MiddleContainer.className
            }, React.createElement(SkilfulQuestionContent$ActivityProgress, {}), React.createElement(SkilfulQuestionContent$CorrectCount, {
                  questionsCorrect: match.questionsCorrect
                }), React.createElement(SkilfulQuestionContent$IncorrectCount, {
                  questionsIncorrect: match.questionsIncorrect
                }));
}

var MiddleContainer = {
  make: SkilfulQuestionContent$MiddleContainer
};

function SkilfulQuestionContent$ContentHeader(Props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ContentHeader.className
            }, React.createElement(SkilfulQuestionContent$SecondarySummaryButton, {}), React.createElement(SkilfulQuestionContent$MiddleContainer, {}));
}

var ContentHeader = {
  make: SkilfulQuestionContent$ContentHeader
};

function SkilfulQuestionContent$Button(Props) {
  var onClick = Props.onClick;
  var className = Props.className;
  var children = Props.children;
  var tmp = {
    className: className
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("div", tmp, children);
}

var Button = {
  make: SkilfulQuestionContent$Button
};

function SkilfulQuestionContent$MarkButton(Props) {
  var className = Props.className;
  var onClick = Props.onClick;
  return React.createElement(SkilfulQuestionContent$Button, {
              onClick: onClick,
              className: className,
              children: "Submit"
            });
}

var MarkButton = {
  make: SkilfulQuestionContent$MarkButton
};

var enabledCss = Skillet$LiteracyplanetTupperware.BehaviourMarkButton.enabledCss;

function SkilfulQuestionContent$BehaviourMarkButton(Props) {
  var markState = MarkButtonStatesHook$LiteracyplanetTupperware.use(undefined);
  if (typeof markState === "number") {
    if (markState !== 0) {
      return null;
    } else {
      return React.createElement(SkilfulQuestionContent$MarkButton, {
                  className: Skillet$LiteracyplanetTupperware.BehaviourMarkButton.disabledCss,
                  onClick: (function (param) {
                      
                    })
                });
    }
  }
  var cb = markState._0;
  return React.createElement(SkilfulQuestionContent$MarkButton, {
              className: enabledCss,
              onClick: (function (param) {
                  return Curry._1(cb, undefined);
                })
            });
}

var BehaviourMarkButton = {
  enabledCss: enabledCss,
  make: SkilfulQuestionContent$BehaviourMarkButton
};

function SkilfulQuestionContent$BackButton(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.BackButton.className,
              onClick: onClick
            }, React.createElement(PrevArrow$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.BackArrowIcon.className
                }), React.createElement("div", undefined, "Back"));
}

var BackButton = {
  make: SkilfulQuestionContent$BackButton
};

function SkilfulQuestionContent$BehaviourBackButton(Props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  if (match.currentQuestionIndex === 0) {
    return React.createElement("div", undefined);
  }
  var onPreviousQuestion = match.onPreviousQuestion;
  return React.createElement(SkilfulQuestionContent$BackButton, {
              onClick: (function (param) {
                  return Curry._1(onPreviousQuestion, undefined);
                })
            });
}

var BehaviourBackButton = {
  make: SkilfulQuestionContent$BehaviourBackButton
};

function SkilfulQuestionContent$NextButton(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.NextButton.className,
              onClick: onClick
            }, React.createElement("div", undefined, "Next"), React.createElement(NextArrow$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.NextArrowIcon.className
                }));
}

var NextButton = {
  make: SkilfulQuestionContent$NextButton
};

function SkilfulQuestionContent$BehaviourNextButton(Props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var onNextQuestion = match.onNextQuestion;
  return React.createElement(SkilfulQuestionContent$NextButton, {
              onClick: (function (param) {
                  return Curry._1(onNextQuestion, undefined);
                })
            });
}

var BehaviourNextButton = {
  make: SkilfulQuestionContent$BehaviourNextButton
};

function SkilfulQuestionContent$ShowCorrectAnswerButton(Props) {
  var text = Props.text;
  var onClick = Props.onClick;
  return React.createElement(SkilfulQuestionContent$Button, {
              onClick: onClick,
              className: Skillet$LiteracyplanetTupperware.ShowCorrectAnswerButton.className,
              children: text
            });
}

var ShowCorrectAnswerButton = {
  make: SkilfulQuestionContent$ShowCorrectAnswerButton
};

function SkilfulQuestionContent$BehaviourShowCorrectAnswerButton(Props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var onToggleShowCorrectAnswer = match.onToggleShowCorrectAnswer;
  var currentQuestionIndex = match.currentQuestionIndex;
  var answerVisible = Curry._1(match.isCorrectAnswerVisible, currentQuestionIndex);
  var marked = Curry._1(match.getMarkState, currentQuestionIndex);
  var onClick = function (param) {
    return Curry._1(onToggleShowCorrectAnswer, undefined);
  };
  if (answerVisible !== undefined) {
    if (answerVisible) {
      return React.createElement(SkilfulQuestionContent$ShowCorrectAnswerButton, {
                  text: "Show My Answer",
                  onClick: onClick
                });
    }
    if (marked && !marked._0.questionCorrect) {
      return React.createElement(SkilfulQuestionContent$ShowCorrectAnswerButton, {
                  text: "Show Correct Answer",
                  onClick: onClick
                });
    }
    
  }
  if (marked) {
    return React.createElement("div", undefined);
  } else {
    return null;
  }
}

var BehaviourShowCorrectAnswerButton = {
  make: SkilfulQuestionContent$BehaviourShowCorrectAnswerButton
};

function SkilfulQuestionContent$GridJustifyCenter(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.GridJustifyCenter.className
            }, children);
}

var GridJustifyCenter = {
  make: SkilfulQuestionContent$GridJustifyCenter
};

function SkilfulQuestionContent$ContentFooter(Props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ContentFooter.className
            }, React.createElement(SkilfulQuestionContent$BehaviourBackButton, {}), React.createElement(SkilfulQuestionContent$GridJustifyCenter, {
                  children: null
                }, React.createElement(SkilfulQuestionContent$BehaviourMarkButton, {}), React.createElement(SkilfulQuestionContent$BehaviourShowCorrectAnswerButton, {})), React.createElement(SkilfulQuestionContent$BehaviourNextButton, {}));
}

var ContentFooter = {
  make: SkilfulQuestionContent$ContentFooter
};

function SkilfulQuestionContent$MarkStatusPanel(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: className
            }, children);
}

var MarkStatusPanel = {
  make: SkilfulQuestionContent$MarkStatusPanel
};

function SkilfulQuestionContent$Content(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.Content.className
            }, React.createElement(BehaviourActivityMarkClassNames$LiteracyplanetTupperware.make, {
                  children: null
                }, React.createElement(SkilfulQuestionContent$MarkStatusPanel, {
                      className: Skillet$LiteracyplanetTupperware.MarkStatusPanelCorrect.className,
                      children: React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.CorrectCountIcon.className
                          })
                    }), React.createElement(SkilfulQuestionContent$MarkStatusPanel, {
                      className: Skillet$LiteracyplanetTupperware.MarkStatusPanelIncorrect.className,
                      children: React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.IncorrectCountIcon.className
                          })
                    })), children);
}

var Content = {
  make: SkilfulQuestionContent$Content
};

function SkilfulQuestionContent$ContentContainer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.ContentContainer.className
            }, children);
}

var ContentContainer = {
  make: SkilfulQuestionContent$ContentContainer
};

function SkilfulQuestionContent(Props) {
  var children = Props.children;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulContentContainer.className
            }, React.createElement(SkilfulQuestionContent$ContentContainer, {
                  children: null
                }, React.createElement(SkilfulQuestionContent$ContentHeader, {}), React.createElement(SkilfulQuestionContent$Content, {
                      children: children
                    }), React.createElement(SkilfulQuestionContent$ContentFooter, {})), match.isEndscreenShowing ? React.createElement(SkilfulEndScreenContainer$LiteracyplanetTupperware.make, {}) : null);
}

var make = SkilfulQuestionContent;

var $$default = SkilfulQuestionContent;

export {
  SecondarySummaryButton ,
  ActivityProgressBar ,
  ActivityProgressMeter ,
  ActivityProgress ,
  CorrectCount ,
  IncorrectCount ,
  MiddleContainer ,
  ContentHeader ,
  Button ,
  MarkButton ,
  BehaviourMarkButton ,
  BackButton ,
  BehaviourBackButton ,
  NextButton ,
  BehaviourNextButton ,
  ShowCorrectAnswerButton ,
  BehaviourShowCorrectAnswerButton ,
  GridJustifyCenter ,
  ContentFooter ,
  MarkStatusPanel ,
  Content ,
  ContentContainer ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
