// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Mark$LiteracyplanetTupperware from "../Mark.bs.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as ExerciseStateStore$LiteracyplanetTupperware from "../ExerciseStateStore.bs.js";
import * as StateSerialization$LiteracyplanetTupperware from "../StateSerialization.bs.js";

function isSelected(step, responseOptionId) {
  var matchQuestionState = function (questionState) {
    if (!questionState) {
      return false;
    }
    var userAnswer = Reducer$LiteracyplanetTupperware.extractAnswers(questionState._0);
    return List.exists((function (id) {
                  return id === responseOptionId;
                }), userAnswer);
  };
  if (typeof step === "number") {
    return false;
  } else if (step.TAG === /* Attempted */0) {
    return matchQuestionState(step._0);
  } else {
    return matchQuestionState(step._1);
  }
}

var serialize = StateSerialization$LiteracyplanetTupperware.Encode.state;

function K2ExerciseInSeries(Props) {
  var questionCount = Props.questionCount;
  var questionsComplete = Props.questionsComplete;
  var callbacks = Props.callbacks;
  var setState = Props.setState;
  var state = Props.state;
  var children = Props.children;
  var match = React.useState(function () {
        return questionsComplete;
      });
  var setQuestionIndex = match[1];
  var questionIndex = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setActivityEnded = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setFinishedLastQuestion = match$2[1];
  var match$3 = React.useState(function () {
        return /* QuestionTab */0;
      });
  var setQuestionTab = match$3[1];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setShowAnswer = match$4[1];
  var showAnswer = match$4[0];
  var setFinished = function (param) {
    return Curry._1(setFinishedLastQuestion, (function (param) {
                  return true;
                }));
  };
  Mark$LiteracyplanetTupperware.useMarkCompleted(callbacks, state, questionCount, setFinished);
  var onMarkActivityQuestion = function (param) {
    return Mark$LiteracyplanetTupperware.markQuestion(callbacks, setState, Mark$LiteracyplanetTupperware.subscriptions.contents, questionIndex);
  };
  var partial_arg = Mark$LiteracyplanetTupperware.subscriptions.contents;
  var onMarkActivity = function (param) {
    return Mark$LiteracyplanetTupperware.markActivity(callbacks, setState, partial_arg, param);
  };
  var getQuestionState = function (questionIndex) {
    return ExerciseStateStore$LiteracyplanetTupperware.findQuestionState(questionIndex, state.questionStates);
  };
  var getMarkState = function (questionIndex) {
    return ExerciseStateStore$LiteracyplanetTupperware.findMarkState(questionIndex, state.markStates);
  };
  var getStep = function (questionIndex) {
    return Reducer$LiteracyplanetTupperware.checkStep(ExerciseStateStore$LiteracyplanetTupperware.findQuestionState(questionIndex, state.questionStates), ExerciseStateStore$LiteracyplanetTupperware.findMarkState(questionIndex, state.markStates));
  };
  var currentQuestionStep = getStep(questionIndex);
  var isQuestionVisible = function (index) {
    return index === questionIndex;
  };
  var isResponseSelected = function (questionIndex, responseOptionId) {
    return isSelected(getStep(questionIndex), responseOptionId);
  };
  var onNextQuestion = function (param) {
    var q = questionIndex + 1 | 0;
    if (q !== questionCount) {
      Curry._2(callbacks.onQuestionStarted, q, serialize(state));
    }
    return Curry._1(setQuestionIndex, (function (param) {
                  return q;
                }));
  };
  var onSetActivityEnd = function (param) {
    Curry._1(setActivityEnded, (function (param) {
            return true;
          }));
    return Curry._1(callbacks.onActivityReachedEnd, undefined);
  };
  var onPreviousQuestion = function (param) {
    if (questionIndex !== 0) {
      return Curry._1(setQuestionIndex, (function (param) {
                    return questionIndex - 1 | 0;
                  }));
    }
    
  };
  var onExit = function (param) {
    return Curry._2(callbacks.onExit, questionIndex, serialize(state));
  };
  var onSetQuestionTab = function (tab) {
    return Curry._1(setQuestionTab, (function (param) {
                  return tab;
                }));
  };
  var onGotoEndScreen = function (param) {
    return Curry._1(setQuestionIndex, (function (param) {
                  return questionCount;
                }));
  };
  var onChangeQuestion = function (i) {
    return Curry._1(setQuestionIndex, (function (param) {
                  return i;
                }));
  };
  var getCorrectAnswer = function (questionIndex) {
    return Belt_List.reduce(Mark$LiteracyplanetTupperware.subscriptions.contents, undefined, (function (acc, param) {
                  if (param[1] === questionIndex) {
                    return param[2];
                  } else {
                    return acc;
                  }
                }));
  };
  var onToggleShowCorrectAnswer = function (param) {
    return Curry._1(setShowAnswer, (function (showAnswer) {
                  if (Belt_List.some(showAnswer, (function (param) {
                            return param[0] === questionIndex;
                          }))) {
                    return Belt_List.map(showAnswer, (function (param) {
                                  var v = param[1];
                                  var i = param[0];
                                  if (i === questionIndex) {
                                    return [
                                            i,
                                            !v
                                          ];
                                  } else {
                                    return [
                                            i,
                                            v
                                          ];
                                  }
                                }));
                  } else {
                    return showAnswer;
                  }
                }));
  };
  var isCorrectAnswerVisible = function (index) {
    return Belt_Option.map(Belt_List.getBy(showAnswer, (function (param) {
                      return param[0] === index;
                    })), (function (param) {
                  return param[1];
                }));
  };
  var setShowCorrectAnswerForQuestion = function (questionIndex, showCorrect) {
    if (showCorrect) {
      return Curry._1(setShowAnswer, (function (showAnswer) {
                    if (Belt_List.some(showAnswer, (function (param) {
                              return param[0] === questionIndex;
                            }))) {
                      return showAnswer;
                    } else {
                      return Belt_List.concat(showAnswer, {
                                  hd: [
                                    questionIndex,
                                    false
                                  ],
                                  tl: /* [] */0
                                });
                    }
                  }));
    }
    
  };
  var value_activityEnded = match$1[0];
  var value_finishedLastQuestion = match$2[0];
  var value_questionTab = match$3[0];
  var value_questionsCorrect = state.questionsCorrect;
  var value_questionsIncorrect = state.questionsIncorrect;
  var value_isEndscreenShowing = questionIndex === questionCount;
  var value = {
    getMarkState: getMarkState,
    getQuestionState: getQuestionState,
    getStep: getStep,
    currentQuestionStep: currentQuestionStep,
    isQuestionVisible: isQuestionVisible,
    isResponseSelected: isResponseSelected,
    setMarkCb: Mark$LiteracyplanetTupperware.setMarkCb,
    onMarkActivityQuestion: onMarkActivityQuestion,
    onMarkActivity: onMarkActivity,
    onPreviousQuestion: onPreviousQuestion,
    onNextQuestion: onNextQuestion,
    onSetActivityEnd: onSetActivityEnd,
    onExit: onExit,
    activityEnded: value_activityEnded,
    finishedLastQuestion: value_finishedLastQuestion,
    currentQuestionIndex: questionIndex,
    onSetQuestionTab: onSetQuestionTab,
    questionTab: value_questionTab,
    questionCount: questionCount,
    questionsCorrect: value_questionsCorrect,
    questionsIncorrect: value_questionsIncorrect,
    isEndscreenShowing: value_isEndscreenShowing,
    onGotoEndScreen: onGotoEndScreen,
    onChangeQuestion: onChangeQuestion,
    getCorrectAnswer: getCorrectAnswer,
    onToggleShowCorrectAnswer: onToggleShowCorrectAnswer,
    isCorrectAnswerVisible: isCorrectAnswerVisible,
    setShowCorrectAnswerForQuestion: setShowCorrectAnswerForQuestion
  };
  return React.createElement(V2ExerciseContext$LiteracyplanetTupperware.make, V2ExerciseContext$LiteracyplanetTupperware.makeProps(value, children, undefined));
}

var markQuestion = Mark$LiteracyplanetTupperware.markQuestion;

var setMarkCb = Mark$LiteracyplanetTupperware.setMarkCb;

var make = K2ExerciseInSeries;

var $$default = K2ExerciseInSeries;

export {
  isSelected ,
  serialize ,
  markQuestion ,
  setMarkCb ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
