// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as LocaleContext$LiteracyplanetClientFlags from "./LocaleContext.bs.js";

function get(usersLocale) {
  return Belt_Option.map(usersLocale === "unknown" ? undefined : usersLocale, (function (l) {
                return [
                        l,
                        LocaleContext$LiteracyplanetClientFlags.toAccentString(LocaleContext$LiteracyplanetClientFlags.localeToAccent(LocaleContext$LiteracyplanetClientFlags.fromLocaleString(l)))
                      ];
              }));
}

export {
  get ,
  
}
/* LocaleContext-LiteracyplanetClientFlags Not a pure module */
