// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Grade from "@material-ui/icons/Grade";
import PlayArrow from "@material-ui/icons/PlayArrow";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import GradeOutlined from "@material-ui/icons/GradeOutlined";
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardCapslock from "@material-ui/icons/KeyboardCapslock";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

var PlayArrow$1 = {};

var Check$1 = {};

var Cross = {};

var DropDown = {};

var Return = {};

var Return$1 = {};

var Backspace = {};

var Shift = {};

var Lock = {};

var Caps = {
  Shift: Shift,
  Lock: Lock
};

function TupperwareIcon$Key(Props) {
  var variant = Props.variant;
  var style = Props.style;
  var className = Props.className;
  if (typeof variant === "number") {
    if (variant !== 0) {
      return React.createElement(KeyboardBackspace, {
                  className: className,
                  style: style
                });
    } else {
      return React.createElement(KeyboardReturn, {
                  className: className,
                  style: style
                });
    }
  } else if (variant._0) {
    return React.createElement(KeyboardCapslock, {
                className: className,
                style: style
              });
  } else {
    return React.createElement(KeyboardArrowUp, {
                className: className,
                style: style
              });
  }
}

var Key = {
  Return: Return$1,
  Backspace: Backspace,
  Caps: Caps,
  make: TupperwareIcon$Key
};

var Right = {};

var Left = {};

function TupperwareIcon$Chevron(Props) {
  var style = Props.style;
  var variant = Props.variant;
  var className = Props.className;
  if (variant) {
    return React.createElement(ChevronLeft, {
                className: className,
                style: style
              });
  } else {
    return React.createElement(ChevronRight, {
                className: className,
                style: style
              });
  }
}

var Chevron = {
  Right: Right,
  Left: Left,
  make: TupperwareIcon$Chevron
};

var Filled = {};

var Outlined = {};

function TupperwareIcon$Grade(Props) {
  var style = Props.style;
  var variant = Props.variant;
  var className = Props.className;
  if (variant) {
    return React.createElement(GradeOutlined, {
                className: className,
                style: style
              });
  } else {
    return React.createElement(Grade, {
                className: className,
                style: style
              });
  }
}

var Grade$1 = {
  Filled: Filled,
  Outlined: Outlined,
  make: TupperwareIcon$Grade
};

function TupperwareIcon(Props) {
  var style = Props.style;
  var classNameOpt = Props.className;
  var icon = Props.icon;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  if (typeof icon === "number") {
    switch (icon) {
      case /* DropDown */0 :
          return React.createElement(ArrowDropDown, {
                      className: className,
                      style: style
                    });
      case /* Check */1 :
          return React.createElement(Check, {
                      className: className,
                      style: style
                    });
      case /* Cross */2 :
          return React.createElement(Close, {
                      className: className,
                      style: style
                    });
      case /* PlayArrow */3 :
          return React.createElement(PlayArrow, {
                      className: className,
                      style: style
                    });
      
    }
  } else {
    switch (icon.TAG | 0) {
      case /* Key */0 :
          return React.createElement(TupperwareIcon$Key, {
                      variant: icon._0,
                      style: style,
                      className: className
                    });
      case /* Grade */1 :
          return React.createElement(TupperwareIcon$Grade, {
                      style: style,
                      variant: icon._0,
                      className: className
                    });
      case /* Chevron */2 :
          return React.createElement(TupperwareIcon$Chevron, {
                      style: style,
                      variant: icon._0,
                      className: className
                    });
      
    }
  }
}

var make = TupperwareIcon;

export {
  PlayArrow$1 as PlayArrow,
  Check$1 as Check,
  Cross ,
  DropDown ,
  Return ,
  Key ,
  Chevron ,
  Grade$1 as Grade,
  make ,
  
}
/* react Not a pure module */
