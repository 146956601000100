// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";

function initialState_hasFlag(param) {
  return false;
}

function initialState_unsafeHasFlag(param) {
  return false;
}

var initialState = {
  hasFlag: initialState_hasFlag,
  unsafeHasFlag: initialState_unsafeHasFlag
};

var context = React.createContext(initialState);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function FlagsContext$Provider(Props) {
  var hasFlagInState = Props.hasFlagInState;
  var features = Props.features;
  var classicFlags = Props.classicFlags;
  var userRoles = Props.userRoles;
  var systemFlags = Props.systemFlags;
  var children = Props.children;
  var cFlags = Belt_Option.getWithDefault(classicFlags, []);
  var sFlags = Belt_Option.getWithDefault(systemFlags, {});
  var roles = Belt_Option.getWithDefault(userRoles, []);
  var fs = Belt_Option.getWithDefault(features, {});
  var match = Curry._1(hasFlagInState, {
        classicFlags: cFlags,
        systemFlags: sFlags,
        userRoles: roles,
        features: fs
      });
  return React.createElement(React.Fragment, undefined, React.createElement(make, makeProps({
                      hasFlag: match.getFlag,
                      unsafeHasFlag: match.unsafeGetFlag
                    }, children, undefined)));
}

var Provider = {
  make: FlagsContext$Provider
};

function use(param) {
  return React.useContext(context).hasFlag;
}

function unsafeUse(param) {
  return React.useContext(context).unsafeHasFlag;
}

function FlagsContext$Consumer(Props) {
  var children = Props.children;
  return Curry._1(children, React.useContext(context).hasFlag);
}

var Consumer = {
  make: FlagsContext$Consumer
};

export {
  initialState ,
  Context ,
  Provider ,
  use ,
  unsafeUse ,
  Consumer ,
  
}
/* context Not a pure module */
