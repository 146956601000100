import JSRSASign from 'jsrsasign';

const parseQuery = (queryString) => {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

const loadUser = (queryParameters) => {
  const parameters = parseQuery(queryParameters);
  const idToken = parameters['id_token'];

  if (idToken) {
    let payloadObj = JSRSASign.KJUR.jws.JWS.readSafeJSONString(
      JSRSASign.b64utoutf8(idToken.split('.')[1])
    );

    const expiresIn = parseInt(parameters['expires_in']);
    const expiresAt = (Date.now() + (expiresIn * 1000)) / 1000;
    const userData = {
      id_token: idToken,
      access_token: parameters['access_token'],
      token_type: parameters['token_type'],
      expires_at: expiresAt,
      profile: payloadObj
    };
    return userData;
  } else {
    return null;
  }
};

export const createInitialState = () => {
  let initialState = {};
  if (process.env.MOBILE) {
    let userRecord = loadUser(window.location.search);
    initialState = {
      routing: {},
      openId: {
        user: userRecord,
        isLoadingUser: false
      },
      oidcPrompt: {},
      featureToggles: {
        useCollectionsSVG: true
      }
    };
  }
  return initialState;
};
