// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Error$LiteracyplanetClientErrors from "../../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as CobraContainer$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/CobraContainer.bs.js";
import * as BackgroundChanger$LiteracyplanetClientSxEntry from "../BackgroundChanger.bs.js";

var props = {
  extraVocals: [
    {
      name: "intro01",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-01.mp3"
      }
    },
    {
      name: "intro02",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-02.mp3"
      }
    },
    {
      name: "intro03",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-03.mp3"
      }
    },
    {
      name: "intro04",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-04.mp3"
      }
    },
    {
      name: "intro05",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-05.mp3"
      }
    },
    {
      name: "intro06",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-06.mp3"
      }
    },
    {
      name: "intro07",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-07.mp3"
      }
    },
    {
      name: "intro08",
      audio: {
        source: "https://intrepica-support-dir.s3.amazonaws.com/cobra2-placeholder-assets/en-temp/jwt/jwt-intro-08.mp3"
      }
    }
  ]
};

function StoryverseIntro(Props) {
  var onExit = Props.onExit;
  var match = Error$LiteracyplanetClientErrors.Consumer.use(undefined);
  var onSetError = match.onSetError;
  var match$1 = BackgroundChanger$LiteracyplanetClientSxEntry.use(undefined);
  var setBackground = match$1[1];
  var ready = function (c) {
    return Curry._1(setBackground, (function (param) {
                  return c.backgroundColor;
                }));
  };
  var onDispatch_onFailed = function (err) {
    return Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError(/* JourneyMissionsError */0, err));
  };
  var onDispatch = {
    onExit: onExit,
    onPreloaderReady: ready,
    onFailed: onDispatch_onFailed,
    onJourneyReady: ready
  };
  return React.createElement("div", {
              style: match$1[0]
            }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                  view: "jwt",
                  props: props,
                  onDispatch: onDispatch
                }));
}

var make = StoryverseIntro;

export {
  props ,
  make ,
  
}
/* react Not a pure module */
