// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as MemoPlay$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/MemoPlay.bs.js";
import * as TupperwareIcon$LiteracyplanetTupperware from "./TupperwareIcon.bs.js";

var inlineBlock = {
  display: "inline-block"
};

var main = {
  display: "inline-block",
  height: "70px",
  padding: "10px"
};

function AudioPlayButton(Props) {
  var src = Props.src;
  var match = React.useState(function () {
        return false;
      });
  var setPlaying = match[1];
  var playing = match[0];
  return React.createElement("div", {
              className: "AudioPlayButton",
              style: main
            }, React.createElement(Core.Fab, {
                  onClick: (function (param) {
                      if (!playing) {
                        return Curry._1(setPlaying, (function (param) {
                                      return true;
                                    }));
                      }
                      
                    }),
                  children: React.createElement(TupperwareIcon$LiteracyplanetTupperware.make, {
                        icon: /* PlayArrow */3
                      })
                }), React.createElement("div", {
                  style: inlineBlock
                }, React.createElement(MemoPlay$LiteracyplanetClientFlags.make, {
                      src: src,
                      onEnd: (function (param) {
                          return Curry._1(setPlaying, (function (param) {
                                        return false;
                                      }));
                        }),
                      playing: playing
                    })));
}

var make = AudioPlayButton;

var $$default = AudioPlayButton;

export {
  inlineBlock ,
  main ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
