// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as PrevIcon$LiteracyplanetTupperware from "./PrevIcon.bs.js";

function PrevPageButton(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.PrevPageButton.className,
              onClick: onClick
            }, React.createElement(PrevIcon$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.PrevPageButton.iconStyle
                }));
}

var make = PrevPageButton;

export {
  make ,
  
}
/* react Not a pure module */
