// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "../../../../node_modules/rescript/lib/es6/belt_SetString.js";
import * as FlagsContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/FlagsContext.bs.js";
import * as GameTypeData$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/GameTypeData.bs.js";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceWrap.bs.js";

function blacklistItem(item) {
  if (typeof item === "number") {
    return true;
  } else if (item.TAG === /* MyWordsItem */0) {
    return !Belt_SetString.has(GameTypeData$LiteracyplanetClientFlags.starterAccessMyWordsActivities, item._0);
  } else {
    return !Belt_SetString.has(GameTypeData$LiteracyplanetClientFlags.starterAccessArcadeGames, item._0);
  }
}

function blacklist(page) {
  if (typeof page === "number") {
    return false;
  }
  switch (page.TAG | 0) {
    case /* ArcadeGame */7 :
        return blacklistItem({
                    TAG: /* ArcadeItem */1,
                    _0: page._0
                  });
    case /* MyWordsActivity */12 :
        return blacklistItem({
                    TAG: /* MyWordsItem */0,
                    _0: page._1
                  });
    default:
      return false;
  }
}

function isProRequiredForPage(limited, page) {
  if (limited) {
    return blacklist(page);
  } else {
    return false;
  }
}

function isProRequiredForItem(limited, item) {
  if (limited) {
    return blacklistItem(item);
  } else {
    return false;
  }
}

function useForMain(limited, page, redirect, modalMsg) {
  var isProRequired = function (param) {
    return isProRequiredForPage(limited, param);
  };
  var match = React.useState(function () {
        
      });
  var setProRequiredModal = match[1];
  var showProRequiredModal = match[0];
  var onOpenModal = function (param) {
    return Curry._1(setProRequiredModal, (function (param) {
                  return Caml_option.some(modalMsg);
                }));
  };
  var onToggleModal = function (param) {
    if (showProRequiredModal !== undefined) {
      return Curry._1(setProRequiredModal, (function (param) {
                    
                  }));
    } else {
      return Curry._1(setProRequiredModal, (function (param) {
                    return Caml_option.some(modalMsg);
                  }));
    }
  };
  var redirectToMissions = StudentExperienceWrap$LiteracyplanetClientSxEntry.useStudentRedirectToMissions(redirect, isProRequired);
  React.useEffect((function () {
          if (isProRequiredForPage(limited, page)) {
            Curry._1(redirectToMissions, undefined);
          }
          
        }), [
        limited,
        page
      ]);
  return [
          (function (param) {
              if (limited && isProRequiredForPage(limited, param)) {
                return Curry._1(onOpenModal, undefined);
              } else {
                return Curry._1(redirect, param);
              }
            }),
          onToggleModal,
          showProRequiredModal
        ];
}

var context = React.createContext(function (param) {
      return false;
    });

function use(param) {
  return React.useContext(context);
}

var provider = context.Provider;

function ProRequired$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: ProRequired$Provider
};

function ProRequired(Props) {
  var redirect = Props.redirect;
  var page = Props.page;
  var children = Props.children;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var limited = Curry._1(hasFlag, "AuthStarterAccess");
  var match = useForMain(limited, page, redirect, "Talk with your teacher about this LiteracyPlanet Pro feature.");
  return React.createElement(ProRequired$Provider, {
              value: (function (param) {
                  return isProRequiredForItem(limited, param);
                }),
              children: Curry._3(children, match[0], match[1], match[2])
            });
}

var make = ProRequired;

export {
  blacklistItem ,
  blacklist ,
  isProRequiredForPage ,
  isProRequiredForItem ,
  useForMain ,
  context ,
  use ,
  Provider ,
  make ,
  
}
/* context Not a pure module */
