// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FlipperStoreTypes$LiteracyplanetClientSxActivity from "./FlipperStoreTypes.bs.js";

function useResultsDispatch(param) {
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var dispatch = match[1];
  var state = match[0];
  return {
          onResultReady: (function (param) {
              return Curry._1(dispatch, /* ResultsReady */10);
            }),
          onExit: (function (param) {
              return Curry._1(dispatch, state.needsToShowMissionComplete ? /* ExitToMissions */8 : /* Exit */6);
            }),
          onFailed: (function (param) {
              
            }),
          onRetryActivity: (function (param) {
              location.reload();
              return Curry._1(dispatch, /* Exit */6);
            }),
          onGoToCollections: (function (param) {
              return Curry._1(dispatch, /* ExitToCollections */7);
            })
        };
}

function defaultCb(param) {
  
}

var defaultResultDispatchables = {
  onResultReady: defaultCb,
  onExit: defaultCb,
  onFailed: defaultCb,
  onRetryActivity: defaultCb,
  onGoToCollections: defaultCb
};

var context = React.createContext(defaultResultDispatchables);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var Context = {
  context: context,
  makeProps: makeProps,
  make: make,
  use: use
};

function FlipperCobraResultsCallbacks$Provider(Props) {
  var dispachables = Props.dispachables;
  var children = Props.children;
  return React.createElement(make, makeProps(dispachables, children, undefined));
}

var Provider = {
  make: FlipperCobraResultsCallbacks$Provider
};

export {
  useResultsDispatch ,
  defaultCb ,
  defaultResultDispatchables ,
  Context ,
  Provider ,
  
}
/* context Not a pure module */
