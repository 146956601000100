// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook.bs.js";

var Raw = {};

var query = Client.gql(["query LevelupQuery($userId: uuid!)  {\nstoryverse_user_points_by_pk(userId: $userId)  {\n__typename  \npoints  \n}\n\nstoryverse_user_latest_level(order_by: [{points: asc}])  {\n__typename  \ncheckpoint  \nlevel  \npoints  \n}\n\nstoryverse_levels(order_by: [{points: asc}])  {\n__typename  \nid  \ncheckpoint  \nlevel  \npoints  \n}\n\nbog_user_backgrounds  {\n__typename  \nimage  {\n__typename  \nlocationIndex  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.storyverse_user_points_by_pk;
  var value$2 = value.storyverse_user_latest_level;
  var value$3 = value.storyverse_levels;
  var value$4 = value.bog_user_backgrounds;
  return {
          storyverse_user_points_by_pk: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                points: value$1.points
              }) : undefined,
          storyverse_user_latest_level: value$2.map(function (value) {
                var value$1 = value.checkpoint;
                var value$2 = value.level;
                var value$3 = value.points;
                return {
                        __typename: value.__typename,
                        checkpoint: !(value$1 == null) ? value$1 : undefined,
                        level: !(value$2 == null) ? value$2 : undefined,
                        points: !(value$3 == null) ? value$3 : undefined
                      };
              }),
          storyverse_levels: value$3.map(function (value) {
                return {
                        __typename: value.__typename,
                        id: value.id,
                        checkpoint: value.checkpoint,
                        level: value.level,
                        points: value.points
                      };
              }),
          bog_user_backgrounds: value$4.map(function (value) {
                var value$1 = value.image;
                return {
                        __typename: value.__typename,
                        image: {
                          __typename: value$1.__typename,
                          locationIndex: value$1.locationIndex
                        }
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.bog_user_backgrounds;
  var bog_user_backgrounds = value$1.map(function (value) {
        var value$1 = value.image;
        var value$2 = value$1.locationIndex;
        var value$3 = value$1.__typename;
        var image = {
          __typename: value$3,
          locationIndex: value$2
        };
        var value$4 = value.__typename;
        return {
                __typename: value$4,
                image: image
              };
      });
  var value$2 = value.storyverse_levels;
  var storyverse_levels = value$2.map(function (value) {
        var value$1 = value.points;
        var value$2 = value.level;
        var value$3 = value.checkpoint;
        var value$4 = value.id;
        var value$5 = value.__typename;
        return {
                __typename: value$5,
                id: value$4,
                checkpoint: value$3,
                level: value$2,
                points: value$1
              };
      });
  var value$3 = value.storyverse_user_latest_level;
  var storyverse_user_latest_level = value$3.map(function (value) {
        var value$1 = value.points;
        var points = value$1 !== undefined ? value$1 : null;
        var value$2 = value.level;
        var level = value$2 !== undefined ? value$2 : null;
        var value$3 = value.checkpoint;
        var checkpoint = value$3 !== undefined ? value$3 : null;
        var value$4 = value.__typename;
        return {
                __typename: value$4,
                checkpoint: checkpoint,
                level: level,
                points: points
              };
      });
  var value$4 = value.storyverse_user_points_by_pk;
  var storyverse_user_points_by_pk;
  if (value$4 !== undefined) {
    var value$5 = value$4.points;
    var value$6 = value$4.__typename;
    storyverse_user_points_by_pk = {
      __typename: value$6,
      points: value$5
    };
  } else {
    storyverse_user_points_by_pk = null;
  }
  return {
          storyverse_user_points_by_pk: storyverse_user_points_by_pk,
          storyverse_user_latest_level: storyverse_user_latest_level,
          storyverse_levels: storyverse_levels,
          bog_user_backgrounds: bog_user_backgrounds
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var LevelupQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var LevelupQuery_refetchQueryDescription = include.refetchQueryDescription;

var LevelupQuery_useLazy = include.useLazy;

var LevelupQuery_useLazyWithVariables = include.useLazyWithVariables;

var LevelupQuery = {
  LevelupQuery_inner: LevelupQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: LevelupQuery_refetchQueryDescription,
  use: use,
  useLazy: LevelupQuery_useLazy,
  useLazyWithVariables: LevelupQuery_useLazyWithVariables
};

function convertLevels(res) {
  return Belt_Array.map(res.storyverse_levels, (function (l) {
                return {
                        id: Json_decode.string(l.id),
                        level: l.level,
                        points: l.points,
                        checkpoint: l.checkpoint
                      };
              }));
}

function convertCurrentLevel(res) {
  return Belt_Array.reduce(res.storyverse_user_latest_level, [], (function (acc, l) {
                var match = l.checkpoint;
                var match$1 = l.level;
                var match$2 = l.points;
                if (acc !== undefined && match !== undefined && match$1 !== undefined && match$2 !== undefined) {
                  return Belt_Array.concat(acc, [{
                                checkpoint: match,
                                level: match$1,
                                points: match$2
                              }]);
                }
                
              }));
}

function convertLocationIndex(res) {
  return Belt_Array.reduce(res.bog_user_backgrounds, 0, (function (acc, b) {
                return b.image.locationIndex;
              }));
}

function handleResponse(res) {
  return Belt_Option.map(convertCurrentLevel(res), (function (currentLevel) {
                return {
                        points: Belt_Option.mapWithDefault(res.storyverse_user_points_by_pk, 0, (function (l) {
                                return l.points;
                              })),
                        currentLevel: currentLevel,
                        levels: convertLevels(res),
                        locationIndex: convertLocationIndex(res)
                      };
              }));
}

function onResult(query) {
  return Belt_Result.flatMap(query, (function (res) {
                var l = handleResponse(res);
                if (l !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: l
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* ErrorWithMessage */4,
                            _0: "StoryverseLevelupQuery",
                            _1: "handleResponse is returning None"
                          }
                        };
                }
              }));
}

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function onLoading(param) {
  
}

function defaultState(param) {
  
}

function use$1(skip, userId) {
  return Curry._5(Q.useSkippableWithErrorModal, defaultState, onLoading, onResult, skip, {
              userId: userId
            });
}

var decode = Json_decode.string;

export {
  LevelupQuery ,
  decode ,
  convertLevels ,
  convertCurrentLevel ,
  convertLocationIndex ,
  handleResponse ,
  onResult ,
  Q ,
  onLoading ,
  defaultState ,
  use$1 as use,
  
}
/* query Not a pure module */
