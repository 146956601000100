// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Page$LiteracyplanetClientSxEntry from "./Page.bs.js";
import * as PageTypes$LiteracyplanetClientSxEntry from "./PageTypes.bs.js";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceWrap.bs.js";

function pageToUrl(page) {
  if (typeof page === "number") {
    switch (page) {
      case /* StudentExperience */0 :
          return "/sx";
      case /* StudentCollections */1 :
          return "/sx/collections";
      case /* AvatarShop */2 :
          return "/sx/avatar_shop";
      case /* NoMatch */3 :
          return "/sx/";
      case /* StoryverseIntro */4 :
          return "/sx/storyverse";
      case /* Bog */5 :
          return "/sx/book_of_goings";
      case /* Journey */6 :
          return "/sx/journey";
      case /* JourneyIntroPreDiag */7 :
          return "/sx/journey/pre_diag";
      case /* JourneyIntroPostDiag */8 :
          return "/sx/journey/post_diag";
      case /* JourneyIntroNoDiag */9 :
          return "/sx/journey/no_diag";
      case /* JourneyMissions */10 :
          return "/sx/journey/missions";
      case /* Arcade */11 :
          return "/sx/arcade";
      case /* MyWordsLists */12 :
          return "/sx/my_words";
      case /* ChecksLobby */13 :
          return "/sx/checks/lobby";
      case /* ChecksActivity */14 :
          return "/sx/checks/activity";
      case /* OverviewReport */15 :
          return "/sx/overview_report";
      
    }
  } else {
    switch (page.TAG | 0) {
      case /* SecondaryMissions */0 :
          var studentMissionId = page._0;
          if (studentMissionId !== undefined) {
            return "/sx/secondary/" + studentMissionId;
          } else {
            return "/sx/secondary";
          }
      case /* StudentLibrary */1 :
          var groupIndex = page._0;
          if (groupIndex === undefined) {
            return "/sx/library";
          }
          var bookId = page._1;
          if (bookId !== undefined) {
            return "/sx/library/" + groupIndex + "/" + bookId;
          } else {
            return "/sx/library/" + groupIndex;
          }
      case /* StudentActivity */2 :
          return "/sx/" + page._0 + "/" + page._1 + "/activities/" + page._2;
      case /* PlayAnyActivity */3 :
          return "/sx/" + page._0 + "/" + page._1 + "/" + page._2 + "/" + page._3 + "/" + page._4 + "/" + page._5;
      case /* JourneyActivities */4 :
          return "/sx/journey/activities/" + page._0;
      case /* JourneyActivity */5 :
          var isAssessment = PageTypes$LiteracyplanetClientSxEntry.toIsAssessmentInt(page._4);
          return "/sx/journey/activity/" + page._0 + "/" + page._1 + "/" + page._2 + "/" + page._3 + "/" + isAssessment;
      case /* TupperwareDemo */6 :
          return "/sx/demo/" + page._0;
      case /* ArcadeGame */7 :
          return "/sx/arcade/" + page._0;
      case /* DeepLinkActivities */8 :
          return "/sx/missions/" + page._0;
      case /* MyWordsAssign */9 :
          return "/sx/my_words_assign/" + page._0;
      case /* MyWordsNew */10 :
          return "/sx/my_words_new/" + page._0;
      case /* MyWordsEdit */11 :
          return "/sx/my_words_edit/" + page._0;
      case /* MyWordsActivity */12 :
          return "/sx/my_words_activities/" + page._0 + "/" + page._1;
      case /* Intrepizine */13 :
          var optionalPostId = page._0;
          if (optionalPostId !== undefined) {
            return "/sx/intrepizine/" + optionalPostId;
          } else {
            return "/sx/intrepizine";
          }
      
    }
  }
}

function pathToPage(path) {
  if (!path) {
    return /* NoMatch */3;
  }
  var exit = 0;
  if (path.hd === "sx") {
    var match = path.tl;
    if (!match) {
      return /* StudentExperience */0;
    }
    switch (match.hd) {
      case "avatar_shop" :
          if (!match.tl) {
            return /* AvatarShop */2;
          }
          exit = 1;
          break;
      case "home" :
          if (!match.tl) {
            return /* StudentExperience */0;
          }
          exit = 1;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var match$1 = path.tl;
    if (!match$1) {
      return /* NoMatch */3;
    }
    var locale = match$1.hd;
    var exit$1 = 0;
    switch (locale) {
      case "arcade" :
          var match$2 = match$1.tl;
          if (!match$2) {
            return /* Arcade */11;
          }
          if (!match$2.tl) {
            return {
                    TAG: /* ArcadeGame */7,
                    _0: match$2.hd
                  };
          }
          exit$1 = 2;
          break;
      case "book_of_goings" :
          if (!match$1.tl) {
            return /* Bog */5;
          }
          exit$1 = 2;
          break;
      case "checks" :
          var match$3 = match$1.tl;
          if (!match$3) {
            return /* NoMatch */3;
          }
          switch (match$3.hd) {
            case "activity" :
                if (!match$3.tl) {
                  return /* ChecksActivity */14;
                }
                exit$1 = 2;
                break;
            case "lobby" :
                if (!match$3.tl) {
                  return /* ChecksLobby */13;
                }
                exit$1 = 2;
                break;
            default:
              exit$1 = 2;
          }
          break;
      case "collections" :
          if (!match$1.tl) {
            return /* StudentCollections */1;
          }
          exit$1 = 2;
          break;
      case "demo" :
          var match$4 = match$1.tl;
          if (!match$4) {
            return /* NoMatch */3;
          }
          if (!match$4.tl) {
            return {
                    TAG: /* TupperwareDemo */6,
                    _0: match$4.hd
                  };
          }
          exit$1 = 2;
          break;
      case "intrepizine" :
          var match$5 = match$1.tl;
          if (!match$5) {
            return {
                    TAG: /* Intrepizine */13,
                    _0: undefined
                  };
          }
          if (!match$5.tl) {
            return {
                    TAG: /* Intrepizine */13,
                    _0: match$5.hd
                  };
          }
          exit$1 = 2;
          break;
      case "journey" :
          var match$6 = match$1.tl;
          if (!match$6) {
            return /* Journey */6;
          }
          switch (match$6.hd) {
            case "activities" :
                var match$7 = match$6.tl;
                if (!match$7) {
                  return /* NoMatch */3;
                }
                if (!match$7.tl) {
                  return {
                          TAG: /* JourneyActivities */4,
                          _0: match$7.hd
                        };
                }
                exit$1 = 2;
                break;
            case "activity" :
                var match$8 = match$6.tl;
                if (!match$8) {
                  return /* NoMatch */3;
                }
                var match$9 = match$8.tl;
                if (!match$9) {
                  return /* NoMatch */3;
                }
                var match$10 = match$9.tl;
                if (match$10) {
                  var match$11 = match$10.tl;
                  if (!match$11) {
                    return /* NoMatch */3;
                  }
                  var match$12 = match$11.tl;
                  if (match$12) {
                    if (match$12.tl) {
                      return /* NoMatch */3;
                    } else {
                      return {
                              TAG: /* JourneyActivity */5,
                              _0: match$8.hd,
                              _1: match$9.hd,
                              _2: match$10.hd,
                              _3: match$11.hd,
                              _4: PageTypes$LiteracyplanetClientSxEntry.toIsAssessment(match$12.hd)
                            };
                    }
                  }
                  exit$1 = 2;
                } else {
                  exit$1 = 2;
                }
                break;
            case "missions" :
                if (!match$6.tl) {
                  return /* JourneyMissions */10;
                }
                exit$1 = 2;
                break;
            case "no_diag" :
                if (!match$6.tl) {
                  return /* JourneyIntroNoDiag */9;
                }
                exit$1 = 2;
                break;
            case "post_diag" :
                if (!match$6.tl) {
                  return /* JourneyIntroPostDiag */8;
                }
                exit$1 = 2;
                break;
            case "pre_diag" :
                if (!match$6.tl) {
                  return /* JourneyIntroPreDiag */7;
                }
                exit$1 = 2;
                break;
            default:
              exit$1 = 2;
          }
          break;
      case "library" :
          var match$13 = match$1.tl;
          if (!match$13) {
            return {
                    TAG: /* StudentLibrary */1,
                    _0: undefined,
                    _1: undefined
                  };
          }
          var match$14 = match$13.tl;
          var groupIndex = match$13.hd;
          if (!match$14) {
            return {
                    TAG: /* StudentLibrary */1,
                    _0: groupIndex,
                    _1: undefined
                  };
          }
          if (!match$14.tl) {
            return {
                    TAG: /* StudentLibrary */1,
                    _0: groupIndex,
                    _1: match$14.hd
                  };
          }
          exit$1 = 2;
          break;
      case "missions" :
          var match$15 = match$1.tl;
          if (!match$15) {
            return /* NoMatch */3;
          }
          if (!match$15.tl) {
            return {
                    TAG: /* DeepLinkActivities */8,
                    _0: match$15.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words" :
          if (!match$1.tl) {
            return /* MyWordsLists */12;
          }
          exit$1 = 2;
          break;
      case "my_words_activities" :
          var match$16 = match$1.tl;
          if (!match$16) {
            return /* NoMatch */3;
          }
          var match$17 = match$16.tl;
          if (!match$17) {
            return /* NoMatch */3;
          }
          if (!match$17.tl) {
            return {
                    TAG: /* MyWordsActivity */12,
                    _0: match$16.hd,
                    _1: match$17.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words_assign" :
          var match$18 = match$1.tl;
          if (!match$18) {
            return /* NoMatch */3;
          }
          if (!match$18.tl) {
            return {
                    TAG: /* MyWordsAssign */9,
                    _0: match$18.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words_edit" :
          var match$19 = match$1.tl;
          if (!match$19) {
            return /* NoMatch */3;
          }
          if (!match$19.tl) {
            return {
                    TAG: /* MyWordsEdit */11,
                    _0: match$19.hd
                  };
          }
          exit$1 = 2;
          break;
      case "my_words_new" :
          var match$20 = match$1.tl;
          if (!match$20) {
            return /* NoMatch */3;
          }
          if (!match$20.tl) {
            return {
                    TAG: /* MyWordsNew */10,
                    _0: match$20.hd
                  };
          }
          exit$1 = 2;
          break;
      case "overview_report" :
          if (!match$1.tl) {
            return /* OverviewReport */15;
          }
          exit$1 = 2;
          break;
      case "secondary" :
          var match$21 = match$1.tl;
          if (!match$21) {
            return {
                    TAG: /* SecondaryMissions */0,
                    _0: undefined
                  };
          }
          if (!match$21.tl) {
            return {
                    TAG: /* SecondaryMissions */0,
                    _0: match$21.hd
                  };
          }
          exit$1 = 2;
          break;
      case "storyverse" :
          if (!match$1.tl) {
            return /* StoryverseIntro */4;
          }
          exit$1 = 2;
          break;
      default:
        exit$1 = 2;
    }
    if (exit$1 === 2) {
      var match$22 = match$1.tl;
      if (!match$22) {
        return /* NoMatch */3;
      }
      var match$23 = match$22.tl;
      if (!match$23) {
        return /* NoMatch */3;
      }
      var contentId = match$23.hd;
      var accent = match$22.hd;
      var exit$2 = 0;
      if (contentId === "activities") {
        var match$24 = match$23.tl;
        if (!match$24) {
          return /* NoMatch */3;
        }
        if (!match$24.tl) {
          return {
                  TAG: /* StudentActivity */2,
                  _0: locale,
                  _1: accent,
                  _2: match$24.hd
                };
        }
        exit$2 = 3;
      } else {
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        var match$25 = match$23.tl;
        if (!match$25) {
          return /* NoMatch */3;
        }
        var match$26 = match$25.tl;
        if (!match$26) {
          return /* NoMatch */3;
        }
        var match$27 = match$26.tl;
        if (match$27 && !match$27.tl) {
          return {
                  TAG: /* PlayAnyActivity */3,
                  _0: locale,
                  _1: accent,
                  _2: contentId,
                  _3: match$25.hd,
                  _4: match$26.hd,
                  _5: match$27.hd
                };
        } else {
          return /* NoMatch */3;
        }
      }
      
    }
    
  }
  
}

function pageToComponent(page, state, goBack, redirect, redirectWithState, replaceWithState, isPageBlacklisted, isItemBlacklisted, onToggleLockedModal, onToggleProModal, pageToUrlWithState) {
  var onGoToMissions = StudentExperienceWrap$LiteracyplanetClientSxEntry.useStudentRedirectToMissions(redirect, isPageBlacklisted);
  if (typeof page === "number") {
    switch (page) {
      case /* StudentExperience */0 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentExperience.make, {
                      redirect: redirect
                    });
      case /* StudentCollections */1 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentCollections.make, {
                      redirect: redirect,
                      isPageBlacklisted: isPageBlacklisted,
                      isItemBlacklisted: isItemBlacklisted,
                      onToggleProModal: onToggleProModal,
                      onToggleLockedModal: onToggleLockedModal
                    });
      case /* AvatarShop */2 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.AvatarShop.make, {
                      onGoToMissions: onGoToMissions
                    });
      case /* NoMatch */3 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.NoMatch.make, {});
      case /* StoryverseIntro */4 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StoryverseIntro.make, {
                      redirect: redirect
                    });
      case /* Bog */5 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.Bog.make, {
                      redirect: redirect,
                      state: state,
                      redirectWithState: redirectWithState
                    });
      case /* JourneyMissions */10 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.JourneyMissions.make, {
                      redirect: redirect
                    });
      case /* Arcade */11 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.Arcade.make, {
                      redirect: redirect
                    });
      case /* MyWordsLists */12 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsLists.make, {
                      redirect: redirect,
                      replaceWithState: replaceWithState,
                      state: state
                    });
      case /* ChecksLobby */13 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.ChecksLobby.make, {
                      redirect: redirect,
                      onGoToMissions: onGoToMissions
                    });
      case /* ChecksActivity */14 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.ChecksActivity.make, {
                      redirect: redirect
                    });
      case /* OverviewReport */15 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.OverviewReport.make, {});
      default:
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "Routes.res",
                144,
                2
              ],
              Error: new Error()
            };
    }
  } else {
    switch (page.TAG | 0) {
      case /* SecondaryMissions */0 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.SecondaryMissions.make, {
                      studentMissionId: page._0,
                      redirect: redirect
                    });
      case /* StudentLibrary */1 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentLibrary.make, {
                      groupIndex: page._0,
                      bookId: page._1,
                      redirect: redirect
                    });
      case /* StudentActivity */2 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.StudentActivity.make, {
                      redirect: redirect,
                      locale: page._0,
                      accent: page._1,
                      studentActivityId: page._2,
                      isPageBlacklisted: isPageBlacklisted,
                      onToggleLocked: onToggleLockedModal,
                      onGoToMissions: onGoToMissions
                    });
      case /* PlayAnyActivity */3 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.PlayAnyActivity.make, {
                      state: state,
                      goBack: goBack,
                      locale: page._0,
                      accent: page._1,
                      contentId: page._2,
                      contentVersion: page._3,
                      contentKind: page._4,
                      kind: page._5
                    });
      case /* JourneyActivities */4 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.JourneyActivities.make, {
                      redirect: redirect,
                      studentMissionId: page._0
                    });
      case /* JourneyActivity */5 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.JourneyActivity.make, {
                      redirect: redirect,
                      locale: page._0,
                      accent: page._1,
                      studentMissionId: page._2,
                      studentActivityId: page._3,
                      isAssessment: page._4,
                      isPageBlacklisted: isPageBlacklisted,
                      onToggleLocked: onToggleLockedModal
                    });
      case /* ArcadeGame */7 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.ArcadeGame.make, {
                      redirect: redirect,
                      gameKind: page._0
                    });
      case /* DeepLinkActivities */8 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.DeepLinkActivities.make, {
                      redirect: redirect,
                      missionRef: page._0
                    });
      case /* MyWordsAssign */9 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsAssign.make, {
                      redirect: redirect,
                      id: page._0
                    });
      case /* MyWordsNew */10 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsNew.make, {
                      id: page._0,
                      redirect: redirect
                    });
      case /* MyWordsEdit */11 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsEdit.make, {
                      id: page._0,
                      redirect: redirect
                    });
      case /* MyWordsActivity */12 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.MyWordsActivity.make, {
                      id: page._0,
                      gameId: page._1,
                      redirect: redirect
                    });
      case /* Intrepizine */13 :
          return React.createElement(Page$LiteracyplanetClientSxEntry.Intrepizine.make, {
                      optionalPostId: page._0,
                      pageToUrlWithState: pageToUrlWithState,
                      state: state
                    });
      default:
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "Routes.res",
                144,
                2
              ],
              Error: new Error()
            };
    }
  }
}

function pageToLayout(page) {
  if (typeof page === "number") {
    switch (page) {
      case /* StoryverseIntro */4 :
          return /* AuthNoMenu */5;
      case /* Bog */5 :
          return /* PlainBackground */4;
      case /* Arcade */11 :
          return /* AuthWithTitleBarAndHeader */2;
      case /* StudentCollections */1 :
      case /* MyWordsLists */12 :
          return /* AuthWithTitleBar */1;
      case /* NoMatch */3 :
      case /* JourneyMissions */10 :
      case /* OverviewReport */15 :
          return /* AuthWithMenu */0;
      default:
        return /* AuthNoMenu */5;
    }
  } else {
    switch (page.TAG | 0) {
      case /* SecondaryMissions */0 :
          return /* AuthWithMenu */0;
      case /* StudentLibrary */1 :
          if (page._1 !== undefined) {
            return /* DarkBackground */3;
          } else {
            return /* AuthWithTitleBarAndHeader */2;
          }
      case /* JourneyActivities */4 :
          return /* AuthNoMenu */5;
      case /* MyWordsAssign */9 :
      case /* MyWordsNew */10 :
      case /* MyWordsEdit */11 :
          return /* DarkBackground */3;
      case /* Intrepizine */13 :
          return /* AuthWithTitleBarAndHeader */2;
      default:
        return /* AuthNoMenu */5;
    }
  }
}

function pageToBackground(page) {
  if (typeof page === "number") {
    switch (page) {
      case /* NoMatch */3 :
      case /* Bog */5 :
      case /* Arcade */11 :
      case /* MyWordsLists */12 :
      case /* OverviewReport */15 :
          return /* VoidBackground */2;
      default:
        return /* NoBackground */3;
    }
  } else {
    switch (page.TAG | 0) {
      case /* SecondaryMissions */0 :
      case /* StudentLibrary */1 :
      case /* MyWordsAssign */9 :
      case /* MyWordsNew */10 :
      case /* MyWordsEdit */11 :
          return /* VoidBackground */2;
      case /* Intrepizine */13 :
          return /* IntrepizineBackground */1;
      default:
        return /* NoBackground */3;
    }
  }
}

export {
  pageToUrl ,
  pathToPage ,
  pageToComponent ,
  pageToLayout ,
  pageToBackground ,
  
}
/* react Not a pure module */
