// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.bs.js";
import * as DropDownTypes$LiteracyplanetTupperware from "../DropDownTypes.bs.js";
import * as SkilfulDropDown$LiteracyplanetTupperware from "./SkilfulDropDown.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.bs.js";

function convertOpts(opts) {
  return Belt_List.map(Belt_List.fromArray(opts), (function (obj) {
                return {
                        responseOptionId: obj.responseOptionId,
                        value: obj.value
                      };
              }));
}

function makeRespond(questionIndex, group, step, dispatch, value) {
  if (typeof step !== "number" && step.TAG !== /* Attempted */0) {
    return ;
  }
  return Curry._1(dispatch, {
              TAG: /* SetQuestionState */2,
              _0: questionIndex,
              _1: Reducer$LiteracyplanetTupperware.buildResponseType(group, value)
            });
}

function SkilfulDropDownOptionContainer(Props) {
  var questionIndex = Props.questionIndex;
  var group = Props.group;
  var opts = Props.opts;
  var opts$1 = convertOpts(opts);
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var match$1 = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch(undefined);
  var dispatch = match$1[1];
  var step = Curry._1(match.getStep, questionIndex);
  var answerVisible = Curry._1(match.isCorrectAnswerVisible, questionIndex);
  var correctAnswer = Curry._1(match.getCorrectAnswer, questionIndex);
  var onChange = function (param) {
    return makeRespond(questionIndex, group, step, dispatch, param);
  };
  var optionalResponseId = Caml_obj.caml_equal(answerVisible, true) ? DropDownTypes$LiteracyplanetTupperware.find(opts$1, correctAnswer) : DropDownTypes$LiteracyplanetTupperware.getSelectedDropDownValue(step, opts$1);
  var selectedValue = Belt_Option.map(Belt_List.getBy(opts$1, (function (param) {
              return param.responseOptionId === Belt_Option.getWithDefault(optionalResponseId, "");
            })), (function (param) {
          return param.value;
        }));
  var marked = DropDownTypes$LiteracyplanetTupperware.getMarkTypeForDropDown(optionalResponseId, step, opts$1);
  return React.createElement(SkilfulDropDown$LiteracyplanetTupperware.make, {
              selectedValue: selectedValue,
              marked: marked,
              answerVisible: answerVisible,
              responseOptions: opts$1,
              onChange: onChange
            });
}

var make = SkilfulDropDownOptionContainer;

var $$default = SkilfulDropDownOptionContainer;

export {
  convertOpts ,
  makeRespond ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
