// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function DropDownIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 128 128"
            }, React.createElement("g", {
                  transform: "matrix( 1, 0, 0, 1, 0,0)"
                }, React.createElement("path", {
                      d: "M 0 0 L 63.6 63.35 128 0 0 0 Z",
                      stroke: "none"
                    })));
}

var make = DropDownIcon;

export {
  make ,
  
}
/* react Not a pure module */
