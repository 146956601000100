// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";

function call(f) {
  Belt_Option.map(f, (function (f) {
          return Curry._1(f, undefined);
        }));
  
}

function callWith(f, x) {
  Belt_Option.map(f, (function (f) {
          return Curry._1(f, x);
        }));
  
}

function wrapApiWithStoreEvents(api, dispatch, onActivityCompleted, onActivityPaused, onActivityReachedEnd, onActivityReady, onActivityResumed, onActivityStarted, onExit, onFailed, onQuestionFailed, onQuestionPassed, onQuestionStarted, onBatchQuestionsAnswered, onPreloaderReady, param) {
  return Curry.app(api, [
              (function (param) {
                  Curry._1(dispatch, /* ActivityCompleted */0);
                  return call(onActivityCompleted);
                }),
              (function (param) {
                  Curry._1(dispatch, /* ActivityPaused */1);
                  return call(onActivityPaused);
                }),
              (function (param) {
                  Curry._1(dispatch, /* ActivityReachedEnd */2);
                  return call(onActivityReachedEnd);
                }),
              (function (param) {
                  Curry._1(dispatch, /* ActivityReady */3);
                  return call(onActivityReady);
                }),
              (function (param) {
                  Curry._1(dispatch, /* ActivityResumed */4);
                  return call(onActivityResumed);
                }),
              (function (param) {
                  Curry._1(dispatch, /* ActivityStarted */5);
                  return call(onActivityStarted);
                }),
              (function (param) {
                  Curry._1(dispatch, /* Exit */6);
                  return call(onExit);
                }),
              (function (param) {
                  return callWith(onFailed, param);
                }),
              (function (result) {
                  if (typeof result !== "number") {
                    if (result.TAG === /* Orig */0) {
                      Curry._1(dispatch, {
                            TAG: /* QuestionFailed */1,
                            _0: result._0.question
                          });
                    } else {
                      Curry._1(dispatch, {
                            TAG: /* QuestionFailed */1,
                            _0: result._0.question
                          });
                    }
                  }
                  return callWith(onQuestionFailed, result);
                }),
              (function (result) {
                  if (typeof result !== "number") {
                    if (result.TAG === /* Orig */0) {
                      Curry._1(dispatch, {
                            TAG: /* QuestionPassed */2,
                            _0: result._0.question
                          });
                    } else {
                      Curry._1(dispatch, {
                            TAG: /* QuestionPassed */2,
                            _0: result._0.question
                          });
                    }
                  }
                  return callWith(onQuestionPassed, result);
                }),
              (function (q) {
                  Curry._1(dispatch, /* QuestionStarted */9);
                  return callWith(onQuestionStarted, q);
                }),
              (function (questions) {
                  if (questions) {
                    Curry._1(dispatch, {
                          TAG: /* BulkQuestions */3,
                          _0: Belt_Array.map(questions._0, (function (param) {
                                  return param.correct;
                                }))
                        });
                  }
                  return callWith(onBatchQuestionsAnswered, questions);
                }),
              (function (background) {
                  Curry._1(dispatch, {
                        TAG: /* PreloaderReady */4,
                        _0: background
                      });
                  return callWith(onPreloaderReady, background);
                })
            ]);
}

function defaultCb(param) {
  
}

var defaultDispatchables = {
  onActivityCompleted: defaultCb,
  onActivityPaused: defaultCb,
  onActivityReachedEnd: defaultCb,
  onActivityReady: defaultCb,
  onActivityResumed: defaultCb,
  onActivityStarted: defaultCb,
  onExit: defaultCb,
  onFailed: defaultCb,
  onQuestionFailed: defaultCb,
  onQuestionPassed: defaultCb,
  onQuestionStarted: defaultCb,
  onBatchQuestionsAnswered: defaultCb,
  onPreloaderReady: defaultCb
};

var context = React.createContext(defaultDispatchables);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var Context = {
  context: context,
  makeProps: makeProps,
  make: make,
  use: use
};

function FlipperCobraCallbacks$Provider(Props) {
  var dispachables = Props.dispachables;
  var children = Props.children;
  return React.createElement(make, makeProps(dispachables, children, undefined));
}

var Provider = {
  make: FlipperCobraCallbacks$Provider
};

export {
  call ,
  callWith ,
  wrapApiWithStoreEvents ,
  defaultCb ,
  defaultDispatchables ,
  Context ,
  Provider ,
  
}
/* context Not a pure module */
