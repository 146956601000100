// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Theme$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Theme.bs.js";

var bookIcon = (require("./assets/icon_book.svg"));

var sackIcon = (require("./assets/icon_sack.svg"));

var arcadeIcon = (require("./assets/icon_arcade.svg"));

var shopIcon = (require("./assets/icon_shop.svg"));

var gem = (require("./assets/gem.svg"));

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        46
      ],
      tl: {
        hd: [
          Css.height,
          44
        ],
        tl: /* [] */0
      }
    });

var css = Curry._1(Css.style, {
      hd: Css.background(Css.url(gem)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition("right"),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: r,
        tl: {
          hd: "GemIcon",
          tl: /* [] */0
        }
      }
    });

var GemIconStyle = {
  r: r,
  css: css,
  className: className
};

function ForestIcons$GemIcon(Props) {
  return React.createElement("div", {
              className: className
            });
}

var GemIcon = {
  make: ForestIcons$GemIcon
};

var profileIcon = (require("./assets/icon_profile.svg"));

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: {
        hd: [
          Css.height,
          28
        ],
        tl: /* [] */0
      }
    });

var css$1 = Curry._1(Css.style, {
      hd: Css.background(Css.url(profileIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: r$1,
        tl: {
          hd: "Gem",
          tl: /* [] */0
        }
      }
    });

var ProfileIconStyle = {
  r: r$1,
  css: css$1,
  className: className$1
};

function ForestIcons$ProfileIcon(Props) {
  return React.createElement("div", {
              className: className$1
            });
}

var ProfileIcon = {
  make: ForestIcons$ProfileIcon
};

var locationIcon = (require("./assets/icon_location.svg"));

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: {
        hd: [
          Css.height,
          19
        ],
        tl: /* [] */0
      }
    });

var css$2 = Curry._1(Css.style, {
      hd: Css.background(Css.url(locationIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: r$2,
        tl: {
          hd: "LocationIcon",
          tl: /* [] */0
        }
      }
    });

var LocationIconStyle = {
  r: r$2,
  css: css$2,
  className: className$2
};

function ForestIcons$LocationIcon(Props) {
  return React.createElement("div", {
              className: className$2
            });
}

var LocationIcon = {
  make: ForestIcons$LocationIcon
};

var closeIcon = (require("./assets/icon_close.svg"));

var r$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        27
      ],
      tl: {
        hd: [
          Css.height,
          27
        ],
        tl: /* [] */0
      }
    });

var css$3 = Curry._1(Css.style, {
      hd: Css.background(Css.url(closeIcon)),
      tl: {
        hd: Css.backgroundSize("contain"),
        tl: {
          hd: Css.backgroundRepeat("noRepeat"),
          tl: {
            hd: Css.backgroundPosition(Css.center),
            tl: {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.justifyContent(Css.center),
                tl: {
                  hd: Css.justifyItems(Css.center),
                  tl: {
                    hd: Css.alignContent(Css.center),
                    tl: {
                      hd: Css.alignItems(Css.center),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: css$3,
      tl: {
        hd: r$3,
        tl: {
          hd: "CloseIcon",
          tl: /* [] */0
        }
      }
    });

var CloseIconStyle = {
  r: r$3,
  css: css$3,
  className: className$3
};

function ForestIcons$CloseIcon(Props) {
  return React.createElement("div", {
              className: className$3
            });
}

var CloseIcon = {
  make: ForestIcons$CloseIcon
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

export {
  ratio ,
  resizeGridCols ,
  resizeGridRows ,
  maximumWidth ,
  bookIcon ,
  sackIcon ,
  arcadeIcon ,
  shopIcon ,
  gem ,
  GemIconStyle ,
  GemIcon ,
  profileIcon ,
  ProfileIconStyle ,
  ProfileIcon ,
  locationIcon ,
  LocationIconStyle ,
  LocationIcon ,
  closeIcon ,
  CloseIconStyle ,
  CloseIcon ,
  
}
/* bookIcon Not a pure module */
