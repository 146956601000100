// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as HtmlBody$LiteracyplanetClientFlags from "./HtmlBody.bs.js";
import * as DropDownIcon$LiteracyplanetClientFlags from "./DropDownIcon.bs.js";
import * as UseModalState$LiteracyplanetClientFlags from "./UseModalState.bs.js";
import * as TooltipPosition$LiteracyplanetClientFlags from "./TooltipPosition.bs.js";
import * as ContainerDimensions$LiteracyplanetClientFlags from "./ContainerDimensions.bs.js";

var bodyClassName = Curry._1(Css.style, {
      hd: Css.position(Css.relative),
      tl: /* [] */0
    });

function FormSelect$Options(Props) {
  var inputDimensions = Props.inputDimensions;
  var options = Props.options;
  var onChange = Props.onChange;
  var optionClassName = Props.optionClassName;
  var optionsClassName = Props.optionsClassName;
  var match = TooltipPosition$LiteracyplanetClientFlags.use(inputDimensions);
  return React.createElement("div", {
              className: bodyClassName,
              onMouseDown: (function (prim) {
                  prim.stopPropagation();
                  
                })
            }, React.createElement("ul", {
                  ref: match[0],
                  className: Curry._1(Css.merge, {
                        hd: optionsClassName,
                        tl: {
                          hd: match[1],
                          tl: /* [] */0
                        }
                      })
                }, Belt_Array.map(options, (function (param) {
                        var key = param[0];
                        return React.createElement("li", {
                                    className: optionClassName,
                                    onClick: (function (param) {
                                        return Curry._1(onChange, key);
                                      }),
                                    onMouseDown: (function (prim) {
                                        prim.stopPropagation();
                                        
                                      })
                                  }, param[1]);
                      }))));
}

var Options = {
  make: FormSelect$Options
};

function useInputBoundingRect(show, selectedValue) {
  var match = ContainerDimensions$LiteracyplanetClientFlags.useDimsWithContent(selectedValue);
  var updateDimensions = match[2];
  React.useLayoutEffect((function () {
          Curry._1(updateDimensions, undefined);
          
        }), [show]);
  return [
          match[0],
          match[1]
        ];
}

function FormSelect(Props) {
  var selectedValue = Props.selectedValue;
  var defaultValueOpt = Props.defaultValue;
  var options = Props.options;
  var onChange = Props.onChange;
  var optionClassName = Props.optionClassName;
  var optionsClassName = Props.optionsClassName;
  var inputClassName = Props.inputClassName;
  var inputTextClassName = Props.inputTextClassName;
  var borderClassName = Props.borderClassName;
  var iconClassName = Props.iconClassName;
  var defaultValue = defaultValueOpt !== undefined ? defaultValueOpt : "\u00A0";
  var match = UseModalState$LiteracyplanetClientFlags.use(undefined);
  var toggleShow = match[1];
  var show = match[0];
  var match$1 = useInputBoundingRect(show, selectedValue);
  var onChangeAndHideOptions = function (key) {
    return Curry._1(toggleShow, Curry._1(onChange, key));
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: match$1[0],
                  className: inputClassName,
                  onClick: (function (param) {
                      return Curry._1(toggleShow, undefined);
                    })
                }, React.createElement("div", {
                      className: inputTextClassName
                    }, Belt_Option.getWithDefault(selectedValue, defaultValue), React.createElement(DropDownIcon$LiteracyplanetClientFlags.make, {
                          className: iconClassName
                        })), React.createElement("div", {
                      className: borderClassName
                    })), show ? React.createElement(HtmlBody$LiteracyplanetClientFlags.make, {
                    children: React.createElement(FormSelect$Options, {
                          inputDimensions: match$1[1],
                          options: options,
                          onChange: onChangeAndHideOptions,
                          optionClassName: optionClassName,
                          optionsClassName: optionsClassName
                        })
                  }) : null);
}

var make = FormSelect;

export {
  bodyClassName ,
  Options ,
  useInputBoundingRect ,
  make ,
  
}
/* bodyClassName Not a pure module */
