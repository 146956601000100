// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function ArrowLeftIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              fill: "#0C9CE5",
              focusable: "false",
              viewBox: "0 0 512 512",
              x: "0px",
              xmlns: "http://www.w3.org/2000/svg",
              y: "0px"
            }, React.createElement("path", {
                  d: "M256,504c137,0,248-111,248-248S393,8,256,8S8,119,8,256S119,504,256,504z M256,456c-110.5,0-200-89.5-200-200\n      S145.5,56,256,56s200,89.5,200,200S366.5,456,256,456z M372,288H256v67c0,10.7-12.9,16.1-20.5,8.5l-99-99c-4.7-4.7-4.7-12.3,0-17\n      l99-99c7.6-7.5,20.5-2.2,20.5,8.5v67h116c6.6,0,12,5.4,12,12v40C384,282.6,378.6,288,372,288z"
                }));
}

var make = ArrowLeftIcon;

export {
  make ,
  
}
/* react Not a pure module */
