// Generated by ReScript, PLEASE EDIT WITH CARE

import Activity_containerJsx from "../activity_container.jsx";

var make = Activity_containerJsx;

export {
  make ,
  
}
/* make Not a pure module */
