import {React} from '@literacyplanet/client_core';

export const Page404 = () =>
  <div>Page Not Found</div>;

export const noMatchRoute = {
  path: '*',
  component: Page404,
  name: 'Page Not Found'
};

const queryParams = () => window.location.search;

export const routes = (appApi) => [
  // {
  //   path: '/tx/test_reports',
  //   getComponent: () => import('./TestReports.bs')
  //     .then((m) => m.default)
  // },
  {
    path: '/tx/*',
    getComponent: () => import('./Main.bs')
      .then((m) => m.default)
  },
  {
    path: '/tx/**/*',
    getComponent: () => import('./Main.bs')
      .then((m) => m.default)
  },
  {
    path: '/tx',
    indexRoute: {
      onEnter: (_, replace) => replace('/tx/home' + queryParams())
    },
    childRoutes: [
      noMatchRoute
    ]
  },
  {
    path: '/ax/*',
    getComponent: () => import('./Main.bs')
      .then((m) => m.default)
  },
  {
    path: '/ax/**/*',
    getComponent: () => import('./Main.bs')
      .then((m) => m.default)
  },
  {
    path: '/ax',
    indexRoute: {
      onEnter: (_, replace) => replace('/ax/home' + queryParams())
    },
    childRoutes: [
      noMatchRoute
    ]
  }
];
