/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import WordMonsterImage from './assets/word-monster.png';

export const MissionsIcon = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500.19 499.82"
  >
    <g>
      <path d="M296,40.37l-.15-.15c-.51.51-1.22.91-1.77,1.46-5,3.91-9.9,
      8-14.87,11.94C201.44,118.89,139.81,202.45,90.9,301L199.21,
      409.29c97.82-48.59,180.93-110,245.9-187,5-5.72,9.75-11.57,
      14.41-17.64l.39-.39C496,144.23,500.19,0,500.19,0S356.08,4.32,296,
      40.37Zm88.13,152.22a54.31,54.31,0,1,1,.16-76.88A54.2,54.2,0,0,1,
      384.18,192.59Z"
      />
      <path d="M342.81,335.49c-30.84,23.74-64.63,45.29-101,65.25a74.15,
      74.15,0,0,1-6.63,21.42l-33.2,68a6.64,6.64,0,0,0,9.78,
      8.4l54.08-37a177.9,177.9,0,0,0,77-126.11Z"
      />
      <path d="M164.24,157.23A178.76,178.76,0,0,0,38.21,234L1.23,288c-3.94,
      5.68,2.28,13,8.55,9.94l68.1-33.13A74.11,74.11,0,0,1,99.07,258,845.22,
      845.22,0,0,1,164.24,157.23Z"
      />
      <path d="M97.61,336.8c-11.56-1.93-31-.92-46.61,21.8C27,393,27.2,469,
      2.54,493.64c24.65-24.65,100.65-24.48,135.2-48.3,22.6-15.74,23.58-35.2,
      21.65-46.76L146.83,386a22.28,22.28,0,0,1-7.55,8.32C124.49,404.64,91.69,
      404.49,81,415.22c10.69-10.69,10.54-43.56,20.8-58.39a22.29,22.29,0,0,1,
      8.32-7.55L97.61,336.8Z"
      />
    </g>
  </svg>
);

export const RetryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"></path>
  </svg>
);

export const IconBox = ({children}) => (
  <div style={{width: '40px', height: '40px', paddingRight: '10px'}}>
    {children}
  </div>
);

export const TextBox = ({children}) => (
  <h1
  style={{
    textAlign: 'center',
    color: 'black',
    marginTop: '36px',
    fontSize: 'xx-large',
    lineHeight: '1.5em'
  }}>
    {children}
  </h1>
);

export const RoundButton = ({children, onClick}) => (
  <div onClick={onClick} style={{
    height: '60px',
    width: '280px',
    borderRadius: '30px',
    borderColor: 'white',
    borderWidth: '2px',
    borderStyle: 'solid',
    color: 'white',
    backgroundColor: '#449bdf',
    margin: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }}>
    {children}
  </div>
);

export const LinkButton = ({children, onClick}) => (
  <a onClick={onClick} style={{
    height: '60px',
    width: '280px',
    borderRadius: '30px',
    borderColor: 'white',
    borderWidth: '2px',
    borderStyle: 'solid',
    color: 'white',
    backgroundColor: '#449bdf',
    margin: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textDecoration: 'none'
  }}>
    {children}
  </a>
);


export const ButtonBox = ({children}) => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    {children}
  </div>
);

export const ButtonRow = ({children}) => (
  <div style={{
    textAlign: 'center',
    paddingTop: '6px',
    display: 'flex',
    flexDirection: 'row'
  }}>
    {children}
  </div>
);

export const WordMonsterBox = ({children}) => (
  <div
  style={{
    backgroundImage: `url('${WordMonsterImage}')`,
    backgroundRepeat: 'no-repeat',
    width: '800px',
    height: '594px',
    margin: '50px auto',
    position: 'relative'
  }}>
    {children}
  </div>
);

export const Fullscreen = ({style, children}) => (
  <div style={{
    backgroundColor: 'white',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    fontFamily: 'Lato, sans-serif',
    ...style
  }}>
    {children}
  </div>
);

export const InnerBox = ({children}) => (
  <div
  style={{
    bottom: '0px',
    position: 'absolute',
    height: '215px',
    width: '800px'
  }}>
    {children}
  </div>
);
