// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as StateSerialization$LiteracyplanetTupperware from "../StateSerialization.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.bs.js";

function usePersistedState(persistedState, dispatch) {
  var match = React.useState(function () {
        return false;
      });
  var setReady = match[1];
  var hydrateExerciseStore = function (stringifiedState) {
    Curry._1(dispatch, {
          TAG: /* SetStore */0,
          _0: StateSerialization$LiteracyplanetTupperware.Decode.state(stringifiedState)
        });
    return function (param) {
      return Curry._1(dispatch, /* ResetStore */0);
    };
  };
  React.useEffect((function () {
          var unsubscribe = Belt_Option.map(persistedState, hydrateExerciseStore);
          Curry._1(setReady, (function (param) {
                  return true;
                }));
          return unsubscribe;
        }), []);
  return match[0];
}

function useModeAndPersistedState(assessment, persistedState) {
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch(undefined);
  var dispatch = match[1];
  var ready = usePersistedState(persistedState, dispatch);
  React.useEffect((function () {
          if (Belt_Option.isNone(persistedState)) {
            Curry._1(dispatch, {
                  TAG: /* SetMode */1,
                  _0: assessment ? /* Assessment */0 : /* Activity */1
                });
          }
          
        }), [assessment]);
  return ready;
}

export {
  usePersistedState ,
  useModeAndPersistedState ,
  
}
/* react Not a pure module */
