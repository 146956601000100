// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Css_Legacy_Core from "../../../../../node_modules/bs-css/src/Css_Legacy_Core.bs.js";

function s(prim) {
  return prim;
}

var missionsColor = Css.hex("009fe8");

var missionsHoverColor = Css.hex("0dadf7");

var missionsActiveColor = Css.hex("a3e1fe");

var reportsColor = Css.hex("E67F22");

var reportsHoverColor = Css.hex("E1661B");

var reportsActiveColor = Css.hex("BF5717");

var collectionsColor = Css.hex("374d95");

var collectionsHoverColor = Css.hex("475da4");

var collectionsActiveColor = Css.hex("6c83ce");

var libraryColor = Css.hex("75258e");

var libraryHoverColor = Css.hex("8832a3");

var libraryActiveColor = Css.hex("ab30d1");

var avatarColor = Css.hex("a055b7");

var avatarHoverColor = Css.hex("b169c7");

var avatarActiveColor = Css.hex("d091e3");

var conversationColor = Css.hex("1BBC9B");

var conversationHoverColor = Css.hex("16AF87");

var conversationActiveColor = Css.hex("139573");

var logOutColor = Css.hex("c52c2c");

var logOutHoverColor = Css.hex("b82e2e");

var logOutActiveColor = Css.hex("a62626");

var iconText = Css.rgb(12, 156, 229);

var closeColor = Css.rgb(255, 255, 255);

var closeHoverColor = Css.rgb(218, 240, 251);

var closeActiveColor = Css.rgb(200, 220, 230);

var menuColor = Css.rgb(255, 255, 255);

var menuLogoutColor = Css.rgb(197, 44, 44);

var defaultFonts = {
  hd: {
    NAME: "custom",
    VAL: "Quicksand"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "Lato"
    },
    tl: {
      hd: {
        NAME: "custom",
        VAL: "Arial"
      },
      tl: /* [] */0
    }
  }
};

var defaultBoxShadow = Css_Legacy_Core.Shadow.box(Css.px(4), Css.px(0), Css.px(19), Css.px(2), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.39
        }));

var headerPanel = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.position(Css.fixed),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.boxSizing(Css.borderBox),
              tl: {
                hd: Css.zIndex(10),
                tl: {
                  hd: Css.paddingTop(Css.px(55)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(55)),
                    tl: {
                      hd: Css.paddingLeft(Css.vw(10.0)),
                      tl: {
                        hd: Css.paddingRight(Css.vw(10.0)),
                        tl: {
                          hd: Css.display(Css.grid),
                          tl: {
                            hd: Css.gridAutoColumns(Css.fr(1.0)),
                            tl: {
                              hd: Css.gridAutoFlow(Css.column),
                              tl: {
                                hd: Css.gridGap(Css.px(9)),
                                tl: {
                                  hd: Css.background(Css.linearGradient(Css.deg(180.0), {
                                            hd: [
                                              Css.pct(0.0),
                                              Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                    NAME: "num",
                                                    VAL: 0.8
                                                  })
                                            ],
                                            tl: {
                                              hd: [
                                                Css.pct(18.5),
                                                Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                      NAME: "num",
                                                      VAL: 0.79
                                                    })
                                              ],
                                              tl: {
                                                hd: [
                                                  Css.pct(34.1),
                                                  Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                        NAME: "num",
                                                        VAL: 0.761
                                                      })
                                                ],
                                                tl: {
                                                  hd: [
                                                    Css.pct(47.1),
                                                    Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                          NAME: "num",
                                                          VAL: 0.717
                                                        })
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      Css.pct(57.7),
                                                      Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                            NAME: "num",
                                                            VAL: 0.66
                                                          })
                                                    ],
                                                    tl: {
                                                      hd: [
                                                        Css.pct(66.4),
                                                        Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                              NAME: "num",
                                                              VAL: 0.593
                                                            })
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          Css.pct(73.2),
                                                          Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                NAME: "num",
                                                                VAL: 0.518
                                                              })
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            Css.pct(78.6),
                                                            Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                  NAME: "num",
                                                                  VAL: 0.44
                                                                })
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              Css.pct(82.7),
                                                              Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                    NAME: "num",
                                                                    VAL: 0.36
                                                                  })
                                                            ],
                                                            tl: {
                                                              hd: [
                                                                Css.pct(85.8),
                                                                Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                      NAME: "num",
                                                                      VAL: 0.282
                                                                    })
                                                              ],
                                                              tl: {
                                                                hd: [
                                                                  Css.pct(88.3),
                                                                  Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                        NAME: "num",
                                                                        VAL: 0.207
                                                                      })
                                                                ],
                                                                tl: {
                                                                  hd: [
                                                                    Css.pct(90.4),
                                                                    Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                          NAME: "num",
                                                                          VAL: 0.14
                                                                        })
                                                                  ],
                                                                  tl: {
                                                                    hd: [
                                                                      Css.pct(92.3),
                                                                      Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                            NAME: "num",
                                                                            VAL: 0.083
                                                                          })
                                                                    ],
                                                                    tl: {
                                                                      hd: [
                                                                        Css.pct(94.4),
                                                                        Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                              NAME: "num",
                                                                              VAL: 0.039
                                                                            })
                                                                      ],
                                                                      tl: {
                                                                        hd: [
                                                                          Css.pct(96.8),
                                                                          Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                                NAME: "num",
                                                                                VAL: 0.01
                                                                              })
                                                                        ],
                                                                        tl: {
                                                                          hd: [
                                                                            Css.pct(100.0),
                                                                            Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                                  NAME: "num",
                                                                                  VAL: 0.0
                                                                                })
                                                                          ],
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          })),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function headerItem(highlight, itemColor, hoverColor, activeColor, notificationCount) {
  return Curry._1(Css.style, {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.gridGap(Css.px(6)),
                tl: {
                  hd: Css.gridTemplateColumns({
                        hd: Css.fr(1.0),
                        tl: {
                          hd: Css.auto,
                          tl: {
                            hd: Css.auto,
                            tl: {
                              hd: Css.fr(1.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: {
                    hd: Css.width(Css.auto),
                    tl: {
                      hd: Css.height(Css.px(74)),
                      tl: {
                        hd: Css.borderRadius(Css.px(10)),
                        tl: {
                          hd: Css.borderWidth(Css.px(2)),
                          tl: {
                            hd: Css.borderColor(highlight ? activeColor : Css.transparent),
                            tl: {
                              hd: Css.borderStyle(Css.solid),
                              tl: {
                                hd: Css.backgroundColor(itemColor),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(hoverColor),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.active({
                                          hd: Css.backgroundColor(activeColor),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.textAlign("left"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.fontFamilies(defaultFonts),
                                          tl: {
                                            hd: Css.fontSize(Css.px(20)),
                                            tl: {
                                              hd: Css.fontWeight(Css.bold),
                                              tl: {
                                                hd: Css.fontStyle(Css.normal),
                                                tl: {
                                                  hd: Css.lineHeight(Css.normal),
                                                  tl: {
                                                    hd: Css.letterSpacing(Css.normal),
                                                    tl: {
                                                      hd: Css.color(Css.white),
                                                      tl: {
                                                        hd: Css.boxShadow(defaultBoxShadow),
                                                        tl: {
                                                          hd: Css.position(Css.relative),
                                                          tl: {
                                                            hd: notificationCount > 0 ? Css.after({
                                                                    hd: Css.contentRule({
                                                                          NAME: "text",
                                                                          VAL: String(notificationCount)
                                                                        }),
                                                                    tl: {
                                                                      hd: Css.position(Css.absolute),
                                                                      tl: {
                                                                        hd: Css.display("flex"),
                                                                        tl: {
                                                                          hd: Css.flexDirection("column"),
                                                                          tl: {
                                                                            hd: Css.alignItems("center"),
                                                                            tl: {
                                                                              hd: Css.justifyContent("center"),
                                                                              tl: {
                                                                                hd: Css.fontSize(Css.rem(1.4)),
                                                                                tl: {
                                                                                  hd: Css.fontWeight("bold"),
                                                                                  tl: {
                                                                                    hd: Css.top(Css.px(-14)),
                                                                                    tl: {
                                                                                      hd: Css.right(Css.px(14)),
                                                                                      tl: {
                                                                                        hd: Css.width(Css.px(28)),
                                                                                        tl: {
                                                                                          hd: Css.height(Css.px(28)),
                                                                                          tl: {
                                                                                            hd: Css.color(Css.white),
                                                                                            tl: {
                                                                                              hd: Css.backgroundColor(Css.red),
                                                                                              tl: {
                                                                                                hd: Css.borderRadius(Css.pct(50.0)),
                                                                                                tl: /* [] */0
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }) : Css.position(Css.relative),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var missionIcon = Curry._1(Css.style, {
      hd: Css.width(Css.px(32)),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(Css.rgb(255, 255, 255)),
        tl: /* [] */0
      }
    });

var menuIcon = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.fontFamilies(defaultFonts),
              tl: {
                hd: Css.fontSize(Css.rem(1.4)),
                tl: {
                  hd: Css.fontWeight("bold"),
                  tl: {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.top(Css.px(21)),
                      tl: {
                        hd: Css.right(Css.px(28)),
                        tl: {
                          hd: Css.width(Css.px(70)),
                          tl: {
                            hd: Css.height(Css.px(70)),
                            tl: {
                              hd: Css.color(iconText),
                              tl: {
                                hd: Css.backgroundColor(Css.white),
                                tl: {
                                  hd: Css.borderRadius(Css.pct(50.0)),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.fill(iconText),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var menuOpenIcon = Curry._1(Css.style, {
      hd: Css.width(Css.px(18)),
      tl: {
        hd: Css.height(Css.px(18)),
        tl: /* [] */0
      }
    });

var notificationIcon = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.fontFamilies(defaultFonts),
              tl: {
                hd: Css.fontSize(Css.rem(1.4)),
                tl: {
                  hd: Css.fontWeight("bold"),
                  tl: {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.top(Css.px(14)),
                      tl: {
                        hd: Css.right(Css.px(19)),
                        tl: {
                          hd: Css.width(Css.px(28)),
                          tl: {
                            hd: Css.height(Css.px(28)),
                            tl: {
                              hd: Css.color(Css.white),
                              tl: {
                                hd: Css.backgroundColor(Css.red),
                                tl: {
                                  hd: Css.borderRadius(Css.pct(50.0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var navigationContainer = Curry._1(Css.style, {
      hd: Css.boxSizing("borderBox"),
      tl: {
        hd: Css.borderWidth(Css.px(4)),
        tl: {
          hd: Css.borderColor(Css.white),
          tl: {
            hd: Css.borderStyle("solid"),
            tl: {
              hd: Css.fontFamilies(defaultFonts),
              tl: {
                hd: Css.position("fixed"),
                tl: {
                  hd: Css.top(Css.zero),
                  tl: {
                    hd: Css.right(Css.zero),
                    tl: {
                      hd: Css.display("grid"),
                      tl: {
                        hd: Css.zIndex(30),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.height(Css.pct(100.0)),
                            tl: {
                              hd: Css.gridGap(Css.px(4)),
                              tl: {
                                hd: Css.gridTemplateColumns({
                                      hd: Css.fr(1.0),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.gridTemplateRows({
                                        hd: Css.px(70),
                                        tl: {
                                          hd: Css.fr(1.0),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.userSelect("none"),
                                    tl: {
                                      hd: Css.backgroundColor(Css.white),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var navigationName = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.fontSize(Css.px(30)),
        tl: {
          hd: Css.color(Css.white),
          tl: {
            hd: Css.backgroundColor(iconText),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var navigationLinksContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.height(Css.pct(100.0)),
          tl: {
            hd: Css.flexWrap("wrap"),
            tl: {
              hd: Css.boxSizing("borderBox"),
              tl: {
                hd: Css.backgroundColor(Css.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var closeBtn = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.position("fixed"),
        tl: {
          hd: Css.top(Css.zero),
          tl: {
            hd: Css.right(Css.zero),
            tl: {
              hd: Css.width(Css.px(90)),
              tl: {
                hd: Css.height(Css.px(90)),
                tl: {
                  hd: Css.borderBottomLeftRadius(Css.pct(100.0)),
                  tl: {
                    hd: Css.zIndex(50),
                    tl: {
                      hd: Css.backgroundColor(closeColor),
                      tl: {
                        hd: Css.hover({
                              hd: Css.backgroundColor(closeHoverColor),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.active({
                                hd: Css.backgroundColor(closeActiveColor),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var closeIcon = Curry._1(Css.style, {
      hd: Css.width(Css.pct(30.0)),
      tl: {
        hd: Css.height(Css.pct(30.0)),
        tl: {
          hd: Css.paddingLeft(Css.pct(45.0)),
          tl: {
            hd: Css.paddingTop(Css.pct(25.0)),
            tl: {
              hd: Css.color(iconText),
              tl: /* [] */0
            }
          }
        }
      }
    });

var linkIcon = Curry._1(Css.style, {
      hd: Css.width(Css.px(100)),
      tl: {
        hd: Css.height("auto"),
        tl: /* [] */0
      }
    });

function menuLink(bgColor, hoverColor, activeColor) {
  return Curry._1(Css.style, {
              hd: Css.fontSize(Css.px(24)),
              tl: {
                hd: Css.textAlign("center"),
                tl: {
                  hd: Css.textDecoration("none"),
                  tl: {
                    hd: Css.width(Css.pct(50.0)),
                    tl: {
                      hd: Css.flexGrow(1.0),
                      tl: {
                        hd: Css.minHeight(Css.px(120)),
                        tl: {
                          hd: Css.boxSizing("borderBox"),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.overflow("hidden"),
                              tl: {
                                hd: Css.color(menuColor),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.flexDirection("column"),
                                        tl: {
                                          hd: Css.backgroundColor(bgColor),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.backgroundColor(hoverColor),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.active({
                                                    hd: Css.backgroundColor(activeColor),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.userSelect("none"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var linkText = Curry._1(Css.style, {
      hd: Css.borderBottomStyle("solid"),
      tl: {
        hd: Css.borderBottomWidth(Css.px(3)),
        tl: {
          hd: Css.borderBottomColor("transparent"),
          tl: {
            hd: Css.hover({
                  hd: Css.borderBottomColor(menuColor),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var iconAndText = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var wordManiaColor = libraryColor;

var wordManiaHoverColor = libraryHoverColor;

var wordManiaActiveColor = libraryActiveColor;

var closeIconColor = iconText;

export {
  s ,
  missionsColor ,
  missionsHoverColor ,
  missionsActiveColor ,
  reportsColor ,
  reportsHoverColor ,
  reportsActiveColor ,
  collectionsColor ,
  collectionsHoverColor ,
  collectionsActiveColor ,
  libraryColor ,
  libraryHoverColor ,
  libraryActiveColor ,
  avatarColor ,
  avatarHoverColor ,
  avatarActiveColor ,
  wordManiaColor ,
  wordManiaHoverColor ,
  wordManiaActiveColor ,
  conversationColor ,
  conversationHoverColor ,
  conversationActiveColor ,
  logOutColor ,
  logOutHoverColor ,
  logOutActiveColor ,
  iconText ,
  closeIconColor ,
  closeColor ,
  closeHoverColor ,
  closeActiveColor ,
  menuColor ,
  menuLogoutColor ,
  defaultFonts ,
  defaultBoxShadow ,
  headerPanel ,
  headerItem ,
  missionIcon ,
  menuIcon ,
  menuOpenIcon ,
  notificationIcon ,
  navigationContainer ,
  navigationName ,
  navigationLinksContainer ,
  closeBtn ,
  closeIcon ,
  linkIcon ,
  menuLink ,
  linkText ,
  iconAndText ,
  
}
/* missionsColor Not a pure module */
