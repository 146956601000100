// Jump starting CobraLauncher Jenkins
import React from 'react';
import equal from 'deep-equal';
import load from './load';
import 'whatwg-fetch';

const containerStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%'
};

const canvasStyle = {
  display: 'block',
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto'
};

const cobraHTMLStyle = {
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto'
};

export class CobraLauncher extends React.Component {
  loadCobra() {
    this.cobra = load(this.props);
  }

  renderCobra(props) {
    this.cobra.then(({render}) => render(props));
  }

  cleanupCobra() {
    this.cobra.then(({cleanup}) => cleanup());
  }

  shouldComponentUpdate(nextProps) {
    const update = !equal(nextProps.props, this.props.props);
    return update;
  }

  componentDidMount() {
    this.loadCobra();
    this.renderCobra(this.props.props);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.name !== this.props.name) {
      this.cleanupCobra();
      this.loadCobra();
    }
    this.renderCobra(this.props.props);
  }

  componentWillUnmount() {
    this.cleanupCobra();
  }

  render() {
    return (
      <div id={this.props.name + '-cobra-container'} style={containerStyle}>
        <canvas id={this.props.name + '-cobra-canvas'} style={canvasStyle}/>
        <div id={this.props.name + '-cobra-html'} style={cobraHTMLStyle}/>
      </div>
    );
  }
}

// CobraLauncher.propTypes = {
//   props: PropTypes.object,
//   name: PropTypes.string,
//   view: PropTypes.string,
//   width: PropTypes.string,
//   height: PropTypes.string,
//   onDispatch: PropTypes.object
// };
