// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FlagsContext$LiteracyplanetClientFlags from "./FlagsContext.bs.js";

function experienceToString(ex) {
  switch (ex) {
    case /* Secondary */0 :
        return "Secondary";
    case /* Journey */1 :
        return "Journey";
    case /* Storyverse */2 :
        return "Storyverse";
    
  }
}

function navBarToString(ex) {
  switch (ex) {
    case /* Modern */0 :
        return "Modern";
    case /* Barless */1 :
        return "Barless";
    case /* Bubble */2 :
        return "Bubble";
    case /* Papyrus */3 :
        return "Papyrus";
    
  }
}

function menuToString(ex) {
  switch (ex) {
    case /* Initials */0 :
        return "Initials";
    case /* JourneyInitials */1 :
        return "JourneyInitials";
    case /* Menuless */2 :
        return "Menuless";
    
  }
}

function whatExperience(hasFlag) {
  if (Curry._1(hasFlag, "StudentHasSecondary")) {
    return /* Secondary */0;
  } else if (Curry._1(hasFlag, "StudentHasStoryverse")) {
    return /* Storyverse */2;
  } else {
    return /* Journey */1;
  }
}

function whatNav(studentExperience) {
  switch (studentExperience) {
    case /* Secondary */0 :
        return /* Modern */0;
    case /* Journey */1 :
        return /* Bubble */2;
    case /* Storyverse */2 :
        return /* Papyrus */3;
    
  }
}

function whatMenu(studentExperience) {
  return studentExperience;
}

function api_changeExperience(param) {
  
}

function api_changeMenu(param) {
  
}

function api_changeNavBar(param) {
  
}

var api = {
  whatExperience: /* Journey */1,
  whatMenu: /* Menuless */2,
  whatNavBar: /* Barless */1,
  changeExperience: api_changeExperience,
  changeMenu: api_changeMenu,
  changeNavBar: api_changeNavBar
};

var context = React.createContext(api);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

var changeExperience = whatNav;

function Wrap$Provider(Props) {
  var children = Props.children;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var match = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* SetExperience */0 :
                var whatExperience = action._0;
                return {
                        whatExperience: whatExperience,
                        whatMenu: whatExperience,
                        whatNavBar: whatNav(whatExperience)
                      };
            case /* SetWhatMenu */1 :
                return {
                        whatExperience: state.whatExperience,
                        whatMenu: action._0,
                        whatNavBar: state.whatNavBar
                      };
            case /* SetNavBar */2 :
                return {
                        whatExperience: state.whatExperience,
                        whatMenu: state.whatMenu,
                        whatNavBar: action._0
                      };
            
          }
        }), {
        whatExperience: whatExperience(hasFlag),
        whatMenu: whatExperience(hasFlag),
        whatNavBar: whatNav(whatExperience(hasFlag))
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var changeExperience = function (sx) {
    return Curry._1(dispatch, {
                TAG: /* SetExperience */0,
                _0: sx
              });
  };
  var changeMenu = function (m) {
    return Curry._1(dispatch, {
                TAG: /* SetWhatMenu */1,
                _0: m
              });
  };
  var changeNavBar = function (nb) {
    return Curry._1(dispatch, {
                TAG: /* SetNavBar */2,
                _0: nb
              });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(make, makeProps({
                      whatExperience: match$1.whatExperience,
                      whatMenu: match$1.whatMenu,
                      whatNavBar: match$1.whatNavBar,
                      changeExperience: changeExperience,
                      changeMenu: changeMenu,
                      changeNavBar: changeNavBar
                    }, children, undefined)));
}

var Provider = {
  make: Wrap$Provider
};

function useStudentExperience(param) {
  return React.useContext(context).whatExperience;
}

function useNavBar(param) {
  return React.useContext(context).whatNavBar;
}

function useMenu(param) {
  return React.useContext(context).whatMenu;
}

function useApi(param) {
  return React.useContext(context);
}

var state = {
  whatExperience: /* Journey */1,
  whatMenu: /* Menuless */2,
  whatNavBar: /* Barless */1
};

export {
  experienceToString ,
  navBarToString ,
  menuToString ,
  whatExperience ,
  whatNav ,
  whatMenu ,
  api ,
  Context ,
  state ,
  changeExperience ,
  Provider ,
  useStudentExperience ,
  useNavBar ,
  useMenu ,
  useApi ,
  
}
/* context Not a pure module */
