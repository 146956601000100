/* eslint-disable max-len */
import styled from '@emotion/styled';

export const Col = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 96vh 1fr;
  grid-template-rows: 0.5fr auto 1fr;
  grid-template-areas:
  "leftgap message rightgap"
  "leftgap grid rightgap"
  "footer footer footer";
  @media (orientation: portrait) {
    grid-template-columns: 1fr 50vh 1fr;
  }
`;

export const RightGap = styled.div`
  grid-area: rightgap;
`;

export const LeftGap = styled.div`
  grid-area: leftgap;
`;

export const NameTitle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  color: #ffffff;
  text-align: right;
  font-size: 3.6vh;
  font-weight: bold;
  margin: 0;
  margin-top: 4vh;
  display: flex;
  justify-content: center;
`;

export const Grid = styled.div`
  grid-area: grid;
  display: grid;
  grid-template-columns: 44vh 44vh;
  grid-auto-rows: 44vh;
  grid-row-gap: 4vh;
  grid-column-gap: 4vh;
  margin-top: 16vh;
  margin-left: 2vh;
  margin-right: 2vh;
  margin-bottom: 10vh;
  font-size: 3.2vh;
  justify-items: center;
  align-items: center;
  @media (orientation: portrait) {
    grid-template-columns: 21vh 21vh;
    grid-auto-rows: 21vh;
    font-size: 1.8vh;
  }
`;

export const Footer = styled.div`
  grid-area: footer;
  width: 100%;
`;

export const LevelButton = styled.div`
  display: inline-block;
  color: white;
  border: 4px solid white;
  border-radius: 4vh;
  width: 10vh;
  height: 4vh;
  margin: 0;
  text-align: center;
  vertical-align: middle;
`;

export const BookThumb = styled.div`
  position: relative;
`;

export const BookThumbnail = styled.img`
  color: white;
  height: 40vh;
  box-shadow: 0 0 1rem black;
  @media (orientation: portrait) {
    height: 20vh;
  }
  user-select: none;
  user-drag: none;
  cursor: pointer;

  &:hover {
    border-color: #FFFFFF;
    border-width: 0.6vh;
    border-style: solid;
    border-radius: 0;
  }
`;

export const BookLevel = styled.div`
  font-family: Quicksand, Lato, Arial;
  font-size: 2.6vh;
  font-weight: bold;
  color: white;
  width: 12vh;
  height: 6vh;
  background-color: rgb(12, 156, 229);
  position: absolute;
  bottom: 0.6vh;
  right: 0;
  border-top-left-radius: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (orientation: portrait) {
    font-size: 1.3vh;
    width: 6vh;
    height: 3vh;
    bottom: 0.3vh;
    border-top-left-radius: 0.5vh;
  }
`;

export const BookView = styled.div`
  margin-top: 16vh;
`;

export const HeaderDropdown = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 25;
  font-family: Quicksand, Lato, Arial;
  font-size: 3.6vh;
  font-weight: bold;
  color: white;
  margin-top: 4vh;
  margin-bottom: 4vh;
  user-select: none;
  cursor: pointer;
`;

export const BackButton = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 16.5vh;
  height: 5.4vh;
  background-image: url('${props => props.src}');
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-content: flex-start;
  font-size: 2.6vh;
  font-weight: bold;
  z-index: 200;
  cursor: pointer;
  filter: drop-shadow( 1vh 1vh 1vh rgba(0, 0, 0, .7));
  
  color: #0c9ce5;
  &:hover {
    color: #0077b3;
  }
  &:active {
    color: #005580;
  }
`;

export const BackButtonIcon = styled.div`
  height: 5.4vh;
  display: flex;
  flex-direction: row;
  align-items: center;

  #LeftArrowIcon {
    margin-left: 2vh;
    height: 4vh;
  }

  #BookIcon {
    margin-left: 1vh;
    height: 4vh;
  }
`;

// PdfViewer
export const ArrowButton = styled.img`
  cursor: pointer;
  align-self: center;
  padding: 1vh;
  filter: drop-shadow( 1vh 1vh 1vh rgba(0, 0, 0, .7));
  
  grid-row: 1;
  width: 11vh;
  height: 11vh;
  &:hover {
    // Brent couldn't get the colours to change for hover/active for this component.
    // So just change the size instead
    width: 12vh;
    height: 12vh;
  }
  
  @media (orientation: portrait) {
    grid-row: 3;
    width: 20vw;
    height: 20vw;

    &:hover {
      width: 20vw;
      height: 20vw;

      // Don't do this because it moves the book too much
      //  width: 21vw;
      //  height: 21vw;
    }
  }
  
  user-select: none;
  user-drag: none;
`;

export const LeftArrowButton = styled(ArrowButton)`
  grid-column: 1;
  justify-self: end;
`;

export const RightArrowButton = styled(ArrowButton)`
  grid-column: 3;
  justify-self: start;
`;

export const LeftBlankButton = styled.div`
  grid-column: 1;
  width: 11vh;
  height: 11vh;
  grid-row: 1;
  @media (orientation: portrait) {
    grid-row: 3;
  }
`;

export const RightBlankButton = styled.div`
  grid-column: 3;
  width: 11vh;
  height: 11vh;
  grid-row: 1;
  @media (orientation: portrait) {
    grid-row: 3;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  @media (orientation: portrait) {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr auto auto 1fr;
  }
`;

export const StyledDocument = styled.div`
  grid-column: 2;
  justify-self: center;
  align-self: center;
  @media (orientation: portrait) {
    grid-column: 1/4;
    grid-row: 2;
  }
`;
