import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {
  OpenIdCallbackComponent,
  openIdUserManager,
  openIdProcessSilentRenew
} from './redux/open_id_connect';
import {push as pushRoute} from 'react-router-redux';
const teacherHome = process.env.TEACHER_HOME || '/tx/home';
const studentHome = process.env.STUDENT_HOME || '/sx/home';

const ErrorWrapper = ({children}) => (
    <div style={{
      padding: '4rem',
      fontFamily: 'sans-serif',
      zIndex: '100',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'start'
    }}>
      <div style={{
        minWidth: '400px',
        padding: '2rem',
        border: '1px solid black'
      }}>
      {children}
    </div>
  </div>
);

ErrorWrapper.propTypes = {
  children: PropTypes.nodes
};

export const ErrorPage = () => (
  <ErrorWrapper>
    <h1>Error Logging In</h1>
  </ErrorWrapper>
);
export const Logout = () => {
  const reason = encodeURIComponent('Something went wrong. Please try and login again');
  const logoutUrl = `${process.env.LOGIN_HOST}/logout?reason=${reason}`;
  const [show, setShow] = React.useState(() => false);

  React.useEffect(() => {
    const timeout = setTimeout(() => setShow(() => true), 2000);
    window.location.href = logoutUrl;
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return null;

  return (
    <ErrorWrapper>
      <h3>Something happened checking authentication.</h3>
      <p>please <a href={logoutUrl}>log out</a> and try again.</p>
    </ErrorWrapper>
  );
};

class CallbackPage extends React.Component {
  /* eslint-disable no-invalid-this */
  successCallback = (user) => {
    openIdUserManager.clearStaleState();
    let returnTo = sessionStorage.getItem('lpReturnTo');
    if (returnTo !== null) {
      sessionStorage.removeItem('lpReturnTo');
      this.props.dispatch(pushRoute(returnTo));
    } else {
      let route = studentHome;
      if (user.profile.user_type === 'teacher') {
        route = teacherHome;
      }
      this.props.dispatch(pushRoute(route));
    }
  }

  errorCallback = (err) => {
    console.error('OIDC error', err.message);
    openIdUserManager.clearStaleState();
    openIdUserManager.storeUser()
      .then(() => {
        openIdSilentRenew({dispatch: this.props.dispatch}, openIdUserManager);
      })
      .catch(() => {
        const errorPath = process.env.MOBILE ? '/error_page' : '/logout';
        this.props.dispatch(pushRoute(errorPath));
      });
  }
  /* eslint-enable no-invalid-this */

  render() {
    // just redirect to '/' in both cases
    return (
      <OpenIdCallbackComponent
        userManager={openIdUserManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div>
          Redirecting...
        </div>
      </OpenIdCallbackComponent>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

CallbackPage.propTypes = {
  dispatch: PropTypes.func
};

export default connect(null, mapDispatchToProps)(CallbackPage);


export const SilentCallbackPage = () => {
  openIdProcessSilentRenew();
  openIdUserManager.clearStaleState();
  return (
    <div></div>
  );
};
