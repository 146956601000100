export default () => {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount < 1) return;
  const range = selection.getRangeAt(0);
  const node = selection.anchorNode;
  const wordRegexp = /^\w*$/;
  let word;

  try {
    // Extend the range backward until it matches word beginning
    while ((range.startOffset > 0) && range.toString().match(wordRegexp)) {
      range.setStart(node, (range.startOffset - 1));
    }
    // Restore the valid word match after overshooting
    if (!range.toString().match(wordRegexp)) {
      range.setStart(node, range.startOffset + 1);
    }
    // Extend the range forward until it matches word ending
    while ((range.endOffset < node.length) && range.toString().match(wordRegexp)) {
      range.setEnd(node, range.endOffset + 1);
    }
    // Restore the valid word match after overshooting
    if (!range.toString().match(wordRegexp)) {
      range.setEnd(node, range.endOffset - 1);
    }
    word = range.toString().toLowerCase();
  } catch(_) {
    // Range setting has failed so we don't have a word
    // NOOP
  }

  // Clear the selection after we have the word
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    document.selection.empty();
  }

  return word;
};
