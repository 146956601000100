// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function defaultCb(param) {
  
}

function defaultDispatchables_onToolbarExit(unit) {
  return unit;
}

var defaultDispatchables = {
  onInitialized: defaultCb,
  onPlay: defaultCb,
  onPause: defaultCb,
  onScrubStart: defaultCb,
  onScrubEnd: defaultCb,
  onMute: defaultCb,
  onVolumeChange: defaultCb,
  onEnded: defaultCb,
  onTerminated: defaultCb,
  onToolbarExit: defaultDispatchables_onToolbarExit
};

var context = React.createContext(defaultDispatchables);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var Context = {
  context: context,
  makeProps: makeProps,
  make: make,
  use: use
};

function FlipperVideoDispatch$Provider(Props) {
  var dispachables = Props.dispachables;
  var children = Props.children;
  return React.createElement(make, makeProps(dispachables, children, undefined));
}

var Provider = {
  make: FlipperVideoDispatch$Provider
};

export {
  defaultCb ,
  defaultDispatchables ,
  Context ,
  Provider ,
  
}
/* context Not a pure module */
