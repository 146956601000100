// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as StimulusLayout$LiteracyplanetTupperware from "./StimulusLayout.bs.js";
import * as K2ExerciseInSeriesContainer$LiteracyplanetTupperware from "./K2ExerciseInSeriesContainer.bs.js";

function PieceOfCakeLayoutContainer$Layout(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.Layout.className
            }, children);
}

var Layout = {
  make: PieceOfCakeLayoutContainer$Layout
};

function PieceOfCakeLayoutContainer(Props) {
  var stimulusId = Props.stimulusId;
  var stimulusType = Props.stimulusType;
  var questionCount = Props.questionCount;
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement("link", {
                  href: "https://use.typekit.net/jqw0veo.css",
                  rel: "stylesheet"
                }), React.createElement(K2ExerciseInSeriesContainer$LiteracyplanetTupperware.make, {
                  questionCount: questionCount,
                  children: React.createElement(StimulusLayout$LiteracyplanetTupperware.make, {
                        stimuliPresent: true,
                        stimulusId: stimulusId,
                        stimulusType: stimulusType,
                        children: React.createElement(PieceOfCakeLayoutContainer$Layout, {
                              children: children
                            })
                      })
                }));
}

var make = PieceOfCakeLayoutContainer;

var $$default = PieceOfCakeLayoutContainer;

export {
  Layout ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
