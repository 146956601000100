// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Cobra_launcher from "@literacyplanet/cobra_launcher";
import * as CobraGenericBehaviourContext$LiteracyplanetClientFlags from "./CobraGenericBehaviourContext.bs.js";

var merge = (function (a, b) {
    return {
      ...a,
      ...b
    }
  });

function CobraContainer(Props) {
  var view = Props.view;
  var p = Props.props;
  var d = Props.onDispatch;
  var match = Curry._1(CobraGenericBehaviourContext$LiteracyplanetClientFlags.use, undefined);
  var onDispatch = match.onDispatch;
  var props = match.props;
  return React.useMemo((function () {
                return React.createElement(Cobra_launcher.CobraLauncher, {
                            width: "1280",
                            height: "768",
                            view: view,
                            name: view,
                            props: merge(p, props),
                            onDispatch: merge(d, onDispatch)
                          });
              }), [
              p,
              props,
              view
            ]);
}

var make = CobraContainer;

export {
  merge ,
  make ,
  
}
/* react Not a pure module */
