// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";

function arrayToList(a) {
  var _i = a.length - 1 | 0;
  var _res = /* [] */0;
  while(true) {
    var res = _res;
    var i = _i;
    if (i < 0) {
      return res;
    }
    _res = {
      hd: a[i],
      tl: res
    };
    _i = i - 1 | 0;
    continue ;
  };
}

function use(getPath) {
  var raw = Curry._1(getPath, undefined);
  switch (raw) {
    case "" :
    case "/" :
        return /* [] */0;
    default:
      var raw$1 = raw.slice(1);
      var match = raw$1[raw$1.length - 1 | 0];
      var raw$2 = match === "/" ? raw$1.slice(0, -1) : raw$1;
      return arrayToList(raw$2.split("/"));
  }
}

export {
  arrayToList ,
  use ,
  
}
/* No side effect */
