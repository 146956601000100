import {silentRenewStarted, silentRenewError} from '../actions';

// stores the redux store here to be accessed by all functions
let reduxStore;

// helper function to set the redux store (for testing)
export function setReduxStore(newStore) {
  reduxStore = newStore;
}

// helper function to get the redux store (for testing)
export function getReduxStore() {
  return reduxStore;
}

// error callback called when the userManager's loadUser() function failed
export function errorCallback(error) {
  console.error(`redux-oidc: Error in silentRenew() function: ${error.message}`);
  reduxStore.dispatch(silentRenewError(error));
}

// function to load the current user into the store
// NOTE: use only when silent renew is configured
export default function silentRenew(store, userManager) {
  if (!store || !store.dispatch) {
    throw new Error('redux-oidc: You need to pass the redux store into the silentRenew helper!');
  }

  if (!userManager || !userManager.signinSilent) {
    throw new Error('redux-oidc: You need to pass the userManager into the silentRenew helper!');
  }

  reduxStore = store;
  store.dispatch(silentRenewStarted());

  return userManager.signinSilent().
    catch(errorCallback);
}
