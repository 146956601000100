// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ForestHeader$LiteracyplanetClientSxEntry from "./ForestHeader.bs.js";
import * as ForestLayout$LiteracyplanetClientSxEntry from "./ForestLayout.bs.js";
import * as PapyrusComponents$LiteracyplanetClientSxEntry from "./PapyrusComponents.bs.js";
import * as StoryversePanelContext$LiteracyplanetClientSxEntry from "../StoryversePanelContext.bs.js";
import * as StoryverseNavigationQuery$LiteracyplanetClientSxEntry from "../StoryverseNavigationQuery.bs.js";

function ForestNavBar(Props) {
  var userName = Props.userName;
  var onClickMissions = Props.onClickMissions;
  var onClickIntrepizine = Props.onClickIntrepizine;
  var onClickMyWords = Props.onClickMyWords;
  var onClickLibrary = Props.onClickLibrary;
  var onClickBog = Props.onClickBog;
  var onClickCollections = Props.onClickCollections;
  var lockArcade = Props.lockArcade;
  var onClickArcade = Props.onClickArcade;
  var lockShop = Props.lockShop;
  var onClickShop = Props.onClickShop;
  var showLibraryLink = Props.showLibraryLink;
  var showWordManiaLink = Props.showWordManiaLink;
  var onClickReports = Props.onClickReports;
  var onClickWordMania = Props.onClickWordMania;
  var onClickLogout = Props.onClickLogout;
  var showTeacherModeWidget = Props.showTeacherModeWidget;
  var teacherModeLink = Props.teacherModeLink;
  var match = Curry._1(StoryversePanelContext$LiteracyplanetClientSxEntry.Context.use, undefined);
  var panelOpen = match.panelOpen;
  var navigation = StoryverseNavigationQuery$LiteracyplanetClientSxEntry.use(!panelOpen);
  return React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.ScrollPanel.make, {
              children: null
            }, React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.Spacer.make, {}), React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.HeaderPanel.make, {
                  children: null
                }, React.createElement(ForestHeader$LiteracyplanetClientSxEntry.make, {
                      onClickMissions: onClickMissions,
                      onClickIntrepizine: onClickIntrepizine,
                      onClickMyWords: onClickMyWords,
                      onClickLibrary: onClickLibrary,
                      onClickBog: onClickBog,
                      onClickCollections: onClickCollections,
                      lockArcade: lockArcade,
                      onClickArcade: onClickArcade,
                      lockShop: lockShop,
                      onClickShop: onClickShop,
                      showLibraryLink: showLibraryLink,
                      showWordManiaLink: showWordManiaLink,
                      onClickWordMania: onClickWordMania,
                      onClickReports: onClickReports,
                      onClickLogout: onClickLogout,
                      teacherModeLink: teacherModeLink,
                      showTeacherModeWidget: showTeacherModeWidget
                    }), panelOpen ? React.createElement(ForestLayout$LiteracyplanetClientSxEntry.make, {
                        userName: userName,
                        navigation: navigation,
                        onClickBog: onClickBog,
                        onClickCollections: onClickCollections,
                        lockArcade: lockArcade,
                        onClickArcade: onClickArcade,
                        lockShop: lockShop,
                        onClickShop: onClickShop,
                        closeHeaderPanel: match.closePanel
                      }) : React.createElement(PapyrusComponents$LiteracyplanetClientSxEntry.Spacer.make, {})));
}

var make = ForestNavBar;

export {
  make ,
  
}
/* react Not a pure module */
