// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.bs.js";
import * as NextArrow$LiteracyplanetTupperware from "./NextArrow.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as NextButtonStatesHook$LiteracyplanetTupperware from "./NextButtonStatesHook.bs.js";
import * as K2MarkButtonStatesHook$LiteracyplanetTupperware from "./K2MarkButtonStatesHook.bs.js";
import * as BehaviourActivityMarkClassNames$LiteracyplanetTupperware from "./BehaviourActivityMarkClassNames.bs.js";

function K2Answers$Answers(Props) {
  var divRef = Props.divRef;
  var children = Props.children;
  return React.createElement("div", {
              ref: divRef,
              className: Flavor$LiteracyplanetTupperware.K2Answers.Answers.className
            }, children);
}

var Answers = {
  make: K2Answers$Answers
};

function K2Answers$SubmitCheckbox(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 512 445",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("polygon", {
                  points: "202.6,445 0,258.4 70.5,181.8 192,293.7 431.4,1 512,66.9 "
                }));
}

var SubmitCheckbox = {
  make: K2Answers$SubmitCheckbox
};

function K2Answers$Tick(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 292 254",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("polygon", {
                  points: "115.7 253.1 0.6 147.1 40.6 103.6 109.6 167.1 245.6 0.9 291.3 38.3"
                }));
}

var Tick = {
  make: K2Answers$Tick
};

function K2Answers$Cross(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 244 244",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("polygon", {
                  points: "243.2 202.3 202.3 243.2 122 162.9 41.7 243.2 0.8 202.3 81.1 122 0.8 41.7 41.7 0.8 122 81.1 202.3 0.8 243.2 41.7 162.9 122"
                }));
}

var Cross = {
  make: K2Answers$Cross
};

function K2Answers$MarkStatusPanel(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.MarkStatusPanel.className, className)
            }, children);
}

var MarkStatusPanel = {
  make: K2Answers$MarkStatusPanel
};

function K2Answers$Button(Props) {
  var onClick = Props.onClick;
  var className = Props.className;
  var children = Props.children;
  var tmp = {
    className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.Button.className, className)
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("div", tmp, children);
}

var Button = {
  make: K2Answers$Button
};

function K2Answers$MarkButton(Props) {
  var onClick = Props.onClick;
  var className = Props.className;
  var tmp = {
    className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.MarkButton.className, className),
    children: null
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(K2Answers$Button, tmp, React.createElement("div", undefined, "Submit"), React.createElement(K2Answers$SubmitCheckbox, {
                  className: Flavor$LiteracyplanetTupperware.K2Answers.iconCss
                }));
}

var MarkButton = {
  make: K2Answers$MarkButton
};

var enabledCss = Flavor$LiteracyplanetTupperware.K2Answers.BehaviourMarkButton.enabledCss;

function K2Answers$BehaviourMarkButton(Props) {
  var markState = K2MarkButtonStatesHook$LiteracyplanetTupperware.use(undefined);
  if (typeof markState === "number") {
    if (markState !== 0) {
      return null;
    } else {
      return React.createElement(K2Answers$MarkButton, {
                  className: Flavor$LiteracyplanetTupperware.K2Answers.disabledCss
                });
    }
  }
  var cb = markState._0;
  return React.createElement(K2Answers$MarkButton, {
              onClick: (function (param) {
                  return Curry._1(cb, undefined);
                }),
              className: enabledCss
            });
}

var BehaviourMarkButton = {
  enabledCss: enabledCss,
  make: K2Answers$BehaviourMarkButton
};

function K2Answers$NextButton(Props) {
  var label = Props.label;
  var onClick = Props.onClick;
  var className = Props.className;
  var tmp = {
    className: Curry._1(Flavor$LiteracyplanetTupperware.K2Answers.NextButton.className, className),
    children: null
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(K2Answers$Button, tmp, React.createElement("div", undefined, label), React.createElement(NextArrow$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.K2Answers.iconCss
                }));
}

var NextButton = {
  make: K2Answers$NextButton
};

var enabledCss$1 = Flavor$LiteracyplanetTupperware.K2Answers.BehaviourNextButton.enabledCss;

function K2Answers$BehaviourNextButton(Props) {
  var markState = NextButtonStatesHook$LiteracyplanetTupperware.use(undefined);
  if (typeof markState === "number") {
    if (markState === /* ActivityEnded */0) {
      return React.createElement(K2Answers$NextButton, {
                  label: "Complete",
                  className: Flavor$LiteracyplanetTupperware.K2Answers.disabledCss
                });
    } else {
      return null;
    }
  }
  if (markState.TAG === /* FinishActivity */0) {
    var cb = markState._0;
    return React.createElement(K2Answers$NextButton, {
                label: "Complete",
                onClick: (function (param) {
                    return Curry._1(cb, undefined);
                  }),
                className: enabledCss$1
              });
  }
  var cb$1 = markState._0;
  return React.createElement(K2Answers$NextButton, {
              label: "Next",
              onClick: (function (param) {
                  return Curry._1(cb$1, undefined);
                }),
              className: enabledCss$1
            });
}

var BehaviourNextButton = {
  enabledCss: enabledCss$1,
  make: K2Answers$BehaviourNextButton
};

var setScrollTop = ((el, num) => el.scrollTo(0, num));

function useSetScrollOnMarkedAndNewQuestion(param) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var divRef = React.useRef(null);
  var marked = Reducer$LiteracyplanetTupperware.isMarked(match.currentQuestionStep);
  React.useEffect((function () {
          Belt_Option.map(Caml_option.nullable_to_opt(divRef.current), (function (div) {
                  if (marked) {
                    return setScrollTop(div, 100000);
                  } else {
                    return setScrollTop(div, 0);
                  }
                }));
          
        }), [
        match.currentQuestionIndex,
        marked
      ]);
  return divRef;
}

var correctClassName = Flavor$LiteracyplanetTupperware.K2Answers.correctClassName;

var incorrectClassName = Flavor$LiteracyplanetTupperware.K2Answers.incorrectClassName;

var markClassname = Flavor$LiteracyplanetTupperware.K2Answers.markClassname;

var textLabelClassname = Flavor$LiteracyplanetTupperware.K2Answers.textLabelClassname;

var submitAnswerPanel = Flavor$LiteracyplanetTupperware.K2Answers.submitAnswerPanel;

function K2Answers(Props) {
  var children = Props.children;
  var divRef = useSetScrollOnMarkedAndNewQuestion(undefined);
  return React.createElement(K2Answers$Answers, {
              divRef: divRef,
              children: null
            }, children, React.createElement(BehaviourActivityMarkClassNames$LiteracyplanetTupperware.make, {
                  children: null
                }, React.createElement(K2Answers$MarkStatusPanel, {
                      className: correctClassName,
                      children: null
                    }, React.createElement(K2Answers$Tick, {
                          className: markClassname
                        }), React.createElement("div", {
                          className: textLabelClassname
                        }, "Correct")), React.createElement(K2Answers$MarkStatusPanel, {
                      className: incorrectClassName,
                      children: null
                    }, React.createElement(K2Answers$Cross, {
                          className: markClassname
                        }), React.createElement("div", {
                          className: textLabelClassname
                        }, "Incorrect")), React.createElement("div", {
                      className: submitAnswerPanel
                    }, React.createElement(K2Answers$BehaviourMarkButton, {}), React.createElement(K2Answers$BehaviourNextButton, {}))));
}

var make = K2Answers;

var $$default = K2Answers;

export {
  Answers ,
  SubmitCheckbox ,
  Tick ,
  Cross ,
  MarkStatusPanel ,
  Button ,
  MarkButton ,
  BehaviourMarkButton ,
  NextButton ,
  BehaviourNextButton ,
  setScrollTop ,
  useSetScrollOnMarkedAndNewQuestion ,
  correctClassName ,
  incorrectClassName ,
  markClassname ,
  textLabelClassname ,
  submitAnswerPanel ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
