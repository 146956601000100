// Generated by ReScript, PLEASE EDIT WITH CARE


var unleashFeatures = [
  "AuthCanAccessMorphAlpha",
  "AuthCanAccessMorphBeta",
  "AuthCanAccessMorphShowcase",
  "CanAccessCustomWordLists",
  "DeveloperArcadeGames",
  "DeveloperTestingIpad",
  "StudentCanUseLibrary",
  "StudentHasLiteracyCheck",
  "StudentHasStoryverse",
  "StudentHasTileCurrency",
  "StudentHasArcade",
  "StudentHasLocking",
  "StudentHasSecondary",
  "TeacherCanFilterListsOnAuthor",
  "TeacherHasLiteracyCheck"
];

function stringToFlag(x) {
  switch (x) {
    case "AuthCanAccessMorphAlpha" :
        return "AuthCanAccessMorphAlpha";
    case "AuthCanAccessMorphBeta" :
        return "AuthCanAccessMorphBeta";
    case "AuthCanAccessMorphShowcase" :
        return "AuthCanAccessMorphShowcase";
    case "AuthFullAccess" :
        return "AuthFullAccess";
    case "AuthProAccess" :
        return "AuthProAccess";
    case "AuthStarterAccess" :
        return "AuthStarterAccess";
    case "CanAccessAx" :
        return "CanAccessAx";
    case "CanAccessAxReports" :
        return "CanAccessAxReports";
    case "CanAccessCustomWordLists" :
        return "CanAccessCustomWordLists";
    case "CanAccessWordMania" :
        return "CanAccessWordMania";
    case "DeveloperArcadeGames" :
        return "DeveloperArcadeGames";
    case "DeveloperTestingIpad" :
        return "DeveloperTestingIpad";
    case "DeveloperTestingLayouts" :
        return "DeveloperTestingLayouts";
    case "DeveloperTestingLocales" :
        return "DeveloperTestingLocales";
    case "HomeProduct" :
        return "HomeProduct";
    case "InTrial" :
        return "InTrial";
    case "IsAdmin" :
        return "IsAdmin";
    case "IsDeveloper" :
        return "IsDeveloper";
    case "IsParent" :
        return "IsParent";
    case "IsPlg" :
        return "IsPlg";
    case "LibraryProduct" :
        return "LibraryProduct";
    case "PLGProduct" :
        return "PLGProduct";
    case "PlaySoundOnWordClick" :
        return "PlaySoundOnWordClick";
    case "SchoolProduct" :
        return "SchoolProduct";
    case "SchoolProductTrial" :
        return "SchoolProductTrial";
    case "StudentCanAccessWM" :
        return "StudentCanAccessWM";
    case "StudentCanUseLibrary" :
        return "StudentCanUseLibrary";
    case "StudentHasArcade" :
        return "StudentHasArcade";
    case "StudentHasJourneyIntro" :
        return "StudentHasJourneyIntro";
    case "StudentHasLiteracyCheck" :
        return "StudentHasLiteracyCheck";
    case "StudentHasLocking" :
        return "StudentHasLocking";
    case "StudentHasPrimaryUx" :
        return "StudentHasPrimaryUx";
    case "StudentHasSecondary" :
        return "StudentHasSecondary";
    case "StudentHasStoryverse" :
        return "StudentHasStoryverse";
    case "StudentHasTileCurrency" :
        return "StudentHasTileCurrency";
    case "StudentIsStudentMode" :
        return "StudentIsStudentMode";
    case "TeacherCanFilterListsOnAuthor" :
        return "TeacherCanFilterListsOnAuthor";
    case "TeacherCanManageTeachingGroups" :
        return "TeacherCanManageTeachingGroups";
    case "TeacherHasLiteracyCheck" :
        return "TeacherHasLiteracyCheck";
    case "TeacherProduct" :
        return "TeacherProduct";
    case "TeacherProductProAccess" :
        return "TeacherProductProAccess";
    case "TeacherProductProTrial" :
        return "TeacherProductProTrial";
    case "TeacherProductStarterAccess" :
        return "TeacherProductStarterAccess";
    case "TrialAvailable" :
        return "TrialAvailable";
    default:
      if (x.indexOf("classic") === 0) {
        return {
                NAME: "Classic",
                VAL: x
              };
      } else {
        return {
                NAME: "Unleash",
                VAL: x
              };
      }
  }
}

function unleashFeatureToString(x) {
  if (typeof x === "object") {
    return x.VAL;
  } else if (x === "DeveloperTestingIpad") {
    return "DeveloperTestingIpad";
  } else if (x === "StudentHasArcade") {
    return "release_studentHasArcade";
  } else if (x === "TeacherHasLiteracyCheck") {
    return "access_teacherHasLiteracyCheck";
  } else if (x === "StudentHasSecondary") {
    return "release_studentHasSecondary";
  } else if (x === "AuthCanAccessMorphBeta") {
    return "auth_canAccessMorphBeta";
  } else if (x === "AuthCanAccessMorphShowcase") {
    return "auth_canAccessMorphShowcase";
  } else if (x === "StudentHasTileCurrency") {
    return "release_studentHasTileCurrency";
  } else if (x === "DeveloperTestingLocales") {
    return "developer_testingLocales";
  } else if (x === "PlaySoundOnWordClick") {
    return "PlaySoundOnWordClick";
  } else if (x === "DeveloperTestingLayouts") {
    return "developer_testingLayouts";
  } else if (x === "StudentHasJourneyIntro") {
    return "release_studentJourneyIntro";
  } else if (x === "CanAccessCustomWordLists") {
    return "release_canAccessCustomWordLists";
  } else if (x === "AuthCanAccessMorphAlpha") {
    return "auth_canAccessMorphAlpha";
  } else if (x === "StudentHasStoryverse") {
    return "release_StudentHasStoryverse";
  } else if (x === "StudentCanUseLibrary") {
    return "access_studentCanViewLibrary";
  } else if (x === "TeacherCanFilterListsOnAuthor") {
    return "release_CanFilterListsOnAuthor";
  } else if (x === "StudentHasLiteracyCheck") {
    return "access_studentHasLiteracyCheck";
  } else if (x === "StudentHasLocking") {
    return "release_studentHasLocking";
  } else {
    return "developer_arcadeGames";
  }
}

export {
  unleashFeatures ,
  stringToFlag ,
  unleashFeatureToString ,
  
}
/* No side effect */
