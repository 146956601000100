// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Theme$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Theme.bs.js";
import * as CssUtils$LiteracyplanetClientSxEntry from "./CssUtils.bs.js";
import * as ForestIcons$LiteracyplanetClientSxEntry from "./ForestIcons.bs.js";
import * as DynamicLevelBar$LiteracyplanetClientSxEntry from "./DynamicLevelBar.bs.js";
import * as ForestComponents$LiteracyplanetClientSxEntry from "./ForestComponents.bs.js";

var brownColor = Css.rgba(85, 48, 19, {
      NAME: "num",
      VAL: 1.0
    });

var whiteColor = Css.rgba(255, 255, 255, {
      NAME: "num",
      VAL: 1.0
    });

var bottomBlueColor = Css.rgba(109, 207, 246, {
      NAME: "num",
      VAL: 1.0
    });

var topBlueColor = Css.rgba(141, 223, 255, {
      NAME: "num",
      VAL: 1.0
    });

var blueColor = Css.rgba(11, 185, 254, {
      NAME: "num",
      VAL: 1.0
    });

var offWhiteColor = Css.rgba(233, 220, 193, {
      NAME: "num",
      VAL: 1.0
    });

var lightOffWhiteColor = Css.rgba(253, 250, 240, {
      NAME: "num",
      VAL: 1.0
    });

var checkpoint = (require("./assets/checkpoint.svg"));

var dropDown = (require("./assets/drop_down.svg"));

var iconBacking = (require("./assets/icon_backing.svg"));

var iconBackingActive = (require("./assets/icon_backing_active.svg"));

var iconBackingLocked = (require("./assets/icon_backing_locked.svg"));

var r = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var css = Curry._1(Css.style, /* [] */0);

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: r,
        tl: {
          hd: "DynamicLevelBarStyle",
          tl: /* [] */0
        }
      }
    });

var DynamicLevelBarStyle = {
  r: r,
  css: css,
  className: className
};

function ForestLayout$MaxLevelBarComponent(Props) {
  var fromLevel = Props.fromLevel;
  var currentValue = Props.currentValue;
  return React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LevelPanel.make, {
              children: null
            }, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.MaxLevelGuide.make, {
                  children: null
                }, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.Flare.make, {}), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.YellowStar.make, {}), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.StartLevelText.make, {
                      children: String(fromLevel)
                    }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.MaxLevelText.make, {})), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.StoryPoints.make, {
                  children: "Story Points: " + currentValue.toLocaleString()
                }));
}

var MaxLevelBarComponent = {
  make: ForestLayout$MaxLevelBarComponent
};

function ForestLayout$DefaultLevelBarComponent(Props) {
  var fromLevel = Props.fromLevel;
  var toLevel = Props.toLevel;
  var fromValue = Props.fromValue;
  var toValue = Props.toValue;
  var currentValue = Props.currentValue;
  var allowedToShowLevelBar = toValue >= fromValue && currentValue >= fromValue && currentValue <= toValue;
  if (!allowedToShowLevelBar) {
    return null;
  }
  var range = toValue - fromValue | 0;
  var valueOffset = currentValue - fromValue | 0;
  var percentOne = valueOffset / range;
  var percentValue = percentOne * 100.0;
  var blueStars = percentOne >= 0.75 ? React.createElement(React.Fragment, undefined, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.BlueStar.make, {
              index: 0
            }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.BlueStar.make, {
              index: 1
            }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.BlueStar.make, {
              index: 2
            })) : (
      percentOne >= 0.5 ? React.createElement(React.Fragment, undefined, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.BlueStar.make, {
                  index: 0
                }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.BlueStar.make, {
                  index: 1
                }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.QuestionStar.make, {
                  index: 2
                })) : (
          percentOne >= 0.25 ? React.createElement(React.Fragment, undefined, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.BlueStar.make, {
                      index: 0
                    }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.QuestionStar.make, {
                      index: 1
                    }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.QuestionStar.make, {
                      index: 2
                    })) : React.createElement(React.Fragment, undefined, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.QuestionStar.make, {
                      index: 0
                    }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.QuestionStar.make, {
                      index: 1
                    }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.QuestionStar.make, {
                      index: 2
                    }))
        )
    );
  return React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LevelPanel.make, {
              children: null
            }, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LevelGuide.make, {
                  children: null
                }, React.createElement(DynamicLevelBar$LiteracyplanetClientSxEntry.LevelBarValue.make, {
                      className: className,
                      percentValue: percentValue
                    }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.Flare.make, {}), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.YellowStar.make, {}), blueStars, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.StartLevelText.make, {
                      children: String(fromLevel)
                    }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.EndLevelText.make, {
                      children: String(toLevel)
                    })), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.StoryPoints.make, {
                  children: "Story Points: " + (currentValue.toLocaleString() + (" / " + toValue.toLocaleString()))
                }));
}

var DefaultLevelBarComponent = {
  make: ForestLayout$DefaultLevelBarComponent
};

function ForestLayout$LevelBarLoadedComponent(Props) {
  var fromLevel = Props.fromLevel;
  var toLevel = Props.toLevel;
  var fromValue = Props.fromValue;
  var toValue = Props.toValue;
  var currentValue = Props.currentValue;
  var hasReachedLastLevel = fromLevel >= toLevel;
  if (hasReachedLastLevel) {
    return React.createElement(ForestLayout$MaxLevelBarComponent, {
                fromLevel: fromLevel,
                currentValue: currentValue
              });
  } else {
    return React.createElement(ForestLayout$DefaultLevelBarComponent, {
                fromLevel: fromLevel,
                toLevel: toLevel,
                fromValue: fromValue,
                toValue: toValue,
                currentValue: currentValue
              });
  }
}

var LevelBarLoadedComponent = {
  make: ForestLayout$LevelBarLoadedComponent
};

function ForestLayout$LevelBarLoadingComponent(Props) {
  return React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LevelPanel.make, {
              children: React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LoadingLevelText.make, {})
            });
}

var LevelBarLoadingComponent = {
  make: ForestLayout$LevelBarLoadingComponent
};

function ForestLayout$LevelBarContainer(Props) {
  var navigation = Props.navigation;
  if (!navigation) {
    return React.createElement(ForestLayout$LevelBarLoadingComponent, {});
  }
  var match = navigation._0.spBar;
  return React.createElement(ForestLayout$LevelBarLoadedComponent, {
              fromLevel: match.fromLevel,
              toLevel: match.toLevel,
              fromValue: match.fromValue,
              toValue: match.toValue,
              currentValue: match.currentValue
            });
}

var LevelBarContainer = {
  make: ForestLayout$LevelBarContainer
};

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        488
      ],
      tl: /* [] */0
    });

var cols = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "css",
        VAL: {
          NAME: "fr",
          VAL: 1.0
        }
      },
      tl: /* [] */0
    });

var css$1 = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.justifyItems(Css.center),
          tl: {
            hd: Css.alignContent(Css.center),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: r$1,
      tl: {
        hd: cols,
        tl: {
          hd: css$1,
          tl: {
            hd: "IconPanel",
            tl: /* [] */0
          }
        }
      }
    });

var IconCenterPanelStyle = {
  r: r$1,
  cols: cols,
  css: css$1,
  className: className$1
};

function ForestLayout$IconCenterPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: className$1
            }, children);
}

var IconCenterPanel = {
  make: ForestLayout$IconCenterPanel
};

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.gridColumnGap,
        12
      ],
      tl: /* [] */0
    });

var cols$1 = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "css",
        VAL: {
          NAME: "fr",
          VAL: 1.0
        }
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: {
          hd: {
            NAME: "css",
            VAL: {
              NAME: "fr",
              VAL: 1.0
            }
          },
          tl: /* [] */0
        }
      }
    });

var css$2 = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.justifyItems(Css.center),
          tl: {
            hd: Css.alignContent(Css.center),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: r$2,
      tl: {
        hd: cols$1,
        tl: {
          hd: css$2,
          tl: {
            hd: "IconPanel",
            tl: /* [] */0
          }
        }
      }
    });

var IconPanelStyle = {
  r: r$2,
  cols: cols$1,
  css: css$2,
  className: className$2
};

function ForestLayout$IconPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: className$2
            }, children);
}

var IconPanel = {
  make: ForestLayout$IconPanel
};

var r$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        564
      ],
      tl: {
        hd: [
          Css.height,
          587
        ],
        tl: {
          hd: [
            Css.top,
            56
          ],
          tl: /* [] */0
        }
      }
    });

var rows = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 50
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 158
        },
        tl: {
          hd: {
            NAME: "ratio",
            VAL: 100
          },
          tl: {
            hd: {
              NAME: "ratio",
              VAL: 164
            },
            tl: {
              hd: {
                NAME: "ratio",
                VAL: 80
              },
              tl: /* [] */0
            }
          }
        }
      }
    });

var css$3 = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.position(Css.absolute),
        tl: {
          hd: Css.display("grid"),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: {
                hd: Css.justifyItems(Css.center),
                tl: {
                  hd: Css.background(Css.url(dropDown)),
                  tl: {
                    hd: Css.backgroundSize("contain"),
                    tl: {
                      hd: Css.backgroundRepeat("noRepeat"),
                      tl: {
                        hd: Css.backgroundPosition(Css.center),
                        tl: {
                          hd: Css.userSelect(Css.none),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: r$3,
      tl: {
        hd: rows,
        tl: {
          hd: css$3,
          tl: {
            hd: "BodyPanel",
            tl: /* [] */0
          }
        }
      }
    });

var BodyPanelStyle = {
  r: r$3,
  rows: rows,
  css: css$3,
  className: className$3
};

function ForestLayout$BodyPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: className$3
            }, children);
}

var BodyPanel = {
  make: ForestLayout$BodyPanel
};

var r$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        112
      ],
      tl: {
        hd: [
          Css.height,
          96
        ],
        tl: /* [] */0
      }
    });

var css$4 = Curry._1(Css.style, {
      hd: Css.zIndex(100),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.backgroundColor(Css.red),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$4 = Curry._1(Css.merge, {
      hd: r$4,
      tl: {
        hd: css$4,
        tl: /* [] */0
      }
    });

var ItemIconWrapperStyle = {
  r: r$4,
  css: css$4,
  className: className$4
};

function ForestLayout$ItemIconWrapper(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: className$4
            }, children);
}

var ItemIconWrapper = {
  make: ForestLayout$ItemIconWrapper
};

var r$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        112
      ],
      tl: /* [] */0
    });

var css$5 = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: {
            hd: Css.backgroundSize("contain"),
            tl: {
              hd: Css.backgroundRepeat("noRepeat"),
              tl: {
                hd: Css.backgroundPosition(Css.center),
                tl: {
                  hd: Css.gridColumnStart(1),
                  tl: {
                    hd: Css.gridColumnEnd(2),
                    tl: {
                      hd: Css.gridRowStart(1),
                      tl: {
                        hd: Css.gridRowEnd(2),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$5 = Curry._1(Css.merge, {
      hd: r$5,
      tl: {
        hd: css$5,
        tl: {
          hd: "ItemIcon",
          tl: /* [] */0
        }
      }
    });

var ItemIconStyle = {
  r: r$5,
  css: css$5,
  className: className$5
};

function ForestLayout$ItemIcon(Props) {
  var children = Props.children;
  var icon = Props.icon;
  var lockedOpt = Props.locked;
  var locked = lockedOpt !== undefined ? lockedOpt : false;
  var className$6 = locked ? Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.background(Css.url(icon)),
                tl: {
                  hd: Css.filter({
                        hd: {
                          NAME: "grayscale",
                          VAL: 90.0
                        },
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: className$5,
            tl: /* [] */0
          }
        }) : Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.background(Css.url(icon)),
                tl: /* [] */0
              }),
          tl: {
            hd: className$5,
            tl: /* [] */0
          }
        });
  return React.createElement("div", {
              className: className$6
            }, children);
}

var ItemIcon = {
  make: ForestLayout$ItemIcon
};

var r$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        51
      ],
      tl: {
        hd: [
          Css.height,
          53
        ],
        tl: {
          hd: [
            Css.top,
            400
          ],
          tl: {
            hd: [
              Css.left,
              400
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$6 = Curry._1(Css.style, {
      hd: Css.background(Css.url(checkpoint)),
      tl: {
        hd: Css.backgroundSize(Css.cover),
        tl: {
          hd: Css.display(Css.grid),
          tl: /* [] */0
        }
      }
    });

var className$6 = Curry._1(Css.merge, {
      hd: css$6,
      tl: {
        hd: r$6,
        tl: {
          hd: "Checkpoint",
          tl: /* [] */0
        }
      }
    });

var Checkpoint = {
  r: r$6,
  css: css$6,
  className: className$6
};

var r$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        112
      ],
      tl: {
        hd: [
          Css.height,
          99
        ],
        tl: /* [] */0
      }
    });

var css$7 = Curry._1(Css.style, /* [] */0);

var className$7 = Curry._1(Css.merge, {
      hd: r$7,
      tl: {
        hd: css$7,
        tl: /* [] */0
      }
    });

var ResizeOnHoverStyle = {
  r: r$7,
  css: css$7,
  className: className$7
};

function ForestLayout$ResizeOnHover(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: className$7
            }, children);
}

var ResizeOnHover = {
  make: ForestLayout$ResizeOnHover
};

var css$8 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.backgroundRepeat(Css.noRepeat),
        tl: {
          hd: Css.backgroundPosition(Css.center),
          tl: {
            hd: Css.backgroundSize(Css.contain),
            tl: {
              hd: Css.height(Css.pct(100.0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$8 = Curry._1(Css.merge, {
      hd: css$8,
      tl: /* [] */0
    });

var IconStyle = {
  css: css$8,
  className: className$8
};

function ForestLayout$Icon(Props) {
  var children = Props.children;
  var icon = Props.icon;
  var lockedOpt = Props.locked;
  var locked = lockedOpt !== undefined ? lockedOpt : false;
  var className$9 = locked ? Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.backgroundImage(Css.url(icon)),
                tl: {
                  hd: Css.filter({
                        hd: {
                          NAME: "grayscale",
                          VAL: 90.0
                        },
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: className$8,
            tl: /* [] */0
          }
        }) : Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.backgroundImage(Css.url(icon)),
                tl: /* [] */0
              }),
          tl: {
            hd: className$8,
            tl: /* [] */0
          }
        });
  return React.createElement("div", {
              className: className$9
            }, children);
}

var Icon = {
  make: ForestLayout$Icon
};

var cssHover = Theme$LiteracyplanetClientFlags.dimensionsHover(className$7, 120, 106);

var r$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        120
      ],
      tl: {
        hd: [
          Css.height,
          138
        ],
        tl: /* [] */0
      }
    });

var rows$1 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 103
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 20
        },
        tl: /* [] */0
      }
    });

var css$9 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.justifyItems(Css.center),
          tl: {
            hd: Css.alignContent(Css.center),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: {
                hd: Css.background(Css.url(iconBacking)),
                tl: {
                  hd: Css.backgroundRepeat("noRepeat"),
                  tl: {
                    hd: Css.backgroundPosition("bottom"),
                    tl: {
                      hd: Css.backgroundSize("contain"),
                      tl: {
                        hd: Css.cursor(Css.pointer),
                        tl: {
                          hd: Css.hover({
                                hd: Css.background(Css.url(iconBackingActive)),
                                tl: {
                                  hd: Css.backgroundRepeat("noRepeat"),
                                  tl: {
                                    hd: Css.backgroundPosition("bottom"),
                                    tl: {
                                      hd: Css.backgroundSize("contain"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.active({
                                  hd: Css.background(Css.url(iconBackingActive)),
                                  tl: {
                                    hd: Css.backgroundRepeat("noRepeat"),
                                    tl: {
                                      hd: Css.backgroundPosition("bottom"),
                                      tl: {
                                        hd: Css.backgroundSize("contain"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }),
                            tl: {
                              hd: CssUtils$LiteracyplanetClientSxEntry.preloadImages({
                                    hd: iconBackingActive,
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$9 = Curry._1(Css.merge, {
      hd: cssHover,
      tl: {
        hd: r$8,
        tl: {
          hd: rows$1,
          tl: {
            hd: css$9,
            tl: /* [] */0
          }
        }
      }
    });

var ItemPanelStyle = {
  cssHover: cssHover,
  r: r$8,
  rows: rows$1,
  css: css$9,
  className: className$9
};

var r$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        120
      ],
      tl: {
        hd: [
          Css.height,
          138
        ],
        tl: /* [] */0
      }
    });

var rows$2 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 103
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 20
        },
        tl: /* [] */0
      }
    });

var css$10 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.justifyItems(Css.center),
          tl: {
            hd: Css.alignContent(Css.center),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: {
                hd: Css.background(Css.url(iconBackingLocked)),
                tl: {
                  hd: Css.backgroundRepeat("noRepeat"),
                  tl: {
                    hd: Css.backgroundPosition("bottom"),
                    tl: {
                      hd: Css.backgroundSize("contain"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$10 = Curry._1(Css.merge, {
      hd: r$9,
      tl: {
        hd: rows$2,
        tl: {
          hd: css$10,
          tl: /* [] */0
        }
      }
    });

var ItemPanelLockedStyle = {
  r: r$9,
  rows: rows$2,
  css: css$10,
  className: className$10
};

function ForestLayout$ItemPanel(Props) {
  var onClick = Props.onClick;
  var name = Props.name;
  var icon = Props.icon;
  var lockedOpt = Props.locked;
  var locked = lockedOpt !== undefined ? lockedOpt : false;
  var className$11 = locked ? className$10 : className$9;
  return React.createElement("div", {
              className: className$11,
              onClick: onClick
            }, React.createElement(ForestLayout$ResizeOnHover, {
                  children: React.createElement(ForestLayout$Icon, {
                        children: null,
                        icon: icon,
                        locked: locked
                      })
                }), locked ? null : React.createElement(ForestComponents$LiteracyplanetClientSxEntry.ItemText.make, {
                    text: name
                  }));
}

var ItemPanel = {
  make: ForestLayout$ItemPanel
};

var css$11 = Curry._1(Css.style, {
      hd: Css.color(Css.red),
      tl: /* [] */0
    });

var className$11 = Curry._1(Css.merge, {
      hd: css$11,
      tl: /* [] */0
    });

var RedStyle = {
  css: css$11,
  className: className$11
};

function ForestLayout$Red(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: className$11
            }, children);
}

var Red = {
  make: ForestLayout$Red
};

function ForestLayout$SubMenuButtons(Props) {
  var onClickBog = Props.onClickBog;
  var lockArcade = Props.lockArcade;
  var onClickArcade = Props.onClickArcade;
  var lockShop = Props.lockShop;
  var onClickShop = Props.onClickShop;
  var closeHeaderPanel = Props.closeHeaderPanel;
  return React.createElement(ForestLayout$IconCenterPanel, {
              children: React.createElement(ForestLayout$IconPanel, {
                    children: null
                  }, React.createElement(ForestLayout$ItemPanel, {
                        onClick: (function (param) {
                            Curry._1(onClickBog, undefined);
                            return Curry._1(closeHeaderPanel, undefined);
                          }),
                        name: "Book of Goings",
                        icon: ForestIcons$LiteracyplanetClientSxEntry.bookIcon
                      }), React.createElement(ForestLayout$ItemPanel, {
                        onClick: (function (param) {
                            Curry._1(onClickArcade, undefined);
                            return Curry._1(closeHeaderPanel, undefined);
                          }),
                        name: "Arcade",
                        icon: ForestIcons$LiteracyplanetClientSxEntry.arcadeIcon,
                        locked: lockArcade
                      }), React.createElement(ForestLayout$ItemPanel, {
                        onClick: (function (param) {
                            Curry._1(onClickShop, undefined);
                            return Curry._1(closeHeaderPanel, undefined);
                          }),
                        name: "Shop",
                        icon: ForestIcons$LiteracyplanetClientSxEntry.shopIcon,
                        locked: lockShop
                      }))
            });
}

var SubMenuButtons = {
  make: ForestLayout$SubMenuButtons
};

function ForestLayout(Props) {
  var userName = Props.userName;
  var navigation = Props.navigation;
  var onClickBog = Props.onClickBog;
  var onClickCollections = Props.onClickCollections;
  var lockArcade = Props.lockArcade;
  var onClickArcade = Props.onClickArcade;
  var lockShop = Props.lockShop;
  var onClickShop = Props.onClickShop;
  var closeHeaderPanel = Props.closeHeaderPanel;
  return React.createElement(ForestLayout$BodyPanel, {
              children: null
            }, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.Spacer.make, {}), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LevelPanelContainer.make, {
                  children: null
                }, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LevelPanelBanner.make, {
                      children: React.createElement(ForestComponents$LiteracyplanetClientSxEntry.BannerText.make, {
                            children: "Storyverse Level"
                          })
                    }), React.createElement(ForestLayout$LevelBarContainer, {
                      navigation: navigation
                    })), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.ProfilePanel.make, {
                  children: React.createElement(ForestComponents$LiteracyplanetClientSxEntry.ProfileInnerPanel.make, {
                        children: null
                      }, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.ProfileNamePanel.make, {
                            children: null
                          }, React.createElement(ForestIcons$LiteracyplanetClientSxEntry.ProfileIcon.make, {}), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.ProfileText.make, {
                                userName: userName
                              }), React.createElement(ForestIcons$LiteracyplanetClientSxEntry.LocationIcon.make, {}), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.LocationText.make, {
                                navigation: navigation
                              })), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.ProfileGemsPanel.make, {
                            children: null
                          }, React.createElement(ForestIcons$LiteracyplanetClientSxEntry.GemIcon.make, {}), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.GemCountText.make, {
                                navigation: navigation
                              })))
                }), React.createElement(ForestLayout$SubMenuButtons, {
                  onClickBog: onClickBog,
                  onClickCollections: onClickCollections,
                  lockArcade: lockArcade,
                  onClickArcade: onClickArcade,
                  lockShop: lockShop,
                  onClickShop: onClickShop,
                  closeHeaderPanel: closeHeaderPanel
                }), React.createElement(ForestComponents$LiteracyplanetClientSxEntry.CloseButton.make, {
                  children: null,
                  onClick: (function (param) {
                      return Curry._1(closeHeaderPanel, undefined);
                    })
                }, React.createElement(ForestComponents$LiteracyplanetClientSxEntry.Spacer.make, {}), React.createElement(ForestIcons$LiteracyplanetClientSxEntry.CloseIcon.make, {}), "Close"));
}

var ratio = Theme$LiteracyplanetClientFlags.resize;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

var make = ForestLayout;

export {
  brownColor ,
  whiteColor ,
  bottomBlueColor ,
  topBlueColor ,
  blueColor ,
  offWhiteColor ,
  lightOffWhiteColor ,
  ratio ,
  resizeGridCols ,
  resizeGridRows ,
  maximumWidth ,
  checkpoint ,
  dropDown ,
  iconBacking ,
  iconBackingActive ,
  iconBackingLocked ,
  DynamicLevelBarStyle ,
  MaxLevelBarComponent ,
  DefaultLevelBarComponent ,
  LevelBarLoadedComponent ,
  LevelBarLoadingComponent ,
  LevelBarContainer ,
  IconCenterPanelStyle ,
  IconCenterPanel ,
  IconPanelStyle ,
  IconPanel ,
  BodyPanelStyle ,
  BodyPanel ,
  ItemIconWrapperStyle ,
  ItemIconWrapper ,
  ItemIconStyle ,
  ItemIcon ,
  Checkpoint ,
  ResizeOnHoverStyle ,
  ResizeOnHover ,
  IconStyle ,
  Icon ,
  ItemPanelStyle ,
  ItemPanelLockedStyle ,
  ItemPanel ,
  RedStyle ,
  Red ,
  SubMenuButtons ,
  make ,
  
}
/* brownColor Not a pure module */
