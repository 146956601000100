// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as SkilfulClozeCommon$LiteracyplanetTupperware from "./SkilfulClozeCommon.bs.js";
import * as V2ClozeDropTextHook$LiteracyplanetTupperware from "./V2ClozeDropTextHook.bs.js";

function SkilfulClozeDropTextContainer$ResponseContainer(Props) {
  var drop = Props.drop;
  var id = Props.id;
  var children = Props.children;
  var tmp = {
    className: Skillet$LiteracyplanetTupperware.SkilfulClozeDropTextContainer.ResponseContainer.className,
    id: "DropId-" + id
  };
  if (drop !== undefined) {
    tmp.ref = Caml_option.valFromOption(drop);
  }
  return React.createElement("span", tmp, children);
}

var ResponseContainer = {
  make: SkilfulClozeDropTextContainer$ResponseContainer
};

function SkilfulClozeDropTextContainer$UndroppedIsOver(Props) {
  var drop = Props.drop;
  var id = Props.id;
  var children = Props.children;
  return React.createElement(SkilfulClozeDropTextContainer$ResponseContainer, {
              drop: drop,
              id: id,
              children: React.createElement("span", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulClozeDropTextContainer.UndroppedIsOver.className
                  }, children)
            });
}

var UndroppedIsOver = {
  make: SkilfulClozeDropTextContainer$UndroppedIsOver
};

function SkilfulClozeDropTextContainer$Undropped(Props) {
  var drop = Props.drop;
  var id = Props.id;
  var children = Props.children;
  return React.createElement(SkilfulClozeDropTextContainer$ResponseContainer, {
              drop: drop,
              id: id,
              children: React.createElement("span", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulClozeDropTextContainer.Undropped.className
                  }, children)
            });
}

var Undropped = {
  make: SkilfulClozeDropTextContainer$Undropped
};

function SkilfulClozeDropTextContainer$EmptyPortalContainer(Props) {
  var drop = Props.drop;
  var id = Props.id;
  var isOverOpt = Props.isOver;
  var isOver = isOverOpt !== undefined ? isOverOpt : false;
  var className = isOver ? Skillet$LiteracyplanetTupperware.SkilfulClozeDropTextContainer.DroppedIsOver.className : undefined;
  var tmp = {
    id: "DropId-" + id
  };
  if (drop !== undefined) {
    tmp.ref = Caml_option.valFromOption(drop);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("span", tmp, null);
}

var EmptyPortalContainer = {
  make: SkilfulClozeDropTextContainer$EmptyPortalContainer
};

function SkilfulClozeDropTextContainer$MarkedNotDropped(Props) {
  var id = Props.id;
  var children = Props.children;
  return React.createElement(SkilfulClozeDropTextContainer$ResponseContainer, {
              id: id,
              children: React.createElement(SkilfulClozeCommon$LiteracyplanetTupperware.IncorrectResponse.make, {
                    children: React.createElement("span", {
                          className: Skillet$LiteracyplanetTupperware.SkilfulClozeDropTextContainer.MarkedNotDropped.className
                        }, children)
                  })
            });
}

var MarkedNotDropped = {
  make: SkilfulClozeDropTextContainer$MarkedNotDropped
};

function SkilfulClozeDropTextContainer(Props) {
  var questionIndex = Props.questionIndex;
  var id = Props.id;
  var children = Props.children;
  var match = V2ClozeDropTextHook$LiteracyplanetTupperware.use(questionIndex, id);
  var drop = match[4];
  var step = match[3];
  var answerVisible = match[2];
  var isOver = match[0];
  var exit = 0;
  if (answerVisible !== undefined) {
    if (answerVisible) {
      return React.createElement(SkilfulClozeDropTextContainer$EmptyPortalContainer, {
                  id: id
                });
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (match[1]) {
      var exit$1 = 0;
      if (typeof step === "number") {
        exit$1 = 2;
      } else {
        if (step.TAG !== /* Attempted */0) {
          return React.createElement(SkilfulClozeDropTextContainer$EmptyPortalContainer, {
                      id: id
                    });
        }
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (isOver) {
          return React.createElement(SkilfulClozeDropTextContainer$EmptyPortalContainer, {
                      drop: drop,
                      id: id,
                      isOver: isOver
                    });
        } else {
          return React.createElement(SkilfulClozeDropTextContainer$EmptyPortalContainer, {
                      drop: drop,
                      id: id
                    });
        }
      }
      
    } else {
      var exit$2 = 0;
      if (typeof step === "number") {
        exit$2 = 2;
      } else {
        if (step.TAG !== /* Attempted */0) {
          return React.createElement(SkilfulClozeDropTextContainer$MarkedNotDropped, {
                      id: id,
                      children: children
                    });
        }
        exit$2 = 2;
      }
      if (exit$2 === 2) {
        if (isOver) {
          return React.createElement(SkilfulClozeDropTextContainer$UndroppedIsOver, {
                      drop: drop,
                      id: id,
                      children: children
                    });
        } else {
          return React.createElement(SkilfulClozeDropTextContainer$Undropped, {
                      drop: drop,
                      id: id,
                      children: children
                    });
        }
      }
      
    }
  }
  
}

var make = SkilfulClozeDropTextContainer;

var $$default = SkilfulClozeDropTextContainer;

export {
  ResponseContainer ,
  UndroppedIsOver ,
  Undropped ,
  EmptyPortalContainer ,
  MarkedNotDropped ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
