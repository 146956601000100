// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as V2MultiChoiceContainer$LiteracyplanetTupperware from "./V2MultiChoiceContainer.bs.js";

function SkilfulMultiChoiceContainer$MultiChoiceMarkStylingContainer(Props) {
  var columns = Props.columns;
  var children = Props.children;
  var css = Curry._1(Skillet$LiteracyplanetTupperware.SkilfulMultiChoiceContainer.MultiChoiceMarkStylingContainer.className, columns);
  return React.createElement("div", {
              className: css
            }, children);
}

var MultiChoiceMarkStylingContainer = {
  make: SkilfulMultiChoiceContainer$MultiChoiceMarkStylingContainer
};

function SkilfulMultiChoiceContainer(Props) {
  var columns = Props.columns;
  var correctAnswer = Props.correctAnswer;
  var fuzzyMarkOpt = Props.fuzzyMark;
  var questionIndex = Props.questionIndex;
  var showCorrectAnswerBehaviourOpt = Props.showCorrectAnswerBehaviour;
  var children = Props.children;
  var fuzzyMark = fuzzyMarkOpt !== undefined ? fuzzyMarkOpt : false;
  var showCorrectAnswerBehaviour = showCorrectAnswerBehaviourOpt !== undefined ? showCorrectAnswerBehaviourOpt : false;
  return React.createElement(V2MultiChoiceContainer$LiteracyplanetTupperware.make, {
              correctAnswer: correctAnswer,
              fuzzyMark: fuzzyMark,
              questionIndex: questionIndex,
              showCorrectAnswerBehaviour: showCorrectAnswerBehaviour,
              children: React.createElement(SkilfulMultiChoiceContainer$MultiChoiceMarkStylingContainer, {
                    columns: columns,
                    children: children
                  })
            });
}

var make = SkilfulMultiChoiceContainer;

var $$default = SkilfulMultiChoiceContainer;

export {
  MultiChoiceMarkStylingContainer ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
