// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactHowler from "react-howler";

var ReactHowler$1 = {};

function PlayPlaceholder(Props) {
  return React.createElement(ReactHowler, {
              src: "none.mp3",
              playing: false,
              preload: false
            });
}

var make = PlayPlaceholder;

export {
  ReactHowler$1 as ReactHowler,
  make ,
  
}
/* react Not a pure module */
