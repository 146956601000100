// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Error$LiteracyplanetClientErrors from "../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";

function Make(S) {
  var use = function (param) {
    var match = Curry.app(S.use, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ]);
    var result = match[1];
    var isCalled = result.called ? true : false;
    var isError = result.error !== undefined;
    var isRunning = result.loading ? true : false;
    var isDone = isCalled && !isRunning;
    return [
            match[0],
            isRunning,
            isError,
            isDone,
            result.data
          ];
  };
  var useRetry = function (errorMessage, attempts) {
    var match = use(undefined);
    var isError = match[2];
    var mutation = match[0];
    var match$1 = React.useState(function () {
          
        });
    var setVariables = match$1[1];
    var variables = match$1[0];
    var match$2 = React.useState(function () {
          return 0;
        });
    var setIteration = match$2[1];
    var iterations = match$2[0];
    var match$3 = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
    var onSetError = match$3.onSetError;
    var runMutation = function (variables) {
      Curry._1(setIteration, (function (i) {
              return i + 1 | 0;
            }));
      return Curry._8(mutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, variables);
    };
    React.useEffect((function () {
            if (iterations > attempts) {
              Curry._1(onSetError, {
                    TAG: /* ErrorWithMessage */4,
                    _0: errorMessage,
                    _1: undefined
                  });
            } else if (isError) {
              Belt_Option.map(variables, runMutation);
            }
            
          }), [
          isError,
          variables
        ]);
    var autoRetryMutation = function (variables, param) {
      runMutation(variables);
      return Curry._1(setVariables, (function (param) {
                    return Caml_option.some(variables);
                  }));
    };
    return [
            autoRetryMutation,
            match[1],
            isError,
            match[3],
            match[4]
          ];
  };
  var useWithWordMonsterError = function (errorMessage) {
    var match = use(undefined);
    var isError = match[2];
    var match$1 = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
    var onSetError = match$1.onSetError;
    React.useEffect((function () {
            if (isError) {
              Curry._1(onSetError, {
                    TAG: /* ErrorWithMessage */4,
                    _0: errorMessage,
                    _1: undefined
                  });
            }
            
          }), [isError]);
    return [
            match[0],
            match[1],
            match[3],
            match[4]
          ];
  };
  return {
          use: use,
          useRetry: useRetry,
          useWithWordMonsterError: useWithWordMonsterError
        };
}

export {
  Make ,
  
}
/* react Not a pure module */
