import react from 'react';
import ReactDOM from 'react-dom';
import pt from 'prop-types';
import * as rr from 'react-router';
import {
  syncHistoryWithStore,
  LOCATION_CHANGE as LOCATION_CHANGE_REDUX
} from 'react-router-redux';
import classnames from 'classnames';
import graphqlTag from 'graphql-tag';
import {configureStore} from './store';
import {
  OpenIdCallbackComponent as CallbackComponent,
  openIdUserManager as userManager,
  OpenIdProvider as OidcProvider,
  openId,
  openIdLoadUser as oidcLoadUser,
  openIdSilentRenew as oidcSilentRenew,
  oidcPrompt
} from './redux/open_id_connect';
import {createInitialState} from './initial_state_helper';
import * as reactRedux from 'react-redux';
import CallbackPage from './callback_page';
import {SilentCallbackPage, ErrorPage, Logout} from './callback_page';
import {createApolloClientWithAuth, createApolloAuthMiddleware} from './redux/apollo';
import {routing, routerMiddleware} from './redux/routing';
import logger from 'redux-logger';
import reduxThunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import {ApolloProvider, ApolloClient, InMemoryCache, HttpLink} from '@apollo/client';

export {library as faLibrary} from '@fortawesome/fontawesome-svg-core';
export {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export {ApolloProvider} from '@apollo/client';
export {graphql} from '@apollo/client/react/hoc';
export {Query, Mutation} from '@apollo/client/react/components';
export {default as compose} from './compose';

export const createSimpleApolloClient = () => {
  const link = new HttpLink({
    uri: process.env.GRAPHQL_SERVER + process.env.GRAPHQL_PATH
  });
  const cache = new InMemoryCache();
  return new ApolloClient({link, cache});
};

export const sentry = Sentry;
export const thunk = reduxThunk;
export const PropTypes = pt;
export const React = react;
export const Router = rr.Router;
export const Link = rr.Link;
export const withRouter = rr.withRouter;
export const cx = classnames;
export const gql = graphqlTag;
export const openIdUserManager = userManager;
export const openIdLoadUser = oidcLoadUser;
export const openIdSilentRenew = oidcSilentRenew;
export const connect = reactRedux.connect;
export const ReduxProvider = reactRedux.Provider;
export const configureReduxStore = configureStore;
export const OpenIdCallbackComponent = CallbackComponent;
export const OpenIdProvider = OidcProvider;
export const findDOMNode = ReactDOM.findDOMNode;
export const LOCATION_CHANGE = LOCATION_CHANGE_REDUX;
export {default as WithOauth} from './with_oauth';

export {
  APOLLO_QUERY_INIT,
  APOLLO_QUERY_RESULT,
  APOLLO_MUTATION_INIT,
  APOLLO_MUTATION_RESULT,
  APOLLO_SUBSCRIPTION_INIT,
  APOLLO_SUBSCRIPTION_RESULT
} from './apollo_link_redux';

export const createApp = (
  getRoutes,
  options
) => {
  if (!options) {
    options = {disableWebsockets: false, sentryIgnoreErrors: []};
  }
  if (['production', 'staging'].includes(process.env.NODE_ENV)) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: process.env.BUILD_TAG,
      environment: process.env.NODE_ENV,
      ignoreErrors: options.sentryIgnoreErrors || []
    });
  }
  const {
    apolloMiddlewareLink,
    reduxApolloAuthMiddleware,
    wsClient
  } = createApolloAuthMiddleware(true, options.disableWebsockets);

  const reducers = {
    routing,
    openId,
    oidcPrompt
  };
  let appApi = {};
  const middlewares = [
    reduxThunk.withExtraArgument(appApi),
    routerMiddleware,
    reduxApolloAuthMiddleware
  ];
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger);
  }

  let initialState = createInitialState();
  const {store, injectAsyncReducer} = configureStore(initialState, {reducers, middlewares});

  const apolloClient = createApolloClientWithAuth({
    apolloMiddlewareLink,
    store,
    wsClient,
    serverUri: options.serverUri,
    possibleTypes: options.possibleTypes
  });

  // eslint-disable-next-line max-len
  const history = syncHistoryWithStore(process.env.HISTORY === 'hash' ? rr.hashHistory : rr.browserHistory, store);

  appApi.history = history;
  appApi.store = store;
  appApi.userManager = userManager;
  appApi.injectAsyncReducer = injectAsyncReducer;
  appApi.apolloClient = apolloClient;

  const routes = (typeof getRoutes === 'function') ? getRoutes(appApi) : getRoutes;

  if (!process.env.MOBILE) {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
      if (process.env.GA_TRACKING_ID) {
        history.listen(loc => {
          ga('set', 'page', loc.pathname);
          ga('send', 'pageview');
        });
      }
    }
  }

  const App = () => (
    <reactRedux.Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ApolloProvider client={apolloClient}>
          <Router
            history={history}
            routes={
              [
                {
                  path: process.env.OAUTH_CALLBACK_PATH,
                  component: CallbackPage
                },
                {
                  path: process.env.OAUTH_SILENT_CALLBACK_PATH,
                  component: SilentCallbackPage
                },
                {
                  path: '/error_page',
                  component: ErrorPage
                },
                {
                  path: '/logout',
                  component: Logout
                }
              ].concat(routes)
            }
          />
        </ApolloProvider>
      </OidcProvider>
    </reactRedux.Provider>
  );

  let app = document.getElementById('app');
  if (!app) {
    const div = document.createElement('div');
    div.id = 'app';
    if (document.body) document.body.appendChild(div);
    app = document.getElementById('app');
  }

  if (app) ReactDOM.render(<App />, app);
  return appApi;
};
