/* eslint-disable max-len */
import React from 'react';

export const RightArrowIcon = () => {
  return (
    <svg
      id="RightArrowIcon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 512.2 512.2">
      <g>
        <path d="M509,248.2L295.5,35.4c-3.1-3-7.6-3.9-11.6-2.3c-4,1.7-6.6,5.5-6.6,9.9v157.3H10.7C4.8,200.3,0,205.1,0,211v90.7
          c0,5.9,4.8,10.7,10.7,10.7h266.7v156.9c0,4.3,2.6,8.2,6.6,9.9c1.3,0.6,2.7,0.8,4.1,0.8c2.8,0,5.5-1.1,7.6-3.1l213.5-213.4
          c2-2,3.1-4.7,3.1-7.6C512.2,252.9,511,250.2,509,248.2z"/>
      </g>
    </svg>
  );
};

export const LeftArrowIcon = () => {
  return (
    <svg
      id="LeftArrowIcon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 513 513">
      <path d="M3.20001 248.2L216.7 35.4C219.8 32.4 224.3 31.5 228.3 33.1C232.3 34.8 234.9 38.6 234.9 43V200.3H501.5C507.4 200.3 512.2 205.1 512.2 211V301.7C512.2 307.6 507.4 312.4 501.5 312.4H234.8V469.3C234.8 473.6 232.2 477.5 228.2 479.2C226.9 479.8 225.5 480 224.1 480C221.3 480 218.6 478.9 216.5 476.9L3 263.5C1 261.5 -0.0999756 258.8 -0.0999756 255.9C2.44156e-05 252.9 1.20001 250.2 3.20001 248.2Z"/>
    </svg>
  );
};

export const VolumeIcon = () => {
  return (
    <svg
      id="VolumeIcon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 512 512">
      <path d="M357.9,366.9c-5.8,0-11.7-2.1-16.3-6.4c-9.7-9-10.3-24.2-1.3-33.9c17.8-19.3,27.7-44.4,27.7-70.6
        s-9.8-51.4-27.7-70.6c-9-9.7-8.4-24.9,1.3-33.9c9.7-9,24.9-8.4,33.9,1.3C401.6,180.9,416,217.6,416,256s-14.4,75.1-40.5,103.2
        C370.8,364.4,364.4,366.9,357.9,366.9z"/>
      <path d="M422.2,438.6c-6.2,0-12.3-2.4-17-7.1c-9.4-9.4-9.3-24.6,0-33.9C443.1,359.8,464,309.5,464,256
        s-20.9-103.8-58.8-141.6c-9.4-9.4-9.4-24.6,0-33.9c9.4-9.4,24.6-9.4,33.9,0c47,46.9,72.9,109.2,72.9,175.6s-25.9,128.7-72.9,175.6
        C434.5,436.3,428.3,438.6,422.2,438.6z"/>
      <path d="M99,160H44c-24.3,0-44,19.7-44,44v104c0,24.3,19.7,44,44,44h55c2.8,0,5-2.2,5-5V165C104,162.2,101.8,160,99,160
        z"/>
      <path d="M280,56h-24c-5.3,0-10.4,1.7-14.6,4.9L138,140.1c-1.2,0.9-2,2.4-2,4v224c0,1.6,0.7,3,2,4l103.5,79.1
        c4.2,3.2,9.3,4.9,14.6,4.9h24c13.3,0,24-10.7,24-24V80C304,66.7,293.3,56,280,56L280,56z"/>
    </svg>
  );
};

export const StopIcon = () => {
  return (
    <svg
      id="StopIcon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 512 512">
      <path d="M437.2,456.1H69.7c-23.6,0-42.8-19.1-42.8-42.8V98.8C26.9,75.1,46,56,69.7,56h367.5c23.6,0,42.8,19.1,42.8,42.8
v314.6C479.9,437,460.8,456.1,437.2,456.1z"/>
    </svg>
  );
};

export const SpinnerIcon = () => {
  return (
    <svg
      id="SpinnerIcon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 80 80">
      <path
        id="spinner"
        fill="currentColor"
        d="M40,72C22.4,72,8,57.6,8,40C8,22.4,
        22.4,8,40,8c17.6,0,32,14.4,32,32c0,1.1-0.9,2-2,2
        s-2-0.9-2-2c0-15.4-12.6-28-28-28S12,24.6,12,40s12.6,
        28,28,28c1.1,0,2,0.9,2,2S41.1,72,40,72z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 40 40"
          to="360 40 40"
          dur="1s"
          repeatCount="indefinite"/>
      </path>
    </svg>
  );
};

export const BookIcon = () => {
  return (
    <svg
      id="BookIcon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 480 480">
      <path d="M128,104V72c0-4.4-3.6-8-8-8H8c-4.4,0-8,3.6-8,8v32H128z"/>
      <path d="M0,120v264h128V120H0z"/>
      <path d="M0,432v40c0,4.4,3.6,8,8,8h112c4.4,0,8-3.6,8-8v-40H0z"/>
      <path d="M0,381.8h128V416H0V381.8z"/>
      <path d="M144,381.8h104V416H144V381.8z"/>
      <path d="M248,32V8c0-4.4-3.6-8-8-8h-88c-4.4,0-8,3.6-8,8v24H248z"/>
      <path d="M144,88h104v296H144V88z"/>
      <path d="M144,432v40c0,4.4,3.6,8,8,8h88c4.4,0,8-3.6,8-8v-40H144z"/>
      <path d="M144,48h104v43.5H144V48z"/>
      <path d="M263.8,165.7l9.7,37.6L409,167.2l-9.7-37.6L263.8,165.7z"/>
      <path d="M330.7,421.9l135.5-36.1l-15.4-58.9L315.2,363L330.7,421.9z"/>
      <path d="M453.5,337.5l-45.9-176l-135.5,36.1l45.9,176L453.5,337.5z"/>
      <path d="M259.8,150.2l135.5-36.1L383.8,70c-1.2-4.3-5.5-6.8-9.8-5.8l-120,32c-4.2,1.2-6.8,5.5-5.7,9.8L259.8,150.2z"/>
      <path d="M470.2,401.3l-135.5,36.1l9.5,36.6c0.6,2.1,1.9,3.8,3.8,4.9c1.2,0.7,2.6,1.1,4,1.1c0.7,0,1.4-0.1,2.1-0.2l120-32
        c4.2-1.2,6.8-5.5,5.7-9.8L470.2,401.3z"/>
    </svg>
  );
};
