// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as V2EventsContext$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/v2/V2EventsContext.bs.js";

function call(f) {
  Belt_Option.map(f, (function (f) {
          return Curry._1(f, undefined);
        }));
  
}

function callWith2(f, x, y) {
  Belt_Option.map(f, (function (f) {
          return Curry._2(f, x, y);
        }));
  
}

function wrapApiWithStoreEvents(api, dispatch, onActivityCompleted, onActivityReachedEnd, onActivityStarted, onExit, onQuestionFailed, onQuestionPassed, onQuestionStarted, param) {
  return Curry._7(api, (function (param) {
                Curry._1(dispatch, /* ActivityCompleted */0);
                return call(onActivityCompleted);
              }), (function (param) {
                Curry._1(dispatch, /* ActivityReachedEnd */2);
                return call(onActivityReachedEnd);
              }), (function (param) {
                Curry._1(dispatch, /* ActivityStarted */5);
                return call(onActivityStarted);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, /* Exit */6);
                return callWith2(onExit, questionIndex, serializedState);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, {
                      TAG: /* QuestionFailed */1,
                      _0: questionIndex + 1 | 0
                    });
                return callWith2(onQuestionFailed, questionIndex, serializedState);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, {
                      TAG: /* QuestionPassed */2,
                      _0: questionIndex + 1 | 0
                    });
                return callWith2(onQuestionPassed, questionIndex, serializedState);
              }), (function (questionIndex, serializedState) {
                Curry._1(dispatch, /* QuestionStarted */9);
                return callWith2(onQuestionStarted, questionIndex, serializedState);
              }));
}

function api(onActivityCompleted, onActivityReachedEnd, onActivityStarted, onExit, onQuestionFailed, onQuestionPassed, onQuestionStarted) {
  return {
          onActivityCompleted: onActivityCompleted,
          onActivityReachedEnd: onActivityReachedEnd,
          onActivityStarted: onActivityStarted,
          onExit: onExit,
          onQuestionFailed: onQuestionFailed,
          onQuestionPassed: onQuestionPassed,
          onQuestionStarted: onQuestionStarted
        };
}

function FlipperTupperwareCallbacks$Provider(Props) {
  var dispachables = Props.dispachables;
  var children = Props.children;
  return React.createElement(V2EventsContext$LiteracyplanetClientLibsActivity.make, V2EventsContext$LiteracyplanetClientLibsActivity.makeProps(dispachables, children, undefined));
}

var Provider = {
  make: FlipperTupperwareCallbacks$Provider
};

export {
  call ,
  callWith2 ,
  wrapApiWithStoreEvents ,
  api ,
  Provider ,
  
}
/* react Not a pure module */
