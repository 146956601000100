import {
  USER_FOUND,
  USER_EXPIRED,
  USER_NOT_FOUND,
  SILENT_RENEW_ERROR,
  SESSION_TERMINATED,
  USER_SIGNED_OUT,
  LOADING_USER,
  LOAD_USER_ERROR,
  SILENT_RENEW_STARTED
} from '@literacyplanet/redux-oidc';

const oidcReducer = (
  state = {
    trigger: null
  },
  action
) => {
  switch (action.type) {
    case USER_NOT_FOUND:
    case LOAD_USER_ERROR:
    case USER_EXPIRED:
      return {...state, trigger: 'silent'};
    case SILENT_RENEW_ERROR:
    case USER_SIGNED_OUT:
    case SESSION_TERMINATED:
      return {...state, trigger: 'prompt'};
    case USER_FOUND:
    case LOADING_USER:
    case SILENT_RENEW_STARTED:
      return {...state, trigger: null};
    default:
      return state;
  }
};

export default oidcReducer;
