import React from 'react';
import PropTypes from 'prop-types';
import {
  openIdUserManager,
  openIdSilentRenew
} from './redux/open_id_connect';
const LOGIN_HOST = process.env.LOGIN_HOST;

const loginLink = () => {
  const newSession = `${(window.location.href.indexOf('?') === -1 ? '?' : '&')}session=new`;
  return `${LOGIN_HOST}/login?return_to=${encodeURIComponent(window.location.href)}${newSession}`;
};

const returnTo = () => {
  const pathname = window.location.pathname;
  const search = window.location.search.slice(1);
  const filteredParams = search && search.length > 0
    ? search.split('&').filter(p => p !== 'session=new')
    : [];
  return filteredParams.length > 0 ? `${pathname}?${filteredParams.join('&')}` : pathname;
};

class WithOauth extends React.Component {
  componentDidMount() {
    if (!this.props.user) {
      if (window.location.search.includes('session=new')) {
        sessionStorage.setItem('lpReturnTo', returnTo());
        openIdUserManager.storeUser()
          .then(() => {
            openIdUserManager.signinRedirect();
          });
      } else {
        openIdSilentRenew({dispatch: this.props.dispatch}, openIdUserManager);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const login = () => window.location.href = loginLink();
    const prevTrigger = prevProps.oidcPrompt.trigger;
    const {oidcPrompt: {trigger}} = this.props;
    if (trigger === 'prompt' && prevTrigger !== trigger) {
      openIdUserManager.removeUser()
        .then(login)
        .catch(login);
    }
    if (trigger === 'silent') {
      openIdUserManager.clearStaleState();
      openIdSilentRenew({dispatch: this.props.dispatch}, openIdUserManager);
    }
  }

  render() {
    return (
      this.props.children
    );
  }
};

WithOauth.propTypes = {
  oidcPrompt: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};


export default WithOauth;
