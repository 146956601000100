// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactDom from "react-dom";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Cloze$LiteracyplanetTupperware from "../Cloze.bs.js";
import * as Render$LiteracyplanetTupperware from "./Render.bs.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as SkilfulClozeCommon$LiteracyplanetTupperware from "./SkilfulClozeCommon.bs.js";
import * as V2ClozeDragTextHook$LiteracyplanetTupperware from "./V2ClozeDragTextHook.bs.js";

function SkilfulClozeDragTextContainer$ResponseContainer(Props) {
  var drag = Props.drag;
  var onClick = Props.onClick;
  var children = Props.children;
  var tmp = {
    className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.ResponseContainer.className
  };
  if (drag !== undefined) {
    tmp.ref = Caml_option.valFromOption(drag);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("span", tmp, children);
}

var ResponseContainer = {
  make: SkilfulClozeDragTextContainer$ResponseContainer
};

function SkilfulClozeDragTextContainer$Dimmed(Props) {
  var children = Props.children;
  return React.createElement("span", {
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Dimmed.className
            }, children);
}

var Dimmed = {
  make: SkilfulClozeDragTextContainer$Dimmed
};

function SkilfulClozeDragTextContainer$PortaledAndDimmed(Props) {
  var domId = Props.domId;
  var onClick = Props.onClick;
  var children = Props.children;
  var dropEl = V2ClozeDragTextHook$LiteracyplanetTupperware.useGetDomElement(domId);
  var tmp = {
    children: children
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  var rendered = React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, tmp);
  return Render$LiteracyplanetTupperware.mapWithNull(dropEl, (function (el) {
                return React.createElement(React.Fragment, undefined, ReactDom.createPortal(rendered, el), React.createElement(SkilfulClozeDragTextContainer$Dimmed, {
                                children: rendered
                              }));
              }));
}

var PortaledAndDimmed = {
  make: SkilfulClozeDragTextContainer$PortaledAndDimmed
};

function SkilfulClozeDragTextContainer$Portaled(Props) {
  var domId = Props.domId;
  var children = Props.children;
  var dropEl = V2ClozeDragTextHook$LiteracyplanetTupperware.useGetDomElement(domId);
  return Render$LiteracyplanetTupperware.mapWithNull(dropEl, (function (el) {
                return ReactDom.createPortal(React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, {
                                children: children
                              }), el);
              }));
}

var Portaled = {
  make: SkilfulClozeDragTextContainer$Portaled
};

function SkilfulClozeDragTextContainer$PortaledCorrectResponse(Props) {
  var domId = Props.domId;
  var children = Props.children;
  return React.createElement(SkilfulClozeDragTextContainer$Portaled, {
              domId: domId,
              children: React.createElement(SkilfulClozeCommon$LiteracyplanetTupperware.CorrectResponse.make, {
                    children: children
                  })
            });
}

var PortaledCorrectResponse = {
  make: SkilfulClozeDragTextContainer$PortaledCorrectResponse
};

function SkilfulClozeDragTextContainer$PortaledIncorrectResponse(Props) {
  var domId = Props.domId;
  var children = Props.children;
  return React.createElement(SkilfulClozeDragTextContainer$Portaled, {
              domId: domId,
              children: React.createElement(SkilfulClozeCommon$LiteracyplanetTupperware.IncorrectResponse.make, {
                    children: children
                  })
            });
}

var PortaledIncorrectResponse = {
  make: SkilfulClozeDragTextContainer$PortaledIncorrectResponse
};

function position(param) {
  return {
          display: "block",
          left: String(param.x) + "px",
          overflow: "visible",
          position: "fixed",
          top: String(param.y) + "px",
          pointerEvents: "none"
        };
}

function SkilfulClozeDragTextContainer$DragLayer(Props) {
  var currentOffset = Props.currentOffset;
  var children = Props.children;
  return React.createElement("span", {
              style: position(currentOffset)
            }, children);
}

var DragLayer = {
  position: position,
  make: SkilfulClozeDragTextContainer$DragLayer
};

function SkilfulClozeDragTextContainer$DragLayerContainer(Props) {
  var id = Props.id;
  var children = Props.children;
  var match = Cloze$LiteracyplanetTupperware.useDragLayer(undefined);
  var currentOffset = match.currentOffset;
  var isDragging = match.isDragging;
  return Belt_Option.mapWithDefault(Belt_Option.flatMap(Belt_Option.map(match.item, (function (i) {
                        if (i.id === id) {
                          return isDragging;
                        } else {
                          return false;
                        }
                      })), (function (dragging) {
                    if (dragging) {
                      return currentOffset;
                    }
                    
                  })), null, (function (currentOffset) {
                return React.createElement(SkilfulClozeDragTextContainer$DragLayer, {
                            currentOffset: currentOffset,
                            children: React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, {
                                  children: React.createElement("span", {
                                        className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Undropped.className
                                      }, children)
                                })
                          });
              }));
}

var DragLayerContainer = {
  make: SkilfulClozeDragTextContainer$DragLayerContainer
};

function SkilfulClozeDragTextContainer$Undropped(Props) {
  var isDragging = Props.isDragging;
  var drag = Props.drag;
  var children = Props.children;
  var el = React.createElement(SkilfulClozeDragTextContainer$ResponseContainer, {
        drag: drag,
        children: React.createElement("span", {
              className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Undropped.className
            }, children)
      });
  if (isDragging) {
    return React.createElement(SkilfulClozeDragTextContainer$Dimmed, {
                children: el
              });
  } else {
    return el;
  }
}

var Undropped = {
  make: SkilfulClozeDragTextContainer$Undropped
};

function SkilfulClozeDragTextContainer$Dropped(Props) {
  var domId = Props.domId;
  var undo = Props.undo;
  var children = Props.children;
  return React.createElement(SkilfulClozeDragTextContainer$PortaledAndDimmed, {
              domId: domId,
              onClick: (function (param) {
                  return Curry._1(undo, undefined);
                }),
              children: React.createElement("span", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulClozeDragTextContainer.Dropped.className
                  }, children)
            });
}

var Dropped = {
  make: SkilfulClozeDragTextContainer$Dropped
};

function SkilfulClozeDragTextContainer(Props) {
  var questionIndex = Props.questionIndex;
  var id = Props.id;
  var children = Props.children;
  var match = V2ClozeDragTextHook$LiteracyplanetTupperware.use(questionIndex, id);
  var droppedId = match[4];
  var answerVisible = match[3];
  var marked = match[0];
  var tmp;
  var exit = 0;
  if (answerVisible !== undefined && answerVisible) {
    tmp = React.createElement(SkilfulClozeDragTextContainer$PortaledCorrectResponse, {
          domId: id,
          children: children
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (droppedId !== undefined) {
      switch (marked) {
        case /* Correct */0 :
            tmp = React.createElement(SkilfulClozeDragTextContainer$PortaledCorrectResponse, {
                  domId: droppedId,
                  children: children
                });
            break;
        case /* Wrong */1 :
            tmp = React.createElement(SkilfulClozeDragTextContainer$PortaledIncorrectResponse, {
                  domId: droppedId,
                  children: children
                });
            break;
        case /* Unanswered */2 :
            tmp = React.createElement(SkilfulClozeDragTextContainer$Dropped, {
                  domId: droppedId,
                  undo: match[5],
                  children: children
                });
            break;
        
      }
    } else {
      tmp = marked !== /* Unanswered */2 ? null : React.createElement(SkilfulClozeDragTextContainer$Undropped, {
              isDragging: match[1],
              id: id,
              drag: match[6],
              children: children
            });
    }
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement(SkilfulClozeDragTextContainer$DragLayerContainer, {
                  id: id,
                  children: children
                }));
}

var make = SkilfulClozeDragTextContainer;

var $$default = SkilfulClozeDragTextContainer;

export {
  ResponseContainer ,
  Dimmed ,
  PortaledAndDimmed ,
  Portaled ,
  PortaledCorrectResponse ,
  PortaledIncorrectResponse ,
  DragLayer ,
  DragLayerContainer ,
  Undropped ,
  Dropped ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
