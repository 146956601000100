import { React } from '@literacyplanet/client_core';
import { createApp } from '@literacyplanet/client_core';
import { routes as sxRoutes } from '@literacyplanet/client-sx-entry';
import { routes as txRoutes } from '@literacyplanet/client-tx-app';
import possibleTypes from './possibleTypes.json';
import Main from './Main.bs';

const NotFound = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      background: 'white',
      padding: '20px'
    }}>Page Not Found</div>
  );
};

const noMatchRoute = {
  path: '/home',
  component: NotFound,
  name: 'Page Not Found'
};

const routes = (appApi) => [
  {
    path: '/',
    component: Main
  },
  ...sxRoutes(appApi),
  ...txRoutes(appApi),
  noMatchRoute
];

createApp(routes, {
  possibleTypes,
  sentryIgnoreErrors: [
    'PixiWebGLNotSupported',
    'WebpNotSupported',
    /AbortError/,
    /This browser does not support WebGL/,
    'ResizeObserver loop limit exceeded',
    /Loading chunk \d+ failed/,
    /elementsFromPoint/
  ]
});
