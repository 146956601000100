// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as BookContext$LiteracyplanetTupperware from "./BookContext.bs.js";
import * as K2BookQuestions$LiteracyplanetTupperware from "./K2BookQuestions.bs.js";
import * as MinimizeBookIcon$LiteracyplanetTupperware from "./MinimizeBookIcon.bs.js";
import * as LibraryBookContext$LiteracyplanetClientSxLibrary from "../../../../../node_modules/@literacyplanet/client-sx-library/src/LibraryBookContext.bs.js";

function StimulusLayout$BookQueryComponent(Props) {
  var id = Props.id;
  var children = Props.children;
  var bookState = Curry._2(LibraryBookContext$LiteracyplanetClientSxLibrary.use, undefined, id);
  if (bookState) {
    return Curry._1(children, bookState._0);
  } else {
    return null;
  }
}

var BookQueryComponent = {
  make: StimulusLayout$BookQueryComponent
};

function StimulusLayout$FullPageStyling(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.StimulusLayout.FullPageStyling.className
            }, children);
}

var FullPageStyling = {
  make: StimulusLayout$FullPageStyling
};

function StimulusLayout$MinimizeBookButton(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.MinimizeBookButton.className,
              onClick: onClick
            }, React.createElement(MinimizeBookIcon$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.MinimizeBookIcon.className
                }));
}

var MinimizeBookButton = {
  make: StimulusLayout$MinimizeBookButton
};

function StimulusLayout$BookComponent(Props) {
  var endedBook = Props.endedBook;
  var onClickBack = Props.onClickBack;
  return React.createElement(BookContext$LiteracyplanetTupperware.Consumer.make, {
              children: (function (book, page, changePage, toggleFullscreen, stimuliPresent) {
                  return React.createElement(React.Fragment, undefined, React.createElement(K2BookQuestions$LiteracyplanetTupperware.MainNav.make, {}), book !== undefined ? React.createElement(K2BookQuestions$LiteracyplanetTupperware.BookContent.make, {
                                    onClickBack: onClickBack,
                                    book: book,
                                    page: page,
                                    changePage: changePage,
                                    w: 600,
                                    h: 680
                                  }) : null, endedBook ? React.createElement(StimulusLayout$MinimizeBookButton, {
                                    onClick: (function (param) {
                                        return Curry._1(toggleFullscreen, undefined);
                                      })
                                  }) : null);
                })
            });
}

var BookComponent = {
  make: StimulusLayout$BookComponent
};

function StimulusLayout$Book(Props) {
  var bookId = Props.bookId;
  var children = Props.children;
  var match = React.useState(function () {
        return true;
      });
  var setFullScreen = match[1];
  var fullScreen = match[0];
  var toggleFullscreen = function (param) {
    return Curry._1(setFullScreen, (function (fs) {
                  return !fs;
                }));
  };
  var match$1 = React.useState(function () {
        return false;
      });
  var setEndBook = match$1[1];
  var endedBook = match$1[0];
  var onClickBack = function (param) {
    Curry._1(setFullScreen, (function (fs) {
            return !fs;
          }));
    return Curry._1(setEndBook, (function (param) {
                  return true;
                }));
  };
  return React.createElement(StimulusLayout$BookQueryComponent, {
              id: bookId,
              children: (function (book) {
                  return React.createElement(BookContext$LiteracyplanetTupperware.Provider.make, {
                              book: book,
                              toggleFullscreen: toggleFullscreen,
                              children: fullScreen ? React.createElement(StimulusLayout$FullPageStyling, {
                                      children: React.createElement(StimulusLayout$BookComponent, {
                                            endedBook: endedBook,
                                            onClickBack: onClickBack
                                          })
                                    }) : children,
                              stimuliPresent: true
                            });
                })
            });
}

var Book = {
  make: StimulusLayout$Book
};

function StimulusLayout$NoBook(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return true;
      });
  var setFullScreen = match[1];
  var toggleFullscreen = function (param) {
    return Curry._1(setFullScreen, (function (fs) {
                  return !fs;
                }));
  };
  return React.createElement(BookContext$LiteracyplanetTupperware.Provider.make, {
              toggleFullscreen: toggleFullscreen,
              children: children,
              stimuliPresent: false
            });
}

var NoBook = {
  make: StimulusLayout$NoBook
};

function StimulusLayout(Props) {
  var stimulusId = Props.stimulusId;
  var stimulusType = Props.stimulusType;
  var children = Props.children;
  if (stimulusId !== undefined && stimulusType === "Book") {
    return React.createElement(StimulusLayout$Book, {
                bookId: stimulusId,
                children: children
              });
  }
  return React.createElement(StimulusLayout$NoBook, {
              children: children
            });
}

var make = StimulusLayout;

export {
  BookQueryComponent ,
  FullPageStyling ,
  MinimizeBookButton ,
  BookComponent ,
  Book ,
  NoBook ,
  make ,
  
}
/* react Not a pure module */
