import {
  USER_EXPIRED,
  REDIRECT_SUCCESS,
  USER_FOUND,
  USER_NOT_FOUND,
  SILENT_RENEW_ERROR,
  SILENT_RENEW_STARTED,
  SESSION_TERMINATED,
  LOADING_USER,
  USER_SIGNED_OUT
} from '../constants';

const initialState = {
  user: null,
  isLoadingUser: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (process.env.MOBILE && action.payload.pathname === '/auth/callback') {
        const queryObject = action.payload.query;
        if (queryObject && queryObject.access_token) {
          const newState = Object.assign({}, state, {access_token: queryObject.access_token});
          return newState;
        }
      }
      return state;
    case USER_EXPIRED:
      return Object.assign({}, state, {user: null, isLoadingUser: false});
    case SILENT_RENEW_ERROR:
      return Object.assign({}, state, {user: null, isLoadingUser: false});
    case SILENT_RENEW_STARTED:
      return Object.assign({}, state, {user: null, isLoadingUser: true});
    case SESSION_TERMINATED:
    case USER_SIGNED_OUT:
      return Object.assign({}, state, {user: null, isLoadingUser: false});
    case REDIRECT_SUCCESS:
    case USER_FOUND:
      if (process.env.MOBILE) {
        // Get access token from anywhere it can find it
        let accessToken = null;
        if (state.user) {
          accessToken = state.user.access_token;
        }
        if (state.access_token) {
          accessToken = state.access_token;
        }
        if (action.payload.access_token) {
          accessToken = action.payload.access_token;
        }

        const newUser = Object.assign({}, action.payload, {
          access_token: accessToken
        });
        const newState = Object.assign({}, state, {user: newUser, isLoadingUser: false});
        return newState;
      }
      return Object.assign({}, state, {user: action.payload, isLoadingUser: false});
    case USER_NOT_FOUND:
      return Object.assign({}, state, {user: null, isLoadingUser: false});
    case LOADING_USER:
      return Object.assign({}, state, {isLoadingUser: true});
    default:
      return state;
  }
}
