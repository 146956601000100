// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_List from "../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function responseIdentifiersMatch(responseIdentifier1, responseIdentifier2) {
  switch (responseIdentifier1.TAG | 0) {
    case /* SingleId */0 :
        switch (responseIdentifier2.TAG | 0) {
          case /* SingleId */0 :
              return responseIdentifier1._0 === responseIdentifier2._0;
          case /* IdPair */1 :
          case /* Group */2 :
              return false;
          
        }
    case /* IdPair */1 :
        switch (responseIdentifier2.TAG | 0) {
          case /* IdPair */1 :
              return responseIdentifier1._0 === responseIdentifier2._0;
          case /* SingleId */0 :
          case /* Group */2 :
              return false;
          
        }
    case /* Group */2 :
        switch (responseIdentifier2.TAG | 0) {
          case /* SingleId */0 :
          case /* IdPair */1 :
              return false;
          case /* Group */2 :
              return responseIdentifier1._0 === responseIdentifier2._0;
          
        }
    
  }
}

function responseTypeExists(responses, responsePayload) {
  return $$Array.fold_left((function (acc, response) {
                if (acc) {
                  return acc;
                } else {
                  return responseIdentifiersMatch(responsePayload._0, response._0._0);
                }
              }), false, responses);
}

function toggleIfMatch(responseIdentifier, existingResponsePayload, toggleResponsePayload) {
  var existingResponseIdentifier = existingResponsePayload._0;
  switch (responseIdentifier.TAG | 0) {
    case /* SingleId */0 :
        switch (existingResponseIdentifier.TAG | 0) {
          case /* SingleId */0 :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [];
              } else {
                return [{
                          TAG: /* ToggleResponse */0,
                          _0: existingResponsePayload
                        }];
              }
          case /* IdPair */1 :
          case /* Group */2 :
              return [{
                        TAG: /* ToggleResponse */0,
                        _0: existingResponsePayload
                      }];
          
        }
    case /* IdPair */1 :
        switch (existingResponseIdentifier.TAG | 0) {
          case /* IdPair */1 :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [];
              } else {
                return [{
                          TAG: /* ToggleResponse */0,
                          _0: existingResponsePayload
                        }];
              }
          case /* SingleId */0 :
          case /* Group */2 :
              return [{
                        TAG: /* ToggleResponse */0,
                        _0: existingResponsePayload
                      }];
          
        }
    case /* Group */2 :
        var id1 = responseIdentifier._1;
        var g1 = responseIdentifier._0;
        switch (id1.TAG | 0) {
          case /* SingleId */0 :
              switch (existingResponseIdentifier.TAG | 0) {
                case /* SingleId */0 :
                case /* IdPair */1 :
                    return [{
                              TAG: /* ToggleResponse */0,
                              _0: existingResponsePayload
                            }];
                case /* Group */2 :
                    var id2 = existingResponseIdentifier._1;
                    switch (id2.TAG | 0) {
                      case /* SingleId */0 :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === id2._0) {
                              return [];
                            } else {
                              return [{
                                        TAG: /* ToggleResponse */0,
                                        _0: toggleResponsePayload
                                      }];
                            }
                          } else {
                            return [{
                                      TAG: /* ToggleResponse */0,
                                      _0: existingResponsePayload
                                    }];
                          }
                      case /* IdPair */1 :
                      case /* Group */2 :
                          return [{
                                    TAG: /* ToggleResponse */0,
                                    _0: existingResponsePayload
                                  }];
                      
                    }
                
              }
          case /* IdPair */1 :
              switch (existingResponseIdentifier.TAG | 0) {
                case /* SingleId */0 :
                case /* IdPair */1 :
                    return [{
                              TAG: /* ToggleResponse */0,
                              _0: existingResponsePayload
                            }];
                case /* Group */2 :
                    var match = existingResponseIdentifier._1;
                    switch (match.TAG | 0) {
                      case /* IdPair */1 :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === match._0) {
                              return [];
                            } else {
                              return [{
                                        TAG: /* ToggleResponse */0,
                                        _0: toggleResponsePayload
                                      }];
                            }
                          } else {
                            return [{
                                      TAG: /* ToggleResponse */0,
                                      _0: existingResponsePayload
                                    }];
                          }
                      case /* SingleId */0 :
                      case /* Group */2 :
                          return [{
                                    TAG: /* ToggleResponse */0,
                                    _0: existingResponsePayload
                                  }];
                      
                    }
                
              }
          case /* Group */2 :
              return [{
                        TAG: /* ToggleResponse */0,
                        _0: existingResponsePayload
                      }];
          
        }
    
  }
}

function toggleResponse(responses, toggleResponsePayload) {
  var responseIdentifier = toggleResponsePayload._0;
  if (responseTypeExists(responses, toggleResponsePayload)) {
    return $$Array.fold_left((function (acc, existingResponse) {
                  if (existingResponse.TAG !== /* ToggleResponse */0) {
                    return $$Array.append(acc, [existingResponse]);
                  }
                  var item = toggleIfMatch(responseIdentifier, existingResponse._0, toggleResponsePayload);
                  return $$Array.append(acc, item);
                }), [], responses);
  } else {
    return $$Array.append(responses, [{
                  TAG: /* ToggleResponse */0,
                  _0: toggleResponsePayload
                }]);
  }
}

function setIfMatch(responseIdentifier, existingResponsePayload, responsePayload, existingResponse) {
  var existingResponseIdentifier = existingResponsePayload._0;
  switch (responseIdentifier.TAG | 0) {
    case /* SingleId */0 :
        switch (existingResponseIdentifier.TAG | 0) {
          case /* SingleId */0 :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [{
                          TAG: /* SetResponse */1,
                          _0: responsePayload
                        }];
              } else {
                return [existingResponse];
              }
          case /* IdPair */1 :
          case /* Group */2 :
              return [existingResponse];
          
        }
    case /* IdPair */1 :
        switch (existingResponseIdentifier.TAG | 0) {
          case /* IdPair */1 :
              if (responseIdentifier._0 === existingResponseIdentifier._0) {
                return [{
                          TAG: /* SetResponse */1,
                          _0: responsePayload
                        }];
              } else {
                return [existingResponse];
              }
          case /* SingleId */0 :
          case /* Group */2 :
              return [existingResponse];
          
        }
    case /* Group */2 :
        var id1 = responseIdentifier._1;
        var g1 = responseIdentifier._0;
        switch (id1.TAG | 0) {
          case /* SingleId */0 :
              switch (existingResponseIdentifier.TAG | 0) {
                case /* SingleId */0 :
                case /* IdPair */1 :
                    return [existingResponse];
                case /* Group */2 :
                    var id2 = existingResponseIdentifier._1;
                    switch (id2.TAG | 0) {
                      case /* SingleId */0 :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === id2._0) {
                              return [{
                                        TAG: /* SetResponse */1,
                                        _0: responsePayload
                                      }];
                            } else {
                              return [existingResponse];
                            }
                          } else {
                            return [existingResponse];
                          }
                      case /* IdPair */1 :
                      case /* Group */2 :
                          return [existingResponse];
                      
                    }
                
              }
          case /* IdPair */1 :
              switch (existingResponseIdentifier.TAG | 0) {
                case /* SingleId */0 :
                case /* IdPair */1 :
                    return [existingResponse];
                case /* Group */2 :
                    var match = existingResponseIdentifier._1;
                    switch (match.TAG | 0) {
                      case /* IdPair */1 :
                          if (g1 === existingResponseIdentifier._0) {
                            if (id1._0 === match._0) {
                              return [{
                                        TAG: /* SetResponse */1,
                                        _0: responsePayload
                                      }];
                            } else {
                              return [existingResponse];
                            }
                          } else {
                            return [existingResponse];
                          }
                      case /* SingleId */0 :
                      case /* Group */2 :
                          return [existingResponse];
                      
                    }
                
              }
          case /* Group */2 :
              return [existingResponse];
          
        }
    
  }
}

function setResponse(responses, strResponsePayload) {
  var responseIdentifier = strResponsePayload._0;
  if (responseTypeExists(responses, strResponsePayload)) {
    return $$Array.fold_left((function (acc, existingResponse) {
                  if (existingResponse.TAG === /* ToggleResponse */0) {
                    throw {
                          RE_EXN_ID: "Match_failure",
                          _1: [
                            "Reducer.res",
                            166,
                            10
                          ],
                          Error: new Error()
                        };
                  }
                  var item = setIfMatch(responseIdentifier, existingResponse._0, strResponsePayload, existingResponse);
                  return $$Array.append(acc, item);
                }), [], responses);
  } else {
    return $$Array.append(responses, [{
                  TAG: /* SetResponse */1,
                  _0: strResponsePayload
                }]);
  }
}

function updateQuestion(responses, response) {
  var qs;
  if (responses.length !== 0) {
    var tmp;
    tmp = response.TAG === /* ToggleResponse */0 ? toggleResponse(responses, response._0) : setResponse(responses, response._0);
    qs = /* QuestionAttempted */{
      _0: tmp
    };
  } else {
    qs = /* QuestionAttempted */{
      _0: [response]
    };
  }
  if (qs && Caml_obj.caml_equal(qs._0, [])) {
    return /* NotAttempted */0;
  } else {
    return qs;
  }
}

function respondToQuestion(questionState, response) {
  if (questionState) {
    return updateQuestion(questionState._0, response);
  } else {
    return updateQuestion([], response);
  }
}

var splitChar = "<>";

function joinIdPair(id1, id2) {
  return id1 + (splitChar + id2);
}

function extractAnswers(responses) {
  return $$Array.fold_left((function (acc, response) {
                if (response.TAG === /* ToggleResponse */0) {
                  var responseIdentifier = response._0._0;
                  switch (responseIdentifier.TAG | 0) {
                    case /* SingleId */0 :
                        return List.append(acc, {
                                    hd: responseIdentifier._0,
                                    tl: /* [] */0
                                  });
                    case /* IdPair */1 :
                        return List.append(acc, {
                                    hd: joinIdPair(responseIdentifier._0, responseIdentifier._1),
                                    tl: /* [] */0
                                  });
                    case /* Group */2 :
                        var id = responseIdentifier._1;
                        switch (id.TAG | 0) {
                          case /* SingleId */0 :
                              return List.append(acc, {
                                          hd: id._0,
                                          tl: /* [] */0
                                        });
                          case /* IdPair */1 :
                              return List.append(acc, {
                                          hd: joinIdPair(id._0, id._1),
                                          tl: /* [] */0
                                        });
                          case /* Group */2 :
                              return acc;
                          
                        }
                    
                  }
                } else {
                  var responsePayload = response._0;
                  if (responsePayload.TAG === /* BoolResponsePayload */0) {
                    var responseIdentifier$1 = responsePayload._0;
                    switch (responseIdentifier$1.TAG | 0) {
                      case /* SingleId */0 :
                          return List.append(acc, {
                                      hd: responseIdentifier$1._0,
                                      tl: /* [] */0
                                    });
                      case /* IdPair */1 :
                          return List.append(acc, {
                                      hd: joinIdPair(responseIdentifier$1._0, responseIdentifier$1._1),
                                      tl: /* [] */0
                                    });
                      case /* Group */2 :
                          var id$1 = responseIdentifier$1._1;
                          switch (id$1.TAG | 0) {
                            case /* SingleId */0 :
                                return List.append(acc, {
                                            hd: id$1._0,
                                            tl: /* [] */0
                                          });
                            case /* IdPair */1 :
                                return List.append(acc, {
                                            hd: joinIdPair(id$1._0, id$1._1),
                                            tl: /* [] */0
                                          });
                            case /* Group */2 :
                                return acc;
                            
                          }
                      
                    }
                  } else {
                    var responseIdentifier$2 = responsePayload._0;
                    switch (responseIdentifier$2.TAG | 0) {
                      case /* SingleId */0 :
                          return List.append(acc, {
                                      hd: responseIdentifier$2._0 + (":" + $$String.trim(responsePayload._1)),
                                      tl: /* [] */0
                                    });
                      case /* IdPair */1 :
                      case /* Group */2 :
                          return acc;
                      
                    }
                  }
                }
              }), /* [] */0, responses);
}

function diff(xs, xss) {
  return List.fold_left((function (acc, el) {
                if (List.exists((function (row) {
                          return Caml_obj.caml_equal(row, el);
                        }), xss)) {
                  return acc;
                } else {
                  return List.append(acc, {
                              hd: el,
                              tl: /* [] */0
                            });
                }
              }), /* [] */0, xs);
}

function markQuestion(questionState, correctAnswer) {
  if (questionState) {
    var responses = questionState._0;
    if (correctAnswer.TAG === /* AnswerExactMatch */0) {
      var answer = correctAnswer._0;
      var usersAnswer = extractAnswers(responses);
      var incorrectResponses = List.append(diff(answer, usersAnswer), diff(usersAnswer, answer));
      var questionCorrect = List.length(incorrectResponses) === 0;
      return [
              questionCorrect,
              /* BoolMarkState */{
                _0: {
                  questionCorrect: questionCorrect,
                  answer: answer,
                  incorrectResponses: incorrectResponses
                }
              }
            ];
    }
    var answer$1 = correctAnswer._0;
    var usersAnswer$1 = extractAnswers(responses);
    var lowercaseUserAnswer = Belt_List.map(usersAnswer$1, $$String.lowercase_ascii);
    var lowercaseAnswers = Belt_List.map(answer$1, $$String.lowercase_ascii);
    var incorrectResponses$1 = List.append(diff(lowercaseAnswers, lowercaseUserAnswer), diff(lowercaseUserAnswer, lowercaseAnswers));
    var questionCorrect$1 = List.length(incorrectResponses$1) === 0;
    return [
            questionCorrect$1,
            /* BoolMarkState */{
              _0: {
                questionCorrect: questionCorrect$1,
                answer: answer$1,
                incorrectResponses: incorrectResponses$1
              }
            }
          ];
  }
  var answer$2 = correctAnswer._0;
  return [
          false,
          /* BoolMarkState */{
            _0: {
              questionCorrect: false,
              answer: answer$2,
              incorrectResponses: answer$2
            }
          }
        ];
}

function getMarkType(step, responseOptionId) {
  if (typeof step === "number" || step.TAG === /* Attempted */0) {
    return /* Unanswered */2;
  } else {
    var markState = step._0;
    if (markState) {
      var param = markState._0;
      if (List.exists((function (x) {
                return x === responseOptionId;
              }), param.incorrectResponses)) {
        return /* Wrong */1;
      } else {
        return /* Correct */0;
      }
    } else {
      return /* Unanswered */2;
    }
  }
}

function getMarkTypeWip(step, responseOptionId) {
  var compareToLeft = function (re, a) {
    var match = a.split(re);
    if (match.length !== 2) {
      return false;
    }
    var left = match[0];
    if (left !== undefined) {
      return left === responseOptionId;
    } else {
      return false;
    }
  };
  var setComparators = function (ra) {
    return Belt_Array.map(ra, (function (r) {
                  var _ri = r._0._0;
                  while(true) {
                    var ri = _ri;
                    switch (ri.TAG | 0) {
                      case /* SingleId */0 :
                          return function (x) {
                            return compareToLeft(/\:/, x);
                          };
                      case /* IdPair */1 :
                          return function (x) {
                            return compareToLeft(/\<>/, x);
                          };
                      case /* Group */2 :
                          _ri = ri._1;
                          continue ;
                      
                    }
                  };
                }));
  };
  if (typeof step === "number") {
    return /* Unanswered */2;
  }
  if (step.TAG === /* Attempted */0) {
    return /* Unanswered */2;
  }
  var ra = step._1;
  if (ra) {
    var ms = step._0;
    var ra$1 = ra._0;
    if (ms) {
      var param = ms._0;
      var incorrectResponses = param.incorrectResponses;
      var comparators = setComparators(ra$1);
      if (List.length(incorrectResponses) === 0) {
        return /* Correct */0;
      } else {
        return List.fold_left((function (acc, ir) {
                      if (acc !== 1) {
                        if ($$Array.fold_left((function (acc, comparator) {
                                  return acc ? acc : Curry._1(comparator, ir);
                                }), false, comparators)) {
                          return /* Wrong */1;
                        } else {
                          return /* Correct */0;
                        }
                      } else {
                        return acc;
                      }
                    }), /* Correct */0, incorrectResponses);
      }
    } else {
      return /* Unanswered */2;
    }
  } else {
    return /* Wrong */1;
  }
}

function buildResponseType(groupName, responseOptionId) {
  return {
          TAG: /* ToggleResponse */0,
          _0: {
            TAG: /* BoolResponsePayload */0,
            _0: groupName !== undefined ? ({
                  TAG: /* Group */2,
                  _0: groupName,
                  _1: {
                    TAG: /* SingleId */0,
                    _0: responseOptionId
                  }
                }) : ({
                  TAG: /* SingleId */0,
                  _0: responseOptionId
                })
          }
        };
}

function checkStep(questionState, markState) {
  if (questionState) {
    if (markState) {
      return {
              TAG: /* Marked */1,
              _0: markState,
              _1: questionState
            };
    } else {
      return {
              TAG: /* Attempted */0,
              _0: questionState
            };
    }
  } else if (markState) {
    return {
            TAG: /* Marked */1,
            _0: markState,
            _1: questionState
          };
  } else {
    return /* WaitingForUser */0;
  }
}

function isMarked(step) {
  if (typeof step === "number" || step.TAG === /* Attempted */0) {
    return false;
  } else {
    return true;
  }
}

function getSingleId(responseIdentifier, text, responseOptionId) {
  switch (responseIdentifier.TAG | 0) {
    case /* SingleId */0 :
        if (responseIdentifier._0 === responseOptionId) {
          return Caml_option.some(text);
        } else {
          return ;
        }
    case /* IdPair */1 :
    case /* Group */2 :
        return ;
    
  }
}

function getSetResponsePayload(responsePayload, responseOptionId) {
  if (responsePayload.TAG === /* BoolResponsePayload */0) {
    return ;
  } else {
    return getSingleId(responsePayload._0, responsePayload._1, responseOptionId);
  }
}

function getSetResponse(response, responseOptionId) {
  if (response.TAG === /* ToggleResponse */0) {
    return ;
  } else {
    return getSetResponsePayload(response._0, responseOptionId);
  }
}

function getResponse(responseArray, responseOptionId) {
  return Belt_Array.reduce(responseArray, "", (function (acc, response) {
                return Belt_Option.getWithDefault(getSetResponse(response, responseOptionId), acc);
              }));
}

function getAttempt(questionState, responseOptionId) {
  if (questionState) {
    return getResponse(questionState._0, responseOptionId);
  } else {
    return "";
  }
}

function getStepResponseValue(step, responseOptionId) {
  if (typeof step === "number") {
    return "";
  } else if (step.TAG === /* Attempted */0) {
    return getAttempt(step._0, responseOptionId);
  } else {
    return getAttempt(step._1, responseOptionId);
  }
}

function findIdPair(test, questionState) {
  if (questionState) {
    return Belt_Array.reduce(questionState._0, undefined, (function (acc, response) {
                  var responsePayload = response._0;
                  if (responsePayload.TAG === /* BoolResponsePayload */0) {
                    var match = responsePayload._0;
                    switch (match.TAG | 0) {
                      case /* IdPair */1 :
                          var resId2 = match._1;
                          var resId1 = match._0;
                          if (Curry._2(test, resId1, resId2)) {
                            return [
                                    resId1,
                                    resId2
                                  ];
                          } else {
                            return acc;
                          }
                      case /* SingleId */0 :
                      case /* Group */2 :
                          break;
                      
                    }
                  }
                  throw {
                        RE_EXN_ID: "Match_failure",
                        _1: [
                          "Reducer.res",
                          436,
                          8
                        ],
                        Error: new Error()
                      };
                }));
  }
  
}

function findQuestionState(step) {
  if (typeof step === "number") {
    return ;
  } else if (step.TAG === /* Attempted */0) {
    return step._0;
  } else {
    return step._1;
  }
}

function mapIdPair(step, f) {
  return Belt_Option.flatMap(findQuestionState(step), (function (param) {
                return findIdPair(f, param);
              }));
}

function mapDropId(idPair) {
  return Belt_Option.flatMap(idPair, (function (param) {
                return param[0];
              }));
}

function filterResponseIdentifier(questionState, identifier) {
  if (questionState) {
    return /* QuestionAttempted */{
            _0: Belt_Array.reduce(questionState._0, [], (function (acc, response) {
                    if (Caml_obj.caml_equal(response._0._0, identifier)) {
                      return acc;
                    } else {
                      return Belt_Array.concat(acc, [response]);
                    }
                  }))
          };
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "Reducer.res",
          469,
          2
        ],
        Error: new Error()
      };
}

export {
  responseIdentifiersMatch ,
  responseTypeExists ,
  toggleIfMatch ,
  toggleResponse ,
  setIfMatch ,
  setResponse ,
  updateQuestion ,
  respondToQuestion ,
  splitChar ,
  joinIdPair ,
  extractAnswers ,
  diff ,
  markQuestion ,
  getMarkType ,
  getMarkTypeWip ,
  buildResponseType ,
  checkStep ,
  isMarked ,
  getSingleId ,
  getSetResponsePayload ,
  getSetResponse ,
  getResponse ,
  getAttempt ,
  getStepResponseValue ,
  findIdPair ,
  findQuestionState ,
  mapIdPair ,
  mapDropId ,
  filterResponseIdentifier ,
  
}
/* No side effect */
