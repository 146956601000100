// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";

function use(param) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  if (match.activityEnded) {
    return /* ActivityEnded */0;
  }
  if (match.finishedLastQuestion) {
    return {
            TAG: /* FinishActivity */0,
            _0: match.onSetActivityEnd
          };
  }
  var tmp = match.currentQuestionStep;
  if (typeof tmp === "number" || tmp.TAG === /* Attempted */0) {
    return /* QuestionNotMarked */1;
  } else {
    return {
            TAG: /* QuestionMarked */1,
            _0: match.onNextQuestion
          };
  }
}

export {
  use ,
  
}
/* V2ExerciseContext-LiteracyplanetTupperware Not a pure module */
