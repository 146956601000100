import React from 'react';
import {Global, css} from '@emotion/react'

export default () =>
  <Global
    styles={css`
      * {
      box-sizing: border-box;
      }
      html {
        font-family: 'museo-sans-serif', 'Lato', 'Arial', sans-serif;
        font-size: 62.5%;
        -webkit-font-smoothing: antialiased;
      }
      img {
        margin: 0;
        padding: 0;
      }
      body {
        background: white;
        font-size: 1.2rem;
      }
    `}
  />;