// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as Spinner$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Spinner.bs.js";

function BookSpinner(Props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.BookSpinnerStyle.className
            }, React.createElement(Spinner$LiteracyplanetClientFlags.make, {}));
}

var make = BookSpinner;

export {
  make ,
  
}
/* react Not a pure module */
