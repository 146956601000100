// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseSubscription from "../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseSubscription.bs.js";
import * as SubscriptionHook$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/SubscriptionHook.bs.js";
import * as JourneyCurrentStudent$LiteracyplanetClientSxEntry from "./JourneyCurrentStudent.bs.js";

var Raw = {};

var query = Client.gql([
      "subscription missionsQuery($userId: String!)  {\nstudentMissionsAddedNonOptional(userId: $userId)  {\n...JourneyStudentMissionFragment   \n}\n\n}\n",
      ""
    ], JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.query);

function parse(value) {
  var value$1 = value.studentMissionsAddedNonOptional;
  return {
          studentMissionsAddedNonOptional: value$1.map(function (value) {
                return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.verifyArgsAndParse("JourneyStudentMissionFragment", value);
              })
        };
}

function serialize(value) {
  var value$1 = value.studentMissionsAddedNonOptional;
  var studentMissionsAddedNonOptional = value$1.map(function (value) {
        return JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyStudentMissionFragment.serialize(value);
      });
  return {
          studentMissionsAddedNonOptional: studentMissionsAddedNonOptional
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var GetMissions_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseSubscription.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetMissions = {
  GetMissions_inner: GetMissions_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use
};

var S = SubscriptionHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function ok(p) {
  return {
          TAG: /* Ok */0,
          _0: Caml_option.some(p)
        };
}

function onResult(response) {
  return {
          TAG: /* Ok */0,
          _0: Caml_option.some(response.studentMissionsAddedNonOptional)
        };
}

function onLoading(param) {
  
}

function useSubscription(userId) {
  return Curry._5(S.useWithError, onLoading, onLoading, onResult, (function (param) {
                return /* JourneyMissionsSubscriptionsError */2;
              }), {
              userId: userId
            });
}

var JourneyStudentMissionFragment;

export {
  JourneyStudentMissionFragment ,
  GetMissions ,
  S ,
  ok ,
  onResult ,
  onLoading ,
  useSubscription ,
  
}
/* query Not a pure module */
