// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import ReactDocumentTitle from "react-document-title";
import * as Error$LiteracyplanetClientErrors from "../../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as Result2$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Result2.bs.js";
import * as GameTypeData$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/GameTypeData.bs.js";
import * as SpellTestPage$LiteracyplanetTupperware from "../../../../../node_modules/@literacyplanet/tupperware/src/tupperware/v2/SpellTestPage.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/LocaleContext.bs.js";
import * as SoundProvider$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/SoundProvider.bs.js";
import * as ToolbarLayout$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/ToolbarLayout.bs.js";
import * as CobraContainer$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/CobraContainer.bs.js";
import * as VideoIcon$LiteracyplanetClientSxActivity from "../VideoIcon.bs.js";
import * as ActivityContainer$LiteracyplanetTupperware from "../../../../../node_modules/@literacyplanet/tupperware/src/tupperware/ActivityContainer.bs.js";
import * as VideoPlayer$LiteracyplanetClientSxActivity from "../VideoPlayer.bs.js";
import * as FlipperStore$LiteracyplanetClientSxActivity from "./FlipperStore.bs.js";
import * as FlipperTypes$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/FlipperTypes.bs.js";
import * as SoundProviderEmbedded$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/SoundProviderEmbedded.bs.js";
import * as FlipperCobraCommon$LiteracyplanetClientSxActivity from "./FlipperCobraCommon.bs.js";
import * as FlipperVideoDispatch$LiteracyplanetClientSxActivity from "./FlipperVideoDispatch.bs.js";
import * as FlipperCobraCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraCallbacks.bs.js";
import * as FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraResultsCallbacks.bs.js";
import * as TupperwareContentQueryParser$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/TupperwareContentQueryParser.bs.js";

function Flipper$PureComponents$Ui$FullScreen(Props) {
  var backgroundColor = Props.backgroundColor;
  var children = Props.children;
  return React.createElement("div", {
              style: {
                background: backgroundColor,
                height: "100%",
                overflowX: "hidden",
                position: "absolute",
                width: "100%"
              }
            }, children);
}

var FullScreen = {
  make: Flipper$PureComponents$Ui$FullScreen
};

function Flipper$PureComponents$Ui$Show(Props) {
  var children = Props.children;
  var visible = Props.visible;
  if (visible) {
    return children;
  } else {
    return null;
  }
}

var Show = {
  make: Flipper$PureComponents$Ui$Show
};

function Flipper$PureComponents$Ui$Visible(Props) {
  var visible = Props.visible;
  var children = Props.children;
  return React.createElement("div", {
              style: {
                display: visible ? "inherit" : "none"
              }
            }, children);
}

var Visible = {
  make: Flipper$PureComponents$Ui$Visible
};

function Flipper$PureComponents$Ui$OffScreen(Props) {
  var visible = Props.visible;
  var backgroundColor = Props.backgroundColor;
  var children = Props.children;
  return React.createElement("div", {
              style: {
                background: backgroundColor,
                height: "100%",
                left: "0px",
                overflow: "hidden",
                position: "absolute",
                top: visible ? "0px" : "-10000px",
                width: "100%"
              }
            }, children);
}

var OffScreen = {
  make: Flipper$PureComponents$Ui$OffScreen
};

var Ui = {
  FullScreen: FullScreen,
  Show: Show,
  Visible: Visible,
  OffScreen: OffScreen
};

function Flipper$PureComponents$ResultsComponent(Props) {
  var loadResults = Props.loadResults;
  var showResults = Props.showResults;
  var resultsProps = Props.resultsProps;
  var resultsDispatch = Props.resultsDispatch;
  if (loadResults) {
    return React.createElement(Flipper$PureComponents$Ui$OffScreen, {
                visible: showResults,
                backgroundColor: "#000000",
                children: React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                      view: "results",
                      props: resultsProps,
                      onDispatch: resultsDispatch
                    })
              });
  } else {
    return null;
  }
}

var ResultsComponent = {
  make: Flipper$PureComponents$ResultsComponent
};

function Flipper$PureComponents$TupperwareComponent(Props) {
  var title = Props.title;
  var visible = Props.visible;
  var backgroundColor = Props.backgroundColor;
  var results = Props.results;
  var children = Props.children;
  return React.createElement(ReactDocumentTitle, {
              title: title,
              children: React.createElement(React.Fragment, undefined, React.createElement(Flipper$PureComponents$Ui$FullScreen, {
                        backgroundColor: backgroundColor,
                        children: React.createElement(Flipper$PureComponents$Ui$Visible, {
                              visible: visible,
                              children: children
                            })
                      }), results)
            });
}

var TupperwareComponent = {
  make: Flipper$PureComponents$TupperwareComponent
};

function Flipper$PureComponents$CobraComponent(Props) {
  var title = Props.title;
  var visible = Props.visible;
  var activityLoaded = Props.activityLoaded;
  var cobraView = Props.cobraView;
  var activityProps = Props.activityProps;
  var activityDispatch = Props.activityDispatch;
  var results = Props.results;
  return React.createElement(ReactDocumentTitle, {
              title: title,
              children: React.createElement(React.Fragment, undefined, React.createElement(Flipper$PureComponents$Ui$FullScreen, {
                        backgroundColor: activityLoaded ? "#090E12" : "#FFFFFF",
                        children: React.createElement(Flipper$PureComponents$Ui$Show, {
                              visible: visible,
                              children: React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                                    view: cobraView,
                                    props: activityProps,
                                    onDispatch: activityDispatch
                                  })
                            })
                      }), results)
            });
}

var CobraComponent = {
  make: Flipper$PureComponents$CobraComponent
};

var PureComponents = {
  Ui: Ui,
  ResultsComponent: ResultsComponent,
  TupperwareComponent: TupperwareComponent,
  CobraComponent: CobraComponent
};

var FlipperCantFindGameById = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.CommonUtil.FlipperCantFindGameById");

function getGameTuple(res, getActivity, getGameId) {
  return Belt_Result.flatMap(Curry._1(getActivity, res), (function (activity) {
                return Belt_Result.map(Belt_Result.flatMap(Curry._1(getGameId, res), (function (game) {
                                  return Result2$LiteracyplanetClientFlags.Opt.toResult(GameTypeData$LiteracyplanetClientFlags.findByGameId(game), {
                                              RE_EXN_ID: FlipperCantFindGameById
                                            });
                                })), (function (game) {
                              return [
                                      game,
                                      activity,
                                      res
                                    ];
                            }));
              }));
}

function handleGameData(queryState, getActivity, getGameId, ready, failed) {
  if (!queryState) {
    return ;
  }
  var res = getGameTuple(queryState._0, getActivity, getGameId);
  if (res.TAG === /* Ok */0) {
    return Curry._1(ready, res._0);
  } else {
    return Curry._1(failed, res._0);
  }
}

function makeCobraResults(state) {
  return {
          currentStudent: {},
          results: {
            percentCorrect: state.percentCorrect,
            chest: "GEM",
            needsToRefreshDivOffsets: state.showResults,
            needsToShowMissionComplete: state.needsToShowMissionComplete
          }
        };
}

var CommonUtil = {
  FlipperCantFindGameById: FlipperCantFindGameById,
  getGameTuple: getGameTuple,
  handleGameData: handleGameData,
  makeCobraResults: makeCobraResults
};

function Flipper$CobraWordGameComponentNoResultsContainer(Props) {
  var activityProps = Props.activityProps;
  var title = Props.title;
  var cobraActivity = Props.cobraActivity;
  var activityDispatch = FlipperCobraCallbacks$LiteracyplanetClientSxActivity.Context.use(undefined);
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var activityProps_currentStudent = activityProps.currentStudent;
  var activityProps_questionIndex = state.numComplete;
  var activityProps_numCorrect = state.numCorrect;
  var activityProps_activityMetadata = activityProps.activityMetadata;
  var activityProps_extraVocals = activityProps.extraVocals;
  var activityProps_wordGameContent = activityProps.wordGameContent;
  var activityProps$1 = {
    currentStudent: activityProps_currentStudent,
    questionIndex: activityProps_questionIndex,
    numCorrect: activityProps_numCorrect,
    activityMetadata: activityProps_activityMetadata,
    extraVocals: activityProps_extraVocals,
    wordGameContent: activityProps_wordGameContent
  };
  var cobraView = cobraActivity.cobraView;
  if (state.initialised) {
    return React.createElement(Flipper$PureComponents$CobraComponent, {
                title: title,
                visible: true,
                activityLoaded: state.activityLoaded,
                cobraView: cobraView,
                activityProps: activityProps$1,
                activityDispatch: activityDispatch,
                results: null
              });
  } else {
    return null;
  }
}

var CobraWordGameComponentNoResultsContainer = {
  make: Flipper$CobraWordGameComponentNoResultsContainer
};

function Flipper$CobraWordGameComponentContainer(Props) {
  var activityProps = Props.activityProps;
  var title = Props.title;
  var cobraActivity = Props.cobraActivity;
  var activityDispatch = FlipperCobraCallbacks$LiteracyplanetClientSxActivity.Context.use(undefined);
  var resultsDispatch = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Context.use(undefined);
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var showResults = state.showResults;
  var resultsProps = makeCobraResults(state);
  var activityProps_currentStudent = activityProps.currentStudent;
  var activityProps_questionIndex = state.numComplete;
  var activityProps_numCorrect = state.numCorrect;
  var activityProps_activityMetadata = activityProps.activityMetadata;
  var activityProps_extraVocals = activityProps.extraVocals;
  var activityProps_wordGameContent = activityProps.wordGameContent;
  var activityProps$1 = {
    currentStudent: activityProps_currentStudent,
    questionIndex: activityProps_questionIndex,
    numCorrect: activityProps_numCorrect,
    activityMetadata: activityProps_activityMetadata,
    extraVocals: activityProps_extraVocals,
    wordGameContent: activityProps_wordGameContent
  };
  var cobraView = cobraActivity.cobraView;
  if (state.initialised) {
    return React.createElement(Flipper$PureComponents$CobraComponent, {
                title: title,
                visible: !showResults,
                activityLoaded: state.activityLoaded,
                cobraView: cobraView,
                activityProps: activityProps$1,
                activityDispatch: activityDispatch,
                results: React.createElement(Flipper$PureComponents$ResultsComponent, {
                      loadResults: state.loadResults,
                      showResults: showResults,
                      resultsProps: resultsProps,
                      resultsDispatch: resultsDispatch
                    })
              });
  } else {
    return null;
  }
}

var CobraWordGameComponentContainer = {
  make: Flipper$CobraWordGameComponentContainer
};

function render(cobraActivity, title, props) {
  return React.createElement(Flipper$CobraWordGameComponentContainer, {
              activityProps: props._0,
              title: title,
              cobraActivity: cobraActivity
            });
}

function mergeProps(props, extraVocals, activityMetadata) {
  switch (props.TAG | 0) {
    case /* WordListData */0 :
        var activityProps = props._0;
        return {
                TAG: /* WordListData */0,
                _0: {
                  currentStudent: activityProps.currentStudent,
                  questionIndex: activityProps.questionIndex,
                  numCorrect: activityProps.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps.wordGameContent
                }
              };
    case /* SoundTrainData */1 :
        var activityProps$1 = props._0;
        return {
                TAG: /* SoundTrainData */1,
                _0: {
                  currentStudent: activityProps$1.currentStudent,
                  questionIndex: activityProps$1.questionIndex,
                  numCorrect: activityProps$1.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$1.wordGameContent
                }
              };
    case /* WhackAMunkData */2 :
        var activityProps$2 = props._0;
        return {
                TAG: /* WhackAMunkData */2,
                _0: {
                  currentStudent: activityProps$2.currentStudent,
                  questionIndex: activityProps$2.questionIndex,
                  numCorrect: activityProps$2.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$2.wordGameContent
                }
              };
    case /* MonsterChefData */3 :
        var activityProps$3 = props._0;
        return {
                TAG: /* MonsterChefData */3,
                _0: {
                  currentStudent: activityProps$3.currentStudent,
                  questionIndex: activityProps$3.questionIndex,
                  numCorrect: activityProps$3.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$3.wordGameContent
                }
              };
    case /* InernalStaticData */4 :
        var activityProps$4 = props._0;
        return {
                TAG: /* InernalStaticData */4,
                _0: {
                  currentStudent: activityProps$4.currentStudent,
                  questionIndex: activityProps$4.questionIndex,
                  numCorrect: activityProps$4.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$4.wordGameContent
                }
              };
    case /* UnsafeData */5 :
        var activityProps$5 = props._0;
        return {
                TAG: /* UnsafeData */5,
                _0: {
                  currentStudent: activityProps$5.currentStudent,
                  questionIndex: activityProps$5.questionIndex,
                  numCorrect: activityProps$5.numCorrect,
                  activityMetadata: activityMetadata,
                  extraVocals: extraVocals,
                  wordGameContent: activityProps$5.wordGameContent
                }
              };
    
  }
}

function mergeCommonProps(cobraActivity, localeCtx, title, props) {
  return mergeProps(props, FlipperCobraCommon$LiteracyplanetClientSxActivity.GameInstructions.parse(cobraActivity, localeCtx), {
              title: title
            });
}

function renderCobraActivity(props, cobraActivity, title) {
  return Belt_Result.map(Belt_Result.flatMap(props, (function (param) {
                    return FlipperTypes$LiteracyplanetClientLibsActivity.Cobra.validate(cobraActivity, param);
                  })), (function (param) {
                return render(cobraActivity, title, param);
              }));
}

var Cobra = {
  render: render,
  mergeProps: mergeProps,
  mergeCommonProps: mergeCommonProps,
  renderCobraActivity: renderCobraActivity
};

function Flipper$TupperwareContainer(Props) {
  var data = Props.data;
  var title = Props.title;
  var results = Props.results;
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var visible = !state.showResults;
  var backgroundColor = state.backgroundColor;
  var c = React.createElement(Flipper$PureComponents$TupperwareComponent, {
        title: title,
        visible: visible,
        backgroundColor: backgroundColor,
        results: results,
        children: React.createElement(ActivityContainer$LiteracyplanetTupperware.make, {
              data: data
            })
      });
  return React.createElement(SoundProvider$LiteracyplanetClientFlags.make, {
              children: React.createElement(SoundProviderEmbedded$LiteracyplanetClientFlags.make, {
                    children: c
                  })
            });
}

var TupperwareContainer = {
  make: Flipper$TupperwareContainer
};

function Flipper$TupperwareComponentNoResultsContainer(Props) {
  var data = Props.data;
  var title = Props.title;
  return React.createElement(Flipper$TupperwareContainer, {
              data: data,
              title: title,
              results: null
            });
}

var TupperwareComponentNoResultsContainer = {
  make: Flipper$TupperwareComponentNoResultsContainer
};

function Flipper$TupperwareComponentContainer(Props) {
  var data = Props.data;
  var title = Props.title;
  var resultsDispatch = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Context.use(undefined);
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var showResults = state.showResults;
  var resultsProps = makeCobraResults(state);
  var loadResults = state.loadResults;
  return React.createElement(Flipper$TupperwareContainer, {
              data: data,
              title: title,
              results: React.createElement(Flipper$PureComponents$ResultsComponent, {
                    loadResults: loadResults,
                    showResults: showResults,
                    resultsProps: resultsProps,
                    resultsDispatch: resultsDispatch
                  })
            });
}

var TupperwareComponentContainer = {
  make: Flipper$TupperwareComponentContainer
};

var FlipperTupperwareDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.Tupperware.FlipperTupperwareDataNotValid");

function defaultComponent(title, param) {
  return React.createElement(Flipper$TupperwareComponentContainer, {
              data: param[0],
              title: title
            });
}

function noResultsTupperwareComponent(title, param) {
  return React.createElement(Flipper$TupperwareComponentNoResultsContainer, {
              data: param[0],
              title: title
            });
}

function parseAndRender(componentOpt, content, title) {
  var component = componentOpt !== undefined ? componentOpt : defaultComponent;
  return Belt_Result.map(Result2$LiteracyplanetClientFlags.Opt.toResult(TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.getTupperwareData(content), {
                  RE_EXN_ID: FlipperTupperwareDataNotValid
                }), Curry._1(component, title));
}

var Tupperware = {
  FlipperTupperwareDataNotValid: FlipperTupperwareDataNotValid,
  defaultComponent: defaultComponent,
  noResultsTupperwareComponent: noResultsTupperwareComponent,
  parseAndRender: parseAndRender,
  isSkilfulLayout: TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.isSkilfulLayout
};

var pageStyles = {
  backgroundColor: "000",
  color: "0C9CE5",
  activeColor: "723C8D",
  hoverColor: "0A88E0",
  cover: false
};

function Flipper$VideoComponentContainer(Props) {
  var source = Props.source;
  var title = Props.title;
  var callbacks = FlipperVideoDispatch$LiteracyplanetClientSxActivity.Context.use(undefined);
  return React.createElement(ReactDocumentTitle, {
              title: title,
              children: React.createElement(ToolbarLayout$LiteracyplanetClientFlags.make, {
                    icon: React.createElement(VideoIcon$LiteracyplanetClientSxActivity.make, {}),
                    title: title,
                    exitTo: callbacks.onToolbarExit,
                    pageStyles: pageStyles,
                    children: React.createElement("div", {
                          style: {
                            padding: "1rem"
                          }
                        }, React.createElement(VideoPlayer$LiteracyplanetClientSxActivity.make, {
                              source: source,
                              callbacks: callbacks
                            }))
                  })
            });
}

var VideoComponentContainer = {
  pageStyles: pageStyles,
  make: Flipper$VideoComponentContainer
};

var FlipperVideoDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.Video.FlipperVideoDataNotValid");

function render$1(content, title) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(typeof content === "object" && content.NAME === "VideoData" ? Belt_Option.map(Belt_Option.map(Belt_Option.flatMap(content.VAL.video, (function (v) {
                              return Belt_Array.get(v.sources, 0);
                            })), (function (v) {
                          return v.source;
                        })), (function (source) {
                      return React.createElement(Flipper$VideoComponentContainer, {
                                  source: source,
                                  title: title
                                });
                    })) : undefined, {
              RE_EXN_ID: FlipperVideoDataNotValid
            });
}

var Video = {
  FlipperVideoDataNotValid: FlipperVideoDataNotValid,
  render: render$1
};

function Flipper$SpellTestComponentContainer(Props) {
  var list = Props.list;
  var title = Props.title;
  var resultsDispatch = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Context.use(undefined);
  var state = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var showResults = state.showResults;
  var resultsProps = makeCobraResults(state);
  var loadResults = state.loadResults;
  var state$1 = FlipperStore$LiteracyplanetClientSxActivity.Context.useState(undefined);
  var visible = !state$1.showResults;
  var backgroundColor = state$1.backgroundColor;
  var results = React.createElement(Flipper$PureComponents$ResultsComponent, {
        loadResults: loadResults,
        showResults: showResults,
        resultsProps: resultsProps,
        resultsDispatch: resultsDispatch
      });
  return React.createElement(Flipper$PureComponents$TupperwareComponent, {
              title: title,
              visible: visible,
              backgroundColor: backgroundColor,
              results: results,
              children: React.createElement(SpellTestPage$LiteracyplanetTupperware.make, {
                    list: list
                  })
            });
}

var SpellTestComponentContainer = {
  make: Flipper$SpellTestComponentContainer
};

var FlipperSpellTestDataNotValid = /* @__PURE__ */Caml_exceptions.create("Flipper-LiteracyplanetClientSxActivity.SpellTest.FlipperSpellTestDataNotValid");

function render$2(content, title) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(typeof content === "object" && content.NAME === "WordGameData" ? Caml_option.some(React.createElement(Flipper$SpellTestComponentContainer, {
                        list: content.VAL.list,
                        title: title
                      })) : undefined, {
              RE_EXN_ID: FlipperSpellTestDataNotValid
            });
}

var SpellTest = {
  FlipperSpellTestDataNotValid: FlipperSpellTestDataNotValid,
  render: render$2
};

function Flipper$CallbackComponent(Props) {
  var callback = Props.callback;
  React.useEffect((function () {
          Curry._1(callback, undefined);
          
        }), []);
  return null;
}

var CallbackComponent = {
  make: Flipper$CallbackComponent
};

var loading = null;

function useWithErrorCallback(onErrorCallback, queryState, getActivity, getGameId, getGameComponent) {
  var match = React.useState(function () {
        return loading;
      });
  var setComponent = match[1];
  var localeCtx = LocaleContext$LiteracyplanetClientFlags.use(undefined);
  var failed = function (e) {
    console.log(e);
    return Curry._1(onErrorCallback, {
                TAG: /* Exception */3,
                _0: e
              });
  };
  var ready = function (param) {
    var el = Curry._1(getGameComponent, [
          localeCtx,
          param[0],
          param[1],
          param[2]
        ]);
    if (el.TAG !== /* Ok */0) {
      return failed(el._0);
    }
    var el$1 = el._0;
    return Curry._1(setComponent, (function (param) {
                  return el$1;
                }));
  };
  React.useEffect((function () {
          handleGameData(queryState, getActivity, getGameId, ready, failed);
          
        }), [queryState]);
  return match[0];
}

function use(queryState, getActivity, getGameId, getGameComponent) {
  var match = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
  return useWithErrorCallback(match.onSetError, queryState, getActivity, getGameId, getGameComponent);
}

var Hooks = {
  useWithErrorCallback: useWithErrorCallback,
  use: use
};

export {
  PureComponents ,
  CommonUtil ,
  CobraWordGameComponentNoResultsContainer ,
  CobraWordGameComponentContainer ,
  Cobra ,
  TupperwareContainer ,
  TupperwareComponentNoResultsContainer ,
  TupperwareComponentContainer ,
  Tupperware ,
  VideoComponentContainer ,
  Video ,
  SpellTestComponentContainer ,
  SpellTest ,
  CallbackComponent ,
  loading ,
  Hooks ,
  
}
/* loading Not a pure module */
