// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Css_Legacy_Core from "../../../../../node_modules/bs-css/src/Css_Legacy_Core.bs.js";
import * as MobileDetect$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/MobileDetect.bs.js";
import * as SecondaryHeaderStyles$LiteracyplanetClientSxEntry from "./SecondaryHeaderStyles.bs.js";

var dropShadowColor = Css.hex("000000");

var planetColor = Css.rgba(121, 206, 255, {
      NAME: "num",
      VAL: 0.7
    });

var chestColor = Css.rgba(255, 236, 168, {
      NAME: "num",
      VAL: 0.7
    });

var avatarColor = Css.rgba(205, 252, 137, {
      NAME: "num",
      VAL: 0.7
    });

var bookColor = Css.rgba(186, 220, 186, {
      NAME: "num",
      VAL: 0.7
    });

var letteraColor = Css.rgba(181, 224, 255, {
      NAME: "num",
      VAL: 0.7
    });

var primaryBlue = Css.rgba(13, 156, 229, {
      NAME: "num",
      VAL: 1.0
    });

var darkBlue = Css.rgba(25, 79, 120, {
      NAME: "num",
      VAL: 1.0
    });

var dims = MobileDetect$LiteracyplanetClientFlags.getDimensions(undefined);

function toVh(pixel) {
  return Css.vh(pixel / dims.height * 100.0);
}

function toVw(pixel) {
  return Css.vw(pixel / dims.width * 100.0);
}

function dimensions(pixelWidth, pixelHeight) {
  return Curry._1(Css.style, {
              hd: Css.width(toVh(pixelWidth)),
              tl: {
                hd: Css.height(toVh(pixelHeight)),
                tl: {
                  hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                        hd: Css.width(toVw(pixelWidth)),
                        tl: {
                          hd: Css.height(toVw(pixelHeight)),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            });
}

var defaultFonts = {
  hd: {
    NAME: "custom",
    VAL: "Quicksand"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "Lato"
    },
    tl: {
      hd: {
        NAME: "custom",
        VAL: "Arial"
      },
      tl: /* [] */0
    }
  }
};

var headerPanel = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: /* [] */0
        }
      }
    });

var headerSubPanel = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.display(Css.inlineBlock),
        tl: /* [] */0
      }
    });

var headerSubSubPanel = Curry._1(Css.style, {
      hd: Css.marginLeft(toVh(20)),
      tl: {
        hd: Css.marginRight(toVh(20)),
        tl: {
          hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                hd: Css.marginLeft(toVw(20)),
                tl: {
                  hd: Css.marginRight(toVw(20)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var headerCenter = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.position(Css.relative),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: {
            hd: Css.justifyContent(Css.center),
            tl: /* [] */0
          }
        }
      }
    });

var headerList = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridAutoColumns(toVh(92)),
        tl: {
          hd: Css.height(toVh(100)),
          tl: {
            hd: Css.gridAutoFlow(Css.column),
            tl: {
              hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                    hd: Css.gridAutoColumns(toVw(92)),
                    tl: {
                      hd: Css.height(toVw(100)),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerPanelTemp = Curry._1(Css.style, {
      hd: Css.backgroundColor(Css.red),
      tl: /* [] */0
    });

var headerBar = Curry._1(Css.style, {
      hd: Css.zIndex(1),
      tl: {
        hd: Css.position(Css.absolute),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.backgroundColor(primaryBlue),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: {
                    hd: Css.gridAutoColumns(Css.fr(1.0)),
                    tl: {
                      hd: Css.gridAutoFlow(Css.column),
                      tl: {
                        hd: Css.borderRadius(toVh(22)),
                        tl: {
                          hd: Css.height(toVh(45)),
                          tl: {
                            hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                                  hd: Css.borderRadius(toVw(22)),
                                  tl: {
                                    hd: Css.height(toVw(45)),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function headerItem(large, highlightColor) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.fontFamilies(defaultFonts),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: {
                    hd: Css.fontWeight(Css.bold),
                    tl: {
                      hd: Css.gridTemplateRows({
                            hd: large ? toVh(72) : toVh(60),
                            tl: {
                              hd: Css.auto,
                              tl: /* [] */0
                            }
                          }),
                      tl: {
                        hd: Css.fontSize(large ? toVh(14) : toVh(12)),
                        tl: {
                          hd: Css.height(toVh(100)),
                          tl: {
                            hd: Css.width(toVh(92)),
                            tl: {
                              hd: Css.paddingTop(large ? toVh(0) : toVh(10)),
                              tl: {
                                hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                                      hd: Css.gridTemplateRows({
                                            hd: large ? toVw(72) : toVw(60),
                                            tl: {
                                              hd: Css.auto,
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.fontSize(large ? toVw(14) : toVw(12)),
                                        tl: {
                                          hd: Css.height(toVw(100)),
                                          tl: {
                                            hd: Css.width(toVw(92)),
                                            tl: {
                                              hd: Css.paddingTop(large ? toVw(0) : toVw(10)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Css.justifyContent(Css.center),
                                  tl: {
                                    hd: Css.textAlign(Css.center),
                                    tl: {
                                      hd: Css.zIndex(10),
                                      tl: {
                                        hd: Css.color(Css.white),
                                        tl: {
                                          hd: Css.boxSizing(Css.borderBox),
                                          tl: {
                                            hd: large ? Css.selector("& svg", {
                                                    hd: Css.filter({
                                                          hd: {
                                                            NAME: "dropShadow",
                                                            VAL: [
                                                              Css.vh(0.0),
                                                              Css.vh(0.1),
                                                              Css.vh(0.5),
                                                              dropShadowColor
                                                            ]
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }) : Css.selector("& svg", {
                                                    hd: Css.filter({
                                                          hd: {
                                                            NAME: "dropShadow",
                                                            VAL: [
                                                              Css.vh(0.0),
                                                              Css.vh(0.0),
                                                              Css.vh(0.0),
                                                              dropShadowColor
                                                            ]
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: large ? Css.selector("& svg", {
                                                            hd: Css.filter({
                                                                  hd: {
                                                                    NAME: "dropShadow",
                                                                    VAL: [
                                                                      Css.vh(0.0),
                                                                      Css.vh(0.1),
                                                                      Css.vh(0.5),
                                                                      dropShadowColor
                                                                    ]
                                                                  },
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }) : Css.selector("& svg", {
                                                            hd: Css.filter({
                                                                  hd: {
                                                                    NAME: "dropShadow",
                                                                    VAL: [
                                                                      Css.vh(0.0),
                                                                      Css.vh(0.0),
                                                                      Css.vh(0.5),
                                                                      highlightColor
                                                                    ]
                                                                  },
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var headerLockOverlay = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.top(Css.zero),
        tl: {
          hd: Css.right(Css.zero),
          tl: {
            hd: Css.height(Css.pct(100.0)),
            tl: {
              hd: Css.paddingTop(Css.pct(8.0)),
              tl: {
                hd: Css.paddingRight(Css.pct(12.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var lockSmallIcon = Curry._1(Css.style, {
      hd: Css.height(Css.pct(25.0)),
      tl: /* [] */0
    });

var modernHeaderLockOverlay = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.top(Css.pct(-14.0)),
        tl: {
          hd: Css.right(Css.pct(4.0)),
          tl: {
            hd: Css.height(Css.pct(100.0)),
            tl: /* [] */0
          }
        }
      }
    });

var modernLockSmallIcon = Curry._1(Css.style, {
      hd: Css.height(Css.pct(50.0)),
      tl: /* [] */0
    });

var missionIcon = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.height(Css.pct(100.0)),
        tl: /* [] */0
      }
    });

var missionText = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: Css.fontSize(Css.em(1.0)),
        tl: /* [] */0
      }
    });

var headerRow = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridAutoFlow(Css.column),
        tl: /* [] */0
      }
    });

var testContainer = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.height(Css.pct(100.0)),
        tl: {
          hd: Css.backgroundColor(Css.gray),
          tl: /* [] */0
        }
      }
    });

var menuPanel = Curry._1(Css.style, {
      hd: Css.zIndex(10),
      tl: {
        hd: Css.position("fixed"),
        tl: {
          hd: Css.left(Css.pct(50.0)),
          tl: {
            hd: Css.marginTop(toVh(32)),
            tl: {
              hd: Css.marginLeft(toVh((dims.width / 2 | 0) - 86 | 0)),
              tl: {
                hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                      hd: Css.marginTop(toVw(32)),
                      tl: {
                        hd: Css.marginLeft(toVw((dims.width / 2 | 0) - 86 | 0)),
                        tl: /* [] */0
                      }
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var menuIcon = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.fontFamilies(defaultFonts),
              tl: {
                hd: Css.fontWeight("bold"),
                tl: {
                  hd: Css.fontSize(toVh(18)),
                  tl: {
                    hd: Css.width(toVh(54)),
                    tl: {
                      hd: Css.height(toVh(54)),
                      tl: {
                        hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                              hd: Css.width(toVw(54)),
                              tl: {
                                hd: Css.height(toVw(54)),
                                tl: {
                                  hd: Css.fontSize(toVw(18)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.color(primaryBlue),
                          tl: {
                            hd: Css.backgroundColor(Css.white),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(SecondaryHeaderStyles$LiteracyplanetClientSxEntry.closeHoverColor),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.borderRadius(Css.pct(50.0)),
                                tl: {
                                  hd: Css_Legacy_Core.SVG.fill(primaryBlue),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var menuOpenIcon = Curry._1(Css.style, {
      hd: Css.width(toVh(16)),
      tl: {
        hd: Css.height(toVh(16)),
        tl: {
          hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                hd: Css.width(toVw(16)),
                tl: {
                  hd: Css.height(toVw(16)),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var modalBackgroundColor = Css.rgba(0, 0, 0, {
      NAME: "num",
      VAL: 0.5
    });

var modalColor = Css.rgba(255, 255, 255, {
      NAME: "num",
      VAL: 1.0
    });

var lockedModalBackground = Curry._1(Css.style, {
      hd: Css.position(Css.fixed),
      tl: {
        hd: Css.top(Css.zero),
        tl: {
          hd: Css.left(Css.zero),
          tl: {
            hd: Css.height(Css.pct(100.0)),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.backgroundColor(modalBackgroundColor),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: {
                    hd: Css.alignItems(Css.center),
                    tl: {
                      hd: Css.justifyContent(Css.center),
                      tl: {
                        hd: Css.zIndex(100),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var lockedModalIcon = Curry._1(Css.style, {
      hd: Css.height(Css.pct(30.0)),
      tl: {
        hd: Css.marginBottom(Css.pct(5.0)),
        tl: /* [] */0
      }
    });

var lockedModalText = Curry._1(Css.style, {
      hd: Css.fontFamilies(defaultFonts),
      tl: {
        hd: Css.fontWeight(Css.bold),
        tl: {
          hd: Css.textAlign(Css.center),
          tl: {
            hd: Css.color(darkBlue),
            tl: /* [] */0
          }
        }
      }
    });

var lockedModal = Curry._1(Css.style, {
      hd: Css.borderRadius(toVh(22)),
      tl: {
        hd: Css.width(toVh(450)),
        tl: {
          hd: Css.height(toVh(274)),
          tl: {
            hd: Css.fontSize(toVh(24)),
            tl: {
              hd: Css.backgroundColor(modalColor),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.gridTemplateColumns({
                        hd: toVh(60),
                        tl: {
                          hd: Css.fr(1.0),
                          tl: {
                            hd: toVh(60),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                          hd: Css.borderRadius(toVw(22)),
                          tl: {
                            hd: Css.width(toVw(450)),
                            tl: {
                              hd: Css.height(toVw(274)),
                              tl: {
                                hd: Css.fontSize(toVw(24)),
                                tl: {
                                  hd: Css.gridTemplateColumns({
                                        hd: toVw(60),
                                        tl: {
                                          hd: Css.fr(1.0),
                                          tl: {
                                            hd: toVw(60),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var lockedModalContent = Curry._1(Css.style, {
      hd: Css.height(Css.pct(60.0)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.justifyContent(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

var closeModalIcon = Curry._1(Css.style, {
      hd: Css.height(Css.pct(5.0)),
      tl: {
        hd: Css.color(primaryBlue),
        tl: {
          hd: Css.marginTop(Css.pct(30.0)),
          tl: {
            hd: Css.marginRight(Css.pct(30.0)),
            tl: {
              hd: Css.justifySelf("end_"),
              tl: /* [] */0
            }
          }
        }
      }
    });

export {
  dropShadowColor ,
  planetColor ,
  chestColor ,
  avatarColor ,
  bookColor ,
  letteraColor ,
  primaryBlue ,
  darkBlue ,
  dims ,
  toVh ,
  toVw ,
  dimensions ,
  defaultFonts ,
  headerPanel ,
  headerSubPanel ,
  headerSubSubPanel ,
  headerCenter ,
  headerList ,
  headerPanelTemp ,
  headerBar ,
  headerItem ,
  headerLockOverlay ,
  lockSmallIcon ,
  modernHeaderLockOverlay ,
  modernLockSmallIcon ,
  missionIcon ,
  missionText ,
  headerRow ,
  testContainer ,
  menuPanel ,
  menuIcon ,
  menuOpenIcon ,
  modalBackgroundColor ,
  modalColor ,
  lockedModalBackground ,
  lockedModalIcon ,
  lockedModalText ,
  lockedModal ,
  lockedModalContent ,
  closeModalIcon ,
  
}
/* dropShadowColor Not a pure module */
