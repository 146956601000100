// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_format from "../../../node_modules/rescript/lib/es6/caml_format.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Override$LiteracyplanetClientFlags from "./Override.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "./UserContext.bs.js";
import * as FlagsProvider$LiteracyplanetClientFlags from "./FlagsProvider.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "./LocaleContext.bs.js";
import * as OauthContainer$LiteracyplanetClientFlags from "./OauthContainer.bs.js";

var isMobile = process.env.MOBILE === "true";

function Auth$UserTypeRedirect(Props) {
  var userType = Props.userType;
  var routeReplace = Props.routeReplace;
  var children = Props.children;
  var isValid = isMobile ? true : (
      userType === "student" ? window.location.pathname.includes("sx") : /^\/(tx|ax|sx\/en_)/.test(window.location.pathname)
    );
  React.useEffect((function () {
          if (isValid) {
            
          } else {
            Curry._1(routeReplace, "/");
          }
          
        }), []);
  if (isValid) {
    return children;
  } else {
    return null;
  }
}

var UserTypeRedirect = {
  make: Auth$UserTypeRedirect
};

function Auth(Props) {
  var routeReplace = Props.routeReplace;
  var customUnleashFeatures = Props.customUnleashFeatures;
  var children = Props.children;
  return React.createElement(OauthContainer$LiteracyplanetClientFlags.make, {
              children: (function (param) {
                  var yearLevel = param.yearLevel;
                  var idpAccountId = param.idpAccountId;
                  var idp = param.idp;
                  var userId = param.userId;
                  var loc = LocaleContext$LiteracyplanetClientFlags.fromClassicLocaleString(param.locale);
                  var accent = LocaleContext$LiteracyplanetClientFlags.localeToAccent(loc);
                  var accentString = LocaleContext$LiteracyplanetClientFlags.toAccentString(accent);
                  var localeString = LocaleContext$LiteracyplanetClientFlags.toLocaleString(loc);
                  var systemFlags = Override$LiteracyplanetClientFlags.loadFlags(undefined);
                  var tmp = {
                    userId: userId,
                    classicFlags: param.classicFlags,
                    systemFlags: systemFlags,
                    userRoles: param.userRoles,
                    idp: idp,
                    idpAccountId: idpAccountId,
                    yearLevel: yearLevel,
                    loadingComponent: React.createElement("div", undefined, null),
                    children: React.createElement(UserContext$LiteracyplanetClientFlags.make, UserContext$LiteracyplanetClientFlags.makeProps({
                              name: param.name,
                              userId: userId,
                              accountName: param.accountName,
                              givenName: param.givenName,
                              familyName: param.familyName,
                              accountId: Caml_format.caml_int_of_string(idpAccountId),
                              startingPoint: param.startingPoint,
                              yearLevel: yearLevel,
                              idp: idp,
                              email: param.email
                            }, React.createElement(LocaleContext$LiteracyplanetClientFlags.make, LocaleContext$LiteracyplanetClientFlags.makeProps({
                                      locale: loc,
                                      accent: accent,
                                      localeString: localeString,
                                      accentString: accentString
                                    }, children, undefined)), undefined))
                  };
                  if (customUnleashFeatures !== undefined) {
                    tmp.customUnleashFeatures = Caml_option.valFromOption(customUnleashFeatures);
                  }
                  return React.createElement(Auth$UserTypeRedirect, {
                              userType: param.userType,
                              routeReplace: routeReplace,
                              children: React.createElement(FlagsProvider$LiteracyplanetClientFlags.make, tmp)
                            });
                })
            });
}

var make = Auth;

export {
  isMobile ,
  UserTypeRedirect ,
  make ,
  
}
/* isMobile Not a pure module */
