import React from 'react';

export const DataProviderPlugin = (Component, componentName) => {
  return {
    renderNode(props, editor, next) {
      const {attributes, children, node} = props;
      Component.displayName = componentName
        ? componentName
        : (
            Component.displayName
              ? Component.displayName
              : Component.prototype.constructor.name
          );
      if (node.type === Component.displayName) {
        const data = node.get('data').toJS();
        return (
          <Component {...attributes} {...data}>{children}</Component>
        );
      } else if (node.type === 'span') {
        const data = node.get('data').toJS();
        return <span {...attributes} {...data}>{children}</span>;
      } else if (node.type === 'div') {
        const data = node.get('data').toJS();
        return <div {...attributes} {...data}>{children}</div>;
      }
      return next();
    },

    renderMark(props, editor, next) {
      const {attributes, children, mark} = props;
      Component.displayName = componentName
        ? componentName
        : (
            Component.displayName
              ? Component.displayName
              : Component.prototype.constructor.name
          );
      if (mark.type === Component.displayName) {
        const data = props.mark.get('data').toJS();
        return (
          <Component {...attributes} {...data}>{children}</Component>
        );
      } else if (mark.type === 'span') {
        const data = props.mark.get('data').toJS();
        return <span {...attributes} {...data}>{children}</span>;
      } else if (mark.type === 'div') {
        const data = props.mark.get('data').toJS();
        return <div {...attributes} {...data}>{children}</div>;
      }

      return next();
    }
  };
};
