// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as React from "react";
import * as Theme$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Theme.bs.js";

function marginPanel(topMargin) {
  return Theme$LiteracyplanetClientFlags.resize({
              hd: [
                Css.marginTop,
                topMargin
              ],
              tl: /* [] */0
            });
}

function MarginPanel(Props) {
  var children = Props.children;
  var topMargin = Props.topMargin;
  return React.createElement("div", {
              className: marginPanel(topMargin),
              id: "marginPanel"
            }, children);
}

var make = MarginPanel;

export {
  marginPanel ,
  make ,
  
}
/* Css Not a pure module */
