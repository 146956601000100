// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "../../../node_modules/rescript/lib/es6/js_exn.js";
import * as Browser from "@sentry/browser";
import * as Caml_js_exceptions from "../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";

function captureException(msg) {
  try {
    return Js_exn.raiseError(msg);
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Js_exn.$$Error) {
      return Browser.captureException(e._1);
    }
    throw e;
  }
}

function showReportDialog(param) {
  Browser.lastEventId();
  Browser.showReportDialog();
  
}

export {
  captureException ,
  showReportDialog ,
  
}
/* @sentry/browser Not a pure module */
