import {
  USER_EXPIRED,
  REDIRECT_SUCCESS,
  USER_FOUND,
  USER_NOT_FOUND,
  SILENT_RENEW_ERROR,
  SILENT_RENEW_STARTED,
  SESSION_TERMINATED,
  LOADING_USER,
  USER_SIGNED_OUT
} from '../constants';

let reducer;

try {
  const {fromJS, Seq} = require('immutable');

  /* eslint-disable new-cap */
  const fromJSGreedy = (js) => {
    return typeof js !== 'object' || js === null ? js :
      Array.isArray(js) ?
      Seq(js).map(fromJSGreedy).toList() :
      Seq(js).map(fromJSGreedy).toMap();
  };
  /* eslint-enable new-cap */

  const initialState = fromJS({
    user: null,
    isLoadingUser: false
  });

  reducer = (state = initialState, action) => {
    switch (action.type) {
      case USER_EXPIRED:
        return fromJS({
          user: null,
          isLoadingUser: false
        });
      case SILENT_RENEW_ERROR:
        return fromJS({
          user: null,
          isLoadingUser: false
        });
      case SILENT_RENEW_STARTED:
        return fromJS({
          user: null,
          isLoadingUser: true
        });
      case SESSION_TERMINATED:
      case USER_SIGNED_OUT:
        return fromJS({
          user: null,
          isLoadingUser: false
        });
      case REDIRECT_SUCCESS:
      case USER_FOUND:
        return fromJSGreedy({
          user: action.payload,
          isLoadingUser: false
        });
      case USER_NOT_FOUND:
        return fromJSGreedy({
          user: null,
          isLoadingUser: false
        });
      case LOADING_USER:
        return fromJSGreedy({
          user: state.user ? state.user : null,
          isLoadingUser: true
        });
      default:
        return state;
    }
  };
} catch (error) {
  reducer = () => {
    console.error('You must install immutable-js for the immutable reducer to work!');
  };
}

export default reducer;
