// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Bucket$LiteracyplanetTupperware from "../Bucket.bs.js";

var make = Bucket$LiteracyplanetTupperware.Provider.make;

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* Bucket-LiteracyplanetTupperware Not a pure module */
