// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Error$LiteracyplanetClientErrors from "../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as CobraContainer$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/CobraContainer.bs.js";
import * as BackgroundChanger$LiteracyplanetClientSxEntry from "./BackgroundChanger.bs.js";
import * as JourneyActivitiesQuery$LiteracyplanetClientSxEntry from "./JourneyActivitiesQuery.bs.js";

function JourneyActivitiesScreen(Props) {
  var redirect = Props.redirect;
  var studentMissionId = Props.studentMissionId;
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use(undefined);
  var setBackground = match[1];
  var missionStates = JourneyActivitiesQuery$LiteracyplanetClientSxEntry.useCurrentUser(studentMissionId);
  var isAssessment = function (studendActivityId) {
    if (missionStates) {
      return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getBy(missionStates._0.activities, (function (activity) {
                            return activity.id === studendActivityId;
                          })), (function (a) {
                        return a.assessment;
                      })), false);
    } else {
      return false;
    }
  };
  var match$1 = Error$LiteracyplanetClientErrors.Consumer.use(undefined);
  var onSetError = match$1.onSetError;
  var onDispatch = {
    onJourneyActivitySelected: (function (studendActivityId) {
        if (missionStates) {
          return Curry._1(redirect, {
                      TAG: /* JourneyActivity */5,
                      _0: missionStates._1,
                      _1: missionStates._2,
                      _2: studentMissionId.toString(),
                      _3: studendActivityId,
                      _4: isAssessment(studendActivityId)
                    });
        }
        
      }),
    onExit: (function (param) {
        return Curry._1(redirect, /* JourneyMissions */10);
      }),
    onPreloaderReady: (function (c) {
        return Curry._1(setBackground, (function (param) {
                      return c.backgroundColor;
                    }));
      }),
    onJourneyReady: (function (c) {
        return Curry._1(setBackground, (function (param) {
                      return c.backgroundColor;
                    }));
      }),
    onFailed: (function (err) {
        return Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError(/* JourneyActivitiesError */1, err));
      })
  };
  if (missionStates) {
    return React.createElement("div", {
                style: match[0]
              }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                    view: "jas",
                    props: missionStates._0,
                    onDispatch: onDispatch
                  }));
  } else {
    return null;
  }
}

var make = JourneyActivitiesScreen;

export {
  make ,
  
}
/* react Not a pure module */
