// Generated by ReScript, PLEASE EDIT WITH CARE

import OauthContainerInterop from "./OauthContainerInterop";

var make = OauthContainerInterop;

export {
  make ,
  
}
/* make Not a pure module */
