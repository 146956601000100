// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Cloze$LiteracyplanetTupperware from "../Cloze.bs.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";

function findDroppedId(step, id) {
  return Reducer$LiteracyplanetTupperware.mapDropId(Reducer$LiteracyplanetTupperware.mapIdPair(step, (function (dropId, param) {
                    return dropId === id;
                  })));
}

function use(questionIndex, id) {
  var match = Cloze$LiteracyplanetTupperware.useDrop(id);
  var match$1 = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var step = Curry._1(match$1.getStep, questionIndex);
  var isDropped = Belt_Option.isSome(findDroppedId(step, id));
  var answerVisible = Curry._1(match$1.isCorrectAnswerVisible, questionIndex);
  return [
          match[0],
          isDropped,
          answerVisible,
          step,
          match[1]
        ];
}

export {
  findDroppedId ,
  use ,
  
}
/* Cloze-LiteracyplanetTupperware Not a pure module */
