// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ModernComponents$LiteracyplanetClientSxEntry from "./ModernComponents.bs.js";

function ModernNavBarRatio(Props) {
  var collectionNotificationCount = Props.collectionNotificationCount;
  var highlightMissions = Props.highlightMissions;
  var onClickMissions = Props.onClickMissions;
  var highlightCollections = Props.highlightCollections;
  var lockCollections = Props.lockCollections;
  var onClickCollections = Props.onClickCollections;
  var highlightArcade = Props.highlightArcade;
  var showArcade = Props.showArcade;
  var lockArcade = Props.lockArcade;
  var onClickArcade = Props.onClickArcade;
  var lockShop = Props.lockShop;
  var onClickShop = Props.onClickShop;
  var highlightLibrary = Props.highlightLibrary;
  var showLibraryLink = Props.showLibraryLink;
  var lockLibrary = Props.lockLibrary;
  var onClickLibrary = Props.onClickLibrary;
  var onClickIntrepizine = Props.onClickIntrepizine;
  var highlightMyWords = Props.highlightMyWords;
  var lockMyWords = Props.lockMyWords;
  var onClickMyWords = Props.onClickMyWords;
  var menu = Props.menu;
  var match = React.useState(function () {
        return false;
      });
  var setSubnavOpen = match[1];
  var closeSubnav = function (param) {
    return Curry._1(setSubnavOpen, (function (param) {
                  return false;
                }));
  };
  var openSubnav = function (param) {
    return Curry._1(setSubnavOpen, (function (param) {
                  return true;
                }));
  };
  return React.createElement(ModernComponents$LiteracyplanetClientSxEntry.HeaderBackground.make, {
              children: React.createElement(ModernComponents$LiteracyplanetClientSxEntry.HeaderPanel.make, {
                    children: null
                  }, React.createElement(ModernComponents$LiteracyplanetClientSxEntry.MissionsSlimHeaderItem.make, {
                        highlight: highlightMissions,
                        onClick: (function (param) {
                            Curry._1(onClickMissions, undefined);
                            return Curry._1(setSubnavOpen, (function (param) {
                                          return false;
                                        }));
                          })
                      }), React.createElement(ModernComponents$LiteracyplanetClientSxEntry.MyWordsSlimHeaderItem.make, {
                        highlight: highlightMyWords,
                        onClick: (function (param) {
                            Curry._1(onClickMyWords, undefined);
                            return Curry._1(setSubnavOpen, (function (param) {
                                          return false;
                                        }));
                          }),
                        lock: lockMyWords
                      }), showLibraryLink ? React.createElement(ModernComponents$LiteracyplanetClientSxEntry.LibrarySlimHeaderItem.make, {
                          lockLibrary: lockLibrary,
                          highlightLibrary: highlightLibrary,
                          onClickLibrary: onClickLibrary,
                          onClickIntrepizine: onClickIntrepizine,
                          subnavOpen: match[0],
                          closeSubnav: closeSubnav,
                          openSubnav: openSubnav
                        }) : React.createElement(ModernComponents$LiteracyplanetClientSxEntry.IntrepizineSlimHeaderItem.make, {
                          highlight: highlightLibrary,
                          onClick: (function (param) {
                              return Curry._1(onClickIntrepizine, undefined);
                            }),
                          lock: lockLibrary
                        }), React.createElement(ModernComponents$LiteracyplanetClientSxEntry.CollectionsSlimHeaderItem.make, {
                        highlight: highlightCollections,
                        notificationCount: collectionNotificationCount,
                        onClick: (function (param) {
                            Curry._1(onClickCollections, undefined);
                            return Curry._1(setSubnavOpen, (function (param) {
                                          return false;
                                        }));
                          }),
                        lock: lockCollections
                      }), showArcade ? React.createElement(ModernComponents$LiteracyplanetClientSxEntry.ArcadeSlimHeaderItem.make, {
                          highlight: highlightArcade,
                          onClick: (function (param) {
                              Curry._1(onClickArcade, undefined);
                              return Curry._1(setSubnavOpen, (function (param) {
                                            return false;
                                          }));
                            }),
                          lock: lockArcade
                        }) : null, React.createElement(ModernComponents$LiteracyplanetClientSxEntry.AvatarSlimHeaderItem.make, {
                        highlight: false,
                        onClick: (function (param) {
                            Curry._1(onClickShop, undefined);
                            return Curry._1(setSubnavOpen, (function (param) {
                                          return false;
                                        }));
                          }),
                        lock: lockShop
                      }), menu)
            });
}

var make = ModernNavBarRatio;

export {
  make ,
  
}
/* react Not a pure module */
