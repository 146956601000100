// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as EventsContext$LiteracyplanetClientLibsActivity from "../EventsContext.bs.js";

var context = React.createContext(EventsContext$LiteracyplanetClientLibsActivity.defaultDispatchables);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

var defaultDispatchables = EventsContext$LiteracyplanetClientLibsActivity.defaultDispatchables;

export {
  defaultDispatchables ,
  context ,
  makeProps ,
  make ,
  use ,
  
}
/* context Not a pure module */
