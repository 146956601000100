// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";

function SkilfulDragPanelSticky(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulDragPanelSticky.className
            }, children);
}

var make = SkilfulDragPanelSticky;

var $$default = SkilfulDragPanelSticky;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
