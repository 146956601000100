// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.bs.js";

function use(param) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var match$1 = V2ExerciseStateStoreContext$LiteracyplanetTupperware.use(undefined);
  if (!match$1.state.mode) {
    return /* AssessmentMode */2;
  }
  if (match.activityEnded) {
    return /* QuestionMarked */1;
  }
  var tmp = match.currentQuestionStep;
  if (typeof tmp === "number") {
    return /* QuestionNotAttempted */0;
  } else if (tmp.TAG === /* Attempted */0) {
    return /* QuestionAttempted */{
            _0: match.onMarkActivityQuestion
          };
  } else {
    return /* QuestionMarked */1;
  }
}

export {
  use ,
  
}
/* V2ExerciseContext-LiteracyplanetTupperware Not a pure module */
