// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Theme$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/Theme.bs.js";

function NoMatch(Props) {
  var children = Props.children;
  var childrenSlotCss = Theme$LiteracyplanetClientFlags.resizeExtra({
        hd: Css.fontWeight("bold"),
        tl: {
          hd: Css.color({
                NAME: "hex",
                VAL: "FFF"
              }),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }
          }
        }
      }, undefined, {
        hd: [
          Css.paddingTop,
          50
        ],
        tl: {
          hd: [
            Css.fontSize,
            30
          ],
          tl: /* [] */0
        }
      });
  return React.createElement("div", {
              className: childrenSlotCss
            }, children !== undefined ? Caml_option.valFromOption(children) : "Page not found");
}

var ratioExt = Theme$LiteracyplanetClientFlags.resizeExtra;

var make = NoMatch;

export {
  ratioExt ,
  make ,
  
}
/* Css Not a pure module */
