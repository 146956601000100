// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function fromClassicLocaleString(locale) {
  switch (locale) {
    case "en-AU" :
        return "en_AU";
    case "en-BZ" :
        return "en_BZ";
    case "en-CA" :
        return "en_CA";
    case "en-CB" :
        return "en_CB";
    case "en-IE" :
        return "en_IE";
    case "en-IN" :
        return "en_IN";
    case "en-JM" :
        return "en_JM";
    case "en-NZ" :
        return "en_NZ";
    case "en-PH" :
        return "en_PH";
    case "en-TT" :
        return "en_TT";
    case "en-US" :
        return "en_US";
    case "en-ZA" :
        return "en_ZA";
    case "en-ZW" :
        return "en_ZW";
    default:
      return "en_GB";
  }
}

function fromLocaleString(locale) {
  switch (locale) {
    case "en_AU" :
        return "en_AU";
    case "en_BZ" :
        return "en_BZ";
    case "en_CA" :
        return "en_CA";
    case "en_CB" :
        return "en_CB";
    case "en_IE" :
        return "en_IE";
    case "en_IN" :
        return "en_IN";
    case "en_JM" :
        return "en_JM";
    case "en_NZ" :
        return "en_NZ";
    case "en_PH" :
        return "en_PH";
    case "en_TT" :
        return "en_TT";
    case "en_US" :
        return "en_US";
    case "en_ZA" :
        return "en_ZA";
    case "en_ZW" :
        return "en_ZW";
    default:
      return "en_GB";
  }
}

function fromAccentString(accent) {
  switch (accent) {
    case "American" :
        return "American";
    case "Australian" :
        return "Australian";
    case "Texan" :
        return "Texan";
    default:
      return "British";
  }
}

function toLocaleString(locale) {
  if (locale === "en_AU") {
    return "en_AU";
  } else if (locale === "en_BZ") {
    return "en_BZ";
  } else if (locale === "en_CA") {
    return "en_CA";
  } else if (locale === "en_CB") {
    return "en_CB";
  } else if (locale === "en_IE") {
    return "en_IE";
  } else if (locale === "en_IN") {
    return "en_IN";
  } else if (locale === "en_JM") {
    return "en_JM";
  } else if (locale === "en_NZ") {
    return "en_NZ";
  } else if (locale === "en_PH") {
    return "en_PH";
  } else if (locale === "en_TT") {
    return "en_TT";
  } else if (locale === "en_US") {
    return "en_US";
  } else if (locale === "en_ZA") {
    return "en_ZA";
  } else if (locale === "en_ZW") {
    return "en_ZW";
  } else {
    return "en_GB";
  }
}

function localeToAccent(locale) {
  if (locale === "en_ZW" || locale === "en_ZA" || locale === "en_TT" || locale === "en_PH" || locale === "en_NZ" || locale === "en_JM" || locale === "en_IN" || locale === "en_IE" || locale === "en_GB" || locale === "en_CB" || locale === "en_CA" || locale === "en_BZ") {
    return "British";
  } else if (locale === "en_US") {
    return "American";
  } else {
    return "Australian";
  }
}

function toAccentString(accent) {
  if (accent === "Australian") {
    return "Australian";
  } else if (accent === "American") {
    return "American";
  } else if (accent === "Texan") {
    return "Texan";
  } else {
    return "British";
  }
}

var initialState = {
  locale: "en_GB",
  accent: "British",
  localeString: "en_GB",
  accentString: "British"
};

var context = React.createContext(initialState);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

function use(param) {
  return React.useContext(context);
}

export {
  fromClassicLocaleString ,
  fromLocaleString ,
  fromAccentString ,
  toLocaleString ,
  localeToAccent ,
  toAccentString ,
  initialState ,
  context ,
  makeProps ,
  make ,
  use ,
  
}
/* context Not a pure module */
