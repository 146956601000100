// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function FullscreenIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 383.36 383.36",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("polygon", {
                  points: "60.587,353.067 167.893,245.76 137.6,215.68 30.293,322.987 39.68,301.653 39.68,244.48 0,244.053 0,383.36\n\t\t\t138.88,383.36 139.093,343.68 80.853,343.68"
                }), React.createElement("polygon", {
                  points: "244.053,0 244.48,39.68 301.653,39.68 322.987,30.293 215.68,137.6 245.76,167.893 353.067,60.587 343.68,80.853\n\t\t\t343.68,139.093 383.36,138.88 383.36,0"
                }));
}

var make = FullscreenIcon;

export {
  make ,
  
}
/* react Not a pure module */
