// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as FormSelect$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/FormSelect.bs.js";
import * as ShuffleChildren$LiteracyplanetTupperware from "../ShuffleChildren.bs.js";
import * as SkilfulResponseMark$LiteracyplanetTupperware from "./SkilfulResponseMark.bs.js";

function SkilfulDropDown(Props) {
  var selectedValue = Props.selectedValue;
  var marked = Props.marked;
  var answerVisible = Props.answerVisible;
  var responseOptions = Props.responseOptions;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return [];
      });
  var setResponseOptions = match[1];
  React.useEffect((function () {
          var options = ShuffleChildren$LiteracyplanetTupperware.shuffle($$Array.of_list(Belt_List.map(responseOptions, (function (param) {
                          return [
                                  param.responseOptionId,
                                  param.value
                                ];
                        }))));
          Curry._1(setResponseOptions, (function (param) {
                  return options;
                }));
          
        }), []);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulSelect.className
            }, marked === /* Unanswered */2 ? React.createElement(FormSelect$LiteracyplanetClientFlags.make, {
                    selectedValue: selectedValue,
                    options: match[0],
                    onChange: onChange,
                    optionClassName: Skillet$LiteracyplanetTupperware.SkilfulOption.className,
                    optionsClassName: Skillet$LiteracyplanetTupperware.SkilfulOptions.className,
                    inputClassName: Skillet$LiteracyplanetTupperware.SkilfulInput.className,
                    inputTextClassName: Skillet$LiteracyplanetTupperware.SkilfulInputText.className,
                    borderClassName: Skillet$LiteracyplanetTupperware.SkilfulBorder.className,
                    iconClassName: Skillet$LiteracyplanetTupperware.SkilfulDropDownIcon.className
                  }) : React.createElement(SkilfulResponseMark$LiteracyplanetTupperware.make, {
                    inputClassName: Skillet$LiteracyplanetTupperware.SkilfulDropDown.className,
                    marked: marked,
                    answerVisible: answerVisible,
                    value: Belt_Option.getWithDefault(selectedValue, "")
                  }));
}

var make = SkilfulDropDown;

export {
  make ,
  
}
/* react Not a pure module */
