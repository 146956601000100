// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RememberLast$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/RememberLast.bs.js";
import * as StoryverseLevelupData$LiteracyplanetClientSxEntry from "./StoryverseLevelupData.bs.js";
import * as StoryverseUnlockQuery$LiteracyplanetClientSxEntry from "./StoryverseUnlockQuery.bs.js";
import * as StoryverseLevelupQuery$LiteracyplanetClientSxEntry from "./StoryverseLevelupQuery.bs.js";
import * as StoryverseLevelupMutation$LiteracyplanetClientSxEntry from "./StoryverseLevelupMutation.bs.js";

var S = {
  useLevelupQuery: StoryverseLevelupQuery$LiteracyplanetClientSxEntry.use,
  useInsertLevelup: StoryverseLevelupMutation$LiteracyplanetClientSxEntry.use,
  useLocalStorage: RememberLast$LiteracyplanetClientFlags.useValue,
  useUnlocksQuery: StoryverseUnlockQuery$LiteracyplanetClientSxEntry.use
};

var include = StoryverseLevelupData$LiteracyplanetClientSxEntry.Make(S);

var useLevelupMutation = include.useLevelupMutation;

var usePreviousPoints = include.usePreviousPoints;

var useHasLocationChanged = include.useHasLocationChanged;

var useUnlockType = include.useUnlockType;

var useCobraProps = include.useCobraProps;

var use = include.use;

export {
  S ,
  useLevelupMutation ,
  usePreviousPoints ,
  useHasLocationChanged ,
  useUnlockType ,
  useCobraProps ,
  use ,
  
}
/* include Not a pure module */
