import React from 'react';
import {VolumeIcon, StopIcon, SpinnerIcon} from './icons.jsx';
import styled from '@emotion/styled';

const ControlButton = styled.div`
  cursor: pointer;
  width: 16vh;
  height: 6vh;
  margin: 1vh;
  filter: drop-shadow(1vh 1vh 1vh rgba(0, 0, 0, .7));
  grid-column: 3;
  grid-row: 1;
  justify-self: start;
  align-self: end;  
  border-radius: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;  
  @media (orientation: portrait) {
    grid-column: 2;
    grid-row: 3;
    justify-self: center;
    align-self: center;
    width: 32vw;
    height: 12vw;
  }

  user-select: none;
  
  background-color: #0c9ce5;
  color: white;
  
  &:hover {
    background-color: #0077b3;
  }

  &:active {
    background-color: #005580;
  }

  #VolumeIcon {
    height: 80%;
  }

  #StopIcon {
    height: 80%;
  }

  #SpinnerIcon {
    height: 80%;
  }
`;

let readAudio = null;
export const AudioButton = ({audioPath}) => {
  const [playState, setPlayState] = React.useState('Play'); // Stop, Load, Play

  React.useEffect(() => {
    return () => {
      if (readAudio !== null) {
        readAudio.pause();
      }
    };
  }, []);

  React.useEffect(() => {
    if (readAudio !== null) {
      readAudio.pause();
    }
  }, [audioPath]);

  const playAudioForPage = () => {
    if (readAudio !== null) {
      readAudio.pause();
    }
    if (audioPath && playState === 'Play') {
      readAudio = new Audio(audioPath);
      readAudio.oncanplay = () => {
        setPlayState('Stop');
      };
      readAudio.onended = () => {
        setPlayState('Play');
      };
      readAudio.onpause = () => {
        setPlayState('Play');
      };
      readAudio.play();
      setPlayState('Load');
    }
  };

  let icon = <VolumeIcon />;
  if (playState === 'Load') {
    icon = <SpinnerIcon />;
  } else if (playState === 'Stop') {
    icon = <StopIcon />;
  }

  return (
    <ControlButton onClick={() => playAudioForPage()} >
      {icon}
    </ControlButton>
  );
};

// AudioButton.propTypes = {
//   audioPath: PropTypes.string
// };

// export default AudioButton;
