// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ExerciseStateStore$LiteracyplanetTupperware from "../ExerciseStateStore.bs.js";

var state = {
  questionStates: /* [] */0,
  markStates: /* [] */0,
  mode: /* Activity */1,
  questionsComplete: 0,
  questionsCorrect: 0,
  questionsIncorrect: 0
};

function initialApi_setState(param) {
  
}

var initialApi = {
  setState: initialApi_setState,
  state: state
};

var context = React.createContext(initialApi);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function V2ExerciseStateStoreContext$Provider(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return state;
      });
  var setState = match[1];
  var state$1 = match[0];
  var value = React.useMemo((function () {
          return {
                  setState: setState,
                  state: state$1
                };
        }), [state$1]);
  return React.createElement(React.Fragment, undefined, React.createElement(make, makeProps(value, children, undefined)));
}

var Provider = {
  make: V2ExerciseStateStoreContext$Provider
};

function use(param) {
  return React.useContext(context);
}

function useDispatch(param) {
  var match = React.useContext(context);
  var setState = match.setState;
  var dispatch = function (action) {
    return Curry._1(setState, (function (state) {
                  return ExerciseStateStore$LiteracyplanetTupperware.reducer(state, action);
                }));
  };
  return [
          match.state,
          dispatch
        ];
}

var $$default = V2ExerciseStateStoreContext$Provider;

export {
  state ,
  initialApi ,
  Context ,
  Provider ,
  use ,
  useDispatch ,
  $$default ,
  $$default as default,
  
}
/* context Not a pure module */
