// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as V2EventsContext$LiteracyplanetTupperware from "./V2EventsContext.bs.js";
import * as BehaviourMultiChoiceMarkCallback$LiteracyplanetTupperware from "./BehaviourMultiChoiceMarkCallback.bs.js";

function BehaviourMultiChoiceContainer(Props) {
  var correctAnswer = Props.correctAnswer;
  var fuzzyMark = Props.fuzzyMark;
  var questionIndex = Props.questionIndex;
  var showCorrectAnswerBehaviourOpt = Props.showCorrectAnswerBehaviour;
  var children = Props.children;
  var showCorrectAnswerBehaviour = showCorrectAnswerBehaviourOpt !== undefined ? showCorrectAnswerBehaviourOpt : false;
  var callbacks = V2EventsContext$LiteracyplanetTupperware.use(undefined);
  return React.createElement(BehaviourMultiChoiceMarkCallback$LiteracyplanetTupperware.make, {
              callbacks: callbacks,
              questionIndex: questionIndex,
              correctAnswer: correctAnswer,
              fuzzyMark: fuzzyMark,
              showCorrectAnswerBehaviour: showCorrectAnswerBehaviour,
              children: children
            });
}

var make = BehaviourMultiChoiceContainer;

var $$default = BehaviourMultiChoiceContainer;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
