// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_js_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";

function encodeImageTab(imageTabSelection) {
  if (imageTabSelection) {
    return imageTabSelection._0;
  } else {
    return "DefaultTab";
  }
}

function encodeImage(area, id, imageTabSelection) {
  return Json_encode.object_({
              hd: [
                "area",
                area
              ],
              tl: {
                hd: [
                  "id",
                  id
                ],
                tl: {
                  hd: [
                    "imagetab",
                    encodeImageTab(imageTabSelection)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function encode(nav) {
  var tmp;
  if (typeof nav === "number") {
    tmp = Json_encode.object_({
          hd: [
            "area",
            "BogDefault"
          ],
          tl: /* [] */0
        });
  } else {
    switch (nav.TAG | 0) {
      case /* BogBooks */0 :
          var bookSelection = nav._0;
          tmp = typeof bookSelection === "number" ? Json_encode.object_({
                  hd: [
                    "area",
                    "BogDefault"
                  ],
                  tl: /* [] */0
                }) : (
              bookSelection.TAG === /* SelectedBook */0 ? Json_encode.object_({
                      hd: [
                        "area",
                        "BogBooks"
                      ],
                      tl: {
                        hd: [
                          "bookId",
                          bookSelection._0
                        ],
                        tl: /* [] */0
                      }
                    }) : Json_encode.object_({
                      hd: [
                        "area",
                        "BogBooks"
                      ],
                      tl: {
                        hd: [
                          "bookId",
                          bookSelection._0
                        ],
                        tl: {
                          hd: [
                            "storyId",
                            bookSelection._1
                          ],
                          tl: /* [] */0
                        }
                      }
                    })
            );
          break;
      case /* BogLocations */1 :
          var locationSelection = nav._0;
          tmp = locationSelection ? encodeImage("SelectedLocation", locationSelection._0, locationSelection._1) : Json_encode.object_({
                  hd: [
                    "area",
                    "DefaultLocation"
                  ],
                  tl: /* [] */0
                });
          break;
      case /* BogCharacters */2 :
          var characterSelection = nav._0;
          tmp = characterSelection ? encodeImage("SelectedCharacter", characterSelection._0, characterSelection._1) : Json_encode.object_({
                  hd: [
                    "area",
                    "DefaultCharacter"
                  ],
                  tl: /* [] */0
                });
          break;
      
    }
  }
  return btoa(Json.stringify(tmp));
}

function decodeBooks(json) {
  var match = Json_decode.optional((function (param) {
          return Json_decode.field("bookId", Json_decode.string, param);
        }), json);
  var match$1 = Json_decode.optional((function (param) {
          return Json_decode.field("storyId", Json_decode.string, param);
        }), json);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return {
              TAG: /* SelectedStory */1,
              _0: match,
              _1: match$1
            };
    } else {
      return {
              TAG: /* SelectedBook */0,
              _0: match
            };
    }
  }
  if (match$1 === undefined) {
    return /* DefaultBook */0;
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "BogTabSerialisation.res",
          66,
          2
        ],
        Error: new Error()
      };
}

function decodeImageTab(imagetab) {
  if (imagetab === "DefaultTab") {
    return /* DefaultTab */0;
  } else {
    return /* ImageTab */{
            _0: imagetab
          };
  }
}

function decodeImageSelection(json) {
  var match = Json_decode.optional((function (param) {
          return Json_decode.field("id", Json_decode.string, param);
        }), json);
  var match$1 = Json_decode.optional((function (param) {
          return Json_decode.field("imagetab", Json_decode.string, param);
        }), json);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return /* SelectedImage */{
              _0: match,
              _1: decodeImageTab(match$1)
            };
    } else {
      return /* SelectedImage */{
              _0: match,
              _1: /* DefaultTab */0
            };
    }
  }
  if (match$1 === undefined) {
    return /* DefaultImage */0;
  }
  throw {
        RE_EXN_ID: "Match_failure",
        _1: [
          "BogTabSerialisation.res",
          82,
          2
        ],
        Error: new Error()
      };
}

function decode(str) {
  var json;
  try {
    json = Json.parseOrRaise(atob(str));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Json_decode.DecodeError) {
      return /* BogDefault */0;
    }
    throw exn;
  }
  var area = Json_decode.field("area", Json_decode.string, json);
  switch (area) {
    case "BogBooks" :
        return {
                TAG: /* BogBooks */0,
                _0: decodeBooks(json)
              };
    case "BogDefault" :
        return /* BogDefault */0;
    case "DefaultCharacter" :
    case "SelectedCharacter" :
        return {
                TAG: /* BogCharacters */2,
                _0: decodeImageSelection(json)
              };
    case "DefaultLocation" :
    case "SelectedLocation" :
        return {
                TAG: /* BogLocations */1,
                _0: decodeImageSelection(json)
              };
    default:
      throw {
            RE_EXN_ID: "Match_failure",
            _1: [
              "BogTabSerialisation.res",
              99,
              8
            ],
            Error: new Error()
          };
  }
}

export {
  encodeImageTab ,
  encodeImage ,
  encode ,
  decodeBooks ,
  decodeImageTab ,
  decodeImageSelection ,
  decode ,
  
}
/* No side effect */
