// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

var initialState = {
  show: true,
  timeout: 0,
  retries: 0
};

function reducer(state, action) {
  if (action) {
    return {
            show: true,
            timeout: state.timeout,
            retries: state.retries
          };
  } else {
    return {
            show: false,
            timeout: Math.imul(state.retries + 1 | 0, 1000),
            retries: state.retries + 1 | 0
          };
  }
}

function use(param) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var onRetry = function (param) {
    return Curry._1(dispatch, /* Retry */0);
  };
  React.useEffect((function () {
          if (state.show !== false) {
            return ;
          }
          var id = setTimeout((function (param) {
                  return Curry._1(dispatch, /* Show */1);
                }), state.timeout);
          return (function (param) {
                    clearTimeout(id);
                    
                  });
        }), [state.show]);
  return [
          state.show,
          onRetry
        ];
}

export {
  initialState ,
  reducer ,
  use ,
  
}
/* react Not a pure module */
