// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

function useWindowSize(param) {
  var match = React.useState(function () {
        return [
                0,
                0
              ];
      });
  var setSize = match[1];
  React.useLayoutEffect((function () {
          var updateSize = function (param) {
            return Curry._1(setSize, (function (param) {
                          return [
                                  window.innerWidth,
                                  window.innerHeight
                                ];
                        }));
          };
          window.addEventListener("resize", updateSize);
          Curry._1(setSize, (function (param) {
                  return [
                          window.innerWidth,
                          window.innerHeight
                        ];
                }));
          return (function (param) {
                    window.removeEventListener("resize", updateSize);
                    
                  });
        }), []);
  return match[0];
}

function useWindowOuterSize(param) {
  var match = React.useState(function () {
        return [
                0,
                0
              ];
      });
  var setSize = match[1];
  React.useLayoutEffect((function () {
          var updateSize = function (param) {
            return Curry._1(setSize, (function (param) {
                          return [
                                  window.outerWidth,
                                  window.outerHeight
                                ];
                        }));
          };
          window.addEventListener("resize", updateSize);
          Curry._1(setSize, (function (param) {
                  return [
                          window.outerWidth,
                          window.outerHeight
                        ];
                }));
          return (function (param) {
                    window.removeEventListener("resize", updateSize);
                    
                  });
        }), []);
  return match[0];
}

export {
  useWindowSize ,
  useWindowOuterSize ,
  
}
/* react Not a pure module */
