// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Theme$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Theme.bs.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as Sentry$LiteracyplanetClientErrors from "../../../../../node_modules/@literacyplanet/client-errors/src/Sentry.bs.js";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.bs.js";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.bs.js";
import * as SkilfulContent$LiteracyplanetTupperware from "./SkilfulContent.bs.js";
import * as AudioPlayButton$LiteracyplanetTupperware from "./AudioPlayButton.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as SkilfulLayoutContainer$LiteracyplanetTupperware from "./SkilfulLayoutContainer.bs.js";
import * as SkilfulQuestionContent$LiteracyplanetTupperware from "./SkilfulQuestionContent.bs.js";
import * as SkilfulEndScreenContainer$LiteracyplanetTupperware from "./SkilfulEndScreenContainer.bs.js";
import * as SkilfulTextInputContainer$LiteracyplanetTupperware from "./SkilfulTextInputContainer.bs.js";
import * as SkilfulMultiChoiceContainer$LiteracyplanetTupperware from "./SkilfulMultiChoiceContainer.bs.js";

function string(prim) {
  return prim;
}

function stringify(parts) {
  return Belt_Array.reduce(parts, [], (function (acc, part) {
                return Belt_Array.concat(acc, [Belt_Option.getWithDefault(part, "")]);
              }));
}

function split(regex, str) {
  return stringify(str.split(regex));
}

function splitSpaces(str) {
  return stringify(str.split(/ /));
}

function splitQuestionMark(str) {
  return stringify(str.split(/\?/));
}

function splitComma(str) {
  return stringify(str.split(/,/));
}

function splitPeriod(str) {
  return stringify(str.split(/\./));
}

function SpellTestPage$Question(Props) {
  var src = Props.src;
  var responseOptionId = Props.responseOptionId;
  var questionIndex = Props.questionIndex;
  return React.createElement("span", undefined, React.createElement(AudioPlayButton$LiteracyplanetTupperware.make, {
                  src: src
                }), React.createElement(SkilfulTextInputContainer$LiteracyplanetTupperware.make, {
                  questionIndex: questionIndex,
                  responseOptionId: responseOptionId,
                  largeField: false
                }), " ");
}

var Question = {
  make: SpellTestPage$Question
};

function SpellTestPage$WordPunctuation(Props) {
  var word = Props.word;
  var hasQuestionMark = splitQuestionMark(word).length > 1;
  var hasComma = splitComma(word).length > 1;
  var hasPeriod = splitPeriod(word).length > 1;
  return React.createElement("span", undefined, hasQuestionMark ? "?" : "", hasComma ? "," : "", hasPeriod ? "." : "");
}

var WordPunctuation = {
  make: SpellTestPage$WordPunctuation
};

function isAnswer(esw, w) {
  return esw.id === w.id;
}

function matchesWordName(esw, w) {
  return esw.name.toLowerCase() === w.name.toLowerCase();
}

function renderSentenceWords(questionIndex, wordIndex, sentenceParts, words, word) {
  return Belt_Array.mapWithIndex(words, (function (index, esw) {
                var punctuatedWord = Belt_Option.getWithDefault(Belt_Array.get(sentenceParts, index), "");
                if (index === wordIndex) {
                  return React.createElement("span", undefined, React.createElement(SpellTestPage$Question, {
                                  src: word.audio.source,
                                  responseOptionId: word.id,
                                  questionIndex: questionIndex
                                }), React.createElement(SpellTestPage$WordPunctuation, {
                                  word: punctuatedWord
                                }));
                } else {
                  return React.createElement("span", undefined, punctuatedWord + " ");
                }
              }));
}

function sentry(msg) {
  console.log(msg);
  Sentry$LiteracyplanetClientErrors.captureException(msg);
  
}

function SpellTestPage$ExampleSentenceStem(Props) {
  var index = Props.index;
  var exampleSentence = Props.exampleSentence;
  var word = Props.word;
  var sentenceParts = splitSpaces($$String.trim(exampleSentence.text));
  var words = exampleSentence.words;
  var wordIndex = Belt_Array.reduceWithIndex(words, undefined, (function (acc, esw, index) {
          if (isAnswer(esw, word)) {
            return index;
          } else {
            return acc;
          }
        }));
  React.useEffect((function () {
          if (Belt_Option.isNone(wordIndex)) {
            sentry("Spell Test: Sentence doesnt contain word with exampleSentence.id: " + exampleSentence.id);
          } else if (sentenceParts.length !== words.length) {
            sentry("Spell Test: Sentence text length doesn't match words count exampleSentence.id: " + exampleSentence.id);
          }
          
        }), []);
  var wordIndex$1 = wordIndex !== undefined ? wordIndex : Belt_Array.reduceWithIndex(words, -1, (function (acc, esw, index) {
            if (matchesWordName(esw, word)) {
              return index;
            } else {
              return acc;
            }
          }));
  if (sentenceParts.length !== words.length) {
    return "Error: Sentence text length doesn't match words count";
  } else {
    return renderSentenceWords(index, wordIndex$1, sentenceParts, words, word);
  }
}

var ExampleSentenceStem = {
  isAnswer: isAnswer,
  matchesWordName: matchesWordName,
  renderSentenceWords: renderSentenceWords,
  sentry: sentry,
  make: SpellTestPage$ExampleSentenceStem
};

var m = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        22
      ],
      tl: /* [] */0
    });

var className = Curry._1(Css.merge, {
      hd: m,
      tl: {
        hd: "MultiChoiceInstruction",
        tl: /* [] */0
      }
    });

function SpellTestPage$Instruction(Props) {
  return React.createElement("span", {
              className: className
            }, "Use the play button to listen to the word and type the spelling.");
}

var Instruction = {
  ratio: Theme$LiteracyplanetClientFlags.resize,
  m: m,
  className: className,
  make: SpellTestPage$Instruction
};

function getAnswer(answer) {
  return Belt_List.reduce(answer, "", (function (acc, a) {
                return acc + Belt_Option.getWithDefault(Belt_Array.get(a.split(":"), 1), "");
              }));
}

function SpellTestPage$CustomSkilfulQuestionContent(Props) {
  var children = Props.children;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var detailRowRender = function (getStep, i) {
    var match = Curry._1(getStep, i);
    if (typeof match === "number") {
      return [
              "---",
              null,
              "---"
            ];
    }
    if (match.TAG === /* Attempted */0) {
      var match$1 = match._0;
      if (!match$1) {
        return [
                "Attempted",
                null,
                "---"
              ];
      }
      var match$2 = match$1._0;
      if (match$2.length !== 1) {
        return [
                "Attempted",
                null,
                "---"
              ];
      }
      var match$3 = match$2[0];
      if (match$3.TAG === /* ToggleResponse */0) {
        return [
                "Attempted",
                null,
                "---"
              ];
      }
      var match$4 = match$3._0;
      if (match$4.TAG === /* BoolResponsePayload */0) {
        return [
                "Attempted",
                null,
                "---"
              ];
      } else {
        return [
                match$4._1,
                null,
                "---"
              ];
      }
    }
    var match$5 = match._0;
    if (!match$5) {
      return [
              "Attempted",
              null,
              ""
            ];
    }
    var match$6 = match._1;
    var match$7 = match$5._0;
    var answer = match$7.answer;
    var questionCorrect = match$7.questionCorrect;
    if (!match$6) {
      if (questionCorrect) {
        return [
                "---",
                React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                      className: Skillet$LiteracyplanetTupperware.EndScreenCorrectIcon.className
                    }),
                getAnswer(answer)
              ];
      } else if (questionCorrect) {
        return [
                "Attempted",
                null,
                ""
              ];
      } else {
        return [
                "---",
                React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                      className: Skillet$LiteracyplanetTupperware.EndScreenIncorrectIcon.className
                    }),
                getAnswer(answer)
              ];
      }
    }
    var match$8 = match$6._0;
    if (match$8.length !== 1) {
      return [
              "Attempted",
              null,
              ""
            ];
    }
    var match$9 = match$8[0];
    if (match$9.TAG === /* ToggleResponse */0) {
      return [
              "Attempted",
              null,
              ""
            ];
    }
    var match$10 = match$9._0;
    if (match$10.TAG === /* BoolResponsePayload */0) {
      return [
              "Attempted",
              null,
              ""
            ];
    }
    var responseText = match$10._1;
    if (questionCorrect) {
      return [
              responseText,
              React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.EndScreenCorrectIcon.className
                  }),
              getAnswer(answer)
            ];
    } else if (questionCorrect) {
      return [
              "Attempted",
              null,
              ""
            ];
    } else {
      return [
              responseText,
              React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.EndScreenIncorrectIcon.className
                  }),
              getAnswer(answer)
            ];
    }
  };
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulContentContainer.className
            }, React.createElement(SkilfulQuestionContent$LiteracyplanetTupperware.ContentContainer.make, {
                  children: null
                }, React.createElement(SkilfulQuestionContent$LiteracyplanetTupperware.ContentHeader.make, {}), React.createElement(SkilfulQuestionContent$LiteracyplanetTupperware.Content.make, {
                      children: children
                    }), React.createElement(SkilfulQuestionContent$LiteracyplanetTupperware.ContentFooter.make, {})), match.isEndscreenShowing ? React.createElement(SkilfulEndScreenContainer$LiteracyplanetTupperware.make, {
                    detailRowRender: detailRowRender
                  }) : null);
}

var CustomSkilfulQuestionContent = {
  getAnswer: getAnswer,
  make: SpellTestPage$CustomSkilfulQuestionContent
};

function SpellTestPage(Props) {
  var list = Props.list;
  return React.createElement(SkilfulLayoutContainer$LiteracyplanetTupperware.make, {
              title: "Spell Test",
              stimuliPresent: false,
              questionCount: list.words.length,
              children: React.createElement(SpellTestPage$CustomSkilfulQuestionContent, {
                    children: Belt_Array.mapWithIndex(list.words, (function (index, word) {
                            return React.createElement(SkilfulMultiChoiceContainer$LiteracyplanetTupperware.make, {
                                        columns: 1,
                                        correctAnswer: [word.id + (":" + word.name)],
                                        fuzzyMark: true,
                                        questionIndex: index,
                                        showCorrectAnswerBehaviour: true,
                                        children: React.createElement(SkilfulContent$LiteracyplanetTupperware.make, {
                                              children: null
                                            }, React.createElement(SpellTestPage$Instruction, {}), React.createElement("h2", {
                                                  className: "MultiChoiceStem"
                                                }, React.createElement(SpellTestPage$ExampleSentenceStem, {
                                                      index: index,
                                                      exampleSentence: word.exampleSentence,
                                                      word: word
                                                    })))
                                      });
                          }))
                  })
            });
}

var trim = $$String.trim;

var make = SpellTestPage;

export {
  string ,
  stringify ,
  split ,
  splitSpaces ,
  splitQuestionMark ,
  splitComma ,
  splitPeriod ,
  trim ,
  Question ,
  WordPunctuation ,
  ExampleSentenceStem ,
  Instruction ,
  CustomSkilfulQuestionContent ,
  make ,
  
}
/* m Not a pure module */
