import {
  reducer,
  OidcProvider,
  CallbackComponent,
  createUserManager,
  processSilentRenew,
  loadUser,
  silentRenew
} from '@literacyplanet/redux-oidc';
import oidcPromptReducer from './oidc_reducer';

const isTrue = (str) => str === 'true' ? true : false;
const redirectUrl = (
  process.env.OVERIDE_OAUTH_REDIRECT_URL ||
  process.env.OAUTH_REDIRECT_URL
);
const clientId = (
  process.env.OVERIDE_OAUTH_CLIENT_ID ||
  process.env.OAUTH_CLIENT_ID
);

const userManagerConfig = {
  client_id: clientId,
  redirect_uri: redirectUrl + process.env.OAUTH_CALLBACK_PATH,
  response_type: process.env.OAUTH_RESPONSE_TYPE,
  scope: process.env.OAUTH_SCOPE,
  authority: process.env.OAUTH_AUTHORITY,
  silent_redirect_uri: redirectUrl +
    process.env.OAUTH_SILENT_CALLBACK_PATH,
  automaticSilentRenew: isTrue(process.env.OAUTH_AUTOMATIC_SILENT_RENEW),
  filterProtocolClaims: isTrue(process.env.OAUTH_FILTER_PROTOCOL_CLAIMS),
  loadUserInfo: isTrue(process.env.OAUTH_LOAD_USER_INFO)
};

export const openIdUserManager = createUserManager(userManagerConfig);
export const openId = reducer;
export const oidcPrompt = oidcPromptReducer;
export const OpenIdProvider = OidcProvider;
export const OpenIdCallbackComponent = CallbackComponent;
export const openIdProcessSilentRenew = processSilentRenew;
export const openIdSilentRenew = silentRenew;
export const openIdLoadUser = loadUser;
