// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_array from "../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Path$LiteracyplanetClientFlags from "./Path.bs.js";

function makePath(param) {
  return Path$LiteracyplanetClientFlags.use(function (param) {
              return window.location.pathname;
            });
}

var BrowserHistoryMatcher = {
  makePath: makePath
};

function makePath$1(param) {
  var splitPath = window.location.hash.split("?");
  var firstPathItem = Caml_array.get(splitPath, 0);
  return Path$LiteracyplanetClientFlags.use(function (param) {
              return firstPathItem.slice(1);
            });
}

var HashHistoryMatcher = {
  makePath: makePath$1
};

var makePath$2 = process.env.HISTORY === "hash" ? makePath$1 : makePath;

export {
  BrowserHistoryMatcher ,
  HashHistoryMatcher ,
  makePath$2 as makePath,
  
}
/* makePath Not a pure module */
