import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DragLayer} from 'react-dnd';
function collect(monitor) {
    return {
      clientOffset: monitor.getSourceClientOffset()
    };
}
class DragPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      el: null
    };
  };
  componentDidMount() {
    const {domId} = this.props;
    const el = document.getElementById(domId);
    this.setState({el});
  }
  getLayerStyles() {
    const {clientOffset} = this.props;
    const {el} = this.state;
    if (el) {
      const pos = el.getBoundingClientRect();
      const xOffset = pos.left;
      const yOffset = pos.top;
      return {
        transform: clientOffset
        ? `translate(${clientOffset.x - xOffset}px, ${clientOffset.y - yOffset}px)`
        : ''
      };
    };
    return(null);
  };
  render() {
    const {isDragging, element, style} = this.props;
    const dragStyle = {...style, overflow: 'visible', position: 'absolute', opacity: 0.8, pointerEvents: 'none'};
    if (!isDragging) {
      return null;
    };
    return (
      <span className="source-preview" style={{...dragStyle, ...this.getLayerStyles()}}>
        {element}
      </span>
    );
  };
};
DragPreview.propTypes = {
    isDragging: PropTypes.bool.isRequired,
    style: PropTypes.object.isRequired,
    domId: PropTypes.string.isRequired,
    element: PropTypes.node.isRequired,
    clientOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    })
};
export default DragLayer(collect)(DragPreview);