// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as PlayAudioIcon$LiteracyplanetTupperware from "./PlayAudioIcon.bs.js";
import * as AudioButtonApi$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/AudioButtonApi.bs.js";

function PlayBookAudioButton(Props) {
  var src = Props.src;
  var match = React.useState(function () {
        return false;
      });
  var setIsPlaying = match[1];
  var onTogglePlay = function (param) {
    return Curry._1(setIsPlaying, (function (y) {
                  return !y;
                }));
  };
  return React.createElement(AudioButtonApi$LiteracyplanetClientFlags.make, {
              isPlaying: match[0],
              src: src,
              onEnd: onTogglePlay,
              onTogglePlay: onTogglePlay,
              children: (function (state, onTogglePlay) {
                  return React.createElement("div", {
                              className: Flavor$LiteracyplanetTupperware.PlayBookAudioButton.className,
                              onClick: (function (param) {
                                  return Curry._1(onTogglePlay, undefined);
                                })
                            }, React.createElement(PlayAudioIcon$LiteracyplanetTupperware.make, {
                                  state: state
                                }));
                })
            });
}

var make = PlayBookAudioButton;

export {
  make ,
  
}
/* react Not a pure module */
