// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";

function BehaviourExitButton(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var onExit = match.onExit;
  return React.createElement("div", {
              className: className,
              onClick: (function (param) {
                  return Curry._1(onExit, undefined);
                })
            }, children);
}

var make = BehaviourExitButton;

var $$default = BehaviourExitButton;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
