// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Context$LiteracyplanetClientFlags from "./Context.bs.js";

function default_isTabContentShowing(param) {
  return false;
}

function default_onChangeTab(param) {
  
}

var $$default = {
  isTabContentShowing: default_isTabContentShowing,
  onChangeTab: default_onChangeTab
};

var T = {
  $$default: $$default
};

function Make($star) {
  var Ctx = Context$LiteracyplanetClientFlags.Make(T);
  var TabContext$Make$Provider = function (Props) {
    var isTabContentShowing = Props.isTabContentShowing;
    var onChangeTab = Props.onChangeTab;
    var children = Props.children;
    var value = {
      isTabContentShowing: isTabContentShowing,
      onChangeTab: onChangeTab
    };
    return React.createElement(Ctx.make, Curry._3(Ctx.makeProps, value, children, undefined));
  };
  var Provider = {
    make: TabContext$Make$Provider
  };
  var TabContext$Make = function (Props) {
    var tabs = Props.tabs;
    var children = Props.children;
    var match = React.useState(function () {
          return Belt_Array.get(tabs, 0);
        });
    var setCurrentTab = match[1];
    var currentTab = match[0];
    var isTabContentShowing = function (tab) {
      return Caml_obj.caml_equal(tab, currentTab);
    };
    var onChangeTab = function (tab) {
      return Curry._1(setCurrentTab, (function (param) {
                    return tab;
                  }));
    };
    return React.createElement(TabContext$Make$Provider, {
                isTabContentShowing: isTabContentShowing,
                onChangeTab: onChangeTab,
                children: children
              });
  };
  var useTabContent = function (tab) {
    var api = Curry._1(Ctx.use, undefined);
    return Curry._1(api.isTabContentShowing, tab);
  };
  var useTabLink = function (param) {
    return Curry._1(Ctx.use, undefined).onChangeTab;
  };
  var useIsTabSelected = function (param) {
    return Curry._1(Ctx.use, undefined).isTabContentShowing;
  };
  return {
          Ctx: Ctx,
          Provider: Provider,
          make: TabContext$Make,
          use: Ctx.use,
          useTabContent: useTabContent,
          useTabLink: useTabLink,
          useIsTabSelected: useIsTabSelected
        };
}

export {
  T ,
  Make ,
  
}
/* react Not a pure module */
