// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "./QueryHook.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "./UserContext.bs.js";

var Raw = {};

var query = Client.gql(["query seenBefore($userId: String!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \nseenBefore  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.currentStudent;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.seenBefore;
    tmp = {
      __typename: value$1.__typename,
      seenBefore: value$2.map(function (value) {
            switch (value) {
              case "JOURNEY_INTRO" :
                  return "JOURNEY_INTRO";
              case "STORYVERSE_INTRO" :
                  return "STORYVERSE_INTRO";
              case "TX_FTUE_PANEL" :
                  return "TX_FTUE_PANEL";
              default:
                throw {
                      RE_EXN_ID: "Not_found",
                      Error: new Error()
                    };
            }
          })
    };
  }
  return {
          currentStudent: tmp
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.seenBefore;
    var seenBefore = value$2.map(function (value) {
          if (value === "TX_FTUE_PANEL") {
            return "TX_FTUE_PANEL";
          } else if (value === "JOURNEY_INTRO") {
            return "JOURNEY_INTRO";
          } else {
            return "STORYVERSE_INTRO";
          }
        });
    var value$3 = value$1.__typename;
    currentStudent = {
      __typename: value$3,
      seenBefore: seenBefore
    };
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var GetSeenBefore_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetSeenBefore_refetchQueryDescription = include.refetchQueryDescription;

var GetSeenBefore_useLazy = include.useLazy;

var GetSeenBefore_useLazyWithVariables = include.useLazyWithVariables;

var GetSeenBefore = {
  GetSeenBefore_inner: GetSeenBefore_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: GetSeenBefore_refetchQueryDescription,
  use: use,
  useLazy: GetSeenBefore_useLazy,
  useLazyWithVariables: GetSeenBefore_useLazyWithVariables
};

function makeSeenBefore(response) {
  return Belt_Option.map(response.currentStudent, (function (s) {
                return s.seenBefore;
              }));
}

var error = {
  TAG: /* ErrorWithMessage */4,
  _0: "SeenBeforeQuery.useQuery",
  _1: undefined
};

function onResult(query) {
  return Belt_Result.flatMap(query, (function (response) {
                var seen = makeSeenBefore(response);
                if (seen !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: /* SeenBefore */{
                            _0: seen
                          }
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: error
                        };
                }
              }));
}

function onLoading(param) {
  return /* LoadingSeenBefore */0;
}

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function useQuery(userId) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, onResult, {
              userId: userId
            });
}

function useCurrentUser(param) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  return useQuery(user.userId);
}

function seenTypeExists(seenTypes, seenType) {
  return Belt_Option.isSome(Belt_Array.getBy(seenTypes, (function (t) {
                    return t === seenType;
                  })));
}

function hasSeenType(state, seenType, trueCb, falseCb) {
  if (!state) {
    return ;
  }
  var seen = seenTypeExists(state._0, seenType);
  if (seen) {
    return Curry._1(trueCb, undefined);
  } else {
    return Curry._1(falseCb, undefined);
  }
}

export {
  GetSeenBefore ,
  makeSeenBefore ,
  error ,
  onResult ,
  onLoading ,
  Q ,
  useQuery ,
  useCurrentUser ,
  seenTypeExists ,
  hasSeenType ,
  
}
/* query Not a pure module */
