// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as StimulusTabContext$LiteracyplanetTupperware from "./StimulusTabContext.bs.js";
import * as ClickWordToHearContainer$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/ClickWordToHearContainer.bs.js";

function SkilfulStimulusTabContent(Props) {
  var tab = Props.tab;
  var children = Props.children;
  var showing = Curry._1(StimulusTabContext$LiteracyplanetTupperware.useTabContent, tab);
  return React.createElement(ClickWordToHearContainer$LiteracyplanetClientFlags.make, {
              children: showing ? children : null
            });
}

var make = SkilfulStimulusTabContent;

var $$default = SkilfulStimulusTabContent;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
