// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";

function SkilfulContent(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulContent.className
            }, children);
}

var make = SkilfulContent;

var $$default = SkilfulContent;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
