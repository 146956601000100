// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDnd$LiteracyplanetTupperware from "./ReactDnd.bs.js";

function V2ClozeChoiceProvider(Props) {
  var children = Props.children;
  return React.createElement(ReactDnd$LiteracyplanetTupperware.Provider.make, {
              children: children
            });
}

var make = V2ClozeChoiceProvider;

var $$default = V2ClozeChoiceProvider;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
