// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as NextIcon$LiteracyplanetTupperware from "./NextIcon.bs.js";
import * as CloseBookIcon$LiteracyplanetTupperware from "./CloseBookIcon.bs.js";

function NextPageButton(Props) {
  var finishedBook = Props.finishedBook;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.NextPageButton.className,
              onClick: onClick
            }, finishedBook ? React.createElement(CloseBookIcon$LiteracyplanetTupperware.make, {
                    className: Flavor$LiteracyplanetTupperware.CloseBookIcon.className
                  }) : React.createElement(NextIcon$LiteracyplanetTupperware.make, {
                    className: Flavor$LiteracyplanetTupperware.NextPageButton.iconStyle
                  }));
}

var make = NextPageButton;

export {
  make ,
  
}
/* react Not a pure module */
