// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as Error$LiteracyplanetClientErrors from "../../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as Option2$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Option2.bs.js";
import * as Result2$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Result2.bs.js";
import * as Flipper$LiteracyplanetClientSxActivity from "./Flipper.bs.js";
import * as InMemoryStore$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/InMemoryStore.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/LocaleContext.bs.js";
import * as FlipperStore$LiteracyplanetClientSxActivity from "./FlipperStore.bs.js";
import * as FlipperTypes$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/FlipperTypes.bs.js";
import * as FlipperStoreTypes$LiteracyplanetClientSxActivity from "./FlipperStoreTypes.bs.js";
import * as BehaviourTestActivityPage$LiteracyplanetTupperware from "../../../../../node_modules/@literacyplanet/tupperware/src/tupperware/v2/BehaviourTestActivityPage.bs.js";
import * as Flipper_GameContent$LiteracyplanetClientSxActivity from "./Flipper_GameContent.bs.js";
import * as ChecksActivityQuery$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/ChecksActivityQuery.bs.js";
import * as CobraPersistedState$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/CobraPersistedState.bs.js";
import * as FlipperCobraCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraCallbacks.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "../../../../../node_modules/@literacyplanet/tupperware/src/tupperware/v2/V2ExerciseStateStoreContext.bs.js";
import * as CobraQuestionResponse$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/CobraQuestionResponse.bs.js";
import * as FlipperMutationApiAsync$LiteracyplanetClientSxActivity from "./FlipperMutationApiAsync.bs.js";
import * as ChecksActivityMutations$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/ChecksActivityMutations.bs.js";
import * as DeleteStudentQuestionResponses$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/DeleteStudentQuestionResponses.bs.js";
import * as StudentResponseMutation$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/StudentResponseMutation.bs.js";
import * as FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity from "./FlipperTupperwareCallbacks.bs.js";
import * as FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity from "./FlipperCobraResultsCallbacks.bs.js";
import * as TupperwareContentQueryParser$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/TupperwareContentQueryParser.bs.js";

var FlipperChecksCantFindActivity = /* @__PURE__ */Caml_exceptions.create("FlipperChecksActivityLauncher-LiteracyplanetClientSxActivity.FlipperChecksCantFindActivity");

var FlipperChecksCantFindGameId = /* @__PURE__ */Caml_exceptions.create("FlipperChecksActivityLauncher-LiteracyplanetClientSxActivity.FlipperChecksCantFindGameId");

function parseActivities(res) {
  return Belt_Array.get(res.literacy_checks_outstanding_activities, 0);
}

function getActivity(res) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(Belt_Option.map(Belt_Array.get(res.literacy_checks_outstanding_activities, 0), (function (a) {
                    return a.content;
                  })), {
              RE_EXN_ID: FlipperChecksCantFindActivity
            });
}

function getGameId(res) {
  return Result2$LiteracyplanetClientFlags.Opt.toResult(Belt_Option.flatMap(Belt_Array.get(res.literacy_checks_outstanding_activities, 0), (function (a) {
                    return a.gameId;
                  })), {
              RE_EXN_ID: FlipperChecksCantFindGameId
            });
}

function getLatestResponse(attempt) {
  return Belt_Option.flatMap(attempt, (function (attempt) {
                return Belt_Array.get(attempt.responses, 0);
              }));
}

function getLatestQuestionIndex(attempt) {
  return Belt_Option.getWithDefault(Belt_Option.map(getLatestResponse(attempt), (function (response) {
                    return response.questionIndex + 1 | 0;
                  })), 0);
}

function getNumCorrect(attempt) {
  return Belt_Option.getWithDefault(Belt_Option.map(attempt, (function (attempt) {
                    return Belt_Array.reduce(attempt.responses, 0, (function (acc, res) {
                                  return acc + res.points | 0;
                                }));
                  })), 0);
}

function getAttemptId(attempt) {
  return Belt_Option.map(attempt, (function (a) {
                return Json_decode.string(a.id);
              }));
}

function getPersistedState(state) {
  return Belt_Option.map(state, (function (s) {
                return s.state;
              }));
}

function getContentMd5(state) {
  return Belt_Option.flatMap(state, (function (s) {
                return s.contentMd5;
              }));
}

function useExitOnCompleteHook(param) {
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state.loadResults) {
            Curry._1(dispatch, /* Exit */6);
          }
          
        }), [state.loadResults]);
  
}

function useMutations(activityId, assignationId, gameId) {
  var match = ChecksActivityMutations$LiteracyplanetClientLibsActivity.useInsertAttempt(assignationId, gameId, activityId);
  var match$1 = ChecksActivityMutations$LiteracyplanetClientLibsActivity.useMarkComplete(assignationId);
  useExitOnCompleteHook(undefined);
  return [
          match[0],
          match[1],
          match$1[0],
          match$1[1]
        ];
}

function use(questionPasseMutation, questionFailedMutation, activityCompletedMutation, dispatch) {
  return FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity.wrapApiWithStoreEvents(FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity.api, dispatch, (function (param) {
                return Curry._1(activityCompletedMutation, undefined);
              }), undefined, undefined, (function (param, param$1) {
                console.log("exit on outside");
                
              }), (function (question, state) {
                return Curry._3(questionFailedMutation, question, state, undefined);
              }), (function (question, state) {
                return Curry._3(questionPasseMutation, question, state, undefined);
              }), undefined, undefined);
}

function FlipperChecksActivityLauncher$TupperwareCallbacks(Props) {
  var questionPasseMutation = Props.questionPasseMutation;
  var questionFailedMutation = Props.questionFailedMutation;
  var activityCompletedMutation = Props.activityCompletedMutation;
  var lc = Props.lc;
  var children = Props.children;
  var match = FlipperStore$LiteracyplanetClientSxActivity.Context.initaliseState(false, TupperwareContentQueryParser$LiteracyplanetClientLibsActivity.highestAnsweredQuestion(lc.state), lc.numCorrect);
  var activityDispachables = use(questionPasseMutation, questionFailedMutation, activityCompletedMutation, match[1]);
  var resultDispatchables = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.useResultsDispatch(undefined);
  return React.createElement(FlipperTupperwareCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
              dispachables: activityDispachables,
              children: React.createElement(FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
                    dispachables: resultDispatchables,
                    children: React.createElement(V2ExerciseStateStoreContext$LiteracyplanetTupperware.Provider.make, {
                          children: React.createElement(BehaviourTestActivityPage$LiteracyplanetTupperware.make, {
                                persistedState: lc.state,
                                assessment: lc.assessment,
                                questionIndex: lc.questionIndex,
                                children: children
                              })
                        })
                  })
            });
}

var TupperwareCallbacks = {
  use: use,
  make: FlipperChecksActivityLauncher$TupperwareCallbacks
};

function use$1(persistedState, md5, questionPasseMutation, questionFailedMutation, activityCompletedMutation, dispatch) {
  CobraPersistedState$LiteracyplanetClientLibsActivity.use(persistedState, md5);
  var match = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
  var onSetError = match.onSetError;
  return FlipperCobraCallbacks$LiteracyplanetClientSxActivity.wrapApiWithStoreEvents(FlipperTypes$LiteracyplanetClientLibsActivity.Cobra.api, dispatch, activityCompletedMutation, undefined, undefined, undefined, undefined, undefined, undefined, (function (err) {
                return Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError(undefined, err));
              }), (function (response) {
                var optState = CobraPersistedState$LiteracyplanetClientLibsActivity.collectResponses(response);
                return CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.mapQuestionIndexAndAnswers(response, (function (questionIndex, answers) {
                              return Curry._3(questionFailedMutation, questionIndex, optState, answers);
                            }));
              }), (function (response) {
                var optState = CobraPersistedState$LiteracyplanetClientLibsActivity.collectResponses(response);
                return CobraQuestionResponse$LiteracyplanetClientLibsActivity.Single.mapQuestionIndexAndAnswers(response, (function (questionIndex, answers) {
                              return Curry._3(questionPasseMutation, questionIndex, optState, answers);
                            }));
              }), undefined, undefined, undefined, undefined);
}

function FlipperChecksActivityLauncher$CobraCallbacks(Props) {
  var lc = Props.lc;
  var questionPasseMutation = Props.questionPasseMutation;
  var questionFailedMutation = Props.questionFailedMutation;
  var activityCompletedMutation = Props.activityCompletedMutation;
  var children = Props.children;
  var match = FlipperStore$LiteracyplanetClientSxActivity.Context.initaliseState(false, lc.numComplete, lc.numCorrect);
  var activityDispachables = use$1(lc.state, lc.contentMd5, questionPasseMutation, questionFailedMutation, activityCompletedMutation, match[1]);
  var resultDispatchables = FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.useResultsDispatch(undefined);
  return React.createElement(FlipperCobraCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
              dispachables: activityDispachables,
              children: React.createElement(FlipperCobraResultsCallbacks$LiteracyplanetClientSxActivity.Provider.make, {
                    dispachables: resultDispatchables,
                    children: children
                  })
            });
}

var CobraCallbacks = {
  use: use$1,
  make: FlipperChecksActivityLauncher$CobraCallbacks
};

function setFinishedActivityOnLoad(param) {
  var setInMemory = InMemoryStore$LiteracyplanetClientFlags.useSetter(undefined);
  React.useEffect((function () {
          Curry._2(setInMemory, "ranLcActivity", "true");
          
        }), []);
  
}

function FlipperChecksActivityLauncher$Mutations(Props) {
  var lc = Props.lc;
  var activityId = Props.activityId;
  var assignationId = Props.assignationId;
  var gameId = Props.gameId;
  var children = Props.children;
  setFinishedActivityOnLoad(undefined);
  var existingAttemptId = lc.studentActivityAttemptId;
  var match = useMutations(activityId, assignationId, gameId);
  var match$1 = StudentResponseMutation$LiteracyplanetClientLibsActivity.use(undefined);
  var match$2 = FlipperMutationApiAsync$LiteracyplanetClientSxActivity.useMutationApi(lc.contentMd5, match[0], match[1], existingAttemptId, match[2], match$1[0], match$1[1], match[3]);
  return Curry._3(children, match$2[0], match$2[1], match$2[2]);
}

var Mutations = {
  make: FlipperChecksActivityLauncher$Mutations
};

function FlipperChecksActivityLauncher$LocaleAndMutations(Props) {
  var locale = Props.locale;
  var accent = Props.accent;
  var lc = Props.lc;
  var activityId = Props.activityId;
  var assignationId = Props.assignationId;
  var gameId = Props.gameId;
  var children = Props.children;
  var render = function (questionPasseMutation, questionFailedMutation, activityCompletedMutation) {
    return React.createElement(LocaleContext$LiteracyplanetClientFlags.make, LocaleContext$LiteracyplanetClientFlags.makeProps({
                    locale: LocaleContext$LiteracyplanetClientFlags.fromLocaleString(locale),
                    accent: accent,
                    localeString: locale,
                    accentString: LocaleContext$LiteracyplanetClientFlags.toAccentString(accent)
                  }, Curry._3(children, questionPasseMutation, questionFailedMutation, activityCompletedMutation), undefined));
  };
  return React.createElement(FlipperMutationApiAsync$LiteracyplanetClientSxActivity.Later.make, {
              children: React.createElement(FlipperChecksActivityLauncher$Mutations, {
                    lc: lc,
                    activityId: activityId,
                    assignationId: assignationId,
                    gameId: gameId,
                    children: render
                  })
            });
}

var LocaleAndMutations = {
  make: FlipperChecksActivityLauncher$LocaleAndMutations
};

function render(cobraActivity, title, props) {
  return React.createElement(Flipper$LiteracyplanetClientSxActivity.CobraWordGameComponentNoResultsContainer.make, {
              activityProps: props._0,
              title: title,
              cobraActivity: cobraActivity
            });
}

function getGameComponent(getTitle, param) {
  var content = param[2];
  var match = param[1].activityType;
  var match$1 = Curry._1(getTitle, param[3]);
  if (typeof match === "number") {
    if (match === /* InstructionalVideo */0) {
      throw {
            RE_EXN_ID: "Match_failure",
            _1: [
              "FlipperChecksActivityLauncher.res",
              327,
              2
            ],
            Error: new Error()
          };
    }
    throw {
          RE_EXN_ID: "Match_failure",
          _1: [
            "FlipperChecksActivityLauncher.res",
            327,
            2
          ],
          Error: new Error()
        };
  } else {
    if (match.TAG !== /* CobraActivity */0) {
      return Flipper$LiteracyplanetClientSxActivity.Tupperware.parseAndRender(Flipper$LiteracyplanetClientSxActivity.Tupperware.noResultsTupperwareComponent, content, match$1);
    }
    var cobraActivity = match._0;
    return Belt_Result.map(Belt_Result.flatMap(Flipper_GameContent$LiteracyplanetClientSxActivity.parseCobra(content, cobraActivity, param[0], match$1), (function (param) {
                      return FlipperTypes$LiteracyplanetClientLibsActivity.Cobra.validate(cobraActivity, param);
                    })), (function (param) {
                  return render(cobraActivity, match$1, param);
                }));
  }
}

function parseLiteracyCheckActivity(res) {
  return Belt_Option.flatMap(Belt_Array.get(res.literacy_checks_outstanding_activities, 0), (function (a) {
                return Option2$LiteracyplanetClientFlags.combine7(a.locale, a.accent, a.activityName, Caml_option.some(a.attempt), Caml_option.some(a.state), a.assignationId, a.activityId);
              }));
}

function makeNewLc(content, state, attempt) {
  return {
          studentActivityAttemptId: getAttemptId(attempt),
          contentMd5: Flipper_GameContent$LiteracyplanetClientSxActivity.getMd5(content),
          attemptMd5: Belt_Option.flatMap(state, (function (s) {
                  return s.contentMd5;
                })),
          state: Belt_Option.map(state, (function (s) {
                  return s.state;
                })),
          assessment: false,
          questionIndex: getLatestQuestionIndex(attempt),
          numComplete: getLatestQuestionIndex(attempt),
          numCorrect: getNumCorrect(attempt),
          batchQuestions: Curry._1(Flipper$LiteracyplanetClientSxActivity.Tupperware.isSkilfulLayout, content)
        };
}

function FlipperChecksActivityLauncher$FixVersionConflict(Props) {
  var state = Props.state;
  var content = Props.content;
  var attempt = Props.attempt;
  var children = Props.children;
  var match = React.useState(function () {
        return makeNewLc(content, state, attempt);
      });
  var setLc = match[1];
  var lc = match[0];
  var match$1 = React.useState(function () {
        if (Belt_Option.isSome(lc.contentMd5) && Belt_Option.isSome(lc.attemptMd5) && Caml_obj.caml_notequal(lc.contentMd5, lc.attemptMd5)) {
          return true;
        } else if (Belt_Option.isSome(lc.contentMd5) && Belt_Option.isNone(lc.attemptMd5)) {
          if (lc.numComplete > 0 || lc.questionIndex > 0) {
            return true;
          } else {
            return Belt_Option.mapWithDefault(lc.state, false, (function (s) {
                          return s !== "";
                        }));
          }
        } else {
          return false;
        }
      });
  var setConflict = match$1[1];
  var conflict = match$1[0];
  var match$2 = DeleteStudentQuestionResponses$LiteracyplanetClientFlags.use(undefined);
  var newAttempt = match$2[2];
  var run = match$2[0];
  React.useEffect((function () {
          if (conflict) {
            Belt_Option.map(lc.studentActivityAttemptId, Curry.__1(run));
          }
          
        }), [conflict]);
  React.useEffect((function () {
          if (newAttempt !== undefined) {
            Curry._1(setLc, (function (lc) {
                    return {
                            studentActivityAttemptId: lc.studentActivityAttemptId,
                            contentMd5: lc.contentMd5,
                            attemptMd5: undefined,
                            state: undefined,
                            assessment: lc.assessment,
                            questionIndex: 0,
                            numComplete: 0,
                            numCorrect: 0,
                            batchQuestions: lc.batchQuestions
                          };
                  }));
            Curry._1(setConflict, (function (param) {
                    return false;
                  }));
          }
          
        }), [match$2[1]]);
  if (conflict) {
    return React.createElement("div", undefined, "This content has been updated, and there are previous responses to an earlier version.");
  } else {
    return Curry._1(children, lc);
  }
}

var FixVersionConflict = {
  makeNewLc: makeNewLc,
  make: FlipperChecksActivityLauncher$FixVersionConflict
};

function wrapWithCallbacks(_queryState, gameTuple) {
  var content = gameTuple[2];
  var game = gameTuple[1];
  var match = parseLiteracyCheckActivity(gameTuple[3]);
  if (match === undefined) {
    return {
            TAG: /* Error */1,
            _0: {
              RE_EXN_ID: FlipperChecksCantFindGameId
            }
          };
  }
  var activityId = match[6];
  var assignationId = match[5];
  var state = match[4];
  var attempt = match[3];
  var title = match[2];
  var accent = match[1];
  var locale = match[0];
  return Belt_Result.map(getGameComponent((function (param) {
                    return title;
                  }), gameTuple), (function (component) {
                return React.createElement(FlipperChecksActivityLauncher$FixVersionConflict, {
                            state: state,
                            content: content,
                            attempt: attempt,
                            children: (function (lc) {
                                return React.createElement(FlipperChecksActivityLauncher$LocaleAndMutations, {
                                            locale: locale,
                                            accent: accent,
                                            lc: lc,
                                            activityId: activityId,
                                            assignationId: assignationId,
                                            gameId: game.id,
                                            batchQuestions: lc.batchQuestions,
                                            children: (function (questionPasseMutation, questionFailedMutation, activityCompletedMutation) {
                                                var match = game.activityType;
                                                if (typeof match === "number") {
                                                  if (match === /* InstructionalVideo */0) {
                                                    throw {
                                                          RE_EXN_ID: "Match_failure",
                                                          _1: [
                                                            "FlipperChecksActivityLauncher.res",
                                                            451,
                                                            14
                                                          ],
                                                          Error: new Error()
                                                        };
                                                  }
                                                  
                                                } else if (match.TAG === /* CobraActivity */0) {
                                                  return React.createElement(FlipperChecksActivityLauncher$CobraCallbacks, {
                                                              lc: lc,
                                                              questionPasseMutation: questionPasseMutation,
                                                              questionFailedMutation: questionFailedMutation,
                                                              activityCompletedMutation: activityCompletedMutation,
                                                              children: component
                                                            });
                                                }
                                                return React.createElement(FlipperChecksActivityLauncher$TupperwareCallbacks, {
                                                            questionPasseMutation: questionPasseMutation,
                                                            questionFailedMutation: questionFailedMutation,
                                                            activityCompletedMutation: activityCompletedMutation,
                                                            lc: lc,
                                                            children: component
                                                          });
                                              })
                                          });
                              })
                          });
              }));
}

function FlipperChecksActivityLauncher$MarkCompleteWhenAllComplete(Props) {
  var assignationId = Props.assignationId;
  var studentActivityAttemptId = Props.studentActivityAttemptId;
  var match = ChecksActivityMutations$LiteracyplanetClientLibsActivity.useMarkComplete(assignationId);
  var updateActivityCompleted = match[0];
  React.useEffect((function () {
          Curry._1(updateActivityCompleted, studentActivityAttemptId);
          
        }), []);
  return null;
}

var MarkCompleteWhenAllComplete = {
  make: FlipperChecksActivityLauncher$MarkCompleteWhenAllComplete
};

function FlipperChecksActivityLauncher(Props) {
  var onBack = Props.onBack;
  var queryState = ChecksActivityQuery$LiteracyplanetClientLibsActivity.use(undefined);
  var onTryBack = FlipperMutationApiAsync$LiteracyplanetClientSxActivity.useTryBack(onBack);
  var component = Flipper$LiteracyplanetClientSxActivity.Hooks.useWithErrorCallback((function (param) {
          return Curry._1(onBack, undefined);
        }), queryState, getActivity, getGameId, (function (param) {
          return wrapWithCallbacks(queryState, param);
        }));
  return React.createElement(FlipperStore$LiteracyplanetClientSxActivity.ProviderWithExit.make, {
              onExit: onTryBack,
              children: component
            });
}

var decode = Json_decode.string;

var make = FlipperChecksActivityLauncher;

export {
  FlipperChecksCantFindActivity ,
  FlipperChecksCantFindGameId ,
  decode ,
  parseActivities ,
  getActivity ,
  getGameId ,
  getLatestResponse ,
  getLatestQuestionIndex ,
  getNumCorrect ,
  getAttemptId ,
  getPersistedState ,
  getContentMd5 ,
  useExitOnCompleteHook ,
  useMutations ,
  TupperwareCallbacks ,
  CobraCallbacks ,
  setFinishedActivityOnLoad ,
  Mutations ,
  LocaleAndMutations ,
  render ,
  getGameComponent ,
  parseLiteracyCheckActivity ,
  FixVersionConflict ,
  wrapWithCallbacks ,
  MarkCompleteWhenAllComplete ,
  make ,
  
}
/* react Not a pure module */
