// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphQL_PPX from "../../../../node_modules/@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as InMemoryStore$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/InMemoryStore.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/LocaleContext.bs.js";
import * as TexasUsersLocale$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/TexasUsersLocale.bs.js";
import * as JourneyCurrentStudent$LiteracyplanetClientSxEntry from "./JourneyCurrentStudent.bs.js";
import * as StudentMissionFragments$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/StudentMissionFragments.bs.js";

var Raw = {};

var query = Client.gql([
      "query journeyActivitiesQuery($userId: String!, $studentMissionId: Int!)  {\ncurrentStudent(userId: $userId)  {\n__typename  \n...JourneyCurrentStudentFragment   \nmission: findStudentMission(studentMissionId: $studentMissionId)  {\n...StudentMissionFragment   \n}\n\n}\n\n}\n",
      "",
      ""
    ], JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.query, StudentMissionFragments$LiteracyplanetClientFlags.StudentMissionFragment.query);

function parse(value) {
  var value$1 = value.currentStudent;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["mission"];
    tmp = {
      __typename: value$1["__typename"],
      journeyCurrentStudentFragment: JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.verifyArgsAndParse("JourneyCurrentStudentFragment", value$1),
      mission: StudentMissionFragments$LiteracyplanetClientFlags.StudentMissionFragment.verifyArgsAndParse("StudentMissionFragment", value$2)
    };
  }
  return {
          currentStudent: tmp
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent;
  if (value$1 !== undefined) {
    var value$2 = value$1.mission;
    var mission = StudentMissionFragments$LiteracyplanetClientFlags.StudentMissionFragment.serialize(value$2);
    var value$3 = value$1.__typename;
    currentStudent = [JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.serialize(value$1.journeyCurrentStudentFragment)].reduce(GraphQL_PPX.deepMerge, {
          __typename: value$3,
          mission: mission
        });
  } else {
    currentStudent = null;
  }
  return {
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId,
          studentMissionId: inp.studentMissionId
        };
}

function makeVariables(userId, studentMissionId, param) {
  return {
          userId: userId,
          studentMissionId: studentMissionId
        };
}

var GetMission_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetMission_refetchQueryDescription = include.refetchQueryDescription;

var GetMission_useLazy = include.useLazy;

var GetMission_useLazyWithVariables = include.useLazyWithVariables;

var GetMission = {
  GetMission_inner: GetMission_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: GetMission_refetchQueryDescription,
  use: use,
  useLazy: GetMission_useLazy,
  useLazyWithVariables: GetMission_useLazyWithVariables
};

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

var defaultMissionTitle = "";

var defaultActivityTitle = "";

function makeMission(m) {
  return {
          title: Belt_Option.getWithDefault(m.title, defaultMissionTitle),
          chest: JourneyCurrentStudent$LiteracyplanetClientSxEntry.chestToString(m.chest)
        };
}

function makeActivity(a) {
  return {
          id: String(a.id),
          title: Belt_Option.getWithDefault(a.activity.title, defaultActivityTitle),
          kind: Belt_Option.getWithDefault(a.activity.kind, ""),
          complete: a.complete,
          bestScore: Belt_Option.map(a.attemptAggregates, (function (a) {
                  return a.bestScore;
                })),
          inProgress: a.currentAttempt.numComplete > 0,
          locked: false,
          assessment: a.assessment
        };
}

function makeActivities(a) {
  return Belt_Array.map(a, makeActivity);
}

function makeProps(response, referrer) {
  return Belt_Option.map(response.currentStudent, (function (s) {
                return {
                        currentStudent: JourneyCurrentStudent$LiteracyplanetClientSxEntry.makeCurrentStudent(s.journeyCurrentStudentFragment),
                        activities: Belt_Array.map(s.mission.activities, makeActivity),
                        mission: makeMission(s.mission),
                        referrer: referrer
                      };
              }));
}

function usersLocale(res) {
  return Belt_Option.flatMap(res.currentStudent, (function (s) {
                return TexasUsersLocale$LiteracyplanetClientFlags.get(s.mission.usersLocale);
              }));
}

function onResult(referrer, locale, accent, query) {
  return Belt_Result.flatMap(query, (function (response) {
                var props = makeProps(response, referrer);
                if (props === undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* ErrorWithMessage */4,
                            _0: "JourneyActivitiesError",
                            _1: "error in makeProps"
                          }
                        };
                }
                var props$1 = Caml_option.valFromOption(props);
                var match = usersLocale(response);
                if (match !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: /* ActivityProps */{
                            _0: props$1,
                            _1: match[0],
                            _2: match[1]
                          }
                        };
                } else {
                  return {
                          TAG: /* Ok */0,
                          _0: /* ActivityProps */{
                            _0: props$1,
                            _1: locale,
                            _2: accent
                          }
                        };
                }
              }));
}

function onLoading(param) {
  return /* LoadingActivity */0;
}

function useMissionState(userId, studentMissionId, referrer, locale, accent) {
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, (function (param) {
                return onResult(referrer, locale, accent, param);
              }), {
              userId: userId,
              studentMissionId: studentMissionId
            });
}

function useCurrentUser(studentMissionId) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var api = LocaleContext$LiteracyplanetClientFlags.use(undefined);
  var locale = api.localeString;
  var accent = api.accentString;
  var referrer = Belt_Option.getWithDefault(InMemoryStore$LiteracyplanetClientFlags.useGetter("referrer"), "pageRefresh");
  return useMissionState(userId, studentMissionId, referrer, locale, accent);
}

function setReferrerOnLoad(referrer) {
  var setInMemory = InMemoryStore$LiteracyplanetClientFlags.useSetter(undefined);
  React.useEffect((function () {
          Curry._2(setInMemory, "referrer", referrer);
          
        }), []);
  
}

function referrerSetter(param) {
  var setInMemory = InMemoryStore$LiteracyplanetClientFlags.useSetter(undefined);
  return function (referrer) {
    return Curry._2(setInMemory, "referrer", referrer);
  };
}

var JourneyCurrentStudentFragment;

var StudentMissionFragment;

var StudentActivityFragment;

export {
  JourneyCurrentStudentFragment ,
  StudentMissionFragment ,
  StudentActivityFragment ,
  GetMission ,
  Q ,
  defaultMissionTitle ,
  defaultActivityTitle ,
  makeMission ,
  makeActivity ,
  makeActivities ,
  makeProps ,
  usersLocale ,
  onResult ,
  onLoading ,
  useMissionState ,
  useCurrentUser ,
  setReferrerOnLoad ,
  referrerSetter ,
  
}
/* query Not a pure module */
