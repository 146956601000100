import {createStore, combineReducers, applyMiddleware, compose} from 'redux';

export function composeMiddleware(middlewares) {
  return compose(
    applyMiddleware(...middlewares),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  );
}

export function createReducer(reducers, asyncReducers) {
  return combineReducers({
    ...reducers,
    ...asyncReducers
  });
}

export function configureStore(initialState, {reducers, middlewares}) {
  const store = createStore(
    createReducer(reducers),
    initialState,
    composeMiddleware(middlewares)
  );
  store.asyncReducers = {};
  return {
    store,
    injectAsyncReducer: (name, asyncReducer) => {
      store.asyncReducers[name] = asyncReducer;
      store.replaceReducer(createReducer(reducers, store.asyncReducers));
    }
  };
}
