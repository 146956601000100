// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Css_Legacy_Core from "../../../../../node_modules/bs-css/src/Css_Legacy_Core.bs.js";
import * as Theme$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Theme.bs.js";
import * as MobileDetect$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/MobileDetect.bs.js";

function s(prim) {
  return prim;
}

var missionsColor = Css.hex("009fe8");

var missionsHoverColor = Css.hex("0dadf7");

var missionsActiveColor = Css.hex("a3e1fe");

var reportsColor = Css.hex("E67F22");

var reportsHoverColor = Css.hex("E1661B");

var reportsActiveColor = Css.hex("BF5717");

var collectionsColor = Css.hex("374d95");

var collectionsHoverColor = Css.hex("475da4");

var collectionsActiveColor = Css.hex("6c83ce");

var arcadeColor = Css.hex("00a286");

var arcadeHoverColor = Css.hex("0bb799");

var arcadeActiveColor = Css.hex("43ebce");

var libraryColor = Css.hex("75258e");

var libraryHoverColor = Css.hex("8832a3");

var libraryActiveColor = Css.hex("ab30d1");

var avatarColor = Css.hex("a055b7");

var avatarHoverColor = Css.hex("b169c7");

var avatarActiveColor = Css.hex("d091e3");

var lpClassicColor = Css.hex("8dcb46");

var lpClassicHoverColor = Css.hex("84bc40");

var lpClassicActiveColor = Css.hex("78aa3a");

var conversationColor = Css.hex("1BBC9B");

var conversationHoverColor = Css.hex("16AF87");

var conversationActiveColor = Css.hex("139573");

var myWordsColor = Css.hex("50068F");

var myWordsHoverColor = Css.hex("6d16b8");

var myWordsActiveColor = Css.hex("a557e6");

var libraryDropdownColor = Css.hex("75258e");

var libraryDropdownHoverColor = Css.hex("7F2B99");

var libraryDropdownDividerColor = Css.rgba(89, 15, 110, {
      NAME: "num",
      VAL: 0.9
    });

var logOutColor = Css.hex("c52c2c");

var logOutHoverColor = Css.hex("b82e2e");

var logOutActiveColor = Css.hex("a62626");

var iconText = Css.rgb(12, 156, 229);

var closeColor = Css.rgb(255, 255, 255);

var closeHoverColor = Css.rgb(218, 240, 251);

var closeActiveColor = Css.rgb(200, 220, 230);

var menuColor = Css.rgb(255, 255, 255);

var menuLogoutColor = Css.rgb(197, 44, 44);

var notificationColor = Css.hex("ff0000");

var defaultFonts = {
  hd: {
    NAME: "custom",
    VAL: "Quicksand"
  },
  tl: {
    hd: {
      NAME: "custom",
      VAL: "Lato"
    },
    tl: {
      hd: {
        NAME: "custom",
        VAL: "Arial"
      },
      tl: /* [] */0
    }
  }
};

var dims = MobileDetect$LiteracyplanetClientFlags.getDimensions(undefined);

function toVh(pixel) {
  return Css.vh(pixel / dims.height * 100.0);
}

function toVw(pixel) {
  return Css.vw(pixel / dims.width * 100.0);
}

var defaultBoxShadow = Css_Legacy_Core.Shadow.box(Css.px(4), Css.px(0), Css.px(19), Css.px(2), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.39
        }));

var css = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridAutoColumns(Css.fr(1.0)),
        tl: {
          hd: Css.justifyItems(Css.center),
          tl: {
            hd: Css.position(Css.fixed),
            tl: {
              hd: Css.top(Css.zero),
              tl: {
                hd: Css.left(Css.zero),
                tl: {
                  hd: Css.boxSizing(Css.borderBox),
                  tl: {
                    hd: Css.zIndex(10),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.background(Css.linearGradient(Css.deg(180.0), {
                                  hd: [
                                    Css.pct(0.0),
                                    Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                          NAME: "num",
                                          VAL: 0.8
                                        })
                                  ],
                                  tl: {
                                    hd: [
                                      Css.pct(18.5),
                                      Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                            NAME: "num",
                                            VAL: 0.79
                                          })
                                    ],
                                    tl: {
                                      hd: [
                                        Css.pct(34.1),
                                        Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                              NAME: "num",
                                              VAL: 0.761
                                            })
                                      ],
                                      tl: {
                                        hd: [
                                          Css.pct(47.1),
                                          Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                NAME: "num",
                                                VAL: 0.717
                                              })
                                        ],
                                        tl: {
                                          hd: [
                                            Css.pct(57.7),
                                            Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                  NAME: "num",
                                                  VAL: 0.66
                                                })
                                          ],
                                          tl: {
                                            hd: [
                                              Css.pct(66.4),
                                              Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                    NAME: "num",
                                                    VAL: 0.593
                                                  })
                                            ],
                                            tl: {
                                              hd: [
                                                Css.pct(73.2),
                                                Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                      NAME: "num",
                                                      VAL: 0.518
                                                    })
                                              ],
                                              tl: {
                                                hd: [
                                                  Css.pct(78.6),
                                                  Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                        NAME: "num",
                                                        VAL: 0.44
                                                      })
                                                ],
                                                tl: {
                                                  hd: [
                                                    Css.pct(82.7),
                                                    Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                          NAME: "num",
                                                          VAL: 0.36
                                                        })
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      Css.pct(85.8),
                                                      Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                            NAME: "num",
                                                            VAL: 0.282
                                                          })
                                                    ],
                                                    tl: {
                                                      hd: [
                                                        Css.pct(88.3),
                                                        Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                              NAME: "num",
                                                              VAL: 0.207
                                                            })
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          Css.pct(90.4),
                                                          Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                NAME: "num",
                                                                VAL: 0.14
                                                              })
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            Css.pct(92.3),
                                                            Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                  NAME: "num",
                                                                  VAL: 0.083
                                                                })
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              Css.pct(94.4),
                                                              Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                    NAME: "num",
                                                                    VAL: 0.039
                                                                  })
                                                            ],
                                                            tl: {
                                                              hd: [
                                                                Css.pct(96.8),
                                                                Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                      NAME: "num",
                                                                      VAL: 0.01
                                                                    })
                                                              ],
                                                              tl: {
                                                                hd: [
                                                                  Css.pct(100.0),
                                                                  Css.hsla(Css.deg(0.0), Css.pct(0.0), Css.pct(0.0), {
                                                                        NAME: "num",
                                                                        VAL: 0.0
                                                                      })
                                                                ],
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: "HeaderBackground",
        tl: /* [] */0
      }
    });

var HeaderBackground = {
  css: css,
  className: className
};

var css$1 = Curry._1(Css.style, {
      hd: Css.boxSizing(Css.borderBox),
      tl: {
        hd: Css.display(Css.grid),
        tl: {
          hd: Css.gridAutoColumns(Css.fr(1.0)),
          tl: {
            hd: Css.gridAutoFlow(Css.column),
            tl: /* [] */0
          }
        }
      }
    });

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        Theme$LiteracyplanetClientFlags.maximumWidth
      ],
      tl: {
        hd: [
          Css.paddingBottom,
          23
        ],
        tl: {
          hd: [
            Css.paddingLeft,
            100
          ],
          tl: {
            hd: [
              Css.paddingRight,
              100
            ],
            tl: {
              hd: [
                Css.gridGap,
                9
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: r,
        tl: {
          hd: "HeaderPanel",
          tl: /* [] */0
        }
      }
    });

var HeaderPanel = {
  css: css$1,
  r: r,
  className: className$1
};

var rows = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 33
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 25
        },
        tl: {
          hd: {
            NAME: "css",
            VAL: Css.fr(1.0)
          },
          tl: /* [] */0
        }
      }
    });

var css$2 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: Css.fr(1.0),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: {
            hd: Css.hover({
                  hd: Css.cursor(Css.pointer),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var r$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        94
      ],
      tl: /* [] */0
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: r$1,
        tl: {
          hd: rows,
          tl: {
            hd: "SlimHeaderItem",
            tl: /* [] */0
          }
        }
      }
    });

var SlimHeaderItem = {
  rows: rows,
  css: css$2,
  r: r$1,
  className: className$2
};

function css$3(highlight, itemColor, hoverColor, activeColor) {
  return Curry._1(Css.style, {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.gridRowStart(2),
                tl: {
                  hd: Css.gridRowEnd(4),
                  tl: {
                    hd: Css.gridColumnStart(1),
                    tl: {
                      hd: Css.gridColumnEnd(2),
                      tl: {
                        hd: Css.position(Css.relative),
                        tl: {
                          hd: Css.zIndex(3),
                          tl: {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.height(Css.pct(100.0)),
                              tl: {
                                hd: Css.justifyItems(Css.center),
                                tl: {
                                  hd: Css.alignItems(Css.center),
                                  tl: {
                                    hd: Css.color(menuColor),
                                    tl: {
                                      hd: Css.fontWeight(Css.bold),
                                      tl: {
                                        hd: Css.fontFamilies(defaultFonts),
                                        tl: {
                                          hd: Css.backgroundColor(itemColor),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.backgroundColor(hoverColor),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.active({
                                                    hd: Css.backgroundColor(activeColor),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.borderColor(highlight ? activeColor : Css.transparent),
                                                tl: {
                                                  hd: Css.borderStyle(Css.solid),
                                                  tl: {
                                                    hd: Css.boxSizing(Css.borderBox),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var r$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.borderRadius,
        10
      ],
      tl: {
        hd: [
          Css.fontSize,
          20
        ],
        tl: {
          hd: [
            Css.borderWidth,
            4
          ],
          tl: /* [] */0
        }
      }
    });

function className$3(highlight, itemColor, hoverColor, activeColor) {
  return Curry._1(Css.merge, {
              hd: css$3(highlight, itemColor, hoverColor, activeColor),
              tl: {
                hd: r$2,
                tl: {
                  hd: "SlimHeaderItemButton",
                  tl: /* [] */0
                }
              }
            });
}

var SlimHeaderItemButton = {
  css: css$3,
  r: r$2,
  className: className$3
};

var css$4 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridRowStart(1),
        tl: {
          hd: Css.gridRowEnd(3),
          tl: {
            hd: Css.gridColumnStart(1),
            tl: {
              hd: Css.gridColumnEnd(2),
              tl: {
                hd: Css.position(Css.relative),
                tl: {
                  hd: Css.zIndex(4),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.height(Css.pct(100.0)),
                      tl: {
                        hd: Css.justifyItems(Css.center),
                        tl: {
                          hd: Css.alignItems(Css.center),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var r$3 = Theme$LiteracyplanetClientFlags.resize(/* [] */0);

var className$4 = Curry._1(Css.merge, {
      hd: css$4,
      tl: {
        hd: r$3,
        tl: {
          hd: "SlimHeaderItemIcon",
          tl: /* [] */0
        }
      }
    });

var SlimHeaderItemIcon = {
  css: css$4,
  r: r$3,
  className: className$4
};

var css$5 = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: /* [] */0
    });

var className$5 = Curry._1(Css.merge, {
      hd: css$5,
      tl: {
        hd: "SlimIcon",
        tl: /* [] */0
      }
    });

var SlimIcon = {
  css: css$5,
  className: className$5
};

var css$6 = Curry._1(Css.style, {
      hd: Css.position(Css.relative),
      tl: /* [] */0
    });

var r$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        58
      ],
      tl: /* [] */0
    });

var className$6 = Curry._1(Css.merge, {
      hd: css$6,
      tl: {
        hd: r$4,
        tl: {
          hd: "SlimHeaderItemWrapper",
          tl: /* [] */0
        }
      }
    });

var SlimHeaderItemWrapper = {
  css: css$6,
  r: r$4,
  className: className$6
};

var css$7 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: /* [] */0
    });

var r$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        25
      ],
      tl: {
        hd: [
          Css.right,
          -15
        ],
        tl: {
          hd: [
            Css.top,
            18
          ],
          tl: /* [] */0
        }
      }
    });

var className$7 = Curry._1(Css.merge, {
      hd: css$7,
      tl: {
        hd: r$5,
        tl: {
          hd: "SlimHeaderLockIcon",
          tl: /* [] */0
        }
      }
    });

var SlimHeaderLockIcon = {
  css: css$7,
  r: r$5,
  className: className$7
};

var css$8 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.position(Css.absolute),
        tl: {
          hd: Css.backgroundColor(notificationColor),
          tl: {
            hd: Css.color(menuColor),
            tl: {
              hd: Css.justifyItems(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: {
                  hd: Css.fontWeight(Css.bold),
                  tl: {
                    hd: Css.fontFamilies(defaultFonts),
                    tl: {
                      hd: Css.boxSizing(Css.borderBox),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var r$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.minWidth,
        20
      ],
      tl: {
        hd: [
          Css.height,
          20
        ],
        tl: {
          hd: [
            Css.left,
            -10
          ],
          tl: {
            hd: [
              Css.top,
              5
            ],
            tl: {
              hd: [
                Css.borderRadius,
                12
              ],
              tl: {
                hd: [
                  Css.fontSize,
                  12
                ],
                tl: {
                  hd: [
                    Css.paddingLeft,
                    5
                  ],
                  tl: {
                    hd: [
                      Css.paddingRight,
                      5
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var className$8 = Curry._1(Css.merge, {
      hd: css$8,
      tl: {
        hd: r$6,
        tl: {
          hd: "SlimHeaderNotificationCount",
          tl: /* [] */0
        }
      }
    });

var SlimHeaderNotificationCount = {
  css: css$8,
  r: r$6,
  className: className$8
};

var missionIcon = Curry._1(Css.style, {
      hd: Css.width(Css.px(32)),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(Css.rgb(255, 255, 255)),
        tl: /* [] */0
      }
    });

var r$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.borderBottomLeftRadius,
        10
      ],
      tl: {
        hd: [
          Css.borderBottomRightRadius,
          10
        ],
        tl: {
          hd: [
            Css.borderTopWidth,
            10
          ],
          tl: {
            hd: [
              Css.marginTop,
              -10
            ],
            tl: /* [] */0
          }
        }
      }
    });

var dropdown = Curry._1(Css.keyframes, {
      hd: [
        0,
        {
          hd: Css.transform(Css.scaleY(0.0)),
          tl: /* [] */0
        }
      ],
      tl: {
        hd: [
          100,
          {
            hd: Css.transform(Css.scaleY(1.0)),
            tl: /* [] */0
          }
        ],
        tl: /* [] */0
      }
    });

var css$9 = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.height(Css.auto),
        tl: {
          hd: Css.animationName(dropdown),
          tl: {
            hd: Css.animationDuration(300),
            tl: {
              hd: Css.animationTimingFunction(Css.easeInOut),
              tl: {
                hd: Css.transformOrigin(Css.px(0), Css.px(0)),
                tl: {
                  hd: Css.position(Css.relative),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: {
                      hd: Css.display(Css.grid),
                      tl: {
                        hd: Css.gridAutoFlow(Css.row),
                        tl: {
                          hd: Css.backgroundColor(libraryDropdownColor),
                          tl: {
                            hd: Css.borderTopStyle(Css.solid),
                            tl: {
                              hd: Css.borderTopColor(libraryDropdownColor),
                              tl: {
                                hd: Css.opacity(0.9),
                                tl: {
                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(2), Css.px(6), undefined, undefined, Css.rgba(0, 0, 0, {
                                                NAME: "num",
                                                VAL: 0.1
                                              }))),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$9 = Curry._1(Css.merge, {
      hd: r$7,
      tl: {
        hd: css$9,
        tl: {
          hd: "DropdownHeaderPanel",
          tl: /* [] */0
        }
      }
    });

var DropdownHeaderPanel = {
  r: r$7,
  dropdown: dropdown,
  css: css$9,
  className: className$9
};

var rows$1 = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "css",
        VAL: Css.fr(1.0)
      },
      tl: /* [] */0
    });

var cols = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "ratio",
        VAL: 38
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 100
        },
        tl: /* [] */0
      }
    });

var r$8 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.gridColumnGap,
        8
      ],
      tl: {
        hd: [
          Css.paddingLeft,
          10
        ],
        tl: {
          hd: [
            Css.paddingRight,
            10
          ],
          tl: {
            hd: [
              Css.paddingTop,
              5
            ],
            tl: {
              hd: [
                Css.paddingBottom,
                5
              ],
              tl: {
                hd: [
                  Css.fontSize,
                  18
                ],
                tl: {
                  hd: [
                    Css.borderTopWidth,
                    2
                  ],
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var css$10 = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.color(Css.white),
        tl: {
          hd: Css.selector(":last-child", {
                hd: Css.hover({
                      hd: Css.borderBottomRightRadius(toVh(10)),
                      tl: {
                        hd: Css.borderBottomLeftRadius(toVh(10)),
                        tl: {
                          hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                                hd: Css.borderBottomRightRadius(toVw(10)),
                                tl: {
                                  hd: Css.borderBottomLeftRadius(toVw(10)),
                                  tl: /* [] */0
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.display(Css.grid),
            tl: {
              hd: Css.position(Css.relative),
              tl: {
                hd: Css.boxSizing(Css.borderBox),
                tl: {
                  hd: Css.fontWeight(Css.bold),
                  tl: {
                    hd: Css.alignItems(Css.center),
                    tl: {
                      hd: Css.textAlign("left"),
                      tl: {
                        hd: Css.fontFamilies(defaultFonts),
                        tl: {
                          hd: Css.hover({
                                hd: Css.backgroundColor(libraryDropdownHoverColor),
                                tl: {
                                  hd: Css.cursor(Css.pointer),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.borderTopStyle(Css.solid),
                            tl: {
                              hd: Css.borderTopColor(libraryDropdownDividerColor),
                              tl: {
                                hd: Css.overflow(Css.hidden),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className$10 = Curry._1(Css.merge, {
      hd: cols,
      tl: {
        hd: r$8,
        tl: {
          hd: css$10,
          tl: {
            hd: "DropdownHeaderItem",
            tl: /* [] */0
          }
        }
      }
    });

var DropdownHeaderItem = {
  rows: rows$1,
  cols: cols,
  r: r$8,
  css: css$10,
  className: className$10
};

var r$9 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        40
      ],
      tl: /* [] */0
    });

var className$11 = Curry._1(Css.merge, {
      hd: r$9,
      tl: {
        hd: "DropdownHeaderIcon",
        tl: /* [] */0
      }
    });

var DropdownHeaderIcon = {
  r: r$9,
  className: className$11
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

var maximumWidth = Theme$LiteracyplanetClientFlags.maximumWidth;

var resizeGridCols = Theme$LiteracyplanetClientFlags.resizeGridCols;

var resizeGridRows = Theme$LiteracyplanetClientFlags.resizeGridRows;

var wordManiaColor = libraryColor;

var wordManiaHoverColor = libraryHoverColor;

var wordManiaActiveColor = libraryActiveColor;

var closeIconColor = iconText;

export {
  ratio ,
  maximumWidth ,
  s ,
  resizeGridCols ,
  resizeGridRows ,
  missionsColor ,
  missionsHoverColor ,
  missionsActiveColor ,
  reportsColor ,
  reportsHoverColor ,
  reportsActiveColor ,
  collectionsColor ,
  collectionsHoverColor ,
  collectionsActiveColor ,
  arcadeColor ,
  arcadeHoverColor ,
  arcadeActiveColor ,
  libraryColor ,
  libraryHoverColor ,
  libraryActiveColor ,
  avatarColor ,
  avatarHoverColor ,
  avatarActiveColor ,
  lpClassicColor ,
  lpClassicHoverColor ,
  lpClassicActiveColor ,
  wordManiaColor ,
  wordManiaHoverColor ,
  wordManiaActiveColor ,
  conversationColor ,
  conversationHoverColor ,
  conversationActiveColor ,
  myWordsColor ,
  myWordsHoverColor ,
  myWordsActiveColor ,
  libraryDropdownColor ,
  libraryDropdownHoverColor ,
  libraryDropdownDividerColor ,
  logOutColor ,
  logOutHoverColor ,
  logOutActiveColor ,
  iconText ,
  closeIconColor ,
  closeColor ,
  closeHoverColor ,
  closeActiveColor ,
  menuColor ,
  menuLogoutColor ,
  notificationColor ,
  defaultFonts ,
  dims ,
  toVh ,
  toVw ,
  defaultBoxShadow ,
  HeaderBackground ,
  HeaderPanel ,
  SlimHeaderItem ,
  SlimHeaderItemButton ,
  SlimHeaderItemIcon ,
  SlimIcon ,
  SlimHeaderItemWrapper ,
  SlimHeaderLockIcon ,
  SlimHeaderNotificationCount ,
  missionIcon ,
  DropdownHeaderPanel ,
  DropdownHeaderItem ,
  DropdownHeaderIcon ,
  
}
/* missionsColor Not a pure module */
