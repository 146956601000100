import React from 'react';
import * as Sentry from '@sentry/browser';
import {
  MissionsIcon,
  RetryIcon,
  IconBox,
  TextBox,
  RoundButton,
  LinkButton,
  ButtonBox,
  ButtonRow,
  WordMonsterBox,
  InnerBox,
  Fullscreen
} from './error_modal_components';

// This component is interoped over the Reason Error.re
// please don't change the contracts/props.
const SubmitError = (props) => {
  const {style, message, onClearError, onClickHome} = props;
  return (
    <Fullscreen style={style}>
      <WordMonsterBox>
        <InnerBox>
          <TextBox>
            {message}
            <br />
            <ButtonBox>
              <RoundButton onClick={onClearError}>
                <ButtonRow>
                  <IconBox>
                    <RetryIcon />
                  </IconBox>
                  Retry
                </ButtonRow>
              </RoundButton>
              <LinkButton onClick={_ => {
                onClearError();
                onClickHome();
              }}>
                <ButtonRow>
                  <IconBox>
                    <MissionsIcon />
                  </IconBox>
                  Missions
                </ButtonRow>
              </LinkButton>
            </ButtonBox>
          </TextBox>
        </InnerBox>
      </WordMonsterBox>
    </Fullscreen>
  );
};

// SubmitError.propTypes = {
//   style: PropTypes.object,
//   message: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object
//   ]).isRequired,
//   onClearError: PropTypes.func,
//   onClickHome: PropTypes.func
// };

export const Error = SubmitError;

// Currenlty have 2 versions of error handling.
// One for thrown errors and the other for
// callback type errors.
class ErrorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null};
    this.clearError = this.clearError.bind(this);
    this.onShowReportDialog = this.onShowReportDialog.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error});
    const {profile} = this.props;
    Sentry.captureException(error, {extra: errorInfo, user: profile});
  }

  clearError() {
    this.setState({error: null});
  }

  onShowReportDialog() {
    Sentry.lastEventId() && Sentry.showReportDialog();
  }

  render() {
    const {style, children, onClickHome, errorModal} = this.props;
    const error = this.state.error;
    const message = (this.props.message
      || `We seem to have encountered an error. Please retry or go back to the home page.
      If this problem persists, please contact our customer support team.`);
    const Error = errorModal || SubmitError;
    return (
      error ?
        <Error
          style={style}
          message={message}
          onClearError={this.clearError}
          onClickHome={onClickHome}
          onShowReportDialog={this.onShowReportDialog}
        />
        : children
    );
  }
};

// ErrorModal.propTypes = {
//   style: PropTypes.object,
//   message: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object
//   ]),
//   profile: PropTypes.object,
//   children: PropTypes.node,
//   onClickHome: PropTypes.func,
//   errorModal: PropTypes.func
// };

export default ErrorModal;
