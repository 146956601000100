// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryHook.bs.js";

var Raw = {};

var query = Client.gql(["query StoryverseUnlockQuery($id: uuid!)  {\nstoryverse_unlocks(where: {storyverseLevelId: {_eq: $id}})  {\n__typename  \nimage  {\n__typename  \nid  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.storyverse_unlocks;
  return {
          storyverse_unlocks: value$1.map(function (value) {
                var value$1 = value.image;
                return {
                        __typename: value.__typename,
                        image: !(value$1 == null) ? ({
                              __typename: value$1.__typename,
                              id: value$1.id
                            }) : undefined
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.storyverse_unlocks;
  var storyverse_unlocks = value$1.map(function (value) {
        var value$1 = value.image;
        var image;
        if (value$1 !== undefined) {
          var value$2 = value$1.id;
          var value$3 = value$1.__typename;
          image = {
            __typename: value$3,
            id: value$2
          };
        } else {
          image = null;
        }
        var value$4 = value.__typename;
        return {
                __typename: value$4,
                image: image
              };
      });
  return {
          storyverse_unlocks: storyverse_unlocks
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var StoryverseUnlockQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var StoryverseUnlockQuery_refetchQueryDescription = include.refetchQueryDescription;

var StoryverseUnlockQuery_useLazy = include.useLazy;

var StoryverseUnlockQuery_useLazyWithVariables = include.useLazyWithVariables;

var StoryverseUnlockQuery = {
  StoryverseUnlockQuery_inner: StoryverseUnlockQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: StoryverseUnlockQuery_refetchQueryDescription,
  use: use,
  useLazy: StoryverseUnlockQuery_useLazy,
  useLazyWithVariables: StoryverseUnlockQuery_useLazyWithVariables
};

function convert(res) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(res.storyverse_unlocks, 0), (function (row) {
                    return {
                            hasImage: Belt_Option.isSome(row.image),
                            hasStory: false,
                            hasSot: false
                          };
                  })), {
              hasImage: false,
              hasStory: false,
              hasSot: false
            });
}

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function handleResponse(res) {
  return /* UnlocksLoaded */{
          _0: convert(res)
        };
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

function onLoading(param) {
  return /* UnlocksLoading */1;
}

function defaultState(param) {
  return /* UnlocksSkipped */0;
}

function use$1(skip, id) {
  return Curry._5(Q.useSkippableWithErrorModal, defaultState, onLoading, onResult, skip, {
              id: id
            });
}

export {
  StoryverseUnlockQuery ,
  convert ,
  Q ,
  handleResponse ,
  onResult ,
  onLoading ,
  defaultState ,
  use$1 as use,
  
}
/* query Not a pure module */
