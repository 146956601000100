export const loadJson = (from) => {
  try {
    const data = localStorage.getItem(from);
    if (!data) {
      return {};
    }
    console.log(from);
    console.log(data);
    return JSON.parse(data);
  } catch(_) {
    return {};
  }
};

export const persist = (key, flags) => {
  try {
    localStorage.setItem(key, JSON.stringify(flags));
    return true;
  } catch(_) {
    return false;
  }
};

export const setKey = (type) => (key, val) => {
  const keys = loadJson(type);
  keys[key] = val;
  if (persist(type, keys)) {
    console.log(JSON.stringify(keys));
  }
};

export const removeKey = (type) => (key) => {
  const keys = loadJson(type);
  delete keys[key];
  if (persist(type, keys)) {
    console.log(JSON.stringify(keys));
  }
};

const s = 'settings';
const f = 'flags';

export const loadFlags = _ => loadJson(f);
export const loadSettings = _ => loadJson(s);

global.setFlag = setKey(f);
global.removeFlag = removeKey(f);

global.setSetting = setKey(s);
global.removeSetting = removeKey(s);

export const saveSettings = global.setSetting;
