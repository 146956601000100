// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.bs.js";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.bs.js";
import * as SkilfulMultiChoiceSingleResponseContainer$LiteracyplanetTupperware from "./SkilfulMultiChoiceSingleResponseContainer.bs.js";

function SkilfulRadioButtonMultiChoice$Radio(Props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.Radio.className
            }, React.createElement("div", {
                  className: "SkilfulRadioButtonMultiChoice-RadioSelection"
                }));
}

var Radio = {
  make: SkilfulRadioButtonMultiChoice$Radio
};

function SkilfulRadioButtonMultiChoice(Props) {
  var questionIndex = Props.questionIndex;
  var responseOptionId = Props.responseOptionId;
  var children = Props.children;
  return React.createElement(SkilfulMultiChoiceSingleResponseContainer$LiteracyplanetTupperware.make, {
              questionIndex: questionIndex,
              responseOptionId: responseOptionId,
              children: React.createElement("div", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.className
                  }, React.createElement(SkilfulRadioButtonMultiChoice$Radio, {}), children, React.createElement("div", undefined, React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.CorrectMarkIcon.className
                          }), React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                            className: Skillet$LiteracyplanetTupperware.SkilfulRadioButtonMultiChoice.IncorrectMarkIcon.className
                          })))
            });
}

var make = SkilfulRadioButtonMultiChoice;

var $$default = SkilfulRadioButtonMultiChoice;

export {
  Radio ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
