// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as PlayIcon$LiteracyplanetTupperware from "./PlayIcon.bs.js";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.bs.js";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";

function SkilfulEndScreenContainer$Button(Props) {
  var onClick = Props.onClick;
  var className = Props.className;
  var children = Props.children;
  var tmp = {
    className: className
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("div", tmp, children);
}

var Button = {
  make: SkilfulEndScreenContainer$Button
};

function SkilfulEndScreenContainer$MarkButton(Props) {
  var className = Props.className;
  var onClick = Props.onClick;
  return React.createElement(SkilfulEndScreenContainer$Button, {
              onClick: onClick,
              className: className,
              children: "Submit"
            });
}

var MarkButton = {
  make: SkilfulEndScreenContainer$MarkButton
};

var enabledCss = Skillet$LiteracyplanetTupperware.BehaviourMarkButton.enabledCss;

function SkilfulEndScreenContainer$BehaviourMarkAllButton(Props) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var activityEnded = match.activityEnded;
  var onSetActivityEnd = match.onSetActivityEnd;
  var onMarkActivity = match.onMarkActivity;
  if (match.finishedLastQuestion) {
    if (!activityEnded) {
      return React.createElement(SkilfulEndScreenContainer$Button, {
                  onClick: (function (param) {
                      return Curry._1(onSetActivityEnd, undefined);
                    }),
                  className: enabledCss,
                  children: "Continue"
                });
    }
    
  } else if (!activityEnded) {
    return React.createElement(SkilfulEndScreenContainer$MarkButton, {
                className: enabledCss,
                onClick: (function (param) {
                    return Curry._1(onMarkActivity, undefined);
                  })
              });
  }
  return React.createElement(SkilfulEndScreenContainer$Button, {
              onClick: (function (param) {
                  
                }),
              className: Skillet$LiteracyplanetTupperware.BehaviourMarkButton.disabledCss,
              children: "Continue"
            });
}

var BehaviourMarkAllButton = {
  enabledCss: enabledCss,
  make: SkilfulEndScreenContainer$BehaviourMarkAllButton
};

function SkilfulEndScreenContainer$EndScreenHeader(Props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenHeader.className
            }, "Results Summary");
}

var EndScreenHeader = {
  make: SkilfulEndScreenContainer$EndScreenHeader
};

function SkilfulEndScreenContainer$EndScreenFooter(Props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenFooter.className
            }, React.createElement(SkilfulEndScreenContainer$BehaviourMarkAllButton, {}));
}

var EndScreenFooter = {
  make: SkilfulEndScreenContainer$EndScreenFooter
};

function SkilfulEndScreenContainer$EndScreenQuestionIndex(Props) {
  var i = Props.i;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenQuestionIndex.className
            }, String(i + 1 | 0));
}

var EndScreenQuestionIndex = {
  make: SkilfulEndScreenContainer$EndScreenQuestionIndex
};

function SkilfulEndScreenContainer$EndScreenPlayIcon(Props) {
  return React.createElement(PlayIcon$LiteracyplanetTupperware.make, {
              className: Skillet$LiteracyplanetTupperware.EndScreenPlayIcon.className,
              playClassName: Skillet$LiteracyplanetTupperware.EndScreenPlayIcon.playClassName,
              backgroundClassName: Skillet$LiteracyplanetTupperware.EndScreenPlayIcon.backgroundClassName
            });
}

var EndScreenPlayIcon = {
  make: SkilfulEndScreenContainer$EndScreenPlayIcon
};

function SkilfulEndScreenContainer$EndScreenMarkingState(Props) {
  var answer = Props.answer;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenMarkingState.className
            }, answer);
}

var EndScreenMarkingState = {
  make: SkilfulEndScreenContainer$EndScreenMarkingState
};

function SkilfulEndScreenContainer$EndScreenLink(Props) {
  var detailRowRender = Props.detailRowRender;
  var onChangeQuestion = Props.onChangeQuestion;
  var i = Props.i;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var match$1 = Curry._2(detailRowRender, match.getStep, i);
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenLink.className,
              onClick: (function (param) {
                  return Curry._1(onChangeQuestion, i);
                })
            }, React.createElement(SkilfulEndScreenContainer$EndScreenQuestionIndex, {
                  i: i
                }), React.createElement("div", undefined, match$1[1], match$1[0]), React.createElement(SkilfulEndScreenContainer$EndScreenMarkingState, {
                  answer: match$1[2]
                }), React.createElement(SkilfulEndScreenContainer$EndScreenPlayIcon, {}));
}

var EndScreenLink = {
  make: SkilfulEndScreenContainer$EndScreenLink
};

function SkilfulEndScreenContainer$EndScreenTableHeader(Props) {
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenTableHeader.className
            }, React.createElement("div", undefined), React.createElement("div", undefined, "YOUR RESPONSE"), React.createElement("div", undefined, "ANSWER"), React.createElement("div", undefined));
}

var EndScreenTableHeader = {
  make: SkilfulEndScreenContainer$EndScreenTableHeader
};

function SkilfulEndScreenContainer$EndScreenGrid(Props) {
  var detailRowRender = Props.detailRowRender;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var onChangeQuestion = match.onChangeQuestion;
  return React.createElement("div", undefined, React.createElement(SkilfulEndScreenContainer$EndScreenTableHeader, {}), Belt_Array.mapWithIndex(Belt_Array.make(match.questionCount, 1), (function (i, param) {
                    return React.createElement(SkilfulEndScreenContainer$EndScreenLink, {
                                detailRowRender: detailRowRender,
                                onChangeQuestion: onChangeQuestion,
                                i: i
                              });
                  })));
}

var EndScreenGrid = {
  make: SkilfulEndScreenContainer$EndScreenGrid
};

function SkilfulEndScreenContainer$EndScreenContent(Props) {
  var detailRowRender = Props.detailRowRender;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenContent.className
            }, React.createElement(SkilfulEndScreenContainer$EndScreenGrid, {
                  detailRowRender: detailRowRender
                }));
}

var EndScreenContent = {
  make: SkilfulEndScreenContainer$EndScreenContent
};

function detailRowRender(getStep, i) {
  var match = Curry._1(getStep, i);
  if (typeof match === "number") {
    return [
            "Not Attempted",
            null,
            "---"
          ];
  }
  if (match.TAG === /* Attempted */0) {
    return [
            "Attempted",
            null,
            "---"
          ];
  }
  var match$1 = match._0;
  if (!match$1) {
    return [
            "Attempted",
            null,
            ""
          ];
  }
  var questionCorrect = match$1._0.questionCorrect;
  if (!match._1) {
    if (questionCorrect) {
      return [
              "Not Attempted",
              React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.EndScreenCorrectIcon.className
                  }),
              "Correct"
            ];
    }
    if (!questionCorrect) {
      return [
              "Not Attempted",
              React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                    className: Skillet$LiteracyplanetTupperware.EndScreenIncorrectIcon.className
                  }),
              "Incorrect"
            ];
    }
    
  }
  if (questionCorrect) {
    return [
            "Attempted",
            React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.EndScreenCorrectIcon.className
                }),
            "Correct"
          ];
  } else if (questionCorrect) {
    return [
            "Attempted",
            null,
            ""
          ];
  } else {
    return [
            "Attempted",
            React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                  className: Skillet$LiteracyplanetTupperware.EndScreenIncorrectIcon.className
                }),
            "Incorrect"
          ];
  }
}

function SkilfulEndScreenContainer(Props) {
  var detailRowRenderOpt = Props.detailRowRender;
  var detailRowRender$1 = detailRowRenderOpt !== undefined ? detailRowRenderOpt : detailRowRender;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.EndScreenContainer.className
            }, React.createElement(SkilfulEndScreenContainer$EndScreenHeader, {}), React.createElement(SkilfulEndScreenContainer$EndScreenContent, {
                  detailRowRender: detailRowRender$1
                }), React.createElement(SkilfulEndScreenContainer$EndScreenFooter, {}));
}

var make = SkilfulEndScreenContainer;

export {
  Button ,
  MarkButton ,
  BehaviourMarkAllButton ,
  EndScreenHeader ,
  EndScreenFooter ,
  EndScreenQuestionIndex ,
  EndScreenPlayIcon ,
  EndScreenMarkingState ,
  EndScreenLink ,
  EndScreenTableHeader ,
  EndScreenGrid ,
  EndScreenContent ,
  detailRowRender ,
  make ,
  
}
/* react Not a pure module */
