// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as GameTypeData$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/GameTypeData.bs.js";

function getLayout(tupperware) {
  return tupperware.layout;
}

function formatAsBlock(block) {
  return "{\"document\":{\"nodes\":[" + block + "]}}";
}

function getContent(tupperware) {
  return formatAsBlock(tupperware.content);
}

function getTupperwareData(content) {
  if (typeof content !== "object") {
    return ;
  }
  if (content.NAME !== "TupperwareData") {
    return ;
  }
  var tupperware = content.VAL;
  return [
          formatAsBlock(tupperware.content),
          tupperware.layout
        ];
}

function isSpellTest(content) {
  if (typeof content === "object" && content.NAME === "WordGameData") {
    return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(content.VAL.kind, GameTypeData$LiteracyplanetClientFlags.findByGameId), (function (game) {
                      return game.activityType === /* TupperwareSpellTestActivity */1;
                    })), false);
  } else {
    return false;
  }
}

function isSkilfulLayout(content) {
  if (Belt_Option.getWithDefault(Belt_Option.map(getTupperwareData(content), (function (param) {
                return param[1] === "Skilful";
              })), false)) {
    return true;
  } else {
    return isSpellTest(content);
  }
}

function highestAnsweredQuestion(persistedState) {
  var highestQuestionIndex = function (qs) {
    return qs.reduce((function (qIdx, q) {
                  if (q.questionIndex > qIdx) {
                    return q.questionIndex;
                  } else {
                    return qIdx;
                  }
                }), 0);
  };
  var questionState = function (json) {
    return {
            questionIndex: Json_decode.field("questionIndex", Json_decode.$$int, json)
          };
  };
  return Belt_Option.mapWithDefault(persistedState, 1, (function (ps) {
                var tmp;
                try {
                  tmp = Json_decode.field("questionStates", (function (param) {
                          return Json_decode.array(questionState, param);
                        }), Json.parseOrRaise(ps));
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  console.log("deserialize error StateSerialization");
                  console.log(e);
                  tmp = [];
                }
                return highestQuestionIndex(tmp) + 1 | 0;
              }));
}

export {
  getLayout ,
  formatAsBlock ,
  getContent ,
  getTupperwareData ,
  isSpellTest ,
  isSkilfulLayout ,
  highestAnsweredQuestion ,
  
}
/* GameTypeData-LiteracyplanetClientFlags Not a pure module */
