// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as QueryAudiosContext$LiteracyplanetClientFlags from "./QueryAudiosContext.bs.js";

var state_soundIds = [];

var state_sources = [];

var state = {
  soundIds: state_soundIds,
  sources: state_sources,
  currentlyPlaying: undefined,
  queryForAudio: false
};

function initialApi_queueSound(param, param$1, param$2) {
  
}

function initialApi_unqueueSound(param, param$1) {
  
}

function initialApi_togglePlaySound(param, param$1, param$2) {
  
}

function initialApi_isPlaying(param, param$1) {
  return false;
}

function initialApi_getAudioSrc(param) {
  
}

var initialApi = {
  queueSound: initialApi_queueSound,
  unqueueSound: initialApi_unqueueSound,
  togglePlaySound: initialApi_togglePlaySound,
  isPlaying: initialApi_isPlaying,
  getAudioSrc: initialApi_getAudioSrc
};

var context = React.createContext(initialApi);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function SoundProvider(Props) {
  var children = Props.children;
  var queryAudios = Curry._1(QueryAudiosContext$LiteracyplanetClientFlags.use, undefined);
  var match = React.useState(function () {
        return state;
      });
  var setState = match[1];
  var state$1 = match[0];
  var value = React.useMemo(function () {
        return {
                queueSound: (function (instance, id, autoplay) {
                    return Curry._1(setState, (function (s) {
                                  return {
                                          soundIds: Belt_Option.isSome(Caml_option.undefined_to_opt(s.soundIds.find(function (idx) {
                                                        return id === idx;
                                                      }))) ? s.soundIds : Belt_Array.concat(s.soundIds, [id]),
                                          sources: s.sources,
                                          currentlyPlaying: autoplay ? [
                                              instance,
                                              id
                                            ] : s.currentlyPlaying,
                                          queryForAudio: s.queryForAudio
                                        };
                                }));
                  }),
                unqueueSound: (function (instance, id) {
                    return Curry._1(setState, (function (s) {
                                  return {
                                          soundIds: s.soundIds.filter(function (idx) {
                                                return id !== idx;
                                              }),
                                          sources: s.sources,
                                          currentlyPlaying: Caml_obj.caml_equal(state$1.currentlyPlaying, [
                                                instance,
                                                id
                                              ]) ? undefined : state$1.currentlyPlaying,
                                          queryForAudio: s.queryForAudio
                                        };
                                }));
                  }),
                togglePlaySound: (function (instance, id, param) {
                    if (Caml_obj.caml_equal(state$1.currentlyPlaying, [
                            instance,
                            id
                          ])) {
                      return Curry._1(setState, (function (s) {
                                    return {
                                            soundIds: s.soundIds,
                                            sources: s.sources,
                                            currentlyPlaying: undefined,
                                            queryForAudio: s.queryForAudio
                                          };
                                  }));
                    } else {
                      return Curry._1(setState, (function (s) {
                                    return {
                                            soundIds: s.soundIds,
                                            sources: s.sources,
                                            currentlyPlaying: [
                                              instance,
                                              id
                                            ],
                                            queryForAudio: s.queryForAudio
                                          };
                                  }));
                    }
                  }),
                isPlaying: (function (instance, id) {
                    return Caml_obj.caml_equal(state$1.currentlyPlaying, [
                                instance,
                                id
                              ]);
                  }),
                getAudioSrc: (function (id) {
                    return Belt_Option.flatMap(Caml_option.undefined_to_opt(state$1.sources.find(function (param) {
                                        return id === param[0];
                                      })), (function (param) {
                                  return param[1];
                                }));
                  })
              };
      });
  React.useEffect((function () {
          Curry._1(setState, (function (s) {
                  return {
                          soundIds: s.soundIds,
                          sources: s.sources,
                          currentlyPlaying: s.currentlyPlaying,
                          queryForAudio: true
                        };
                }));
          
        }), []);
  var setSources = React.useMemo(function () {
        return function (param) {
          return Curry._1(setState, (function (s) {
                        return {
                                soundIds: s.soundIds,
                                sources: param,
                                currentlyPlaying: s.currentlyPlaying,
                                queryForAudio: s.queryForAudio
                              };
                      }));
        };
      });
  return React.createElement(React.Fragment, undefined, state$1.queryForAudio ? Curry._2(queryAudios, state$1.soundIds, setSources) : null, React.createElement(make, makeProps(value, children, undefined)));
}

function useApi(param) {
  return React.useContext(context);
}

function use(instance, id, autoPlay) {
  var api = React.useContext(context);
  var isPlaying = Curry._2(api.isPlaying, instance, id);
  React.useEffect((function () {
          Curry._3(api.queueSound, instance, id, autoPlay);
          return (function (param) {
                    return Curry._2(api.unqueueSound, instance, id);
                  });
        }), [id]);
  React.useEffect((function () {
          if (autoPlay) {
            if (isPlaying) {
              
            } else {
              Curry._3(api.togglePlaySound, instance, id, undefined);
            }
          } else if (isPlaying) {
            Curry._3(api.togglePlaySound, instance, id, undefined);
          }
          
        }), [autoPlay]);
  return [
          isPlaying,
          Curry._2(api.togglePlaySound, instance, id),
          Curry._1(api.getAudioSrc, id)
        ];
}

var make$1 = SoundProvider;

export {
  state ,
  initialApi ,
  Context ,
  make$1 as make,
  useApi ,
  use ,
  
}
/* context Not a pure module */
