// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.bs.js";

function makeAction(questionIndex, responseOptionId, value) {
  return {
          TAG: /* SetQuestionState */2,
          _0: questionIndex,
          _1: {
            TAG: /* SetResponse */1,
            _0: {
              TAG: /* StrResponsePayload */1,
              _0: {
                TAG: /* SingleId */0,
                _0: responseOptionId
              },
              _1: value
            }
          }
        };
}

function use(questionIndex, responseOptionId) {
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch(undefined);
  var dispatch = match[1];
  var onChange = function ($$event) {
    var value = $$event.target.value;
    return Curry._1(dispatch, makeAction(questionIndex, responseOptionId, value));
  };
  var match$1 = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var value = Reducer$LiteracyplanetTupperware.getStepResponseValue(Curry._1(match$1.getStep, questionIndex), responseOptionId);
  return [
          onChange,
          value
        ];
}

export {
  makeAction ,
  use ,
  
}
/* V2ExerciseContext-LiteracyplanetTupperware Not a pure module */
