// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Error$LiteracyplanetClientErrors from "../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as NoMatch$LiteracyplanetClientSxEntry from "./NoMatch.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/LocaleContext.bs.js";
import * as CobraContainer$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/CobraContainer.bs.js";
import * as BackgroundChanger$LiteracyplanetClientSxEntry from "./BackgroundChanger.bs.js";
import * as DeepLinkMissionQuery$LiteracyplanetClientSxEntry from "./DeepLinkMissionQuery.bs.js";
import * as JourneyActivitiesScreen$LiteracyplanetClientSxEntry from "./JourneyActivitiesScreen.bs.js";
import * as StudentExperienceLayout$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceLayout.bs.js";
import * as DeepLinkActivityMutation$LiteracyplanetClientSxEntry from "./DeepLinkActivityMutation.bs.js";

function DeepLinkActivitiesScreen(Props) {
  var redirect = Props.redirect;
  var missionRef = Props.missionRef;
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  var api = LocaleContext$LiteracyplanetClientFlags.use(undefined);
  var usersLocale = api.locale;
  var localeString = api.localeString;
  var deeplinkState = DeepLinkMissionQuery$LiteracyplanetClientSxEntry.use(userId, missionRef, localeString);
  var match = BackgroundChanger$LiteracyplanetClientSxEntry.use(undefined);
  var setBackground = match[1];
  var match$1 = DeepLinkActivityMutation$LiteracyplanetClientSxEntry.use(usersLocale);
  var assignData = match$1[1];
  var assign = match$1[0];
  var match$2 = Error$LiteracyplanetClientErrors.Consumer.use(undefined);
  var onSetError = match$2.onSetError;
  var match$3 = Curry._1(StudentExperienceLayout$LiteracyplanetClientSxEntry.Context.use, undefined);
  React.useEffect((function () {
          Belt_Option.map(assignData, (function (param) {
                  var match = param.assignMissionSynchronous;
                  return Curry._1(redirect, {
                              TAG: /* JourneyActivity */5,
                              _0: api.localeString,
                              _1: api.accentString,
                              _2: match.studentMissionId.toString(),
                              _3: match.studentActivityId.toString(),
                              _4: match.isAssessment
                            });
                }));
          
        }), [assignData]);
  if (typeof deeplinkState === "number") {
    if (deeplinkState === /* LoadingMission */0) {
      return null;
    }
    Curry._1(match$3.setLayout, /* AuthWithMenu */0);
    Curry._1(match$3.setBackground, /* VoidBackground */2);
    return React.createElement(NoMatch$LiteracyplanetClientSxEntry.make, {
                children: "Sorry, we can't find any matching activities!"
              });
  } else {
    if (deeplinkState.TAG === /* CurrentStudentMission */0) {
      return React.createElement(JourneyActivitiesScreen$LiteracyplanetClientSxEntry.make, {
                  redirect: redirect,
                  studentMissionId: deeplinkState._0
                });
    }
    var onDispatch = {
      onJourneyActivitySelected: (function (texasActivityRef) {
          return Curry._3(assign, userId, missionRef, texasActivityRef);
        }),
      onExit: (function (param) {
          return Curry._1(redirect, /* JourneyMissions */10);
        }),
      onPreloaderReady: (function (c) {
          return Curry._1(setBackground, (function (param) {
                        return c.backgroundColor;
                      }));
        }),
      onJourneyReady: (function (c) {
          return Curry._1(setBackground, (function (param) {
                        return c.backgroundColor;
                      }));
        }),
      onFailed: (function (err) {
          return Curry._1(onSetError, Error$LiteracyplanetClientErrors.JsInterop.makeError(/* JourneyActivitiesError */1, err));
        })
    };
    return React.createElement("div", {
                style: match[0]
              }, React.createElement(CobraContainer$LiteracyplanetClientFlags.make, {
                    view: "jas",
                    props: deeplinkState._0,
                    onDispatch: onDispatch
                  }));
  }
}

var make = DeepLinkActivitiesScreen;

export {
  make ,
  
}
/* react Not a pure module */
