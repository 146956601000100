// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Uuid$LiteracyplanetTupperware from "../Uuid.bs.js";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as PlayAudioIcon$LiteracyplanetTupperware from "./PlayAudioIcon.bs.js";
import * as AudioButtonApi$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/AudioButtonApi.bs.js";
import * as SoundProviderEmbedded$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/SoundProviderEmbedded.bs.js";

function AudioEmbeddedComponent$ButtonContainer(Props) {
  var onTogglePlay = Props.onTogglePlay;
  var children = Props.children;
  return React.createElement("div", {
              className: "AudioQueryComponentButtonContainer",
              onClick: (function (param) {
                  return Curry._1(onTogglePlay, undefined);
                })
            }, children);
}

var ButtonContainer = {
  make: AudioEmbeddedComponent$ButtonContainer
};

var className = Flavor$LiteracyplanetTupperware.AudioQueryComponent.className;

function AudioEmbeddedComponent(Props) {
  var audioId = Props.audioId;
  var sources = Props.sources;
  var autoPlay = Props.autoPlay;
  var instance = React.useMemo((function () {
          return Uuid$LiteracyplanetTupperware.make(undefined);
        }), [audioId]);
  var match = SoundProviderEmbedded$LiteracyplanetClientFlags.use(instance, audioId, autoPlay, sources);
  var onTogglePlay = match[1];
  return React.createElement(AudioButtonApi$LiteracyplanetClientFlags.make, {
              isPlaying: match[0],
              src: match[2],
              onEnd: onTogglePlay,
              onTogglePlay: onTogglePlay,
              children: (function (state, onTogglePlay) {
                  return React.createElement(AudioEmbeddedComponent$ButtonContainer, {
                              onTogglePlay: onTogglePlay,
                              children: React.createElement(PlayAudioIcon$LiteracyplanetTupperware.make, {
                                    className: className,
                                    state: state
                                  })
                            });
                })
            });
}

var make = AudioEmbeddedComponent;

export {
  ButtonContainer ,
  className ,
  make ,
  
}
/* react Not a pure module */
