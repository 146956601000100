// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as AudiosQuery$LiteracyplanetClientFlags from "./AudiosQuery.bs.js";

function QueryAudios(Props) {
  var ids = Props.ids;
  var setSources = Props.setSources;
  var s = AudiosQuery$LiteracyplanetClientFlags.useQuery(ids);
  React.useEffect((function () {
          if (s) {
            Curry._1(setSources, s._0);
          }
          
        }), [s]);
  return null;
}

var make = QueryAudios;

export {
  make ,
  
}
/* react Not a pure module */
