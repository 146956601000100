// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";

function parseVocal(param) {
  var name = param.name;
  return {
          id: name,
          name: name,
          audio: {
            source: param.source
          }
        };
}

function matchLocale(l, i) {
  if (i.locale === l.locale) {
    return i.accent === l.accent;
  } else {
    return false;
  }
}

function parse(cobraActivity, localeCtx) {
  return Belt_Array.map(cobraActivity.gameInstructions.filter(function (param) {
                  return matchLocale(localeCtx, param);
                }), parseVocal);
}

var GameInstructions = {
  parseVocal: parseVocal,
  matchLocale: matchLocale,
  parse: parse
};

export {
  GameInstructions ,
  
}
/* No side effect */
