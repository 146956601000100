// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";

function useTriggerAfterTimeout(dependenciesOpt, test, timeout, timeoutTriggeredCallback) {
  var dependencies = dependenciesOpt !== undefined ? dependenciesOpt : [];
  React.useEffect((function () {
          var startTimer = function (param) {
            return setTimeout((function (param) {
                          if (Curry._1(test, undefined)) {
                            return Curry._1(timeoutTriggeredCallback, undefined);
                          }
                          
                        }), timeout);
          };
          var id = Curry._1(test, undefined) ? Caml_option.some(startTimer(undefined)) : undefined;
          return Belt_Option.flatMap(id, (function (id) {
                        return (function (param) {
                                  clearTimeout(id);
                                  
                                });
                      }));
        }), dependencies);
  
}

function seconds(sec) {
  return Math.imul(sec, 1000);
}

export {
  useTriggerAfterTimeout ,
  seconds ,
  
}
/* react Not a pure module */
