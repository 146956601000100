// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Result from "../../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/QueryHook.bs.js";
import * as UserContext$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/UserContext.bs.js";
import * as JourneyCurrentStudent$LiteracyplanetClientSxEntry from "../JourneyCurrentStudent.bs.js";

var Raw = {};

var query = Client.gql([
      "query checksLobbyQuery($userId: String!)  {\nchecks: literacy_checks_outstanding_activities  {\n__typename  \nactivityId  \ncontentId  \ngameId  \ncontentKind  \nmissionName  \nstrand  \nactivityName  \nactivityComplete  \nassignationId  \nstudentActivityAttemptId  \n}\n\ncurrentStudent(userId: $userId)  {\n...JourneyCurrentStudentFragment   \n}\n\n}\n",
      ""
    ], JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.query);

function parse(value) {
  var value$1 = value.checks;
  var value$2 = value.currentStudent;
  return {
          checks: value$1.map(function (value) {
                var value$1 = value.activityId;
                var value$2 = value.contentId;
                var value$3 = value.gameId;
                var value$4 = value.contentKind;
                var value$5 = value.missionName;
                var value$6 = value.strand;
                var value$7 = value.activityName;
                var value$8 = value.activityComplete;
                var value$9 = value.assignationId;
                var value$10 = value.studentActivityAttemptId;
                return {
                        __typename: value.__typename,
                        activityId: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                        contentId: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                        gameId: !(value$3 == null) ? value$3 : undefined,
                        contentKind: !(value$4 == null) ? value$4 : undefined,
                        missionName: !(value$5 == null) ? value$5 : undefined,
                        strand: !(value$6 == null) ? value$6 : undefined,
                        activityName: !(value$7 == null) ? value$7 : undefined,
                        activityComplete: !(value$8 == null) ? value$8 : undefined,
                        assignationId: !(value$9 == null) ? Caml_option.some(value$9) : undefined,
                        studentActivityAttemptId: !(value$10 == null) ? Caml_option.some(value$10) : undefined
                      };
              }),
          currentStudent: !(value$2 == null) ? JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.verifyArgsAndParse("JourneyCurrentStudentFragment", value$2) : undefined
        };
}

function serialize(value) {
  var value$1 = value.currentStudent;
  var currentStudent = value$1 !== undefined ? JourneyCurrentStudent$LiteracyplanetClientSxEntry.JourneyCurrentStudentFragment.serialize(value$1) : null;
  var value$2 = value.checks;
  var checks = value$2.map(function (value) {
        var value$1 = value.studentActivityAttemptId;
        var studentActivityAttemptId = value$1 !== undefined ? Caml_option.valFromOption(value$1) : null;
        var value$2 = value.assignationId;
        var assignationId = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
        var value$3 = value.activityComplete;
        var activityComplete = value$3 !== undefined ? value$3 : null;
        var value$4 = value.activityName;
        var activityName = value$4 !== undefined ? value$4 : null;
        var value$5 = value.strand;
        var strand = value$5 !== undefined ? value$5 : null;
        var value$6 = value.missionName;
        var missionName = value$6 !== undefined ? value$6 : null;
        var value$7 = value.contentKind;
        var contentKind = value$7 !== undefined ? value$7 : null;
        var value$8 = value.gameId;
        var gameId = value$8 !== undefined ? value$8 : null;
        var value$9 = value.contentId;
        var contentId = value$9 !== undefined ? Caml_option.valFromOption(value$9) : null;
        var value$10 = value.activityId;
        var activityId = value$10 !== undefined ? Caml_option.valFromOption(value$10) : null;
        var value$11 = value.__typename;
        return {
                __typename: value$11,
                activityId: activityId,
                contentId: contentId,
                gameId: gameId,
                contentKind: contentKind,
                missionName: missionName,
                strand: strand,
                activityName: activityName,
                activityComplete: activityComplete,
                assignationId: assignationId,
                studentActivityAttemptId: studentActivityAttemptId
              };
      });
  return {
          checks: checks,
          currentStudent: currentStudent
        };
}

function serializeVariables(inp) {
  return {
          userId: inp.userId
        };
}

function makeVariables(userId, param) {
  return {
          userId: userId
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function onLoading(param) {
  return /* LoadingChecksLobby */0;
}

function handleResponse(res) {
  return /* OutstandingChecks */{
          _0: res
        };
}

function onResult(query) {
  return Belt_Result.map(query, handleResponse);
}

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function useQuery(skip, userId) {
  return Curry._5(Q.useSkippableWithErrorModal, onLoading, onLoading, onResult, skip, {
              userId: userId
            });
}

function use$1(skip) {
  var user = UserContext$LiteracyplanetClientFlags.use(undefined);
  var userId = user.userId;
  return useQuery(skip, userId);
}

var JourneyCurrentStudentFragment;

export {
  JourneyCurrentStudentFragment ,
  Query ,
  onLoading ,
  handleResponse ,
  onResult ,
  Q ,
  useQuery ,
  use$1 as use,
  
}
/* query Not a pure module */
