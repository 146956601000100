// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Routes$LiteracyplanetClientSxEntry from "./Routes.bs.js";
import * as Locking$LiteracyplanetClientSxEntry from "./Locking.bs.js";
import * as FlagsContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/FlagsContext.bs.js";
import * as LockingQuery$LiteracyplanetClientSxEntry from "./LockingQuery.bs.js";
import * as StudentExperienceWrap$LiteracyplanetClientSxEntry from "./layouts/StudentExperienceWrap.bs.js";

function LockingProvider$Lock(Props) {
  var locked = Props.locked;
  var r = Props.r;
  var page = Props.page;
  var children = Props.children;
  var isPageBlacklisted = function (param) {
    return Locking$LiteracyplanetClientSxEntry.isPageBlacklisted(locked, param);
  };
  var isItemBlacklisted = function (param) {
    return Locking$LiteracyplanetClientSxEntry.isItemBlacklisted(locked, param);
  };
  var redirectToMissions = StudentExperienceWrap$LiteracyplanetClientSxEntry.useStudentRedirectToMissions(r, isPageBlacklisted);
  var match = Locking$LiteracyplanetClientSxEntry.use(locked, page, redirectToMissions, r);
  return Curry._5(children, match[0], match[1], match[2], isPageBlacklisted, isItemBlacklisted);
}

var Lock = {
  make: LockingProvider$Lock
};

function LockingProvider(Props) {
  var redirect = Props.redirect;
  var page = Props.page;
  var children = Props.children;
  var hasFlag = FlagsContext$LiteracyplanetClientFlags.use(undefined);
  var hasLockedFeature = Curry._1(hasFlag, "StudentHasLocking");
  var pagePath = Routes$LiteracyplanetClientSxEntry.pageToUrl(page);
  var state = LockingQuery$LiteracyplanetClientSxEntry.use(pagePath);
  var match = React.useState(function () {
        return false;
      });
  var setLocked = match[1];
  React.useEffect((function () {
          if (hasLockedFeature && state) {
            var locked = state._0;
            Curry._1(setLocked, (function (param) {
                    return locked;
                  }));
          }
          
        }), [
        hasLockedFeature,
        state
      ]);
  return React.createElement(LockingProvider$Lock, {
              locked: match[0],
              r: redirect,
              page: page,
              children: children
            });
}

var make = LockingProvider;

export {
  Lock ,
  make ,
  
}
/* react Not a pure module */
