// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import ReactHowler from "react-howler";
import * as Retry$LiteracyplanetClientFlags from "./Retry.bs.js";

var ReactHowler$1 = {};

function MemoPlay$Memo(Props) {
  var onLoad = Props.onLoad;
  var onPlay = Props.onPlay;
  var src = Props.src;
  var playing = Props.playing;
  var onEnd = Props.onEnd;
  var onLoadError = Props.onLoadError;
  return React.useMemo((function () {
                return React.createElement(ReactHowler, {
                            src: src,
                            playing: playing,
                            onEnd: onEnd,
                            onLoadError: onLoadError,
                            onLoad: onLoad,
                            onPlay: onPlay
                          });
              }), [
              src,
              playing
            ]);
}

var Memo = {
  make: MemoPlay$Memo
};

function MemoPlay(Props) {
  var src = Props.src;
  var onEnd = Props.onEnd;
  var onLoaded = Props.onLoaded;
  var onPlaying = Props.onPlaying;
  var playingOpt = Props.playing;
  var playing = playingOpt !== undefined ? playingOpt : true;
  var match = Retry$LiteracyplanetClientFlags.use(undefined);
  var onLoad = onLoaded !== undefined ? onLoaded : (function (param) {
        
      });
  var onPlay = onPlaying !== undefined ? onPlaying : (function (param) {
        
      });
  if (match[0]) {
    return React.createElement(MemoPlay$Memo, {
                onLoad: onLoad,
                onPlay: onPlay,
                src: src,
                playing: playing,
                onEnd: onEnd,
                onLoadError: match[1]
              });
  } else {
    return null;
  }
}

var make = MemoPlay;

export {
  ReactHowler$1 as ReactHowler,
  Memo ,
  make ,
  
}
/* react Not a pure module */
