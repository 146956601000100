// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2EventsContext$LiteracyplanetClientLibsActivity from "../../../../../node_modules/@literacyplanet/client-libs-activity/src/v2/V2EventsContext.bs.js";

var use = V2EventsContext$LiteracyplanetClientLibsActivity.use;

export {
  use ,
  
}
/* V2EventsContext-LiteracyplanetClientLibsActivity Not a pure module */
