// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactPdf from "react-pdf";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as BookContext$LiteracyplanetTupperware from "./BookContext.bs.js";
import * as BookSpinner$LiteracyplanetTupperware from "./BookSpinner.bs.js";
import * as ArrowLeftIcon$LiteracyplanetTupperware from "./ArrowLeftIcon.bs.js";
import * as NextPageButton$LiteracyplanetTupperware from "./NextPageButton.bs.js";
import * as OpenBookButton$LiteracyplanetTupperware from "./OpenBookButton.bs.js";
import * as PrevPageButton$LiteracyplanetTupperware from "./PrevPageButton.bs.js";
import * as CloseBookButton$LiteracyplanetTupperware from "./CloseBookButton.bs.js";
import * as FullScreenButton$LiteracyplanetTupperware from "./FullScreenButton.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as BehaviourExitButton$LiteracyplanetTupperware from "./BehaviourExitButton.bs.js";
import * as PlayBookAudioButton$LiteracyplanetTupperware from "./PlayBookAudioButton.bs.js";
import * as ContainerDimensions$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/ContainerDimensions.bs.js";
import * as ClickWordToHearContainer$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/ClickWordToHearContainer.bs.js";

function K2BookQuestions$Question(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.Question.className
            }, children);
}

var Question = {
  make: K2BookQuestions$Question
};

function K2BookQuestions$QuestionContent(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.QuestionContent.className
            }, children);
}

var QuestionContent = {
  make: K2BookQuestions$QuestionContent
};

function K2BookQuestions$NoAudioButton(Props) {
  return React.createElement("div", undefined);
}

var NoAudioButton = {
  make: K2BookQuestions$NoAudioButton
};

function usePageLinks(pages, onClickBack, page, changePage) {
  var match = React.useState(function () {
        return 0;
      });
  var setNumPages = match[1];
  var numPages = match[0];
  var previousButton = page > 0 ? React.createElement(PrevPageButton$LiteracyplanetTupperware.make, {
          onClick: (function (param) {
              return Curry._1(changePage, -1);
            })
        }) : null;
  var nextButton = page === (numPages - 1 | 0) ? React.createElement(NextPageButton$LiteracyplanetTupperware.make, {
          finishedBook: true,
          onClick: (function (param) {
              return Curry._1(onClickBack, undefined);
            })
        }) : (
      page < (numPages - 1 | 0) ? React.createElement(NextPageButton$LiteracyplanetTupperware.make, {
              finishedBook: false,
              onClick: (function (param) {
                  return Curry._1(changePage, 1);
                })
            }) : null
    );
  var src = Belt_Option.flatMap(Caml_option.undefined_to_opt(pages.find(function (x) {
                return x.pageIndex === page;
              })), (function (param) {
          return param.mp3;
        }));
  var playButton = src !== undefined ? React.createElement(PlayBookAudioButton$LiteracyplanetTupperware.make, {
          src: src
        }) : React.createElement(K2BookQuestions$NoAudioButton, {});
  var onDocumentLoadSuccess = function (success) {
    Curry._1(setNumPages, (function (param) {
            return success.numPages;
          }));
    return Curry._1(changePage, 0);
  };
  var pageNumber = page + 1 | 0;
  return [
          previousButton,
          nextButton,
          playButton,
          pageNumber,
          onDocumentLoadSuccess
        ];
}

function K2BookQuestions$BookPdf(Props) {
  var onClickBack = Props.onClickBack;
  var height = Props.height;
  var book = Props.book;
  var page = Props.page;
  var changePage = Props.changePage;
  var match = usePageLinks(book.pages, onClickBack, page, changePage);
  return React.createElement(React.Fragment, undefined, match[0], React.createElement("div", {
                  className: Flavor$LiteracyplanetTupperware.K2BookQuestions.BookPdf.className
                }, React.createElement(ReactPdf.Document, {
                      file: book.pdf,
                      onLoadSuccess: match[4],
                      loading: (function (param) {
                          return React.createElement(BookSpinner$LiteracyplanetTupperware.make, {});
                        }),
                      children: React.createElement(ClickWordToHearContainer$LiteracyplanetClientFlags.make, {
                            children: React.createElement(ReactPdf.Page, {
                                  pageNumber: match[3],
                                  height: height,
                                  width: undefined,
                                  renderMode: "canvas",
                                  renderTextLayer: true
                                })
                          })
                    })), match[1], match[2]);
}

var BookPdf = {
  usePageLinks: usePageLinks,
  make: K2BookQuestions$BookPdf
};

function K2BookQuestions$AutoSizingBookPdf(Props) {
  var onClickBack = Props.onClickBack;
  var book = Props.book;
  var page = Props.page;
  var changePage = Props.changePage;
  var match = ContainerDimensions$LiteracyplanetClientFlags.useWidth(undefined);
  var width = match[1];
  var pdf = width !== undefined ? React.createElement(K2BookQuestions$BookPdf, {
          onClickBack: onClickBack,
          height: width | 0,
          book: book,
          page: page,
          changePage: changePage
        }) : null;
  return React.createElement("div", {
              ref: match[0],
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.AutoSizingBookPdf.className
            }, pdf);
}

var AutoSizingBookPdf = {
  make: K2BookQuestions$AutoSizingBookPdf
};

function K2BookQuestions$BookContent(Props) {
  var onClickBack = Props.onClickBack;
  var book = Props.book;
  var page = Props.page;
  var changePage = Props.changePage;
  var w = Props.w;
  var h = Props.h;
  return React.createElement("div", {
              className: Curry._2(Flavor$LiteracyplanetTupperware.K2BookQuestions.BookContent.className, w, h)
            }, React.createElement(K2BookQuestions$AutoSizingBookPdf, {
                  onClickBack: onClickBack,
                  book: book,
                  page: page,
                  changePage: changePage
                }));
}

var BookContent = {
  make: K2BookQuestions$BookContent
};

function K2BookQuestions$QuestionContentStyling(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.QuestionContentStyling.className
            }, children);
}

var QuestionContentStyling = {
  make: K2BookQuestions$QuestionContentStyling
};

function K2BookQuestions$BehaviourTabs(Props) {
  var book = Props.book;
  var page = Props.page;
  var changePage = Props.changePage;
  var toggleFullscreen = Props.toggleFullscreen;
  var children = Props.children;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var onSetQuestionTab = match.onSetQuestionTab;
  if (match.questionTab) {
    return React.createElement(React.Fragment, undefined, React.createElement(FullScreenButton$LiteracyplanetTupperware.make, {
                    onClick: (function (param) {
                        return Curry._1(toggleFullscreen, undefined);
                      })
                  }), React.createElement(K2BookQuestions$QuestionContentStyling, {
                    children: React.createElement("div", {
                          className: Flavor$LiteracyplanetTupperware.K2BookQuestions.BehaviourTabs.className
                        }, React.createElement(K2BookQuestions$BookContent, {
                              onClickBack: (function (param) {
                                  return Curry._1(onSetQuestionTab, /* QuestionTab */0);
                                }),
                              book: book,
                              page: page,
                              changePage: changePage,
                              w: 480,
                              h: 560
                            }))
                  }), React.createElement(CloseBookButton$LiteracyplanetTupperware.make, {
                    onClick: (function (param) {
                        return Curry._1(onSetQuestionTab, /* QuestionTab */0);
                      })
                  }));
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(OpenBookButton$LiteracyplanetTupperware.make, {
                    onClick: (function (param) {
                        return Curry._1(onSetQuestionTab, /* BookStimulusTab */1);
                      })
                  }), React.createElement(K2BookQuestions$QuestionContentStyling, {
                    children: React.createElement(K2BookQuestions$QuestionContent, {
                          children: children
                        })
                  }));
  }
}

var BehaviourTabs = {
  make: K2BookQuestions$BehaviourTabs
};

function K2BookQuestions$MainNav(Props) {
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.K2BookQuestions.MainNav.className
            }, React.createElement(BehaviourExitButton$LiteracyplanetTupperware.make, {
                  children: React.createElement(ArrowLeftIcon$LiteracyplanetTupperware.make, {
                        className: Flavor$LiteracyplanetTupperware.K2BookQuestions.ArrowLeftIcon.className
                      })
                }));
}

var MainNav = {
  make: K2BookQuestions$MainNav
};

function K2BookQuestions$QuestionsTab(Props) {
  var page = Props.page;
  var changePage = Props.changePage;
  var book = Props.book;
  var toggleFullscreen = Props.toggleFullscreen;
  var children = Props.children;
  return React.createElement(K2BookQuestions$Question, {
              children: null
            }, React.createElement(K2BookQuestions$MainNav, {}), React.createElement(K2BookQuestions$BehaviourTabs, {
                  book: book,
                  page: page,
                  changePage: changePage,
                  toggleFullscreen: toggleFullscreen,
                  children: children
                }));
}

var QuestionsTab = {
  make: K2BookQuestions$QuestionsTab
};

function K2BookQuestions$NoStimuliQuestionsTab(Props) {
  var children = Props.children;
  return React.createElement(K2BookQuestions$Question, {
              children: null
            }, React.createElement(K2BookQuestions$MainNav, {}), React.createElement(K2BookQuestions$QuestionContentStyling, {
                  children: React.createElement(K2BookQuestions$QuestionContent, {
                        children: children
                      })
                }));
}

var NoStimuliQuestionsTab = {
  make: K2BookQuestions$NoStimuliQuestionsTab
};

function K2BookQuestions(Props) {
  var children = Props.children;
  return React.createElement(BookContext$LiteracyplanetTupperware.Consumer.make, {
              children: (function (book, page, changePage, toggleFullscreen, stimuliPresent) {
                  if (stimuliPresent) {
                    if (book !== undefined) {
                      return React.createElement(K2BookQuestions$QuestionsTab, {
                                  page: page,
                                  changePage: changePage,
                                  book: book,
                                  toggleFullscreen: toggleFullscreen,
                                  children: children
                                });
                    } else {
                      return null;
                    }
                  } else {
                    return React.createElement(K2BookQuestions$NoStimuliQuestionsTab, {
                                children: children
                              });
                  }
                })
            });
}

var make = K2BookQuestions;

var $$default = K2BookQuestions;

export {
  Question ,
  QuestionContent ,
  NoAudioButton ,
  BookPdf ,
  AutoSizingBookPdf ,
  BookContent ,
  QuestionContentStyling ,
  BehaviourTabs ,
  MainNav ,
  QuestionsTab ,
  NoStimuliQuestionsTab ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
