// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";

function K2AnswerWraps$Answer(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2AnswerWrap.Answer.className, className)
            }, children);
}

var Answer = {
  make: K2AnswerWraps$Answer
};

function K2AnswerWraps$Box(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2AnswerWrap.Box.className, className)
            }, children);
}

var Box = {
  make: K2AnswerWraps$Box
};

export {
  Answer ,
  Box ,
  
}
/* react Not a pure module */
