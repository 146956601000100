// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as AudioQueryComponent$LiteracyplanetTupperware from "./AudioQueryComponent.bs.js";
import * as AudioEmbeddedComponent$LiteracyplanetTupperware from "./AudioEmbeddedComponent.bs.js";

function AudioQuestionContainer(Props) {
  var questionIndex = Props.questionIndex;
  var audioId = Props.audioId;
  var sources = Props.sources;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var isQuestionVisible = match.isQuestionVisible;
  if (sources !== undefined) {
    return React.createElement(AudioEmbeddedComponent$LiteracyplanetTupperware.make, {
                audioId: audioId,
                sources: sources,
                autoPlay: Curry._1(isQuestionVisible, questionIndex)
              });
  } else {
    return React.createElement(AudioQueryComponent$LiteracyplanetTupperware.make, {
                audioId: audioId,
                autoPlay: Curry._1(isQuestionVisible, questionIndex)
              });
  }
}

var make = AudioQuestionContainer;

var $$default = AudioQuestionContainer;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
