// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PlayIcon(Props) {
  var className = Props.className;
  var playClassName = Props.playClassName;
  var backgroundClassName = Props.backgroundClassName;
  return React.createElement("svg", {
              className: className,
              version: "1.1",
              viewBox: "0 0 40 40",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  transform: "translate(-1014.000000, -379.000000)"
                }, React.createElement("g", {
                      transform: "translate(1014.000000, 379.000000)"
                    }, React.createElement("path", {
                          className: playClassName,
                          d: "M40,20 C40,31.0285714 31.0285714,40 20,40 C8.97142857,40 0,31.0285714 0,20 C0,8.97142857 8.97142857,0 20,0 C31.0285714,0 40,8.97142857 40,20 Z"
                        }), React.createElement("path", {
                          className: backgroundClassName,
                          d: "M20,2 C29.95625,2 38,10.04375 38,20 C38,29.95625 29.95625,38 20,38 C10.04375,38 2,29.95625 2,20 C2,10.04375 10.04375,2 20,2 Z"
                        }), React.createElement("path", {
                          className: playClassName,
                          d: "M27.2854442,18.7641149 C27.6030246,18.9272882 27.8147448,19.1992438 27.9206049,19.5255904 C28.026465,19.851937 28.026465,20.2326748 27.9206049,20.5046303 C27.8147448,20.8309769 27.6030246,21.1029325 27.2854442,21.2661058 L16.4877127,27.7930385 C16.1701323,27.9562118 15.852552,28.064994 15.4820416,27.9562118 C15.1644612,27.9018207 14.8468809,27.7386474 14.5822306,27.4666919 C14.3705104,27.1947363 14,26.7596075 14,26.3788697 L14,13.651351 C14,13.2162221 14.3175803,12.7810933 14.5822306,12.5091377 C14.7939509,12.2371822 15.1115312,12.0740089 15.4820416,12.0196178 C15.7996219,11.9652267 16.1701323,12.0196178 16.4347826,12.2371822 L27.2854442,18.7641149 Z"
                        }))));
}

var make = PlayIcon;

export {
  make ,
  
}
/* react Not a pure module */
