// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Pdf$LiteracyplanetClientSxLibrary from "../../../../../node_modules/@literacyplanet/client-sx-library/src/Pdf.bs.js";

function api_changePage(param) {
  
}

function api_toggleFullscreen(param) {
  
}

var api = {
  book: undefined,
  page: 0,
  changePage: api_changePage,
  toggleFullscreen: api_toggleFullscreen,
  stimuliPresent: false
};

var context = React.createContext(api);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  context: context,
  makeProps: makeProps,
  make: make
};

function BookContext$Provider(Props) {
  var book = Props.book;
  var toggleFullscreen = Props.toggleFullscreen;
  var children = Props.children;
  var stimuliPresent = Props.stimuliPresent;
  var match = Pdf$LiteracyplanetClientSxLibrary.usePages(undefined);
  var changePage = match[1];
  var page = match[0];
  var value = React.useMemo((function () {
          return {
                  book: book,
                  page: page,
                  changePage: changePage,
                  toggleFullscreen: toggleFullscreen,
                  stimuliPresent: stimuliPresent
                };
        }), [
        stimuliPresent,
        book,
        page
      ]);
  return React.createElement(make, makeProps(value, children, undefined));
}

var Provider = {
  make: BookContext$Provider
};

function useApi(param) {
  return React.useContext(context);
}

function use(param) {
  var api = React.useContext(context);
  return [
          api.book,
          api.page,
          api.changePage,
          api.toggleFullscreen,
          api.stimuliPresent
        ];
}

function BookContext$Consumer(Props) {
  var children = Props.children;
  var match = use(undefined);
  return Curry._5(children, match[0], match[1], match[2], match[3], match[4]);
}

var Consumer = {
  make: BookContext$Consumer
};

export {
  api ,
  Context ,
  Provider ,
  useApi ,
  use ,
  Consumer ,
  
}
/* context Not a pure module */
