// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function SecondaryExitIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              version: "1.1",
              viewBox: "0 0 35 35",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  transform: "translate(-1365.000000, -23.000000)"
                }, React.createElement("g", {
                      transform: "translate(1313.000000, 15.500000)"
                    }, React.createElement("g", {
                          transform: "translate(52.000000, 7.500000)"
                        }, React.createElement("path", {
                              id: "Shape",
                              d: "M21.9365234,23.6479093 L21.9296875,23.6479093 L24.6640625,23.6479093 L24.6640625,30.8968347 C24.6640625,33.1604142 22.8251953,35 20.5625,35 L4.1015625,35 C1.83886719,35 0,33.1604142 0,30.8968347 L0,4.1031653 C0,1.83958578 1.83886719,0 4.1015625,0 L20.5693359,0 C22.8320313,0 24.6708984,1.83958578 24.6708984,4.1031653 L24.6708984,11.625635 L21.9365234,11.625635 C21.9365234,11.625635 13.5078125,11.5367331 12.4824219,11.5367331 C11.4570313,11.5367331 9.41992188,11.8102775 9.41992188,14.4910121 C9.41992188,17.1717468 9.41992188,19.3669402 9.41992188,20.5978898 C9.41992188,21.835678 9.41308594,23.6684252 12.2431641,23.6684252 C15.0732422,23.6684252 21.9365234,23.6479093 21.9365234,23.6479093 Z M28.5605469,11.0511919 L26.6259766,12.9865182 L29.7705078,16.1322782 L12.0927734,16.1322782 L12.0927734,18.8677218 L29.7705078,18.8677218 L26.6259766,22.0134818 L28.5605469,23.9488081 L35,17.5068386 L28.5605469,11.0511919 Z"
                            })))));
}

var make = SecondaryExitIcon;

export {
  make ,
  
}
/* react Not a pure module */
