// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import PrimaryNavIconWordManiaPng from "./assets/PrimaryNavIcon-wordMania.png";

function SecondaryHeaderIcons$Missions(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 120 120",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  id: "Mission"
                }, React.createElement("path", {
                      d: "M69.66,13l0,0c-.12.11-.27.2-.39.32-1.11.87-2.2,1.78-3.3,2.65C48.69,30.46,35,49,24.16,70.85l24,24c21.7-10.78,40.14-24.4,54.55-41.49,1.11-1.26,2.16-2.56,3.19-3.91l.09-.08C114,36.08,115,4.09,115,4.09S83,5,69.66,13ZM89.21,46.81a11.09,11.09,0,1,1,.06-.05l0,.05Z"
                    }), React.createElement("path", {
                      d: "M80,78.51A173.92,173.92,0,0,1,57.64,93a16.48,16.48,0,0,1-1.47,4.75l-7.36,15.09A1.47,1.47,0,0,0,51,114.68l12-8.21a39.46,39.46,0,0,0,17.08-28Z"
                    }), React.createElement("path", {
                      d: "M40.43,39A39.66,39.66,0,0,0,12.48,56L4.27,68a1.51,1.51,0,0,0,1.9,2.21l15.11-7.35A16.15,16.15,0,0,1,26,61.32,187,187,0,0,1,40.43,39Z"
                    }), React.createElement("path", {
                      d: "M25.65,78.8c-2.56-.43-6.87-.21-10.34,4.83C10,91.26,10,108.12,4.56,113.59c5.47-5.47,22.33-5.43,30-10.72,5-3.49,5.24-7.81,4.81-10.37l-2.79-2.79a5,5,0,0,1-1.67,1.85C31.62,93.84,24.34,93.81,22,96.19c2.37-2.37,2.34-9.66,4.61-13a5,5,0,0,1,1.85-1.68Z"
                    })));
}

var Missions = {
  make: SecondaryHeaderIcons$Missions
};

function SecondaryHeaderIcons$Collections(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 120 120",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  id: "Collections"
                }, React.createElement("rect", {
                      height: "8.21",
                      width: "60.24",
                      x: "29.38",
                      y: "11.33"
                    }), React.createElement("rect", {
                      height: "10.95",
                      width: "87.62",
                      x: "15.69",
                      y: "25.02"
                    }), React.createElement("path", {
                      d: "M2,41.44V109.9H117V41.44ZM83.68,92.35a7.58,7.58,0,0,1-7.56,7.55H42.88a7.58,7.58,0,0,1-7.55-7.55V59.1a7.59,7.59,0,0,1,7.55-7.55H76.12a7.59,7.59,0,0,1,7.56,7.56Z"
                    })));
}

var Collections = {
  make: SecondaryHeaderIcons$Collections
};

function SecondaryHeaderIcons$Avatar(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 120 120",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  id: "Avatar"
                }, React.createElement("path", {
                      d: "M111.12,99.44c-.43-.14-28.67-7.35-32.72-15.38-1.84-3.68,0-8.37,1.81-11.58q1.28-1.7,2.45-3.61h0A59.67,59.67,0,0,0,91.47,38.7C92,20,77.55,4.74,60.35,4.7h-.09c-17.2,0-31.69,15.24-31.12,34A59.9,59.9,0,0,0,38,68.84h0q1.17,1.88,2.45,3.61c1.84,3.25,3.65,7.93,1.82,11.57-4,8-32.28,15.24-32.72,15.39A14,14,0,0,0,0,112.66V114H120.61v-1.34A14,14,0,0,0,111.12,99.44ZM42.75,51.92a5.42,5.42,0,0,1-5.41-5.42v-10a5.42,5.42,0,0,1,5.41-5.42h35.1a5.42,5.42,0,0,1,5.42,5.42v10a5.42,5.42,0,0,1-5.42,5.42Z"
                    }), React.createElement("circle", {
                      cx: "49.7",
                      cy: "41.52",
                      r: "5.42"
                    }), React.createElement("circle", {
                      cx: "70.91",
                      cy: "41.52",
                      r: "5.42"
                    })));
}

var Avatar = {
  make: SecondaryHeaderIcons$Avatar
};

function SecondaryHeaderIcons$Library(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 120 120",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  id: "Library"
                }, React.createElement("path", {
                      d: "M109,18.73A64.18,64.18,0,0,0,89.9,16c-10.62,0-22.06,2.17-30,8.17C52,18.18,40.6,16,30,16S7.92,18.18,0,24.18V104a2.94,2.94,0,0,0,2.73,2.73c.54,0,.81-.28,1.36-.28a66.45,66.45,0,0,1,25.87-6c10.62,0,22.06,2.18,30,8.17,7.35-4.63,20.7-8.17,30-8.17,9,0,18.24,1.64,25.87,5.72a2.47,2.47,0,0,0,1.36.27,2.93,2.93,0,0,0,2.72-2.72V24.18A36.72,36.72,0,0,0,109,18.73Zm0,73.53A63.49,63.49,0,0,0,89.9,89.54c-9.26,0-22.61,3.54-30,8.17V35.07c7.35-4.63,20.7-8.17,30-8.17A63.49,63.49,0,0,1,109,29.62Z"
                    }), React.createElement("path", {
                      d: "M89.9,48.69a64,64,0,0,1,13.61,1.41V41.82a74.1,74.1,0,0,0-13.61-1.3A62.68,62.68,0,0,0,65.39,45v9C71.54,50.59,80.09,48.69,89.9,48.69Z"
                    }), React.createElement("path", {
                      d: "M65.39,59.53v9c6.15-3.49,14.7-5.39,24.51-5.39a64,64,0,0,1,13.61,1.41V56.31A74.1,74.1,0,0,0,89.9,55,63.6,63.6,0,0,0,65.39,59.53Z"
                    }), React.createElement("path", {
                      d: "M89.9,69.55a62.68,62.68,0,0,0-24.51,4.52v9c6.15-3.48,14.7-5.39,24.51-5.39a63.36,63.36,0,0,1,13.61,1.42V70.86A68.46,68.46,0,0,0,89.9,69.55Z"
                    })));
}

var Library = {
  make: SecondaryHeaderIcons$Library
};

function SecondaryHeaderIcons$Arcade(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 120 120",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  id: "Arcade"
                }, React.createElement("path", {
                      d: "M119.836 94.2673L113.028 46.4235C111.279 34.1191 100.723 25 88.2941 25H31.706C19.2766 25 8.72097 34.1191 6.97212 46.4235L0.164057 94.2673C-1.21005 103.886 6.2226 112.443 15.9038 112.443C20.151 112.443 24.1484 110.757 27.1464 107.759L41.2622 93.7052H78.7378L92.7911 107.759C95.7892 110.757 99.849 112.443 104.034 112.443C113.777 112.443 121.21 103.886 119.836 94.2673ZM53.7541 62.4755H41.2622V74.9674H35.0163V62.4755H22.5245V56.2296H35.0163V43.7378H41.2622V56.2296H53.7541V62.4755ZM78.7378 56.2296C75.3025 56.2296 72.4919 53.419 72.4919 49.9837C72.4919 46.5484 75.3025 43.7378 78.7378 43.7378C82.1731 43.7378 84.9837 46.5484 84.9837 49.9837C84.9837 53.419 82.1731 56.2296 78.7378 56.2296ZM91.2296 74.9674C87.7944 74.9674 84.9837 72.1567 84.9837 68.7215C84.9837 65.2862 87.7944 62.4755 91.2296 62.4755C94.6649 62.4755 97.4756 65.2862 97.4756 68.7215C97.4756 72.1567 94.6649 74.9674 91.2296 74.9674Z"
                    })));
}

var Arcade = {
  make: SecondaryHeaderIcons$Arcade
};

function SecondaryHeaderIcons$CloseIcon(Props) {
  var className = Props.className;
  return React.createElement("div", {
              className: className
            }, React.createElement("svg", {
                  fill: "currentColor",
                  viewBox: "0 0 200 200",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      id: "Layer_2"
                    }, React.createElement("g", {
                          id: "Layer_1-2"
                        }, React.createElement("path", {
                              d: "M195.48,173.65a15.44,15.44,0,0,1-21.83,21.83L100,121.82,26.35,195.48a15.49,15.49,\n          0,0,1-21.83,0h0a15.49,15.49,0,0,1,0-21.83L78.18,100,4.52,26.35A15.44,15.44,0,0,1,26.35,\n          4.52L100,78.18,173.65,4.52a15.49,15.49,0,0,1,21.83,0h0a15.49,15.49,0,0,1,0,21.83L121.82,\n          100Z"
                            })))));
}

var CloseIcon = {
  make: SecondaryHeaderIcons$CloseIcon
};

function SecondaryHeaderIcons$LogoutIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              fill: "#ffffff",
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 \n        0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 \n        17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                }));
}

var LogoutIcon = {
  make: SecondaryHeaderIcons$LogoutIcon
};

function SecondaryHeaderIcons$LoginIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              fill: "#ffffff",
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 \n        0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 \n        0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
                }));
}

var LoginIcon = {
  make: SecondaryHeaderIcons$LoginIcon
};

var common = PrimaryNavIconWordManiaPng;

function SecondaryHeaderIcons$WordManiaIcon(Props) {
  var className = Props.className;
  return React.createElement("img", {
              className: className,
              src: common
            });
}

var WordManiaIcon = {
  make: SecondaryHeaderIcons$WordManiaIcon
};

export {
  Missions ,
  Collections ,
  Avatar ,
  Library ,
  Arcade ,
  CloseIcon ,
  LogoutIcon ,
  LoginIcon ,
  common ,
  WordManiaIcon ,
  
}
/* common Not a pure module */
