// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LocalStorage from "./LocalStorage";

function loadFlags(prim) {
  return LocalStorage.loadFlags();
}

function loadSettings(prim) {
  return LocalStorage.loadSettings();
}

function saveSettings(prim0, prim1) {
  LocalStorage.saveSettings(prim0, prim1);
  
}

export {
  loadFlags ,
  loadSettings ,
  saveSettings ,
  
}
/* ./LocalStorage Not a pure module */
