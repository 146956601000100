// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as FireLater$LiteracyplanetClientSxActivity from "./FireLater.bs.js";

var stateMachine = {
  contents: /* Initial */0
};

function isAnythingInTransit(param) {
  var match = stateMachine.contents;
  if (typeof match !== "number") {
    if (match.TAG === /* QuestionPersisting */1) {
      return true;
    } else {
      return false;
    }
  }
  switch (match) {
    case /* ProvisioningNewAttempt */1 :
    case /* MarkingComplete */2 :
        return true;
    default:
      return false;
  }
}

function useTryBack(onBack) {
  var match = React.useState(function () {
        return 0;
      });
  var setTryBack = match[1];
  var tryBack = match[0];
  var onTryBack = function (param) {
    return Curry._1(setTryBack, (function (param) {
                  return 1;
                }));
  };
  React.useEffect((function () {
          if (tryBack <= 0) {
            return ;
          }
          if (isAnythingInTransit(undefined)) {
            var timeoutId = setTimeout((function (param) {
                    console.log("still waiting for questions");
                    return Curry._1(setTryBack, (function (i) {
                                  return i + 1 | 0;
                                }));
                  }), 100);
            return (function (param) {
                      clearTimeout(timeoutId);
                      
                    });
          }
          Curry._1(onBack, undefined);
          
        }), [tryBack]);
  return onTryBack;
}

var Later = FireLater$LiteracyplanetClientSxActivity.Make({});

function useMutationApi(contentMd5, insertAttempt, insertedAttemptId, existingAttemptId, updateActivityCompleted, insertResponse, insertedResponseId, isMarkCompleteDone) {
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setEvents = match[1];
  var events = match[0];
  var fire = Curry._1(Later.use, undefined);
  var pushAsyncActivityEvent = function ($$event) {
    return Curry._1(fire, /* FireSoonish */{
                _0: (function (param) {
                    return Curry._1(setEvents, (function (e) {
                                  return Belt_List.concat(e, {
                                              hd: $$event,
                                              tl: /* [] */0
                                            });
                                }));
                  })
              });
  };
  React.useEffect((function () {
          if (existingAttemptId !== undefined) {
            stateMachine.contents = {
              TAG: /* AttemptFound */0,
              _0: existingAttemptId
            };
          } else {
            stateMachine.contents = /* ProvisioningNewAttempt */1;
            Curry._1(insertAttempt, undefined);
          }
          
        }), [existingAttemptId]);
  React.useEffect((function () {
          if (insertedAttemptId !== undefined) {
            stateMachine.contents = {
              TAG: /* AttemptFound */0,
              _0: insertedAttemptId
            };
          }
          
        }), [insertedAttemptId]);
  React.useEffect((function () {
          var processNext = function (studentActivityAttemptId, prevResponseId) {
            if (!events) {
              stateMachine.contents = {
                TAG: /* QuestionWaiting */2,
                _0: studentActivityAttemptId,
                _1: prevResponseId
              };
              return ;
            }
            var match = events.hd;
            if (match) {
              var tail = events.tl;
              var match$1 = match._0;
              stateMachine.contents = {
                TAG: /* QuestionPersisting */1,
                _0: studentActivityAttemptId,
                _1: prevResponseId
              };
              Curry._6(insertResponse, studentActivityAttemptId, match$1[2], match$1[3] ? 1 : 0, match$1[0], match$1[1], contentMd5);
              return Curry._1(setEvents, (function (param) {
                            return tail;
                          }));
            }
            var tail$1 = events.tl;
            stateMachine.contents = /* MarkingComplete */2;
            Curry._1(updateActivityCompleted, studentActivityAttemptId);
            return Curry._1(setEvents, (function (param) {
                          return tail$1;
                        }));
          };
          var studentActivityAttemptId = stateMachine.contents;
          if (typeof studentActivityAttemptId === "number") {
            if (studentActivityAttemptId === /* MarkingComplete */2) {
              if (isMarkCompleteDone) {
                stateMachine.contents = /* Finished */3;
              }
              
            }
            
          } else {
            switch (studentActivityAttemptId.TAG | 0) {
              case /* AttemptFound */0 :
                  processNext(studentActivityAttemptId._0, "initial");
                  break;
              case /* QuestionPersisting */1 :
                  if (insertedResponseId !== undefined && insertedResponseId !== studentActivityAttemptId._1) {
                    processNext(studentActivityAttemptId._0, insertedResponseId);
                  }
                  break;
              case /* QuestionWaiting */2 :
                  processNext(studentActivityAttemptId._0, studentActivityAttemptId._1);
                  break;
              
            }
          }
          
        }), [
        events,
        insertedAttemptId,
        insertedResponseId,
        isMarkCompleteDone
      ]);
  React.useEffect((function () {
          return (function (param) {
                    stateMachine.contents = /* Initial */0;
                    
                  });
        }), []);
  var onQuestionFailed = function (question, state, answer) {
    return pushAsyncActivityEvent(/* QuestionAnswered */{
                _0: [
                  question,
                  state,
                  answer,
                  false
                ]
              });
  };
  var onQuestionPassed = function (question, state, answer) {
    return pushAsyncActivityEvent(/* QuestionAnswered */{
                _0: [
                  question,
                  state,
                  answer,
                  true
                ]
              });
  };
  var onActivityCompleted = function (param) {
    return pushAsyncActivityEvent(/* ActivityCompleted */0);
  };
  return [
          onQuestionPassed,
          onQuestionFailed,
          onActivityCompleted
        ];
}

export {
  stateMachine ,
  isAnythingInTransit ,
  useTryBack ,
  Later ,
  useMutationApi ,
  
}
/* Later Not a pure module */
