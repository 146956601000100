// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.bs.js";

function BehaviourMultiChoiceGroupedOptionContainer(Props) {
  var questionIndex = Props.questionIndex;
  var group = Props.group;
  var responseOptionId = Props.responseOptionId;
  var children = Props.children;
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch(undefined);
  var dispatch = match[1];
  var match$1 = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var responseType = Reducer$LiteracyplanetTupperware.buildResponseType(group, responseOptionId);
  var marked = Reducer$LiteracyplanetTupperware.getMarkType(Curry._1(match$1.getStep, questionIndex), responseOptionId);
  var selected = Curry._2(match$1.isResponseSelected, questionIndex, responseOptionId);
  var tmp;
  if (selected) {
    switch (marked) {
      case /* Correct */0 :
          tmp = "selected correct";
          break;
      case /* Wrong */1 :
          tmp = "selected wrong";
          break;
      case /* Unanswered */2 :
          tmp = "selected";
          break;
      
    }
  } else {
    switch (marked) {
      case /* Correct */0 :
          tmp = "correct";
          break;
      case /* Wrong */1 :
          tmp = "unselected wrong";
          break;
      case /* Unanswered */2 :
          tmp = "unselected";
          break;
      
    }
  }
  return React.createElement("div", {
              className: tmp,
              onClick: (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* SetQuestionState */2,
                              _0: questionIndex,
                              _1: responseType
                            });
                })
            }, children);
}

var make = BehaviourMultiChoiceGroupedOptionContainer;

var $$default = BehaviourMultiChoiceGroupedOptionContainer;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
