// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseMutation from "../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";
import * as HasuraTypes$LiteracyplanetClientFlags from "./HasuraTypes.bs.js";
import * as MutationHook$LiteracyplanetClientFlags from "./MutationHook.bs.js";

var Raw = {};

var query = Client.gql(["mutation DeleteStudentQuestionResponses($studentActivityAttemptId: uuid!)  {\ncontentVersionConflictWithStudentState: delete_student_question_responses(where: {studentActivityAttemptId: {_eq: $studentActivityAttemptId}})  {\n__typename  \naffected_rows  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.contentVersionConflictWithStudentState;
  return {
          contentVersionConflictWithStudentState: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                affected_rows: value$1.affected_rows
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.contentVersionConflictWithStudentState;
  var contentVersionConflictWithStudentState;
  if (value$1 !== undefined) {
    var value$2 = value$1.affected_rows;
    var value$3 = value$1.__typename;
    contentVersionConflictWithStudentState = {
      __typename: value$3,
      affected_rows: value$2
    };
  } else {
    contentVersionConflictWithStudentState = null;
  }
  return {
          contentVersionConflictWithStudentState: contentVersionConflictWithStudentState
        };
}

function serializeVariables(inp) {
  return {
          studentActivityAttemptId: inp.studentActivityAttemptId
        };
}

function makeVariables(studentActivityAttemptId, param) {
  return {
          studentActivityAttemptId: studentActivityAttemptId
        };
}

var Mutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Mutation_useWithVariables = include.useWithVariables;

var Mutation = {
  Mutation_inner: Mutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use,
  useWithVariables: Mutation_useWithVariables
};

var M = MutationHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function use$1(param) {
  var match = Curry._1(M.useWithWordMonsterError, "DeleteStudentQuestionResponses.use");
  var mutation = match[0];
  var run = function (studentActivityAttemptId) {
    Curry._8(mutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
          studentActivityAttemptId: HasuraTypes$LiteracyplanetClientFlags.encode(studentActivityAttemptId)
        });
    
  };
  return [
          run,
          match[2],
          match[3]
        ];
}

export {
  Mutation ,
  M ,
  use$1 as use,
  
}
/* query Not a pure module */
