// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as StopIcon$LiteracyplanetTupperware from "./StopIcon.bs.js";
import * as VolumeIcon$LiteracyplanetTupperware from "./VolumeIcon.bs.js";
import * as SpinnerIcon$LiteracyplanetTupperware from "./SpinnerIcon.bs.js";

function PlayAudioIcon(Props) {
  var className = Props.className;
  var state = Props.state;
  var css = className !== undefined ? Flavor$LiteracyplanetTupperware.PlayAudioIcon.withClassName(className) : Flavor$LiteracyplanetTupperware.PlayAudioIcon.className;
  switch (state) {
    case /* Loading */0 :
        return React.createElement(SpinnerIcon$LiteracyplanetTupperware.make, {
                    className: css
                  });
    case /* Stopped */1 :
        return React.createElement(VolumeIcon$LiteracyplanetTupperware.make, {
                    className: css
                  });
    case /* Playing */2 :
        return React.createElement(StopIcon$LiteracyplanetTupperware.make, {
                    className: css
                  });
    
  }
}

var make = PlayAudioIcon;

export {
  make ,
  
}
/* react Not a pure module */
