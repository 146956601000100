// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as TickIcon$LiteracyplanetTupperware from "./TickIcon.bs.js";
import * as CrossIcon$LiteracyplanetTupperware from "./CrossIcon.bs.js";
import * as ExpandingInput$LiteracyplanetTupperware from "./ExpandingInput.bs.js";

function SkilfulResponseMark(Props) {
  var inputClassName = Props.inputClassName;
  var marked = Props.marked;
  var answerVisible = Props.answerVisible;
  var value = Props.value;
  var onChange = function (param) {
    
  };
  var exit = 0;
  if (!(answerVisible !== undefined && answerVisible)) {
    exit = 2;
  }
  if (exit === 2 && marked !== 0) {
    return React.createElement("div", {
                className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.className
              }, React.createElement(ExpandingInput$LiteracyplanetTupperware.make, {
                    value: value,
                    className: Curry._1(Css.merge, {
                          hd: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.InputIncorrect.className,
                          tl: {
                            hd: inputClassName,
                            tl: /* [] */0
                          }
                        }),
                    onChange: onChange,
                    disabled: true
                  }), React.createElement("div", {
                    className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.IconGrid.className
                  }, React.createElement(CrossIcon$LiteracyplanetTupperware.make, {
                        className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.IncorrectMarkIcon.className
                      })));
  }
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.className
            }, React.createElement(ExpandingInput$LiteracyplanetTupperware.make, {
                  value: value,
                  className: Curry._1(Css.merge, {
                        hd: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.InputCorrect.className,
                        tl: {
                          hd: inputClassName,
                          tl: /* [] */0
                        }
                      }),
                  onChange: onChange,
                  disabled: true
                }), React.createElement("div", {
                  className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.IconGrid.className
                }, React.createElement(TickIcon$LiteracyplanetTupperware.make, {
                      className: Skillet$LiteracyplanetTupperware.SkilfulResponseMark.CorrectMarkIcon.className
                    })));
}

var make = SkilfulResponseMark;

export {
  make ,
  
}
/* Css Not a pure module */
