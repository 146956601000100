// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";

function preloadImages(images) {
  return Css.after({
              hd: Css.position("absolute"),
              tl: {
                hd: Css.width("zero"),
                tl: {
                  hd: Css.height("zero"),
                  tl: {
                    hd: Css.overflow("hidden"),
                    tl: {
                      hd: Css.zIndex(-1),
                      tl: {
                        hd: Css.contentRules(Belt_List.map(images, Css.url)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

export {
  preloadImages ,
  
}
/* Css Not a pure module */
