// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";

function useMarkCallback(initialized, setMarkCb, fuzzyMark, correctAnswer, questionIndex, callbacks) {
  React.useEffect((function () {
          if (initialized) {
            return Curry._3(setMarkCb, (function (param) {
                          return {
                                  TAG: /* MarkQuestion */4,
                                  _0: fuzzyMark,
                                  _1: correctAnswer,
                                  _2: questionIndex
                                };
                        }), questionIndex, correctAnswer);
          }
          
        }), [initialized]);
  
}

function BehaviourMultiChoiceMarkCallback(Props) {
  var callbacks = Props.callbacks;
  var questionIndex = Props.questionIndex;
  var correctAnswer = Props.correctAnswer;
  var fuzzyMarkOpt = Props.fuzzyMark;
  var showCorrectAnswerBehaviourOpt = Props.showCorrectAnswerBehaviour;
  var children = Props.children;
  var fuzzyMark = fuzzyMarkOpt !== undefined ? fuzzyMarkOpt : false;
  var showCorrectAnswerBehaviour = showCorrectAnswerBehaviourOpt !== undefined ? showCorrectAnswerBehaviourOpt : false;
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var setShowCorrectAnswerForQuestion = match.setShowCorrectAnswerForQuestion;
  React.useEffect((function () {
          Curry._2(setShowCorrectAnswerForQuestion, questionIndex, showCorrectAnswerBehaviour);
          
        }), []);
  useMarkCallback(true, match.setMarkCb, fuzzyMark, correctAnswer, questionIndex, callbacks);
  return children;
}

var make = BehaviourMultiChoiceMarkCallback;

export {
  useMarkCallback ,
  make ,
  
}
/* react Not a pure module */
