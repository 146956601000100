// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as V2MultiChoiceContainer$LiteracyplanetTupperware from "./V2MultiChoiceContainer.bs.js";

function PieceOfCakeMultiChoiceContainer$MultiChoiceMarkStylingContainer(Props) {
  var columns = Props.columns;
  var children = Props.children;
  var css = Curry._1(Flavor$LiteracyplanetTupperware.PieceOfCakeMultiChoiceContainer.MultiChoiceMarkStylingContainer.className, columns);
  return React.createElement("div", {
              className: css
            }, children);
}

var MultiChoiceMarkStylingContainer = {
  make: PieceOfCakeMultiChoiceContainer$MultiChoiceMarkStylingContainer
};

function PieceOfCakeMultiChoiceContainer(Props) {
  var columns = Props.columns;
  var correctAnswer = Props.correctAnswer;
  var fuzzyMarkOpt = Props.fuzzyMark;
  var questionIndex = Props.questionIndex;
  var children = Props.children;
  var fuzzyMark = fuzzyMarkOpt !== undefined ? fuzzyMarkOpt : false;
  return React.createElement(V2MultiChoiceContainer$LiteracyplanetTupperware.make, {
              correctAnswer: correctAnswer,
              fuzzyMark: fuzzyMark,
              questionIndex: questionIndex,
              children: React.createElement(PieceOfCakeMultiChoiceContainer$MultiChoiceMarkStylingContainer, {
                    columns: columns,
                    children: children
                  })
            });
}

var make = PieceOfCakeMultiChoiceContainer;

var $$default = PieceOfCakeMultiChoiceContainer;

export {
  MultiChoiceMarkStylingContainer ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
