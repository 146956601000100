// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDnd from "react-dnd";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import ReactDndTouchBackend from "react-dnd-touch-backend";

var options = {
  enableMouseEvents: true,
  enableKeyboardEvents: true,
  ignoreContextMenu: true,
  enableHoverOutsideTarget: true
};

var TouchBackend = {
  options: options
};

var Html5Backend = {
  options: undefined
};

var DndProvider = {};

function ReactDnd$Provider(Props) {
  var children = Props.children;
  var tmp = {
    backend: ReactDndTouchBackend,
    children: children
  };
  var tmp$1 = options;
  if (tmp$1 !== undefined) {
    tmp.options = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(ReactDnd.DndProvider, tmp);
}

var Provider = {
  make: ReactDnd$Provider
};

var Drag = {};

var Drop = {};

var DragLayer = {};

var isMobile = true;

export {
  TouchBackend ,
  Html5Backend ,
  DndProvider ,
  isMobile ,
  Provider ,
  Drag ,
  Drop ,
  DragLayer ,
  
}
/* react Not a pure module */
