// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as AspectRatio$LiteracyplanetClientFlags from "./AspectRatio.bs.js";

var primaryColor = Css.hex("0C9CE5");

var hoverColor = Css.hex("0077b3");

var activeColor = Css.hex("005580");

var defaultGreen = Css.hex("1ECC11");

var hoverGreen = Css.hex("00802a");

var activeGreen = Css.hex("006622");

var dims = AspectRatio$LiteracyplanetClientFlags.dims;

var toVh = AspectRatio$LiteracyplanetClientFlags.toVh;

var toVw = AspectRatio$LiteracyplanetClientFlags.toVw;

var dimensions = AspectRatio$LiteracyplanetClientFlags.dimensions;

var dimensionsHover = AspectRatio$LiteracyplanetClientFlags.dimensionsHover;

var dimensionsActive = AspectRatio$LiteracyplanetClientFlags.dimensionsActive;

var textStroke = AspectRatio$LiteracyplanetClientFlags.textStroke;

var mapProps = AspectRatio$LiteracyplanetClientFlags.mapProps;

var resizeList = AspectRatio$LiteracyplanetClientFlags.resizeList;

var resize = AspectRatio$LiteracyplanetClientFlags.resize;

var resizeExtra = AspectRatio$LiteracyplanetClientFlags.resizeExtra;

var mapRules = AspectRatio$LiteracyplanetClientFlags.mapRules;

var resizeWithMap = AspectRatio$LiteracyplanetClientFlags.resizeWithMap;

var resizeGridRows = AspectRatio$LiteracyplanetClientFlags.resizeGridRows;

var resizeGridCols = AspectRatio$LiteracyplanetClientFlags.resizeGridCols;

var maximumWidth = AspectRatio$LiteracyplanetClientFlags.maximumWidth;

var maximumHeight = AspectRatio$LiteracyplanetClientFlags.maximumHeight;

export {
  dims ,
  toVh ,
  toVw ,
  dimensions ,
  dimensionsHover ,
  dimensionsActive ,
  textStroke ,
  mapProps ,
  resizeList ,
  resize ,
  resizeExtra ,
  mapRules ,
  resizeWithMap ,
  resizeGridRows ,
  resizeGridCols ,
  maximumWidth ,
  maximumHeight ,
  primaryColor ,
  hoverColor ,
  activeColor ,
  defaultGreen ,
  hoverGreen ,
  activeGreen ,
  
}
/* primaryColor Not a pure module */
