// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PullinIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              version: "1.1",
              viewBox: "0 0 46 72",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  transform: "translate(-542.000000, -475.000000)"
                }, React.createElement("g", {
                      transform: "translate(542.000000, 475.000000)"
                    }, React.createElement("g", {
                          transform: "translate(23.000000, 36.500000) rotate(-180.000000) translate(-23.000000, -36.500000) translate(14.000000, 21.000000)"
                        }, React.createElement("path", {
                              d: "M17.5112743,14.2903798 L3.87664241,0.495239876 C3.56129001,0.175917269 3.14032197,2.27373675e-13 2.69145545,2.27373675e-13 C2.24258893,2.27373675e-13 1.82162089,0.175917269 1.5062685,0.495239876 L0.502172531,1.51092268 C-0.151199765,2.17275469 -0.151199765,3.24842074 0.502172531,3.90924464 L11.951507,15.4935732 L0.489468761,27.0907554 C0.174116368,27.410078 0,27.8357574 0,28.2896643 C0,28.7440753 0.174116368,29.1697547 0.489468761,29.4893294 L1.49356473,30.5047601 C1.80916621,30.8240827 2.22988517,31 2.67875168,31 C3.1276182,31 3.54858625,30.8240827 3.86393864,30.5047601 L17.5112743,16.6970187 C17.8273739,16.376688 18.0009921,15.9489923 18,15.4943293 C18.0009921,15.0379021 17.8273739,14.6104585 17.5112743,14.2903798 Z"
                            })))));
}

var make = PullinIcon;

export {
  make ,
  
}
/* react Not a pure module */
