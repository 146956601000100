// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.bs.js";
import * as ModernStyles$LiteracyplanetClientSxEntry from "./ModernStyles.bs.js";
import * as JourneyHeaderIcons$LiteracyplanetClientSxEntry from "./JourneyHeaderIcons.bs.js";

function ModernComponents$HeaderBackground(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.HeaderBackground.className
            }, children);
}

var HeaderBackground = {
  make: ModernComponents$HeaderBackground
};

function ModernComponents$HeaderPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.HeaderPanel.className
            }, children);
}

var HeaderPanel = {
  make: ModernComponents$HeaderPanel
};

function ModernComponents$SlimHeaderItemIcon(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimHeaderItemIcon.className
            }, children);
}

var SlimHeaderItemIcon = {
  make: ModernComponents$SlimHeaderItemIcon
};

function ModernComponents$PlanetIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Planet.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var PlanetIcon = {
  make: ModernComponents$PlanetIcon
};

function ModernComponents$ChestIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Chest.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var ChestIcon = {
  make: ModernComponents$ChestIcon
};

function ModernComponents$AvatarIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Avatar.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var AvatarIcon = {
  make: ModernComponents$AvatarIcon
};

function ModernComponents$LetterAIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Lettera.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var LetterAIcon = {
  make: ModernComponents$LetterAIcon
};

function ModernComponents$ArcadeIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Arcade.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var ArcadeIcon = {
  make: ModernComponents$ArcadeIcon
};

function ModernComponents$MyWordsIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.MyWords.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var MyWordsIcon = {
  make: ModernComponents$MyWordsIcon
};

function ModernComponents$MagazineIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Magazine.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var MagazineIcon = {
  make: ModernComponents$MagazineIcon
};

function ModernComponents$MagazineDropdownIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Magazine.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.DropdownHeaderIcon.className
            });
}

var MagazineDropdownIcon = {
  make: ModernComponents$MagazineDropdownIcon
};

function ModernComponents$BookDropdownIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Book.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.DropdownHeaderIcon.className
            });
}

var BookDropdownIcon = {
  make: ModernComponents$BookDropdownIcon
};

function ModernComponents$LibraryIcon(Props) {
  return React.createElement(JourneyHeaderIcons$LiteracyplanetClientSxEntry.Library.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimIcon.className
            });
}

var LibraryIcon = {
  make: ModernComponents$LibraryIcon
};

function ModernComponents$SlimHeaderItemButton(Props) {
  var children = Props.children;
  var itemColor = Props.itemColor;
  var hoverColor = Props.hoverColor;
  var activeColor = Props.activeColor;
  var highlight = Props.highlight;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimHeaderItemButton.className(highlight, itemColor, hoverColor, activeColor)
            }, children);
}

var SlimHeaderItemButton = {
  make: ModernComponents$SlimHeaderItemButton
};

function ModernComponents$SlimHeaderItem(Props) {
  var onClick = Props.onClick;
  var children = Props.children;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimHeaderItem.className,
              onClick: onClick
            }, children);
}

var SlimHeaderItem = {
  make: ModernComponents$SlimHeaderItem
};

function ModernComponents$SlimHeaderLockIcon(Props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.LockSmallIcon.make, {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimHeaderLockIcon.className
            });
}

var SlimHeaderLockIcon = {
  make: ModernComponents$SlimHeaderLockIcon
};

function ModernComponents$SlimHeaderNotificationCount(Props) {
  var count = Props.count;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimHeaderNotificationCount.className
            }, String(count));
}

var SlimHeaderNotificationCount = {
  make: ModernComponents$SlimHeaderNotificationCount
};

function ModernComponents$SlimHeaderItemWrapper(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.SlimHeaderItemWrapper.className
            }, children);
}

var SlimHeaderItemWrapper = {
  make: ModernComponents$SlimHeaderItemWrapper
};

function ModernComponents$MissionsSlimHeaderItem(Props) {
  var highlight = Props.highlight;
  var notificationCountOpt = Props.notificationCount;
  var onClick = Props.onClick;
  var notificationCount = notificationCountOpt !== undefined ? notificationCountOpt : 0;
  return React.createElement(ModernComponents$SlimHeaderItem, {
              onClick: onClick,
              children: null
            }, React.createElement(ModernComponents$SlimHeaderItemButton, {
                  children: ModernStyles$LiteracyplanetClientSxEntry.s("Missions"),
                  itemColor: ModernStyles$LiteracyplanetClientSxEntry.missionsColor,
                  hoverColor: ModernStyles$LiteracyplanetClientSxEntry.missionsHoverColor,
                  activeColor: ModernStyles$LiteracyplanetClientSxEntry.missionsActiveColor,
                  highlight: highlight
                }), React.createElement(ModernComponents$SlimHeaderItemIcon, {
                  children: React.createElement(ModernComponents$SlimHeaderItemWrapper, {
                        children: null
                      }, React.createElement(ModernComponents$PlanetIcon, {}), notificationCount > 0 ? React.createElement(ModernComponents$SlimHeaderNotificationCount, {
                              count: notificationCount
                            }) : null)
                }));
}

var MissionsSlimHeaderItem = {
  make: ModernComponents$MissionsSlimHeaderItem
};

function ModernComponents$CollectionsSlimHeaderItem(Props) {
  var highlight = Props.highlight;
  var notificationCountOpt = Props.notificationCount;
  var onClick = Props.onClick;
  var notificationCount = notificationCountOpt !== undefined ? notificationCountOpt : 0;
  return React.createElement(ModernComponents$SlimHeaderItem, {
              onClick: onClick,
              children: null
            }, React.createElement(ModernComponents$SlimHeaderItemButton, {
                  children: ModernStyles$LiteracyplanetClientSxEntry.s("Collections"),
                  itemColor: ModernStyles$LiteracyplanetClientSxEntry.collectionsColor,
                  hoverColor: ModernStyles$LiteracyplanetClientSxEntry.collectionsHoverColor,
                  activeColor: ModernStyles$LiteracyplanetClientSxEntry.collectionsActiveColor,
                  highlight: highlight
                }), React.createElement(ModernComponents$SlimHeaderItemIcon, {
                  children: React.createElement(ModernComponents$SlimHeaderItemWrapper, {
                        children: null
                      }, React.createElement(ModernComponents$ChestIcon, {}), notificationCount > 0 ? React.createElement(ModernComponents$SlimHeaderNotificationCount, {
                              count: notificationCount
                            }) : null)
                }));
}

var CollectionsSlimHeaderItem = {
  make: ModernComponents$CollectionsSlimHeaderItem
};

function ModernComponents$ArcadeSlimHeaderItem(Props) {
  var highlight = Props.highlight;
  var notificationCountOpt = Props.notificationCount;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var notificationCount = notificationCountOpt !== undefined ? notificationCountOpt : 0;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(ModernComponents$SlimHeaderItem, {
              onClick: onClick,
              children: null
            }, React.createElement(ModernComponents$SlimHeaderItemButton, {
                  children: ModernStyles$LiteracyplanetClientSxEntry.s("Arcade"),
                  itemColor: ModernStyles$LiteracyplanetClientSxEntry.arcadeColor,
                  hoverColor: ModernStyles$LiteracyplanetClientSxEntry.arcadeHoverColor,
                  activeColor: ModernStyles$LiteracyplanetClientSxEntry.arcadeActiveColor,
                  highlight: highlight
                }), React.createElement(ModernComponents$SlimHeaderItemIcon, {
                  children: React.createElement(ModernComponents$SlimHeaderItemWrapper, {
                        children: null
                      }, React.createElement(ModernComponents$ArcadeIcon, {}), notificationCount > 0 ? React.createElement(ModernComponents$SlimHeaderNotificationCount, {
                              count: notificationCount
                            }) : null, lock ? React.createElement(ModernComponents$SlimHeaderLockIcon, {}) : null)
                }));
}

var ArcadeSlimHeaderItem = {
  make: ModernComponents$ArcadeSlimHeaderItem
};

function ModernComponents$AvatarSlimHeaderItem(Props) {
  var highlight = Props.highlight;
  var notificationCountOpt = Props.notificationCount;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var notificationCount = notificationCountOpt !== undefined ? notificationCountOpt : 0;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(ModernComponents$SlimHeaderItem, {
              onClick: onClick,
              children: null
            }, React.createElement(ModernComponents$SlimHeaderItemButton, {
                  children: ModernStyles$LiteracyplanetClientSxEntry.s("Shop"),
                  itemColor: ModernStyles$LiteracyplanetClientSxEntry.avatarColor,
                  hoverColor: ModernStyles$LiteracyplanetClientSxEntry.avatarHoverColor,
                  activeColor: ModernStyles$LiteracyplanetClientSxEntry.avatarActiveColor,
                  highlight: highlight
                }), React.createElement(ModernComponents$SlimHeaderItemIcon, {
                  children: React.createElement(ModernComponents$SlimHeaderItemWrapper, {
                        children: null
                      }, React.createElement(ModernComponents$AvatarIcon, {}), notificationCount > 0 ? React.createElement(ModernComponents$SlimHeaderNotificationCount, {
                              count: notificationCount
                            }) : null, lock ? React.createElement(ModernComponents$SlimHeaderLockIcon, {}) : null)
                }));
}

var AvatarSlimHeaderItem = {
  make: ModernComponents$AvatarSlimHeaderItem
};

function ModernComponents$MyWordsSlimHeaderItem(Props) {
  var highlight = Props.highlight;
  var notificationCountOpt = Props.notificationCount;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var notificationCount = notificationCountOpt !== undefined ? notificationCountOpt : 0;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(ModernComponents$SlimHeaderItem, {
              onClick: onClick,
              children: null
            }, React.createElement(ModernComponents$SlimHeaderItemButton, {
                  children: ModernStyles$LiteracyplanetClientSxEntry.s("My Words"),
                  itemColor: ModernStyles$LiteracyplanetClientSxEntry.myWordsColor,
                  hoverColor: ModernStyles$LiteracyplanetClientSxEntry.myWordsHoverColor,
                  activeColor: ModernStyles$LiteracyplanetClientSxEntry.myWordsActiveColor,
                  highlight: highlight
                }), React.createElement(ModernComponents$SlimHeaderItemIcon, {
                  children: React.createElement(ModernComponents$SlimHeaderItemWrapper, {
                        children: null
                      }, React.createElement(ModernComponents$MyWordsIcon, {}), notificationCount > 0 ? React.createElement(ModernComponents$SlimHeaderNotificationCount, {
                              count: notificationCount
                            }) : null, lock ? React.createElement(ModernComponents$SlimHeaderLockIcon, {}) : null)
                }));
}

var MyWordsSlimHeaderItem = {
  make: ModernComponents$MyWordsSlimHeaderItem
};

function ModernComponents$IntrepizineSlimHeaderItem(Props) {
  var highlight = Props.highlight;
  var notificationCountOpt = Props.notificationCount;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var notificationCount = notificationCountOpt !== undefined ? notificationCountOpt : 0;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(ModernComponents$SlimHeaderItem, {
              onClick: onClick,
              children: null
            }, React.createElement(ModernComponents$SlimHeaderItemButton, {
                  children: ModernStyles$LiteracyplanetClientSxEntry.s("Intrepizine"),
                  itemColor: ModernStyles$LiteracyplanetClientSxEntry.libraryColor,
                  hoverColor: ModernStyles$LiteracyplanetClientSxEntry.libraryHoverColor,
                  activeColor: ModernStyles$LiteracyplanetClientSxEntry.libraryActiveColor,
                  highlight: highlight
                }), React.createElement(ModernComponents$SlimHeaderItemIcon, {
                  children: React.createElement(ModernComponents$SlimHeaderItemWrapper, {
                        children: null
                      }, React.createElement(ModernComponents$MagazineIcon, {}), notificationCount > 0 ? React.createElement(ModernComponents$SlimHeaderNotificationCount, {
                              count: notificationCount
                            }) : null, lock ? React.createElement(ModernComponents$SlimHeaderLockIcon, {}) : null)
                }));
}

var IntrepizineSlimHeaderItem = {
  make: ModernComponents$IntrepizineSlimHeaderItem
};

function ModernComponents$DropdownHeaderPanel(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.DropdownHeaderPanel.className
            }, children);
}

var DropdownHeaderPanel = {
  make: ModernComponents$DropdownHeaderPanel
};

function ModernComponents$DropdownHeaderItem(Props) {
  var children = Props.children;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: ModernStyles$LiteracyplanetClientSxEntry.DropdownHeaderItem.className,
              onClick: onClick
            }, children);
}

var DropdownHeaderItem = {
  make: ModernComponents$DropdownHeaderItem
};

function ModernComponents$BooksDropdownItem(Props) {
  var onClick = Props.onClick;
  return React.createElement(ModernComponents$DropdownHeaderItem, {
              children: null,
              onClick: onClick
            }, React.createElement(ModernComponents$BookDropdownIcon, {}), ModernStyles$LiteracyplanetClientSxEntry.s("Books"));
}

var BooksDropdownItem = {
  make: ModernComponents$BooksDropdownItem
};

function ModernComponents$IntrepizineDropdownItem(Props) {
  var onClick = Props.onClick;
  return React.createElement(ModernComponents$DropdownHeaderItem, {
              children: null,
              onClick: onClick
            }, React.createElement(ModernComponents$MagazineDropdownIcon, {}), ModernStyles$LiteracyplanetClientSxEntry.s("Intrepizine"));
}

var IntrepizineDropdownItem = {
  make: ModernComponents$IntrepizineDropdownItem
};

function ModernComponents$LibraryClosedHeaderItem(Props) {
  var highlight = Props.highlight;
  var notificationCountOpt = Props.notificationCount;
  var onClick = Props.onClick;
  var lockOpt = Props.lock;
  var notificationCount = notificationCountOpt !== undefined ? notificationCountOpt : 0;
  var lock = lockOpt !== undefined ? lockOpt : false;
  return React.createElement(ModernComponents$SlimHeaderItem, {
              onClick: onClick,
              children: null
            }, React.createElement(ModernComponents$SlimHeaderItemButton, {
                  children: ModernStyles$LiteracyplanetClientSxEntry.s("Library"),
                  itemColor: ModernStyles$LiteracyplanetClientSxEntry.libraryColor,
                  hoverColor: ModernStyles$LiteracyplanetClientSxEntry.libraryHoverColor,
                  activeColor: ModernStyles$LiteracyplanetClientSxEntry.libraryActiveColor,
                  highlight: highlight
                }), React.createElement(ModernComponents$SlimHeaderItemIcon, {
                  children: React.createElement(ModernComponents$SlimHeaderItemWrapper, {
                        children: null
                      }, React.createElement(ModernComponents$LibraryIcon, {}), notificationCount > 0 ? React.createElement(ModernComponents$SlimHeaderNotificationCount, {
                              count: notificationCount
                            }) : null, lock ? React.createElement(ModernComponents$SlimHeaderLockIcon, {}) : null)
                }));
}

var LibraryClosedHeaderItem = {
  make: ModernComponents$LibraryClosedHeaderItem
};

function ModernComponents$LibraryOpenDropdown(Props) {
  var onClickLibrary = Props.onClickLibrary;
  var onClickIntrepizine = Props.onClickIntrepizine;
  return React.createElement(ModernComponents$DropdownHeaderPanel, {
              children: null
            }, React.createElement(ModernComponents$BooksDropdownItem, {
                  onClick: onClickLibrary
                }), React.createElement(ModernComponents$IntrepizineDropdownItem, {
                  onClick: onClickIntrepizine
                }));
}

var LibraryOpenDropdown = {
  make: ModernComponents$LibraryOpenDropdown
};

function ModernComponents$LibrarySlimHeaderItem(Props) {
  var lockLibrary = Props.lockLibrary;
  var highlightLibrary = Props.highlightLibrary;
  var onClickLibrary = Props.onClickLibrary;
  var onClickIntrepizine = Props.onClickIntrepizine;
  var subnavOpen = Props.subnavOpen;
  var closeSubnav = Props.closeSubnav;
  var openSubnav = Props.openSubnav;
  if (lockLibrary) {
    return React.createElement(ModernComponents$LibraryClosedHeaderItem, {
                highlight: highlightLibrary,
                onClick: (function (param) {
                    return Curry._1(onClickIntrepizine, undefined);
                  }),
                lock: lockLibrary
              });
  } else if (subnavOpen) {
    return React.createElement("div", undefined, React.createElement(ModernComponents$LibraryClosedHeaderItem, {
                    highlight: highlightLibrary,
                    onClick: (function (param) {
                        return Curry._1(closeSubnav, undefined);
                      }),
                    lock: lockLibrary
                  }), React.createElement(ModernComponents$LibraryOpenDropdown, {
                    onClickLibrary: (function (param) {
                        Curry._1(onClickLibrary, undefined);
                        return Curry._1(closeSubnav, undefined);
                      }),
                    onClickIntrepizine: (function (param) {
                        Curry._1(onClickIntrepizine, undefined);
                        return Curry._1(closeSubnav, undefined);
                      })
                  }));
  } else {
    return React.createElement(ModernComponents$LibraryClosedHeaderItem, {
                highlight: highlightLibrary,
                onClick: (function (param) {
                    return Curry._1(openSubnav, undefined);
                  }),
                lock: lockLibrary
              });
  }
}

var LibrarySlimHeaderItem = {
  make: ModernComponents$LibrarySlimHeaderItem
};

export {
  HeaderBackground ,
  HeaderPanel ,
  SlimHeaderItemIcon ,
  PlanetIcon ,
  ChestIcon ,
  AvatarIcon ,
  LetterAIcon ,
  ArcadeIcon ,
  MyWordsIcon ,
  MagazineIcon ,
  MagazineDropdownIcon ,
  BookDropdownIcon ,
  LibraryIcon ,
  SlimHeaderItemButton ,
  SlimHeaderItem ,
  SlimHeaderLockIcon ,
  SlimHeaderNotificationCount ,
  SlimHeaderItemWrapper ,
  MissionsSlimHeaderItem ,
  CollectionsSlimHeaderItem ,
  ArcadeSlimHeaderItem ,
  AvatarSlimHeaderItem ,
  MyWordsSlimHeaderItem ,
  IntrepizineSlimHeaderItem ,
  DropdownHeaderPanel ,
  DropdownHeaderItem ,
  BooksDropdownItem ,
  IntrepizineDropdownItem ,
  LibraryClosedHeaderItem ,
  LibraryOpenDropdown ,
  LibrarySlimHeaderItem ,
  
}
/* react Not a pure module */
