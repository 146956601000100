// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Reducer$LiteracyplanetTupperware from "./Reducer.bs.js";

function findOne(questionIndex, states, $$default) {
  return Belt_List.reduce(states, $$default, (function (acc, param) {
                if (param[1] === questionIndex) {
                  return param[0];
                } else {
                  return acc;
                }
              }));
}

function findMarkState(questionIndex, markStates) {
  return findOne(questionIndex, markStates, /* NotMarked */0);
}

function findQuestionState(questionIndex, questionStates) {
  return findOne(questionIndex, questionStates, /* NotAttempted */0);
}

function updateList(questionIndex, states, state) {
  var exists = Belt_List.reduce(states, false, (function (acc, param) {
          if (param[1] === questionIndex) {
            return true;
          } else {
            return acc;
          }
        }));
  if (exists) {
    return Belt_List.reduce(states, /* [] */0, (function (acc, param) {
                  var index = param[1];
                  if (index === questionIndex) {
                    return Belt_List.concat(acc, {
                                hd: [
                                  state,
                                  index
                                ],
                                tl: /* [] */0
                              });
                  } else {
                    return Belt_List.concat(acc, {
                                hd: [
                                  param[0],
                                  index
                                ],
                                tl: /* [] */0
                              });
                  }
                }));
  } else {
    return Belt_List.concat({
                hd: [
                  state,
                  questionIndex
                ],
                tl: /* [] */0
              }, states);
  }
}

function notMarked(state, questionIndex) {
  var markState = findOne(questionIndex, state.markStates, /* NotMarked */0);
  if (markState) {
    return false;
  } else {
    return true;
  }
}

function setQuestionState(questionIndex, responsePayload, state) {
  if (!notMarked(state, questionIndex)) {
    return state;
  }
  var questionState = findOne(questionIndex, state.questionStates, /* NotAttempted */0);
  var newQuestionState = Reducer$LiteracyplanetTupperware.respondToQuestion(questionState, responsePayload);
  var questionStates = updateList(questionIndex, state.questionStates, newQuestionState);
  return {
          questionStates: questionStates,
          markStates: state.markStates,
          mode: state.mode,
          questionsComplete: state.questionsComplete,
          questionsCorrect: state.questionsCorrect,
          questionsIncorrect: state.questionsIncorrect
        };
}

function deleteResponseIdentifier(questionIndex, responseIdentifier, state) {
  if (!notMarked(state, questionIndex)) {
    return state;
  }
  var questionState = findOne(questionIndex, state.questionStates, /* NotAttempted */0);
  var newQuestionState = Reducer$LiteracyplanetTupperware.filterResponseIdentifier(questionState, responseIdentifier);
  var questionStates = updateList(questionIndex, state.questionStates, newQuestionState);
  return {
          questionStates: questionStates,
          markStates: state.markStates,
          mode: state.mode,
          questionsComplete: state.questionsComplete,
          questionsCorrect: state.questionsCorrect,
          questionsIncorrect: state.questionsIncorrect
        };
}

function setMarkState(questionIndex, newMarkState, state, questionCorrect) {
  if (!notMarked(state, questionIndex)) {
    return state;
  }
  var questionsCorrect = questionCorrect ? state.questionsCorrect + 1 | 0 : state.questionsCorrect;
  var questionsIncorrect = questionCorrect ? state.questionsIncorrect : state.questionsIncorrect + 1 | 0;
  var markStates = updateList(questionIndex, state.markStates, newMarkState);
  return {
          questionStates: state.questionStates,
          markStates: markStates,
          mode: state.mode,
          questionsComplete: state.questionsComplete + 1 | 0,
          questionsCorrect: questionsCorrect,
          questionsIncorrect: questionsIncorrect
        };
}

function markQuestion(state, fuzzyMark, correctAnswer, questionIndex) {
  var questionState = findOne(questionIndex, state.questionStates, /* NotAttempted */0);
  var answer = fuzzyMark ? ({
        TAG: /* AnswerFuzzyMatch */1,
        _0: correctAnswer
      }) : ({
        TAG: /* AnswerExactMatch */0,
        _0: correctAnswer
      });
  var match = Reducer$LiteracyplanetTupperware.markQuestion(questionState, answer);
  return setMarkState(questionIndex, match[1], state, match[0]);
}

var initialState = {
  questionStates: /* [] */0,
  markStates: /* [] */0,
  mode: /* Activity */1,
  questionsComplete: 0,
  questionsCorrect: 0,
  questionsIncorrect: 0
};

function reducer(state, action) {
  if (typeof action === "number") {
    return initialState;
  }
  switch (action.TAG | 0) {
    case /* SetStore */0 :
        return action._0;
    case /* SetMode */1 :
        return {
                questionStates: state.questionStates,
                markStates: state.markStates,
                mode: action._0,
                questionsComplete: state.questionsComplete,
                questionsCorrect: state.questionsCorrect,
                questionsIncorrect: state.questionsIncorrect
              };
    case /* SetQuestionState */2 :
        return setQuestionState(action._0, action._1, state);
    case /* DeleteResponseIdentifier */3 :
        return deleteResponseIdentifier(action._0, action._1, state);
    case /* MarkQuestion */4 :
        return markQuestion(state, action._0, action._1, action._2);
    
  }
}

export {
  findOne ,
  findMarkState ,
  findQuestionState ,
  updateList ,
  notMarked ,
  setQuestionState ,
  deleteResponseIdentifier ,
  setMarkState ,
  markQuestion ,
  initialState ,
  reducer ,
  
}
/* No side effect */
