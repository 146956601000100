// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

function blacklistPage(page) {
  if (typeof page !== "number") {
    if (page.TAG === /* ArcadeGame */7) {
      return true;
    } else {
      return false;
    }
  }
  switch (page) {
    case /* AvatarShop */2 :
    case /* Arcade */11 :
        return true;
    default:
      return false;
  }
}

function blacklistItem(item) {
  if (item) {
    return blacklistPage(item._0);
  } else {
    return true;
  }
}

function isPageBlacklisted(locked, page) {
  if (locked) {
    return blacklistPage(page);
  } else {
    return false;
  }
}

function isItemBlacklisted(locked, item) {
  if (locked) {
    return blacklistItem(item);
  } else {
    return false;
  }
}

function redirectIfNotLocked(locked, onLockClicked, redirectOk, page) {
  if (locked && isPageBlacklisted(locked, page)) {
    return Curry._1(onLockClicked, undefined);
  } else {
    return Curry._1(redirectOk, page);
  }
}

function use(locked, page, redirectToMissions, redirect) {
  React.useEffect((function () {
          if (isPageBlacklisted(locked, page)) {
            Curry._1(redirectToMissions, undefined);
          }
          
        }), [
        page,
        locked
      ]);
  var match = React.useState(function () {
        return false;
      });
  var setLockedModal = match[1];
  var onToggleLocked = function (param) {
    return Curry._1(setLockedModal, (function (l) {
                  return !l;
                }));
  };
  return [
          (function (param) {
              return redirectIfNotLocked(locked, onToggleLocked, redirect, param);
            }),
          onToggleLocked,
          match[0]
        ];
}

export {
  blacklistPage ,
  blacklistItem ,
  isPageBlacklisted ,
  isItemBlacklisted ,
  redirectIfNotLocked ,
  use ,
  
}
/* react Not a pure module */
