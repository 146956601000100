// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function BlockStyleClassName(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: className
            }, children);
}

var make = BlockStyleClassName;

var $$default = BlockStyleClassName;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
