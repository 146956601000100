// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as V2EventsContext$LiteracyplanetTupperware from "./V2EventsContext.bs.js";
import * as K2ExerciseInSeries$LiteracyplanetTupperware from "./K2ExerciseInSeries.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.bs.js";

function K2ExerciseInSeriesContainer(Props) {
  var questionCount = Props.questionCount;
  var children = Props.children;
  var callbacks = V2EventsContext$LiteracyplanetTupperware.use(undefined);
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.use(undefined);
  var state = match.state;
  return React.createElement(K2ExerciseInSeries$LiteracyplanetTupperware.make, {
              questionCount: questionCount,
              questionsComplete: state.questionsComplete,
              callbacks: callbacks,
              setState: match.setState,
              state: state,
              children: children
            });
}

var make = K2ExerciseInSeriesContainer;

var $$default = K2ExerciseInSeriesContainer;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
