// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as PlayAudioIcon$LiteracyplanetTupperware from "./PlayAudioIcon.bs.js";
import * as AudioButtonApi$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/AudioButtonApi.bs.js";

function AudioPillButton$PillButton(Props) {
  var onTogglePlay = Props.onTogglePlay;
  var children = Props.children;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.AudioPillButton.PillButton.className,
              onClick: (function (ev) {
                  Curry._1(onTogglePlay, undefined);
                  ev.stopPropagation();
                  
                })
            }, children);
}

var PillButton = {
  make: AudioPillButton$PillButton
};

function AudioPillButton(Props) {
  var isPlaying = Props.isPlaying;
  var src = Props.src;
  var onTogglePlay = Props.onTogglePlay;
  return React.createElement(AudioButtonApi$LiteracyplanetClientFlags.make, {
              isPlaying: isPlaying,
              src: src,
              onEnd: onTogglePlay,
              onTogglePlay: onTogglePlay,
              children: (function (state, onTogglePlay) {
                  return React.createElement(AudioPillButton$PillButton, {
                              onTogglePlay: onTogglePlay,
                              children: React.createElement(PlayAudioIcon$LiteracyplanetTupperware.make, {
                                    state: state
                                  })
                            });
                })
            });
}

var make = AudioPillButton;

export {
  PillButton ,
  make ,
  
}
/* react Not a pure module */
