// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactPdf from "react-pdf";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as PdfViewer$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/PdfViewer.bs.js";
import Icon_arrow_right_exit_1Svg from "./assets/icon_arrow_right_exit_1.svg";
import * as WindowUtils$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/WindowUtils.bs.js";
import ArrowAltCircleLeftRegularSvg from "./assets/arrow-alt-circle-left-regular.svg";
import ArrowAltCircleRightRegularSvg from "./assets/arrow-alt-circle-right-regular.svg";
import * as LibraryComponents$LiteracyplanetClientSxLibrary from "./LibraryComponents.bs.js";
import * as ClickWordToHearContainer$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/ClickWordToHearContainer.bs.js";

var leftArrow = ArrowAltCircleLeftRegularSvg;

var rightArrow = ArrowAltCircleRightRegularSvg;

var rightExitArrow = Icon_arrow_right_exit_1Svg;

var mapElements = Belt_Array.map;

function usePages(param) {
  var match = React.useState(function () {
        return 0;
      });
  var setPage = match[1];
  var page = match[0];
  var changePage = function (offset) {
    return Curry._1(setPage, (function (param) {
                  return page + offset | 0;
                }));
  };
  return [
          page,
          changePage
        ];
}

function usePageLinks(pages, onClickBack, page, changePage) {
  var match = React.useState(function () {
        return 0;
      });
  var setNumPages = match[1];
  var numPages = match[0];
  var previousButton = page > 0 ? React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.LeftArrowButton.make, {
          src: leftArrow,
          onClick: (function (param) {
              return Curry._1(changePage, -1);
            })
        }) : React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.LeftBlankButton.make, {});
  var nextButton = page === (numPages - 1 | 0) ? React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.RightArrowButton.make, {
          src: rightExitArrow,
          onClick: (function (param) {
              return Curry._1(onClickBack, undefined);
            })
        }) : (
      page < (numPages - 1 | 0) ? React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.RightArrowButton.make, {
              src: rightArrow,
              onClick: (function (param) {
                  return Curry._1(changePage, 1);
                })
            }) : React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.RightBlankButton.make, {})
    );
  var optionalAudio = Belt_Option.flatMap(Caml_option.undefined_to_opt(pages.find(function (x) {
                return x.pageIndex === page;
              })), (function (param) {
          return param.mp3;
        }));
  var playButton = optionalAudio !== undefined ? React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.AudioButton.make, {
          audioPath: optionalAudio
        }) : React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.NoAudioButton.make, {});
  var onDocumentLoadSuccess = function (success) {
    Curry._1(setNumPages, (function (param) {
            return success.numPages;
          }));
    return Curry._1(changePage, 0);
  };
  var pageNumber = page + 1 | 0;
  return [
          previousButton,
          nextButton,
          playButton,
          pageNumber,
          onDocumentLoadSuccess
        ];
}

function Pdf(Props) {
  var pdf = Props.pdf;
  var loading = Props.loading;
  var pages = Props.pages;
  var landscapeHeightPercentOpt = Props.landscapeHeightPercent;
  var portraitWidthPercentOpt = Props.portraitWidthPercent;
  var onClickBack = Props.onClickBack;
  var landscapeHeightPercent = landscapeHeightPercentOpt !== undefined ? landscapeHeightPercentOpt : 0.98;
  var portraitWidthPercent = portraitWidthPercentOpt !== undefined ? portraitWidthPercentOpt : 0.98;
  var match = usePages(undefined);
  var match$1 = usePageLinks(pages, onClickBack, match[0], match[1]);
  var match$2 = WindowUtils$LiteracyplanetClientFlags.useWindowSize(undefined);
  var height = match$2[1];
  var width = match$2[0];
  var isPortrait = height > width;
  var bookHeight = isPortrait ? undefined : height * landscapeHeightPercent | 0;
  var bookWidth = isPortrait ? width * portraitWidthPercent | 0 : undefined;
  return React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.NavWrapper.make, {
              children: null
            }, match$1[0], React.createElement(LibraryComponents$LiteracyplanetClientSxLibrary.StyledDocument.make, {
                  children: React.createElement(ReactPdf.Document, {
                        file: pdf,
                        onLoadSuccess: match$1[4],
                        loading: loading,
                        children: React.createElement(ClickWordToHearContainer$LiteracyplanetClientFlags.make, {
                              children: React.createElement(ReactPdf.Page, {
                                    pageNumber: match$1[3],
                                    height: bookHeight,
                                    width: bookWidth,
                                    renderMode: "canvas",
                                    renderTextLayer: true
                                  })
                            })
                      })
                }), match$1[1], match$1[2]);
}

var $$Document = PdfViewer$LiteracyplanetClientFlags.$$Document;

var Page = PdfViewer$LiteracyplanetClientFlags.Page;

var make = Pdf;

export {
  $$Document ,
  Page ,
  leftArrow ,
  rightArrow ,
  rightExitArrow ,
  mapElements ,
  usePages ,
  usePageLinks ,
  make ,
  
}
/* leftArrow Not a pure module */
