// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function DynamicLevelBar$LevelBarValueCurve(Props) {
  var percentValue = Props.percentValue;
  var width = 3.55 * percentValue;
  var top = (181.013).toString();
  var left = (442.312).toString();
  var bottom = (181.013 + 13.605).toString();
  var right = (442.312 + width).toString();
  var barPath = "M" + (right + ("," + (top + ("L" + (left + ("," + (top + ("C439.681," + (top + (" 437.159,181.73 435.299,183.005C433.439,184.281 432.394,186.011 432.394,187.815C432.394,187.815 432.394,187.816 432.394,187.816C432.394,189.62 433.439,191.35 435.299,192.626C437.159,193.901 439.681," + (bottom + (" " + (left + ("," + (bottom + ("C490.706," + (bottom + (" " + (right + ("," + (bottom + (" " + (right + ("," + (bottom + ("L" + (right + ("," + (top + "Z")))))))))))))))))))))))))))));
  var shineWidth = 3.53 * percentValue;
  (415.692).toString();
  var shineRight = (415.692 + shineWidth).toString();
  var shineTop = (184.001).toString();
  var shinePath = "M" + (shineRight + ("," + (shineTop + ("L" + (shineRight + ("," + (shineTop + ("C414.558,184.616 413.639,184.616 413.639,185.374C413.639,185.374 413.639,185.375 413.639,185.376C413.639,186.134 414.558,186.748 " + (shineRight + (",186.748C439.995,186.748 " + (shineRight + (",186.748 " + (shineRight + (",186.748L" + (shineRight + ",184.001Z")))))))))))))));
  return React.createElement("g", undefined, React.createElement("g", {
                  transform: "matrix(0.930122,0,0,1.70286,33.6162,-123.394)"
                }, React.createElement("path", {
                      d: barPath,
                      fill: "rgb(55,141,239)"
                    }), React.createElement("clipPath", {
                      id: "_clip1"
                    }, React.createElement("path", {
                          d: barPath
                        })), React.createElement("g", {
                      clipPath: "url(#_clip1)"
                    }, React.createElement("g", {
                          transform: "matrix(1.74007,0,-0.415342,0.817866,-206.57,34.3629)"
                        }, React.createElement("g", {
                              transform: "matrix(0.492084,-0,0.171387,0.718024,387.355,155.68)"
                            }, React.createElement("use", {
                                  height: "23.167px",
                                  width: "311.238px",
                                  transform: "matrix(0.997556,0,0,0.965287,0,0)",
                                  x: "35.653",
                                  xlinkHref: "#_Image2",
                                  y: "34.09"
                                }))), React.createElement("g", {
                          opacity: "0.83"
                        }, React.createElement("g", {
                              transform: "matrix(0.856261,-0,-0,0.587247,402.795,161.689)"
                            }, React.createElement("use", {
                                  height: "19.842px",
                                  width: "312.235px",
                                  transform: "matrix(0.997556,0,0,0.992077,0,0)",
                                  x: "34.653",
                                  xlinkHref: "#_Image3",
                                  y: "36.522"
                                }))))), React.createElement("g", {
                  transform: "matrix(0.930809,0,0,1.74891,55.8983,-133.801)"
                }, React.createElement("path", {
                      d: shinePath,
                      fill: "rgb(174,255,237)",
                      fillOpacity: "0.5"
                    })));
}

var LevelBarValueCurve = {
  make: DynamicLevelBar$LevelBarValueCurve
};

function DynamicLevelBar$LevelBarValueSquareCurve(Props) {
  var percentValue = Props.percentValue;
  var width = 3.55 * percentValue;
  var top = (179.013).toString();
  var left = (442.312).toString();
  var bottom = (179.013 + 13.605).toString();
  var right = (442.312 + width).toString();
  var barPath = "M" + (right + ("," + (top + ("L" + (left + ("," + (top + ("C439.681," + (top + (" 437.159,181.73 435.299,183.005C433.439,184.281 432.394,186.011 432.394,187.815C432.394,187.815 432.394,187.816 432.394,187.816C432.394,189.62 433.439,191.35 435.299,192.626C437.159,193.901 439.681," + (bottom + (" " + (left + ("," + (bottom + ("C490.706," + (bottom + (" " + (right + ("," + (bottom + (" " + (right + ("," + (bottom + ("L" + (right + ("," + (top + "Z")))))))))))))))))))))))))))));
  var shineWidth = 3.53 * percentValue;
  (415.692).toString();
  var shineRight = (415.692 + shineWidth).toString();
  var shineTop = (184.001).toString();
  var shinePath = "M" + (shineRight + ("," + (shineTop + ("L" + (shineRight + ("," + (shineTop + ("C414.558,Z 413.639,184.616 413.639,185.374C413.639,185.374 413.639,185.375 413.639,185.376C413.639,186.134 414.558,186.748 " + (shineRight + (",186.748C439.995,186.748 " + (shineRight + (",186.748 " + (shineRight + (",186.748L" + (shineRight + ",184.001Z")))))))))))))));
  return React.createElement("g", undefined, React.createElement("g", {
                  transform: "matrix(0.930122,0,0,1.70286,33.6162,-123.394)"
                }, React.createElement("path", {
                      d: barPath,
                      fill: "rgb(55,141,239)"
                    }), React.createElement("clipPath", {
                      id: "_clip1"
                    }, React.createElement("path", {
                          d: barPath
                        })), React.createElement("g", {
                      clipPath: "url(#_clip1)"
                    }, React.createElement("g", {
                          transform: "matrix(1.74007,0,-0.415342,0.817866,-206.57,34.3629)"
                        }, React.createElement("g", {
                              transform: "matrix(0.492084,-0,0.171387,0.718024,409.508,178.77)"
                            }, React.createElement("use", {
                                  height: "23.167px",
                                  width: "311.238px",
                                  transform: "matrix(0.997556,0,0,0.965287,0,0)",
                                  x: "1.752",
                                  xlinkHref: "#_Image2",
                                  y: "0.777"
                                }))), React.createElement("g", {
                          opacity: "0.83"
                        }, React.createElement("g", {
                              transform: "matrix(0.856261,-0,-0,0.587247,431.752,180.573)"
                            }, React.createElement("use", {
                                  height: "19.842px",
                                  width: "312.235px",
                                  transform: "matrix(0.997556,0,0,0.992077,0,0)",
                                  x: "0.752",
                                  xlinkHref: "#_Image3",
                                  y: "4.108"
                                }))))), React.createElement("g", {
                  transform: "matrix(0.930809,0,0,1.74891,55.8983,-133.801)"
                }, React.createElement("path", {
                      d: shinePath,
                      fill: "rgb(174,255,237)",
                      fillOpacity: "0.5"
                    })));
}

var LevelBarValueSquareCurve = {
  make: DynamicLevelBar$LevelBarValueSquareCurve
};

function DynamicLevelBar$LevelBarValue(Props) {
  var className = Props.className;
  var percentValue = Props.percentValue;
  return React.createElement("svg", {
              className: className,
              version: "1.1",
              viewBox: "0 0 506 87",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink",
              xmlSpace: "preserve"
            }, React.createElement("g", {
                  transform: "matrix(1,0,0,1,-386.21,-136.102)"
                }, React.createElement("g", {
                      transform: "matrix(1.25561,0,0,1,-126.411,-15.8379)"
                    }, React.createElement("g", {
                          id: "Main-Menu---1-Row"
                        }, React.createElement("g", {
                              id: "Drop-Down"
                            }, React.createElement("g", {
                                  id: "Progress-Bar"
                                }, React.createElement(DynamicLevelBar$LevelBarValueCurve, {
                                      percentValue: percentValue
                                    })))))), React.createElement("defs", undefined, React.createElement("defs", undefined, React.createElement("image", {
                          id: "_Image2",
                          height: "24px",
                          width: "426px",
                          xlinkHref: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAaoAAAAYCAYAAACr+rk8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEj0lEQVR4nO2dy24cRRSG/9PdtuP4Nn4U3gXxMKyQEJvwBEjwAIgNLFEUyRuUBYrFBkskrBJZkHCZ+3hm3FWHhXvGY+zEsfuvqM/0+bZ2pk8+Vbs840/Voqq4iVfdiJM/S5y8Djj5I+D5XwGz8ubvfW82RLPDHNiQeq/zfxTQfoCOI/eFN6t5C/K8EdABaV6Byn4O2c0Ig11FpwrtBSBo/TndpQ2X/QCduMvatNHllmjWKYCCMNgq8YaXHE4Vj56c4fFvc96FrIhekFXz7iSY90yh/RIIqD2vbInKYQHkjMlWiID2AvSMcJOldtkrgegu66Jn8eI+Yrh8kKl0cnfZZJeh2khZLg9yyMMELicR2g9XN6qfX5b4/McJ3gwj7UJJRfcCdMp9FyXbmcpBw+f9AAuDcpNZcdnJIdsJXI4jdEB02ckB9pjukkcAtFtCZ4RfmjOodArINvmdHmy6XG5UXz+d4punU9T8cO+SHCoHCUX3A6D1RS/J5eIme5Bg3lG1MAjzysPqhz97YZRA7JUA4yZrvUtF7AZg7i5r00aXO5nKvrtcdVkAwPGrkrpJyU61aNn/B6boS1R2M8h+gnnPq3nPCfMW1cLYSrAwhhE6pNxk7tJd0mijy+wwBzYTuBxE6Miuy2JaKr54POFsUqlEa7VoOaIv2RDNOqnmDdCLj1DrL4y9HLKX8RfGvFoYJeEmS+lyEKCjlrlMFR2xXApUdt0lzeViXbJZE5fFVz9Ncdqr+TephKJ1VsUSDNELrMybqkhiVpLu0o7LroFSjllwpnJppTpcoxq2+PZ4Vu9KFvLOFWRLVBIllNQc9cBIdegum+/SRCnnLlmsYw1bxPt+5mck71xipZSzUEm6y/a5tFJwusu1LDjv9TuHiVR2hZSlnPZIOaqRStKMy07R/LrLC053ScKMy255rxjubhuVEdFLbBRJNirJtrs8V8Seu6Tg1SGPlrh8343KhuhLVPYyyF6CeefVD6wm56i6sjAopVxCl6wiyV0acek1LIWW1bC3b1QW8s5VLMxrpZRLfzYj4C7rYak6ZBacHXfZphr27RtVA5LEO2Fl3oQHN/ohmCTcJQ8rLq1Uhy2tYW9UaSLvXMEPbjRSyrlLDm0s5dxlq10W7/oiE6boJX5wI+8QzNTVYdNdesHJw13y8BoWAFBkAkQ1kneu4Ac3+iGYLEyUcl5w8nCXPD5QDVt8/NGmfvcyNl70kpQHN7YwRzVwNqO7tODSa1ge7vIaMp4F/eSHEf6NxA8smXnnAj+40atDFu6Sh7vk4TXsWxFVxdGLiX56TDg/3R8J3/y6y13acGmhlKO7NFDKWXC5hgWnqF5sUI+Oevr96417X5eZJC6xdAhmAw5ufCdGzmb0x5gbcMkuOFO5tFLKeQ17+79ZbFQA8OT5WL/8JWCgd3iFVOfz+cGNzS/l3CUPd8nDa1geDSk4r2xUAPDPOOhnR0M8G93+7sofCd+sgxuv0XqXRqpDLzhpuEseTaoOr21UC168meuz0xl+/Tvg9xFwOi9QLq7ij4RveiXpLt0ljTa69EfCN8vlf/fuGxX71S9lAAAAAElFTkSuQmCC"
                        }), React.createElement("image", {
                          id: "_Image3",
                          height: "23px",
                          width: "427px",
                          xlinkHref: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAasAAAAXCAYAAAC1bmDXAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAsElEQVR4nO3cMW4CQRBE0VqrZWQDxr4gJ+cuJiFuwu7gPWnzn5W0mpnj/v84AgCLfUwHAMA7FYMFwHLGCoD16vUBwFrGCoD1KsnndAQAdCrJaToCADqV5Gs6AgA6leR7OgIAOpXkOh0BAJ1K8jMdAQCdSnKbjgCATiX5nY4AgE4l+ZuOAICOsQJgPb8BAVjPWAGwntOAAKznnhUA61WSy3QEAHQqyXk6AgA6Xl0HYL0ng9oDd+TIWhkAAAAASUVORK5CYII="
                        }))));
}

var LevelBarValue = {
  make: DynamicLevelBar$LevelBarValue
};

function DynamicLevelBar$LevelBarValueSquare(Props) {
  var className = Props.className;
  var percentValue = Props.percentValue;
  return React.createElement("svg", {
              className: className,
              clipRule: "evenodd",
              fillRule: "evenodd",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: "1.5",
              version: "1.1",
              viewBox: "0 0 314 25",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink",
              xmlSpace: "preserve"
            }, React.createElement("g", {
                  transform: "matrix(1,0,0,1,-422.223,-168.258)"
                }, React.createElement("g", {
                      transform: "matrix(1.35009,0,0,1,-165.39,-15.8379)"
                    }, React.createElement("g", {
                          transform: "matrix(0.930019,0,0,1,30.4975,0)"
                        }, React.createElement(DynamicLevelBar$LevelBarValueSquareCurve, {
                              percentValue: percentValue
                            })))), React.createElement("defs", undefined, React.createElement("image", {
                      id: "_Image2",
                      height: "24px",
                      width: "312px",
                      xlinkHref: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATgAAAAYCAYAAABupOHoAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEi0lEQVR4nO2dz27bRhDGvyHXcZw/dmz5RfoqPfRdemwPBYoCfYEAPfZWJJceigJtc2lzSdpLUiBtT05RIbWsyLL1L1xODyKd2FalnSGdFc35joLI4ZL7W+7sfFoRM2ORDvo5nnUzPO96/N71ePGvxzRb/N0zEZj2HOgmLf/eEvEoB7/2AEN1EtpOme4m6vh4w8gPMyAXxt8gTvYdoA3NAPc9eJzL4hKYdh1oq8I9HzO4n4nvOW0lTLspdE8KQA7kvQyYsewMCTjpOOBGhTaf5OCBF5+A7iRMO6k6LjJG3vNAJmyzK/pXhdD82oNPhf0LYLqXgm7rmeIpg48UTG0SJ3vVmHIXPzudMb78YYxvn89kJ0uJk04KbFTodMMcfCzvdADqAX1SPAgp6DcTpj0DXaSsaLOBvlo1gM69DDwV3msCU8eBNt//hIVuFS9Prfy8f50b4J4eZPj0uxG6x7nsZBs0B61Kp+t78Ejc6eaqD3RAOExFBb3jFryiwhULdMx43uYYoB958EQxS46UmdQFOt4I+1cdE5bjHDxUvDzryMJ6GeBBZ3h89XiC+z9PsCIJvXwxdcxejjJA+nYp1UbQbxRtNtDDFRN0ZWZSD+ge8MI2x5qwUMHUrQqz5AtZmAOAX19musHtdsJ0r2KnO1TMXkoZ6HIZ6OG6RqAHh27ycstpwdQ7V+9mGeOz70fywW0nZboTYfZSxt8qHoRWatCLBxED9Lsp07aBLoobE3RNZpIUL88q614LQA9R5QlLzOWWgQefXO5f7v4vExz0BWtuEat2Z5dQFfSMkR+2DPQpg3sK0DeJqeMMdInWEPRVijZhueIszH39ZBp+sohVu/Jw2m0o6NpKVpNB1y5BuCIddhXa3EbQ22gzOlyehTkfOnmLV56fq42gp8TJfkXQjz14qAA9lp/QQJeryrrXfqzlloRpu6KfMCALC5sMxyrPl2oj6GYclquVfkIzDi/TygEuWtWuVJNBb2Mly0APPsyMw4rQQvfB0gEuWnm+jN980AEzDq88rNGgm80oTJGMw4u7c8Ty/NklGOhyrWkl639loKsU1084/4WA6LiI7oPLGEdczC8Pp50Uja1kGehhajzoLbMZNXS55fwAF7E8D6Ae0Mc5uN8k0M04rFErQVf6Ca/iFwIhWgc/4dsBLlZ5vlSTQTfjcHhoMw7LZcbhcF0Yx1yaAPlmpPJ8qToqWbrdSMw4rAndND+hGYfluiY2I/fhB46/6eovRl2eLy/KQJerjcZhAz08tBmH334+mnn+6OEJerkcOHV5vgxuoMtlxuHw0G00DtfxC4Fr5CckZsajP0f88RPZfiJrsbW4tmTdVNAbWsky47BMrfQTXpFxmMr/ZPji0YAf/BOWKtrW4mYcXqkG+wnNOCwIveY2I3r3T2d++mPEn/+WYbAkXbWtxdsDuvkJBaHXHPRlus7GYbr4r1qDsedPfhzi8XDj/DfXYWtxXSXLjMOa0AZ6sMw4rIj7nrKwSwNcqRevZvz05RTPeh5/DYG/u4TppIVbi5txOFhmHFbENeNwsDRFyf8AeVdpA3XT96IAAAAASUVORK5CYII="
                    }), React.createElement("image", {
                      id: "_Image3",
                      height: "20px",
                      width: "313px",
                      xlinkHref: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATkAAAAUCAYAAAD2pEqtAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAsElEQVR4nO3aXUrlABCE0e9qM4o/jM7sz5W7F33JXUIINOdAoB7rqWiS3L5+vm8BLDXVw9UlAM4y1ePVJQDOMscDsNJUf64uAXCWqZ6O7AMEsM5UL1eXADjLVG9HdskB60z19+oSAGeZ6uPILjlgnan+HdnIAetM9f/IRg5Yx8gBq91HzsABK93fyd0ydMBCU31m5ICl7r+QGDhgpanec8kBS031moEDlprqOZccsNQvIDMDgnSytjgAAAAASUVORK5CYII="
                    })));
}

var LevelBarValueSquare = {
  make: DynamicLevelBar$LevelBarValueSquare
};

export {
  LevelBarValueCurve ,
  LevelBarValueSquareCurve ,
  LevelBarValue ,
  LevelBarValueSquare ,
  
}
/* react Not a pure module */
