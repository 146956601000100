// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ReactUpdateLegacy from "../../../../node_modules/rescript-react-update/src/ReactUpdateLegacy.bs.js";
import * as VideoControls$LiteracyplanetClientSxActivity from "./VideoControls.bs.js";

import "./VideoStyles.css"
;

function getRangeVal($$event) {
  return Number($$event.target.value);
}

function call(method_, video) {
  return Curry._1(method_, video);
}

function set(method_, value, video) {
  return Curry._2(method_, video, value);
}

function mapVideoRef(video) {
  var partial_arg = (video == null) ? undefined : Caml_option.some(video);
  return function (param) {
    return Belt_Option.mapWithDefault(partial_arg, undefined, param);
  };
}

function optDuration(video) {
  var duration = video.duration;
  if (Number.isNaN(duration)) {
    return ;
  } else {
    return duration;
  }
}

function getDefaultDuration(video) {
  return Belt_Option.mapWithDefault(optDuration(video), 0.0, (function (x) {
                return x;
              }));
}

function makePayload(video, state, message, callback) {
  return mapVideoRef(video)(function (video) {
              var payload_paused = video.paused;
              var payload_duration = getDefaultDuration(video);
              var payload_currentTime = video.currentTime;
              var payload_volume = video.volume;
              var payload_muted = video.muted;
              var payload_ended = video.ended;
              var payload = {
                message: message,
                paused: payload_paused,
                duration: payload_duration,
                currentTime: payload_currentTime,
                volume: payload_volume,
                muted: payload_muted,
                ended: payload_ended
              };
              return Curry._2(callback, video, payload);
            });
}

function onPlayClick(param, video, state, param$1) {
  var onPause = param.onPause;
  var onPlay = param.onPlay;
  return makePayload(video, state, state.paused ? "Pressed Play" : "Pressed Paused", (function (video, payload) {
                if (state.paused) {
                  video.play();
                  return Curry._1(onPlay, payload);
                } else {
                  video.pause();
                  return Curry._1(onPause, payload);
                }
              }));
}

function onMuteClick(param, video, state, param$1) {
  var onMute = param.onMute;
  return makePayload(video, state, "Pressed Mute", (function (video, payload) {
                video.muted = !state.muted;
                return Curry._1(onMute, payload);
              }));
}

function onVolumeChange(_callbacks, video, state, $$event) {
  return mapVideoRef(video)(function (video) {
              video.volume = Number($$event.target.value);
              
            });
}

function onVolumeEnd(param, video, state, _event) {
  var onVolumeChange = param.onVolumeChange;
  return makePayload(video, state, "Pressed Mute", (function (param, payload) {
                return Curry._1(onVolumeChange, payload);
              }));
}

var onVolumeTouchEnd = onVolumeEnd;

function onScrubChange(_callbacks, video, state, $$event) {
  return mapVideoRef(video)(function (video) {
              video.currentTime = Number($$event.target.value);
              
            });
}

function onScrubStart(param, video, state, param$1) {
  var onScrubStart$1 = param.onScrubStart;
  return makePayload(video, state, "Started Scrubbing", (function (video, payload) {
                video.pause();
                return Curry._1(onScrubStart$1, payload);
              }));
}

function onScrubEnd(param, video, state, param$1) {
  var onScrubEnd$1 = param.onScrubEnd;
  return makePayload(video, state, "Ended Scrubbing", (function (video, payload) {
                video.play();
                return Curry._1(onScrubEnd$1, payload);
              }));
}

function onTouchScrubStart(callbacks, video, state, param) {
  return onScrubStart(callbacks, video, state, undefined);
}

function onTouchScrubEnd(callbacks, video, state, param) {
  return onScrubEnd(callbacks, video, state, undefined);
}

function onTerminated(param, video, state) {
  var onTerminated$1 = param.onTerminated;
  return makePayload(video, state, "User Cancelled Video", (function (param, payload) {
                return Curry._1(onTerminated$1, payload);
              }));
}

function onInitialized(param, video, state) {
  var onInitialized$1 = param.onInitialized;
  return makePayload(video, state, "Video Initialized", (function (param, payload) {
                return Curry._1(onInitialized$1, payload);
              }));
}

function onEnded(param, video, state) {
  var onEnded$1 = param.onEnded;
  return makePayload(video, state, "Video Ended", (function (param, payload) {
                return Curry._1(onEnded$1, payload);
              }));
}

function syncStateWithVideoPlayer(video, state, send) {
  return mapVideoRef(video)(function (video) {
              var currentTime = video.currentTime;
              var muted = video.muted;
              var volume = video.volume;
              var paused = video.paused;
              var ended = video.ended;
              Belt_Option.mapWithDefault(optDuration(video), undefined, (function (duration) {
                      return Curry._1(send, {
                                  TAG: /* SetDuration */2,
                                  _0: duration
                                });
                    }));
              if (ended) {
                Curry._1(send, /* SetEnded */0);
              }
              Curry._1(send, {
                    TAG: /* SetCurrentTime */3,
                    _0: currentTime
                  });
              Curry._1(send, {
                    TAG: /* SetMuted */4,
                    _0: muted
                  });
              Curry._1(send, {
                    TAG: /* SetVolume */0,
                    _0: volume
                  });
              return Curry._1(send, {
                          TAG: /* SetPaused */1,
                          _0: paused
                        });
            });
}

var Actions = {
  call: call,
  set: set,
  mapVideoRef: mapVideoRef,
  optDuration: optDuration,
  getDefaultDuration: getDefaultDuration,
  makePayload: makePayload,
  onPlayClick: onPlayClick,
  onMuteClick: onMuteClick,
  onVolumeChange: onVolumeChange,
  onVolumeEnd: onVolumeEnd,
  onVolumeTouchEnd: onVolumeTouchEnd,
  onScrubChange: onScrubChange,
  onScrubStart: onScrubStart,
  onScrubEnd: onScrubEnd,
  onTouchScrubStart: onTouchScrubStart,
  onTouchScrubEnd: onTouchScrubEnd,
  onTerminated: onTerminated,
  onInitialized: onInitialized,
  onEnded: onEnded,
  syncStateWithVideoPlayer: syncStateWithVideoPlayer
};

function VideoPlayer(Props) {
  var source = Props.source;
  var callbacks = Props.callbacks;
  var videoRef = React.useRef(null);
  var match = ReactUpdateLegacy.useReducerWithMapState((function (param) {
          return {
                  volume: 1.0,
                  paused: true,
                  scrubStarted: 0.0,
                  duration: 0.0,
                  currentTime: 0.0,
                  muted: false,
                  ended: false,
                  endedCalled: false
                };
        }), (function (action, state) {
          if (typeof action === "number") {
            return {
                    TAG: /* Update */0,
                    _0: {
                      volume: state.volume,
                      paused: state.paused,
                      scrubStarted: state.scrubStarted,
                      duration: state.duration,
                      currentTime: state.currentTime,
                      muted: state.muted,
                      ended: state.ended,
                      endedCalled: true
                    }
                  };
          }
          switch (action.TAG | 0) {
            case /* SetVolume */0 :
                return {
                        TAG: /* Update */0,
                        _0: {
                          volume: action._0,
                          paused: state.paused,
                          scrubStarted: state.scrubStarted,
                          duration: state.duration,
                          currentTime: state.currentTime,
                          muted: state.muted,
                          ended: state.ended,
                          endedCalled: state.endedCalled
                        }
                      };
            case /* SetPaused */1 :
                return {
                        TAG: /* Update */0,
                        _0: {
                          volume: state.volume,
                          paused: action._0,
                          scrubStarted: state.scrubStarted,
                          duration: state.duration,
                          currentTime: state.currentTime,
                          muted: state.muted,
                          ended: state.ended,
                          endedCalled: state.endedCalled
                        }
                      };
            case /* SetDuration */2 :
                return {
                        TAG: /* Update */0,
                        _0: {
                          volume: state.volume,
                          paused: state.paused,
                          scrubStarted: state.scrubStarted,
                          duration: action._0,
                          currentTime: state.currentTime,
                          muted: state.muted,
                          ended: state.ended,
                          endedCalled: state.endedCalled
                        }
                      };
            case /* SetCurrentTime */3 :
                return {
                        TAG: /* Update */0,
                        _0: {
                          volume: state.volume,
                          paused: state.paused,
                          scrubStarted: state.scrubStarted,
                          duration: state.duration,
                          currentTime: action._0,
                          muted: state.muted,
                          ended: state.ended,
                          endedCalled: state.endedCalled
                        }
                      };
            case /* SetMuted */4 :
                return {
                        TAG: /* Update */0,
                        _0: {
                          volume: state.volume,
                          paused: state.paused,
                          scrubStarted: state.scrubStarted,
                          duration: state.duration,
                          currentTime: state.currentTime,
                          muted: action._0,
                          ended: state.ended,
                          endedCalled: state.endedCalled
                        }
                      };
            
          }
        }));
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          onInitialized(callbacks, videoRef.current, state);
          var intervalId = setInterval((function (param) {
                  return syncStateWithVideoPlayer(videoRef.current, state, send);
                }), 10);
          return (function (param) {
                    clearInterval(intervalId);
                    return onTerminated(callbacks, videoRef.current, state);
                  });
        }), []);
  React.useEffect((function () {
          if (state.endedCalled) {
            onEnded(callbacks, videoRef.current, state);
          }
          
        }), [state.endedCalled]);
  var partial_arg = videoRef.current;
  var partial_arg$1 = videoRef.current;
  var partial_arg$2 = videoRef.current;
  var partial_arg$3 = videoRef.current;
  var partial_arg$4 = videoRef.current;
  var partial_arg$5 = videoRef.current;
  var partial_arg$6 = videoRef.current;
  var partial_arg$7 = videoRef.current;
  var partial_arg$8 = videoRef.current;
  var partial_arg$9 = videoRef.current;
  return React.createElement("div", {
              className: "PlayerContainer"
            }, React.createElement("div", {
                  className: "VPPlayer"
                }, React.createElement("div", {
                      className: "VPPlayer-video"
                    }, React.createElement("div", {
                          className: "VPVideo"
                        }, React.createElement("video", {
                              key: source,
                              ref: videoRef,
                              onContextMenu: (function (prim) {
                                  prim.preventDefault();
                                  
                                })
                            }, React.createElement("source", {
                                  src: source
                                })))), React.createElement(VideoControls$LiteracyplanetClientSxActivity.make, {
                      currentTime: state.currentTime,
                      duration: state.duration,
                      paused: state.paused,
                      volume: state.volume,
                      muted: state.muted,
                      onVolumeChange: (function (param) {
                          return onVolumeChange(callbacks, partial_arg, state, param);
                        }),
                      onVolumeEnd: (function (param) {
                          return onVolumeEnd(callbacks, partial_arg$1, state, param);
                        }),
                      onVolumeTouchEnd: (function (param) {
                          return onVolumeTouchEnd(callbacks, partial_arg$2, state, param);
                        }),
                      onPlayClick: (function (param) {
                          return onPlayClick(callbacks, partial_arg$3, state, param);
                        }),
                      onScrubStart: (function (param) {
                          return onScrubStart(callbacks, partial_arg$4, state, param);
                        }),
                      onTouchScrubStart: (function (param) {
                          return onTouchScrubStart(callbacks, partial_arg$5, state, param);
                        }),
                      onScrubEnd: (function (param) {
                          return onScrubEnd(callbacks, partial_arg$6, state, param);
                        }),
                      onScrubChange: (function (param) {
                          return onScrubChange(callbacks, partial_arg$7, state, param);
                        }),
                      onTouchScrubEnd: (function (param) {
                          return onTouchScrubEnd(callbacks, partial_arg$8, state, param);
                        }),
                      onMuteClick: (function (param) {
                          return onMuteClick(callbacks, partial_arg$9, state, param);
                        })
                    })));
}

var make = VideoPlayer;

export {
  getRangeVal ,
  Actions ,
  make ,
  
}
/*  Not a pure module */
