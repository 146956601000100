// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "../../../node_modules/rescript/lib/es6/belt_SetString.js";
import * as Error$LiteracyplanetClientErrors from "../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as GameType$LiteracyplanetClientFlags from "./GameType.bs.js";

function makeExtraVocals(name, fileName) {
  return [
          {
            locale: "en_AU",
            accent: "Australian",
            source: "https://intrepica-support-dir.literacyplanet.com/audio/extra-vocals/en-au/" + fileName,
            name: name
          },
          {
            locale: "en_GB",
            accent: "British",
            source: "https://intrepica-support-dir.literacyplanet.com/audio/extra-vocals/en-gb/" + fileName,
            name: name
          },
          {
            locale: "en_US",
            accent: "American",
            source: "https://intrepica-support-dir.literacyplanet.com/audio/extra-vocals/en-us/" + fileName,
            name: name
          }
        ];
}

function mapExtraVocals(arr) {
  return Belt_Array.reduce(Belt_Array.concat(arr, [[
                    "as in",
                    "as-in.mp3"
                  ]]), [], (function (acc, param) {
                return Belt_Array.concat(acc, makeExtraVocals(param[0], param[1]));
              }));
}

var types = [
  {
    id: "Lesson",
    deprecated: false,
    activityType: {
      TAG: /* TupperwareActivity */1,
      _0: {
        scoreType: /* AccuracyBased */0
      }
    },
    contentKind: /* TupperwareContent */1,
    meta: {
      icon: "lesson",
      name: "Tupperware"
    }
  },
  {
    id: "lesson",
    deprecated: true,
    activityType: {
      TAG: /* TupperwareActivity */1,
      _0: {
        scoreType: /* AccuracyBased */0
      }
    },
    contentKind: /* TupperwareContent */1,
    meta: {
      icon: "lesson",
      name: "Tupperware"
    }
  },
  {
    id: "tupperware",
    deprecated: true,
    activityType: {
      TAG: /* TupperwareActivity */1,
      _0: {
        scoreType: /* AccuracyBased */0
      }
    },
    contentKind: /* TupperwareContent */1,
    meta: {
      icon: "tupperware",
      name: "???"
    }
  },
  {
    id: "instructional",
    deprecated: true,
    activityType: /* InstructionalVideo */0,
    contentKind: /* VideoContent */2,
    meta: {
      icon: "instructional",
      name: "???"
    }
  },
  {
    id: "Instructional",
    deprecated: false,
    activityType: /* InstructionalVideo */0,
    contentKind: /* VideoContent */2,
    meta: {
      icon: "instructional",
      name: "Video"
    }
  },
  {
    id: "spell_test",
    deprecated: false,
    activityType: /* TupperwareSpellTestActivity */1,
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "spell_test",
      name: "Spell Test"
    }
  },
  {
    id: "ss",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "ss",
        gameInstructions: mapExtraVocals([[
                "intro",
                "stubmunk-sentence-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "ss",
      name: "Stubmunk School"
    }
  },
  {
    id: "fwm",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "fwm",
        gameInstructions: mapExtraVocals([[
                "intro",
                "feed-word-monster-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "fwm",
      name: "Feed Word Monster"
    }
  },
  {
    id: "smc",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "smc",
        gameInstructions: mapExtraVocals([[
                "intro",
                "stubmunk-multiple-choice-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "smc",
      name: "Stubmunk Multiple Choice"
    }
  },
  {
    id: "ws",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "ws",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "ws-instruction.mp3"
              ],
              [
                "promptFirstWord",
                "ws-prompt-first-word.mp3"
              ],
              [
                "promptNextWord",
                "ws-prompt-next-word.mp3"
              ],
              [
                "promptAfterWord",
                "ws-prompt-after-word.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "ws",
      name: "Snowshoe Shuffle"
    }
  },
  {
    id: "fwapenguin",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "fwaPenguin",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "fwa-instruction.mp3"
              ],
              [
                "promptFirstWord",
                "fwa-prompt-first-word.mp3"
              ],
              [
                "promptNextWord",
                "fwa-prompt-next-word.mp3"
              ],
              [
                "promptAfterWord",
                "fwa-prompt-after-word.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "fwapenguin",
      name: "Parachuting Penguins"
    }
  },
  {
    id: "fwaCroc",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "fwaCroc",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "fwa-instruction.mp3"
              ],
              [
                "promptFirstWord",
                "fwa-prompt-first-word.mp3"
              ],
              [
                "promptNextWord",
                "fwa-prompt-next-word.mp3"
              ],
              [
                "promptAfterWord",
                "fwa-prompt-after-word.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "fwacroc",
      name: "Parachuting Pirates"
    }
  },
  {
    id: "sw",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "sw",
        gameInstructions: mapExtraVocals([[
                "intro",
                "sw-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "sw",
      name: "Abra-crab-dabra"
    }
  },
  {
    id: "snap",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "snap",
        gameInstructions: mapExtraVocals([[
                "intro",
                "snap-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "snap",
      name: "Stubmunk Snap"
    }
  },
  {
    id: "fw",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "fw",
        gameInstructions: mapExtraVocals([[
                "intro",
                "fw-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordAnswer */0
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "fw",
      name: "Bubbles"
    }
  },
  {
    id: "wf",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "wf",
        gameInstructions: mapExtraVocals([[
                "intro",
                "wf-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* NotRenderable */3
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "wf",
      name: "Word Finder"
    }
  },
  {
    id: "wbCard",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "wbCard",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "wbc-instruction.mp3"
              ],
              [
                "prompt01",
                "wbc-prompt01.mp3"
              ],
              [
                "prompt02",
                "wbc-prompt02.mp3"
              ],
              [
                "prompt03",
                "wbc-prompt03.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "wbcard",
      name: "Card Clam-ity"
    }
  },
  {
    id: "wbWaterfall",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "wbWaterfall",
        gameInstructions: mapExtraVocals([[
                "intro",
                "wbw-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "wbwaterfall",
      name: "Waterfall Words"
    }
  },
  {
    id: "wbDesert",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "wbDesert",
        gameInstructions: mapExtraVocals([[
                "intro",
                "wbw-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "wbdesert",
      name: "Rocky Ruins"
    }
  },
  {
    id: "wb",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "wb",
        gameInstructions: mapExtraVocals([[
                "intro",
                "word-builder-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "wb",
      name: "Cannon Chaos"
    }
  },
  {
    id: "fc",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "fc",
        gameInstructions: mapExtraVocals([[
                "intro",
                "flash-card-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "fc",
      name: "Word Jammin' Jellypus"
    }
  },
  {
    id: "fcu",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "fcu",
        gameInstructions: mapExtraVocals([[
                "intro",
                "fcu-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "fcu",
      name: "Shipwreck Spelling"
    }
  },
  {
    id: "stw",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "stw",
        gameInstructions: mapExtraVocals([[
                "intro",
                "spell-this-word-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "stw",
      name: "Battle Rocket"
    }
  },
  {
    id: "sj",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "sj",
        gameInstructions: mapExtraVocals([[
                "intro",
                "sj-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* SentenceAnswer */2
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "sj",
      name: "Sentence Jumble"
    }
  },
  {
    id: "cp",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "cp",
        gameInstructions: mapExtraVocals([[
                "intro",
                "code-phrase-instruction-2.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* SentenceAnswer */2
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "cp",
      name: "Code Phrase"
    }
  },
  {
    id: "jig",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "jig",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "jig-instruction.mp3"
              ],
              [
                "promptWhatDoes",
                "jig-promptWhatDoes.mp3"
              ],
              [
                "promptStartWith",
                "jig-promptStartWith.mp3"
              ],
              [
                "promptEndWith",
                "jig-promptEndWith.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* WordSentenceAnswer */1
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "jig",
      name: "Cloud Maker"
    }
  },
  {
    id: "mem",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "mem",
        gameInstructions: mapExtraVocals([[
                "intro",
                "mem-instruction.mp3"
              ]]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WordGameData */0,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* NotRenderable */3
      }
    },
    contentKind: /* WordlistContent */0,
    meta: {
      icon: "mem",
      name: "Memory Game"
    }
  },
  {
    id: "wam",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "wam",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "wam-intro.mp3"
              ],
              [
                "instruct1",
                "wam-instruct-1.mp3"
              ],
              [
                "instruct2",
                "wam-instruct-2.mp3"
              ],
              [
                "instruct3",
                "wam-instruct-3.mp3"
              ],
              [
                "wrong1",
                "wam-wrong-1.mp3"
              ],
              [
                "wrong2",
                "wam-wrong-2.mp3"
              ],
              [
                "wrong3",
                "wam-wrong-3.mp3"
              ],
              [
                "wrong4",
                "wam-wrong-4.mp3"
              ],
              [
                "missed1",
                "wam-missed-1.mp3"
              ],
              [
                "missed2",
                "wam-missed-2.mp3"
              ],
              [
                "missed3",
                "wam-missed-3.mp3"
              ],
              [
                "streak_1",
                "wam-streak-1.mp3"
              ],
              [
                "streak_2",
                "wam-streak-2.mp3"
              ],
              [
                "streak_3",
                "wam-streak-3.mp3"
              ],
              [
                "streak_4",
                "wam-streak-4.mp3"
              ],
              [
                "streak_5",
                "wam-streak-5.mp3"
              ],
              [
                "streak_6",
                "wam-streak-6.mp3"
              ],
              [
                "streak_7",
                "wam-streak-7.mp3"
              ],
              [
                "streak_8",
                "wam-streak-8.mp3"
              ],
              [
                "streak_9",
                "wam-streak-9.mp3"
              ],
              [
                "streak_10",
                "wam-streak-10.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_WhackAMunkData */2,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* NotRenderable */3
      }
    },
    contentKind: /* WhackAMunkContent */4,
    meta: {
      icon: "wam",
      name: "Stubmunk Slam"
    }
  },
  {
    id: "st",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "st",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "sound-train-instructionFindPicture.mp3"
              ],
              [
                "clickToHear",
                "sound-train-promptClickToHear.mp3"
              ],
              [
                "questionBeginsWith",
                "sound-train-questionFindStartsWith.mp3"
              ],
              [
                "beginsWith01",
                "sound-train-promptBeginsWith01.mp3"
              ],
              [
                "beginsWith02",
                "sound-train-promptBeginsWith02.mp3"
              ],
              [
                "beginsWith03",
                "sound-train-promptBeginsWith03.mp3"
              ],
              [
                "beginsWith04",
                "sound-train-promptBeginsWith04.mp3"
              ],
              [
                "beginsWith05",
                "sound-train-promptBeginsWith04.mp3"
              ],
              [
                "feedbackStartsWith",
                "sound-train-feedbackStartsWith.mp3"
              ],
              [
                "feedbackFindStartsWith",
                "sound-train-feedbackFindStartsWith.mp3"
              ],
              [
                "questionContains",
                "sound-train-questionFindSound.mp3"
              ],
              [
                "contains01",
                "sound-train-promptContains01.mp3"
              ],
              [
                "contains02",
                "sound-train-promptContains02.mp3"
              ],
              [
                "contains03",
                "sound-train-promptContains03.mp3"
              ],
              [
                "contains04",
                "sound-train-promptContains04.mp3"
              ],
              [
                "contains05",
                "sound-train-promptContains05.mp3"
              ],
              [
                "feedbackDoesNotContain",
                "sound-train-feedbackDoesNotContain.mp3"
              ],
              [
                "feedbackFindContains",
                "sound-train-feedbackFindContains.mp3"
              ],
              [
                "questionEndsWith",
                "sound-train-questionFindEndsWith.mp3"
              ],
              [
                "endsWith01",
                "sound-train-promptEndsWith01.mp3"
              ],
              [
                "endsWith02",
                "sound-train-promptEndsWith02.mp3"
              ],
              [
                "endsWith03",
                "sound-train-promptEndsWith03.mp3"
              ],
              [
                "endsWith04",
                "sound-train-promptEndsWith04.mp3"
              ],
              [
                "endsWith05",
                "sound-train-promptEndsWith05.mp3"
              ],
              [
                "feedbackEndsWith",
                "sound-train-feedbackEndsWith.mp3"
              ],
              [
                "feedbackFindEndsWith",
                "sound-train-feedbackFindEndsWith.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_SoundTrainData */1,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* NotRenderable */3
      }
    },
    contentKind: /* SoundTrainContent */3,
    meta: {
      icon: "st",
      name: "Sound Train"
    }
  },
  {
    id: "mc",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "mc",
        gameInstructions: mapExtraVocals([
              [
                "intro",
                "monster-chef-instruction.mp3"
              ],
              [
                "promptFirst",
                "monster-chef-prompt-first.mp3"
              ],
              [
                "promptNext",
                "monster-chef-prompt-next.mp3"
              ],
              [
                "promptLast",
                "monster-chef-prompt-last.mp3"
              ],
              [
                "feedbackStartOf",
                "monster-chef-feedback-start-of.mp3"
              ],
              [
                "feedbackEndOf",
                "monster-chef-feedback-end-of.mp3"
              ],
              [
                "feedbackAfter",
                "monster-chef-feedback-after.mp3"
              ],
              [
                "feedbackIn",
                "monster-chef-feedback-in.mp3"
              ],
              [
                "expectedStartOf",
                "monster-chef-expected-start-of.mp3"
              ],
              [
                "expectedEndOf",
                "monster-chef-expected-end-of.mp3"
              ],
              [
                "expectedAfter",
                "monster-chef-expected-after.mp3"
              ]
            ]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_MonsterChefData */3,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* NotRenderable */3
      }
    },
    contentKind: /* MonsterChefContent */5,
    meta: {
      icon: "mc",
      name: "Monster Chef"
    }
  },
  {
    id: "ld",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "ld",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Ld */7
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "ld",
      name: "Letter Drop"
    }
  },
  {
    id: "lh",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "lh",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "lh",
      name: "Letter Hunt"
    }
  },
  {
    id: "ph",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "ph",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "ph",
      name: "Picture Hunt"
    }
  },
  {
    id: "pwp",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "pwp",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Pwp */6
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "pwp",
      name: "Picture Word Puzzle"
    }
  },
  {
    id: "sllp",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "sllp",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "sllp",
      name: "Blue Slider"
    }
  },
  {
    id: "slpl",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "slpl",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "slpl",
      name: "Green Slider"
    }
  },
  {
    id: "slpw",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "slpw",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "slpw",
      name: "Red Slider"
    }
  },
  {
    id: "slwp",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "slwp",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "slwp",
      name: "Purple Slider"
    }
  },
  {
    id: "sbtn",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "sbtn",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Pwp */6
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "sbtn",
      name: "Sound Blender"
    }
  },
  {
    id: "sc",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "sc",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* BatchedQuestion */1,
        studentResponseReportType: /* Phonics_Sc */5
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "sc",
      name: "Sound Card"
    }
  },
  {
    id: "sh",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "sh",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "sh",
      name: "Sound Hunt"
    }
  },
  {
    id: "wh",
    deprecated: false,
    activityType: {
      TAG: /* CobraActivity */0,
      _0: {
        cobraView: "wh",
        gameInstructions: mapExtraVocals([]),
        scoreType: /* AccuracyBased */0,
        cobraActivityProps: /* WordGameContent_UnsafeData */5,
        callbackType: /* PerQuestion */0,
        studentResponseReportType: /* Phonics_Lh */4
      }
    },
    contentKind: /* PhonicsContent */6,
    meta: {
      icon: "wh",
      name: "Word Hunt"
    }
  }
];

var starterAccessArcadeGames = Belt_SetString.fromArray(["awm"]);

var starterAccessMyWordsActivities = Belt_SetString.fromArray([
      "ss",
      "smc",
      "ws",
      "fc"
    ]);

var wordGameTypes = types.filter(function (param) {
      if (param.contentKind !== 0) {
        return false;
      }
      var tmp = param.activityType;
      if (typeof tmp === "number" || tmp.TAG !== /* CobraActivity */0) {
        return false;
      } else {
        return true;
      }
    });

var allWordGameTypes = types.filter(function (param) {
      if (param.deprecated) {
        return false;
      } else {
        return param.contentKind === 0;
      }
    });

function findByGameId(gameId) {
  return Belt_Array.getBy(types, (function (param) {
                return param.id === gameId;
              }));
}

function useGameType(gameId) {
  var match = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
  var onSetError = match.onSetError;
  var match$1 = React.useState(function () {
        return findByGameId(gameId);
      });
  var t = match$1[0];
  React.useEffect((function () {
          if (Belt_Option.isNone(t)) {
            Curry._1(onSetError, /* GameTypeLookupFailed */11);
          }
          
        }), []);
  return t;
}

var Query = GameType$LiteracyplanetClientFlags.Query;

export {
  Query ,
  makeExtraVocals ,
  mapExtraVocals ,
  types ,
  starterAccessArcadeGames ,
  starterAccessMyWordsActivities ,
  wordGameTypes ,
  allWordGameTypes ,
  findByGameId ,
  useGameType ,
  
}
/* types Not a pure module */
