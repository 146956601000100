// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_List from "../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Css_Legacy_Core from "../../../node_modules/bs-css/src/Css_Legacy_Core.bs.js";
import * as MobileDetect$LiteracyplanetClientFlags from "./MobileDetect.bs.js";

var dims = MobileDetect$LiteracyplanetClientFlags.getDimensions(undefined);

function toVh(pixel) {
  return Css.vh(pixel / dims.height * 100.0);
}

function toVw(pixel) {
  return Css.vw(pixel / dims.width * 100.0);
}

function dimensions(pixelWidth, pixelHeight) {
  return Curry._1(Css.style, {
              hd: Css.width(toVh(pixelWidth)),
              tl: {
                hd: Css.height(toVh(pixelHeight)),
                tl: {
                  hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                        hd: Css.width(toVw(pixelWidth)),
                        tl: {
                          hd: Css.height(toVw(pixelHeight)),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            });
}

function dimensionsHover(selectorName, pixelWidth, pixelHeight) {
  return Curry._1(Css.style, {
              hd: Css.hover({
                    hd: Css.selector("." + selectorName, {
                          hd: Css.width(toVh(pixelWidth)),
                          tl: {
                            hd: Css.height(toVh(pixelHeight)),
                            tl: {
                              hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                                    hd: Css.width(toVw(pixelWidth)),
                                    tl: {
                                      hd: Css.height(toVw(pixelHeight)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            });
}

function dimensionsActive(pixelWidth, pixelHeight) {
  return Curry._1(Css.style, {
              hd: Css.active({
                    hd: Css.width(toVh(pixelWidth)),
                    tl: {
                      hd: Css.height(toVh(pixelHeight)),
                      tl: {
                        hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                              hd: Css.width(toVw(pixelWidth)),
                              tl: {
                                hd: Css.height(toVw(pixelHeight)),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }),
              tl: /* [] */0
            });
}

function textStroke(borderStroke, shadowX, shadowY, color) {
  return Curry._1(Css.style, {
              hd: Css.textShadows({
                    hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), toVh(-borderStroke | 0), Css.zero, color),
                    tl: {
                      hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), toVh(-borderStroke | 0), Css.zero, color),
                      tl: {
                        hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), toVh(borderStroke), Css.zero, color),
                        tl: {
                          hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), toVh(borderStroke), Css.zero, color),
                          tl: {
                            hd: Css_Legacy_Core.Shadow.text(Css.zero, toVh(-borderStroke | 0), Css.zero, color),
                            tl: {
                              hd: Css_Legacy_Core.Shadow.text(Css.zero, toVh(borderStroke), Css.zero, color),
                              tl: {
                                hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), Css.zero, Css.zero, color),
                                tl: {
                                  hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), Css.zero, Css.zero, color),
                                  tl: {
                                    hd: Css_Legacy_Core.Shadow.text(toVh(borderStroke), toVh(shadowY), Css.zero, color),
                                    tl: {
                                      hd: Css_Legacy_Core.Shadow.text(toVh(-borderStroke | 0), toVh(shadowY), Css.zero, color),
                                      tl: {
                                        hd: Css_Legacy_Core.Shadow.text(Css.zero, toVh(shadowY), Css.zero, color),
                                        tl: {
                                          hd: Css_Legacy_Core.Shadow.text(toVh(shadowX), toVh(borderStroke), Css.zero, color),
                                          tl: {
                                            hd: Css_Legacy_Core.Shadow.text(toVh(shadowX), toVh(-borderStroke | 0), Css.zero, color),
                                            tl: {
                                              hd: Css_Legacy_Core.Shadow.text(toVh(shadowX), Css.zero, Css.zero, color),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                      hd: Css.textShadows({
                            hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), toVw(-borderStroke | 0), Css.zero, color),
                            tl: {
                              hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), toVw(-borderStroke | 0), Css.zero, color),
                              tl: {
                                hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), toVw(borderStroke), Css.zero, color),
                                tl: {
                                  hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), toVw(borderStroke), Css.zero, color),
                                  tl: {
                                    hd: Css_Legacy_Core.Shadow.text(Css.zero, toVw(-borderStroke | 0), Css.zero, color),
                                    tl: {
                                      hd: Css_Legacy_Core.Shadow.text(Css.zero, toVw(borderStroke), Css.zero, color),
                                      tl: {
                                        hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), Css.zero, Css.zero, color),
                                        tl: {
                                          hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), Css.zero, Css.zero, color),
                                          tl: {
                                            hd: Css_Legacy_Core.Shadow.text(toVw(borderStroke), toVw(shadowY), Css.zero, color),
                                            tl: {
                                              hd: Css_Legacy_Core.Shadow.text(toVw(-borderStroke | 0), toVw(shadowY), Css.zero, color),
                                              tl: {
                                                hd: Css_Legacy_Core.Shadow.text(Css.zero, toVw(shadowY), Css.zero, color),
                                                tl: {
                                                  hd: Css_Legacy_Core.Shadow.text(toVw(shadowX), toVw(borderStroke), Css.zero, color),
                                                  tl: {
                                                    hd: Css_Legacy_Core.Shadow.text(toVw(shadowX), toVw(-borderStroke | 0), Css.zero, color),
                                                    tl: {
                                                      hd: Css_Legacy_Core.Shadow.text(toVw(shadowX), Css.zero, Css.zero, color),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

function mapProps(l, f) {
  return Belt_List.map(l, (function (param) {
                return Curry._1(param[0], Curry._1(f, param[1]));
              }));
}

function resizeList(l) {
  return Belt_List.concat(mapProps(l, toVh), {
              hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), mapProps(l, toVw)),
              tl: /* [] */0
            });
}

function resize(l) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, mapProps(l, toVh)),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), mapProps(l, toVw)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

function resizeExtra(extraStyle, className, l) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, mapProps(l, toVh)),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), mapProps(l, toVw)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: extraStyle !== undefined ? Curry._1(Css.style, extraStyle) : Curry._1(Css.style, /* [] */0),
                  tl: {
                    hd: Belt_Option.getWithDefault(className, ""),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function mapRules(l, f) {
  return Belt_List.map(l, (function (t) {
                if (t.NAME === "css") {
                  return t.VAL;
                } else {
                  return Curry._1(f, t.VAL);
                }
              }));
}

function resizeWithMap(f, l) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Curry._1(f, mapRules(l, toVh)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.media("(max-aspect-ratio: " + (dims.aspectRatioString + ")"), {
                            hd: Curry._1(f, mapRules(l, toVw)),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

function resizeGridRows(l) {
  return resizeWithMap(Css.gridTemplateRows, l);
}

function resizeGridCols(l) {
  return resizeWithMap(Css.gridTemplateColumns, l);
}

var maximumWidth = dims.width;

var maximumHeight = dims.height;

export {
  dims ,
  toVh ,
  toVw ,
  dimensions ,
  dimensionsHover ,
  dimensionsActive ,
  textStroke ,
  mapProps ,
  resizeList ,
  resize ,
  resizeExtra ,
  mapRules ,
  resizeWithMap ,
  resizeGridRows ,
  resizeGridCols ,
  maximumWidth ,
  maximumHeight ,
  
}
/* dims Not a pure module */
