// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Bucket$LiteracyplanetTupperware from "../Bucket.bs.js";
import * as Reducer$LiteracyplanetTupperware from "../Reducer.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as V2ExerciseStateStoreContext$LiteracyplanetTupperware from "./V2ExerciseStateStoreContext.bs.js";

var split = (function (str, del) {
    return (str).split(del)
  });

function getGroupNameByResponseOptionId(answers, responseOptionId) {
  return Belt_List.reduce(answers, undefined, (function (acc, combinedId) {
                var parts = split(combinedId, Reducer$LiteracyplanetTupperware.splitChar);
                var left = Belt_Array.get(parts, 0);
                var right = Belt_Array.get(parts, 1);
                if (acc !== undefined) {
                  return acc;
                } else if (Caml_obj.caml_equal(left, responseOptionId)) {
                  return right;
                } else {
                  return ;
                }
              }));
}

function getSelectedGroupName(questionState, responseOptionId) {
  if (questionState) {
    return getGroupNameByResponseOptionId(Reducer$LiteracyplanetTupperware.extractAnswers(questionState._0), responseOptionId);
  }
  
}

function makeToggleResponse(param) {
  var match = V2ExerciseStateStoreContext$LiteracyplanetTupperware.useDispatch(undefined);
  var dispatch = match[1];
  return function (questionIndex, responseOptionId, groupId, param) {
    return Curry._1(dispatch, {
                TAG: /* SetQuestionState */2,
                _0: questionIndex,
                _1: {
                  TAG: /* ToggleResponse */0,
                  _0: {
                    TAG: /* BoolResponsePayload */0,
                    _0: {
                      TAG: /* IdPair */1,
                      _0: responseOptionId,
                      _1: groupId
                    }
                  }
                }
              });
  };
}

function getExerciseStates(questionIndex) {
  var match = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var answerVisible = Curry._1(match.isCorrectAnswerVisible, questionIndex);
  var correctAnswer = Curry._1(match.getCorrectAnswer, questionIndex);
  var step = Curry._1(match.getStep, questionIndex);
  return [
          answerVisible,
          correctAnswer,
          step
        ];
}

function getResponseState(questionIndex, responseOptionId) {
  var match = Bucket$LiteracyplanetTupperware.Context.use(undefined);
  var match$1 = getExerciseStates(questionIndex);
  var step = match$1[2];
  var answerVisible = match$1[0];
  var match$2;
  match$2 = typeof step === "number" ? [
      /* NotAttempted */0,
      false
    ] : (
      step.TAG === /* Attempted */0 ? [
          step._0,
          false
        ] : [
          step._1,
          true
        ]
    );
  var questionState = match$2[0];
  var responseColor = Belt_Option.flatMap(answerVisible !== undefined && answerVisible ? Belt_Option.flatMap(match$1[1], (function (__x) {
                return getGroupNameByResponseOptionId(__x, responseOptionId);
              })) : getSelectedGroupName(questionState, responseOptionId), match.lookupColour);
  return [
          match$2[1],
          responseColor,
          match.selectedGroup
        ];
}

function use(questionIndex, responseOptionId) {
  var match = getResponseState(questionIndex, responseOptionId);
  var selectedGroup = match[2];
  var toggleResponse = makeToggleResponse(undefined);
  var onToggle = Belt_Option.mapWithDefault(selectedGroup, (function (param) {
          
        }), (function (__x) {
          return Curry._3(toggleResponse, questionIndex, responseOptionId, __x);
        }));
  return [
          match[0],
          selectedGroup,
          match[1],
          onToggle
        ];
}

export {
  split ,
  getGroupNameByResponseOptionId ,
  getSelectedGroupName ,
  makeToggleResponse ,
  getExerciseStates ,
  getResponseState ,
  use ,
  
}
/* Bucket-LiteracyplanetTupperware Not a pure module */
