// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Context$LiteracyplanetClientFlags from "./Context.bs.js";

function default_onToggle(param) {
  
}

var $$default = {
  show: false,
  onToggle: default_onToggle
};

var T = {
  $$default: $$default
};

function Make($star) {
  var Ctx = Context$LiteracyplanetClientFlags.Make(T);
  var ToggleContext$Make$Provider = function (Props) {
    var show = Props.show;
    var onToggle = Props.onToggle;
    var children = Props.children;
    var value = React.useMemo((function () {
            return {
                    show: show,
                    onToggle: onToggle
                  };
          }), [show]);
    return React.createElement(Ctx.make, Curry._3(Ctx.makeProps, value, children, undefined));
  };
  var Provider = {
    make: ToggleContext$Make$Provider
  };
  var ToggleContext$Make = function (Props) {
    var show = Props.show;
    var children = Props.children;
    var match = React.useState(function () {
          return show;
        });
    var onSetShow = match[1];
    var onToggle = React.useCallback(function (param) {
          return Curry._1(onSetShow, (function (s) {
                        return !s;
                      }));
        });
    return React.createElement(ToggleContext$Make$Provider, {
                show: match[0],
                onToggle: onToggle,
                children: children
              });
  };
  var use = Ctx.use;
  var useShow = function (param) {
    return Curry._1(use, undefined).show;
  };
  var useState = function (param) {
    var match = Curry._1(use, undefined);
    return [
            match.show,
            match.onToggle
          ];
  };
  return {
          Ctx: Ctx,
          Provider: Provider,
          make: ToggleContext$Make,
          use: use,
          useShow: useShow,
          useState: useState
        };
}

export {
  T ,
  Make ,
  
}
/* react Not a pure module */
