// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function orig(json, correct) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          questionIndex: Json_decode.field("question", Json_decode.$$int, json) - 1 | 0,
          question: Json_decode.field("question", Json_decode.$$int, json),
          answers: Json_decode.field("answers", Json_decode.string, json),
          correct: correct
        };
}

function origPhonics(json, correct) {
  return {
          questionIndex: Json_decode.field("question", Json_decode.$$int, json) - 1 | 0,
          question: Json_decode.field("question", Json_decode.$$int, json),
          answers: Json_decode.field("answers", Json_decode.string, json),
          correct: correct
        };
}

function make(json, correct) {
  var _a;
  try {
    _a = Json_decode.field("name", Json_decode.string, json);
  }
  catch (exn){
    var exit = 0;
    var _a$1;
    try {
      _a$1 = Json_decode.field("answers", Json_decode.string, json);
      exit = 2;
    }
    catch (exn$1){
      return /* NotImplemented */0;
    }
    if (exit === 2) {
      return {
              TAG: /* OrigPhonics */1,
              _0: origPhonics(json, correct)
            };
    }
    
  }
  return {
          TAG: /* Orig */0,
          _0: orig(json, correct)
        };
}

var Decode = {
  orig: orig,
  origPhonics: origPhonics,
  make: make
};

function mapQuestionIndexAndAnswers(response, f) {
  if (typeof response === "number") {
    return ;
  }
  if (response.TAG === /* Orig */0) {
    var match = response._0;
    return Curry._2(f, match.questionIndex, match.answers);
  }
  var match$1 = response._0;
  return Curry._2(f, match$1.questionIndex, match$1.answers);
}

var Single = {
  Decode: Decode,
  mapQuestionIndexAndAnswers: mapQuestionIndexAndAnswers
};

function batman(json) {
  return {
          correct: Json_decode.field("correct", Json_decode.bool, json),
          answer: Json_decode.field("answer", Json_decode.string, json),
          questionIndex: 0,
          question: 0
        };
}

function make$1(json) {
  var match = Json_decode.field("tag", Json_decode.string, json);
  if (match === "arrayOfBatman") {
    return /* Batman */{
            _0: Belt_Array.mapWithIndex(Json_decode.field("answers", (function (param) {
                        return Json_decode.array(batman, param);
                      }), json), (function (i, b) {
                    return {
                            correct: b.correct,
                            answer: b.answer,
                            questionIndex: i,
                            question: i + 1 | 0
                          };
                  }))
          };
  } else {
    return /* NotImplemented */0;
  }
}

var Decode$1 = {
  batman: batman,
  make: make$1
};

var Batch = {
  Decode: Decode$1
};

export {
  Single ,
  Batch ,
  
}
/* No side effect */
