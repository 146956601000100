// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Fontfaceobserver from "fontfaceobserver";

import 'normalize.css'
;

function BlankUserLayout(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return true;
      });
  var setIsLoadingAsset = match[1];
  React.useEffect((function () {
          var __x = Promise.all([
                new Fontfaceobserver("Roboto").load(),
                new Fontfaceobserver("Lato").load()
              ]);
          __x.then(function (param) {
                Curry._1(setIsLoadingAsset, (function (param) {
                        return false;
                      }));
                return Promise.resolve(undefined);
              });
          
        }), []);
  if (match[0]) {
    return null;
  } else {
    return children;
  }
}

var make = BlankUserLayout;

var $$default = BlankUserLayout;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
