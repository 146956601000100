// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";
import * as FullscreenIcon$LiteracyplanetTupperware from "./FullscreenIcon.bs.js";

function FullScreenButton(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Flavor$LiteracyplanetTupperware.FullScreenButton.className,
              onClick: onClick
            }, React.createElement(FullscreenIcon$LiteracyplanetTupperware.make, {
                  className: Flavor$LiteracyplanetTupperware.FullScreenButton.FullScreenIcon.className
                }));
}

var make = FullScreenButton;

export {
  make ,
  
}
/* react Not a pure module */
