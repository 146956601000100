/* eslint-disable new-cap, no-invalid-this, react/prop-types */
import React from 'react';
import {Editor} from 'slate-react';
import {Value} from 'slate';
import {
  DataProviderPlugin
} from './plugins';
// import ExerciseLayoutContainer from './tupperware/ExerciseLayoutContainer.bs';
// import DropDownOptionContainer from './tupperware/DropDownOptionContainer.bs';
// import DropDownContainer from './tupperware/DropDownContainer.bs';
// import ClozeDropTextContainer from './tupperware/ClozeDropTextContainer.bs';
// import ClozeDragTextContainer from './tupperware/ClozeDragTextContainer.bs';
// import ClozeChoiceProvider from './tupperware/ClozeChoiceProvider.bs';
// import MultiChoiceOptionContainer from './tupperware/MultiChoiceOptionContainer.bs';
// import MultiChoiceGroupedOptionContainer from './tupperware/MultiChoiceGroupedOptionContainer.bs';
// import NonMarkingQuestion from './tupperware/NonMarkingQuestion.bs';
import BlockStyleClassName from './tupperware/BlockStyleClassName.bs';
import InlineStyleClassName from './tupperware/InlineStyleClassName.bs';
// import MultiChoiceOptionStatesContainer from './tupperware/MultiChoiceOptionStatesContainer.bs';
import ShuffleChildren from './tupperware/ShuffleChildren';
// import TextInputContainer from './tupperware/TextInputContainer.bs';
// import ShowAnswerWrapper from './tupperware/ShowAnswerWrapper.bs';
// import SideDrawer from './tupperware/SideDrawer.bs';
// import ShowContent from './tupperware/ShowContent.bs';
import SkilfulLayoutContainer from './tupperware/v2/SkilfulLayoutContainer.bs';
import PieceOfCakeLayoutContainer from './tupperware/v2/PieceOfCakeLayoutContainer.bs';
import SkilfulQuestionVisible from './tupperware/v2/SkilfulQuestionVisible.bs';
import PieceOfCakeQuestionVisible from './tupperware/v2/PieceOfCakeQuestionVisible.bs';
import SkilfulMultiChoiceContainer from './tupperware/v2/SkilfulMultiChoiceContainer.bs';
import PieceOfCakeMultiChoiceContainer from './tupperware/v2/PieceOfCakeMultiChoiceContainer.bs';
import SkilfulMultiChoiceSingleResponseContainer from './tupperware/v2/SkilfulMultiChoiceSingleResponseContainer.bs';
import PieceOfCakeMultiChoiceSingleResponseContainer from './tupperware/v2/PieceOfCakeMultiChoiceSingleResponseContainer.bs';
import SkilfulMultiChoiceMultiResponseContainer from './tupperware/v2/SkilfulMultiChoiceMultiResponseContainer.bs';
import PieceOfCakeMultiChoiceMultiResponseContainer from './tupperware/v2/PieceOfCakeMultiChoiceMultiResponseContainer.bs';
import K2BookQuestions from './tupperware/v2/K2BookQuestions.bs';
import K2Answers from './tupperware/v2/K2Answers.bs';
import SkilfulStemHeading from './tupperware/v2/SkilfulStemHeading.bs';
import StemHeading from './tupperware/v2/StemHeading.bs';
import ImageSoundQuestion from './tupperware/v2/ImageSoundQuestion.bs';
import AnswerBox from './tupperware/v2/AnswerBox.bs';
import AnswerBoxMed from './tupperware/v2/AnswerBoxMed.bs';
import AudioQuestionContainer from './tupperware/v2/AudioQuestionContainer.bs';
import AnswerBoxSlim from './tupperware/v2/AnswerBoxSlim.bs';
import SkilfulRadioButtonMultiChoice from './tupperware/v2/SkilfulRadioButtonMultiChoice.bs';
import SkilfulCheckboxButtonMultiChoice from './tupperware/v2/SkilfulCheckboxButtonMultiChoice.bs';
import SkilfulContent from './tupperware/v2/SkilfulContent.bs';
import SkilfulTextInputContainer from './tupperware/v2/SkilfulTextInputContainer.bs';
import SkilfulDropDownOptionContainer from './tupperware/v2/SkilfulDropDownOptionContainer.bs';
import SkilfulClozeDropTextContainer from './tupperware/v2/SkilfulClozeDropTextContainer.bs';
import SkilfulClozeDragTextContainer from './tupperware/v2/SkilfulClozeDragTextContainer.bs';
import V2ClozeChoiceProvider from './tupperware/v2/V2ClozeChoiceProvider.bs';
import SkilfulDragPanelSticky from './tupperware/v2/SkilfulDragPanelSticky.bs';
import SkilfulStimulusContainer from './tupperware/v2/SkilfulStimulusContainer.bs';
import SkilfulQuestionContent from './tupperware/v2/SkilfulQuestionContent.bs';
import SkilfulStimulusTabContent from './tupperware/v2/SkilfulStimulusTabContent.bs';
import SkilfulHighlightProvider from './tupperware/v2/SkilfulHighlightProvider.bs';
import SkilfulHighlightBucket from './tupperware/v2/SkilfulHighlightBucket.bs';
import SkilfulHighlightClickToAddToBucket from './tupperware/v2/SkilfulHighlightClickToAddToBucket.bs';

// const MC = require('./tupperware/MC.bs');
// const GroupedMcCheckBoxBucketButton = require('./tupperware/Bucket.bs').Button.make;
// const GroupedMcCheckBoxBucketProvider = require('./tupperware/Bucket.bs').Provider.make;
// const GroupedMcCheckbox = require('./tupperware/GroupedMcCheckbox.bs').make;

const plugins = [
  // DataProviderPlugin(ExerciseLayoutContainer, 'ExerciseLayoutContainer'),
  // DataProviderPlugin(DropDownOptionContainer, 'DropDownOptionContainer'),
  // DataProviderPlugin(DropDownContainer, 'DropDownContainer'),
  // DataProviderPlugin(ClozeDropTextContainer, 'ClozeDropTextContainer'),
  // DataProviderPlugin(ClozeChoiceProvider, 'ClozeChoiceProvider'),
  // DataProviderPlugin(ClozeDragTextContainer, 'ClozeDragTextContainer'),
  // DataProviderPlugin(MultiChoiceOptionContainer, 'MultiChoiceOptionContainer'),
  // DataProviderPlugin(MultiChoiceGroupedOptionContainer, 'MultiChoiceGroupedOptionContainer'),
  // DataProviderPlugin(NonMarkingQuestion, 'NonMarkingQuestion'),
  DataProviderPlugin(BlockStyleClassName, 'BlockStyleClassName'),
  DataProviderPlugin(InlineStyleClassName, 'InlineStyleClassName'),
  // DataProviderPlugin(MultiChoiceOptionStatesContainer, 'MultiChoiceOptionStatesContainer'),
  // DataProviderPlugin(MC.exactResponseRequiredComponent, 'MC.ExactResponseRequired'),
  // DataProviderPlugin(MC.responseRequiredComponent, 'MC.ResponseRequired'),
  // DataProviderPlugin(MC.singleResponseComponenet, 'MC.SingleResponse'),
  // DataProviderPlugin(MC.multipleResponseComponent, 'MC.MultipleResponse'),
  // DataProviderPlugin(TextInputContainer, 'TextInputContainer'),
  DataProviderPlugin(ShuffleChildren, 'ShuffleChildren'),
  // DataProviderPlugin(ShowAnswerWrapper, 'ShowAnswerWrapper'),
  // DataProviderPlugin(SideDrawer, 'SideDrawer'),
  // DataProviderPlugin(ShowContent, 'ShowContent'),
  // DataProviderPlugin(GroupedMcCheckBoxBucketButton, 'GroupedMcCheckbox.SetGroupButton'),
  // DataProviderPlugin(GroupedMcCheckBoxBucketProvider, 'GroupedMcCheckbox.GroupProvider'),
  // DataProviderPlugin(GroupedMcCheckbox, 'GroupedMcCheckbox.ClickToAddToGroupAndHighlight'),
  DataProviderPlugin(SkilfulLayoutContainer, 'SkilfulLayoutContainer'),
  DataProviderPlugin(PieceOfCakeLayoutContainer, 'PieceOfCakeLayoutContainer'),
  DataProviderPlugin(SkilfulQuestionVisible, 'SkilfulQuestionVisible'),
  DataProviderPlugin(PieceOfCakeQuestionVisible, 'PieceOfCakeQuestionVisible'),
  DataProviderPlugin(SkilfulMultiChoiceContainer, 'SkilfulMultiChoiceContainer'),
  DataProviderPlugin(PieceOfCakeMultiChoiceContainer, 'PieceOfCakeMultiChoiceContainer'),
  DataProviderPlugin(SkilfulMultiChoiceSingleResponseContainer, 'SkilfulMultiChoiceSingleResponseContainer'),
  DataProviderPlugin(PieceOfCakeMultiChoiceSingleResponseContainer, 'PieceOfCakeMultiChoiceSingleResponseContainer'),
  DataProviderPlugin(SkilfulMultiChoiceMultiResponseContainer, 'SkilfulMultiChoiceMultiResponseContainer'),
  DataProviderPlugin(PieceOfCakeMultiChoiceMultiResponseContainer, 'PieceOfCakeMultiChoiceMultiResponseContainer'),
  DataProviderPlugin(K2BookQuestions, 'K2BookQuestions'),
  DataProviderPlugin(K2Answers, 'K2Answers'),
  DataProviderPlugin(SkilfulStemHeading, 'SkilfulStemHeading'),
  DataProviderPlugin(StemHeading, 'StemHeading'),
  DataProviderPlugin(ImageSoundQuestion, 'ImageSoundQuestion'),
  DataProviderPlugin(AnswerBox, 'AnswerBox'),
  DataProviderPlugin(AnswerBoxMed, 'AnswerBoxMed'),
  DataProviderPlugin(AnswerBoxSlim, 'AnswerBoxSlim'),
  DataProviderPlugin(AudioQuestionContainer, 'AudioQuestionContainer'),
  DataProviderPlugin(SkilfulRadioButtonMultiChoice, 'SkilfulRadioButtonMultiChoice'),
  DataProviderPlugin(SkilfulCheckboxButtonMultiChoice, 'SkilfulCheckboxButtonMultiChoice'),
  DataProviderPlugin(SkilfulContent, 'SkilfulContent'),
  DataProviderPlugin(SkilfulTextInputContainer, 'SkilfulTextInputContainer'),
  DataProviderPlugin(SkilfulDropDownOptionContainer, 'SkilfulDropDownOptionContainer'),
  DataProviderPlugin(SkilfulClozeDropTextContainer, 'SkilfulClozeDropTextContainer'),
  DataProviderPlugin(SkilfulClozeDragTextContainer, 'SkilfulClozeDragTextContainer'),
  DataProviderPlugin(V2ClozeChoiceProvider, 'V2ClozeChoiceProvider'),
  DataProviderPlugin(SkilfulDragPanelSticky, 'SkilfulDragPanelSticky'),
  DataProviderPlugin(SkilfulStimulusContainer, 'SkilfulStimulusContainer'),
  DataProviderPlugin(SkilfulQuestionContent, 'SkilfulQuestionContent'),
  DataProviderPlugin(SkilfulStimulusTabContent, 'SkilfulStimulusTabContent'),
  DataProviderPlugin(SkilfulHighlightProvider, 'SkilfulHighlightProvider'),
  DataProviderPlugin(SkilfulHighlightBucket, 'SkilfulHighlightBucket'),
  DataProviderPlugin(SkilfulHighlightClickToAddToBucket, 'SkilfulHighlightClickToAddToBucket')
];

class ActivityContainer extends React.Component {
  state = {
    value: Value.fromJSON(JSON.parse(this.props.data))
  }
  onChange = ({value}) => {
    this.setState({value});
  }

  shouldComponentUpdate = (nextProps) => {
    return nextProps.data !== this.props.data;
  }

  renderNode = (props, editor, next) => {
    const {attributes, children, node} = props;
    let data;

    switch (node.type) {
      case 'image':
        return <img src={node.data.get('src')} {...attributes} />;
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'heading-one':
        data = node.data.toJS();
        return <h1 className={data.className} {...attributes}>{children}</h1>;
      case 'heading-two':
        data = node.data.toJS();
        return <h2 className={data.className} {...attributes}>{children}</h2>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'alignment':
        data = node.data.toJS();
        return <div {...attributes} style={{textAlign: data.align}}>{children}</div>;
      case 'table':
        return <Table {...props} />;
      case 'table_row':
        return <TableRow {...props} />;
      case 'table_cell':
        return <TableCell {...props} />;
      case 'paragraph':
        return <p {...attributes}>{children}</p>;
    }

    return next();
  }

  renderMark = (props, editor, next) => {
    const {children, mark, attributes} = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'code':
        return <code {...attributes}>{children}</code>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underlined':
        return <u {...attributes}>{children}</u>;
    }

    return next();
  }

  render() {
    return (
      <div>
        {this.renderEditor()}
      </div>
    );
  }
  renderEditor = () => {
    return (
      <div className="editor">
        <Editor
          renderNode={this.renderNode}
          renderMark={this.renderMark}
          value={this.state.value}
          onChange={this.onChange}
          plugins={plugins}
          spellCheck={false}
          autoFocus={false}
          readOnly={true}
          autoCorrect={false}
        />
      </div>
    );
  }
}

const tableStyle = {
  width: '15%',
  borderCollapse: 'collapse',
  borderTop: '1px solid black'
};

const rowStyle = {
  border: 'none',
  borderBottom: '1px solid black',
  borderRight: '1px solid black'
};

const cellStyle = {
  border: '1px solid black',
  borderTop: 'none',
  borderBottom: 'none',
  padding: '.2em',
  position: 'relative'
};

const Table = ({attributes, children}) => (
  <table style={tableStyle}>
    <tbody {...attributes}>{children}</tbody>
  </table>
);

const TableRow = ({attributes, children}) => (
  <tr style={rowStyle} {...attributes}>{children}</tr>
);

const TableCell = ({attributes, children}) => (
  <td style={cellStyle} {...attributes}>
    {children}
  </td>
);

export default ActivityContainer;
