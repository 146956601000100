// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Css_Legacy_Core from "../../../../../node_modules/bs-css/src/Css_Legacy_Core.bs.js";
import BackgroundJpg from "../assets/background.jpg";
import * as Theme$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/Theme.bs.js";
import * as K2Styles$LiteracyplanetTupperware from "./K2Styles.bs.js";

var backgroundSrc = BackgroundJpg;

var gap = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.gridColumnGap,
        20
      ],
      tl: /* [] */0
    });

var css = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.background({
              NAME: "url",
              VAL: backgroundSrc
            }),
        tl: {
          hd: Css.backgroundRepeat(Css.noRepeat),
          tl: {
            hd: Css.backgroundPosition("center"),
            tl: {
              hd: Css.backgroundAttachment("fixed"),
              tl: {
                hd: Css.gridTemplateColumns({
                      hd: {
                        NAME: "fr",
                        VAL: 1.0
                      },
                      tl: {
                        hd: {
                          NAME: "fr",
                          VAL: 1.0
                        },
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.gridTemplateAreas({
                        NAME: "areas",
                        VAL: {
                          hd: "question answers",
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.overflow(Css.hidden),
                    tl: {
                      hd: Css.fontFamilies(K2Styles$LiteracyplanetTupperware.layoutFonts),
                      tl: {
                        hd: Css.height({
                              NAME: "vh",
                              VAL: 100.0
                            }),
                        tl: {
                          hd: Css.selector("*", {
                                hd: Css.boxSizing(Css.borderBox),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var className = Curry._1(Css.merge, {
      hd: css,
      tl: {
        hd: gap,
        tl: {
          hd: "PieceOfCakeLayoutContainer-Layout",
          tl: /* [] */0
        }
      }
    });

var Layout = {
  gap: gap,
  css: css,
  className: className
};

var css$1 = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top("zero"),
        tl: {
          hd: Css.left("zero"),
          tl: {
            hd: Css.width({
                  NAME: "percent",
                  VAL: 100.0
                }),
            tl: {
              hd: Css.height({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: {
                hd: Css.zIndex(1000),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: {
                    hd: Css.gridTemplateColumns({
                          hd: Css.fr(1.0),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.gridTemplateRows({
                            hd: Css.fr(1.0),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.backgroundColor(K2Styles$LiteracyplanetTupperware.mainBackgroundColor),
                        tl: {
                          hd: Css.alignItems(Css.center),
                          tl: {
                            hd: Css.unsafe("justifyItems", "center"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var gridCols = Theme$LiteracyplanetClientFlags.resizeGridCols({
      hd: {
        NAME: "css",
        VAL: {
          NAME: "fr",
          VAL: 1.0
        }
      },
      tl: {
        hd: {
          NAME: "ratio",
          VAL: 100
        },
        tl: /* [] */0
      }
    });

var gridRows = Theme$LiteracyplanetClientFlags.resizeGridRows({
      hd: {
        NAME: "ratio",
        VAL: 100
      },
      tl: {
        hd: {
          NAME: "css",
          VAL: {
            NAME: "fr",
            VAL: 1.0
          }
        },
        tl: /* [] */0
      }
    });

var className$1 = Curry._1(Css.merge, {
      hd: css$1,
      tl: {
        hd: gridCols,
        tl: {
          hd: gridRows,
          tl: {
            hd: "StimulusLayout-FullPageStyling",
            tl: /* [] */0
          }
        }
      }
    });

var FullPageStyling = {
  css: css$1,
  gridCols: gridCols,
  gridRows: gridRows,
  className: className$1
};

var StimulusLayout = {
  FullPageStyling: FullPageStyling
};

var css$2 = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.alignSelf(Css.center),
        tl: {
          hd: Css.height({
                NAME: "percent",
                VAL: 50.0
              }),
          tl: {
            hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.primaryColor),
            tl: {
              hd: Css.hover({
                    hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.hoverColor),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.active({
                      hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.activeColor),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$2 = Curry._1(Css.merge, {
      hd: css$2,
      tl: {
        hd: "MinimizeBookIcon",
        tl: /* [] */0
      }
    });

var MinimizeBookIcon = {
  css: css$2,
  className: className$2
};

var css$3 = Curry._1(Css.style, {
      hd: Css.backgroundColor(Css.white),
      tl: {
        hd: Css.cursor(Css.pointer),
        tl: {
          hd: Css.display(Css.grid),
          tl: {
            hd: Css.gridColumnStart(2),
            tl: {
              hd: Css.gridColumnEnd(3),
              tl: /* [] */0
            }
          }
        }
      }
    });

var m = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        60
      ],
      tl: {
        hd: [
          Css.height,
          60
        ],
        tl: {
          hd: [
            Css.borderRadius,
            30
          ],
          tl: /* [] */0
        }
      }
    });

var className$3 = Curry._1(Css.merge, {
      hd: m,
      tl: {
        hd: css$3,
        tl: {
          hd: "MinimizeBookButton",
          tl: /* [] */0
        }
      }
    });

var MinimizeBookButton = {
  css: css$3,
  m: m,
  className: className$3
};

var elementStyle = Curry._1(Css.merge, {
      hd: K2Styles$LiteracyplanetTupperware.mainColumn,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.gridArea({
                      NAME: "ident",
                      VAL: "question"
                    }),
                tl: {
                  hd: Css.overflowX(Css.hidden),
                  tl: {
                    hd: Css.gridTemplateColumns({
                          hd: {
                            NAME: "fr",
                            VAL: 1.0
                          },
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.gridTemplateRows({
                            hd: "auto",
                            tl: {
                              hd: {
                                NAME: "fr",
                                VAL: 1.0
                              },
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: {
          hd: K2Styles$LiteracyplanetTupperware.scroll,
          tl: /* [] */0
        }
      }
    });

var resizeStyle = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.paddingTop,
        60
      ],
      tl: /* [] */0
    });

var className$4 = Curry._1(Css.merge, {
      hd: resizeStyle,
      tl: {
        hd: elementStyle,
        tl: {
          hd: "K2BookQuestions.Question",
          tl: /* [] */0
        }
      }
    });

var Question = {
  elementStyle: elementStyle,
  resizeStyle: resizeStyle,
  className: className$4
};

var className$5 = Curry._1(Css.style, {
      hd: Css.width(Css.fitContent),
      tl: {
        hd: Css.margin2(Css.zero, Css.auto),
        tl: {
          hd: Css.selector("h1", {
                hd: Css.fontSize(Css.vh(4.0)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var QuestionContent = {
  className: className$5
};

var className$6 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: Css.fr(1.0),
              tl: {
                hd: Css.fr(1.0),
                tl: {
                  hd: Css.fr(1.0),
                  tl: /* [] */0
                }
              }
            }),
        tl: {
          hd: Css.gridTemplateRows({
                hd: Css.fr(1.0),
                tl: {
                  hd: Css.auto,
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.width({
                  NAME: "percent",
                  VAL: 100.0
                }),
            tl: {
              hd: Css.height({
                    NAME: "percent",
                    VAL: 100.0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var AutoSizingBookPdf = {
  className: className$6
};

var css$4 = Curry._1(Css.style, {
      hd: Css.gridRowStart(1),
      tl: {
        hd: Css.gridRowEnd(3),
        tl: {
          hd: Css.gridColumnStart(1),
          tl: {
            hd: Css.gridColumnEnd(4),
            tl: /* [] */0
          }
        }
      }
    });

function resize(w, h) {
  return Theme$LiteracyplanetClientFlags.resize({
              hd: [
                Css.width,
                w
              ],
              tl: {
                hd: [
                  Css.height,
                  h
                ],
                tl: /* [] */0
              }
            });
}

function className$7(w, h) {
  return Curry._1(Css.merge, {
              hd: resize(w, h),
              tl: {
                hd: css$4,
                tl: {
                  hd: "BookContent",
                  tl: /* [] */0
                }
              }
            });
}

var BookContent = {
  css: css$4,
  resize: resize,
  className: className$7
};

var className$8 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.gridColumnStart(1),
          tl: {
            hd: Css.gridColumnEnd(4),
            tl: {
              hd: Css.gridRowStart(1),
              tl: {
                hd: Css.gridRowEnd(2),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var BookPdf = {
  className: className$8
};

var r = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.paddingTop,
        250
      ],
      tl: {
        hd: [
          Css.paddingBottom,
          250
        ],
        tl: /* [] */0
      }
    });

var className$9 = Curry._1(Css.merge, {
      hd: r,
      tl: {
        hd: "BookSpinner",
        tl: /* [] */0
      }
    });

var BookSpinnerStyle = {
  r: r,
  className: className$9
};

var className$10 = Curry._1(Css.style, {
      hd: Css.gridColumnStart(1),
      tl: {
        hd: Css.gridColumnEnd(2),
        tl: {
          hd: Css.gridRowStart(2),
          tl: {
            hd: Css.gridRowEnd(3),
            tl: {
              hd: Css.display(Css.grid),
              tl: /* [] */0
            }
          }
        }
      }
    });

var QuestionContentStyling = {
  className: className$10
};

var className$11 = Curry._1(Css.style, {
      hd: Css.unsafe("justifyItems", "center"),
      tl: {
        hd: Css.display(Css.grid),
        tl: /* [] */0
      }
    });

var BehaviourTabs = {
  className: className$11
};

var css$5 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.top("zero"),
        tl: {
          hd: Css.left("zero"),
          tl: {
            hd: Css.background(Css.white),
            tl: /* [] */0
          }
        }
      }
    });

var resizeStyle$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        80
      ],
      tl: {
        hd: [
          Css.height,
          54
        ],
        tl: {
          hd: [
            Css.borderBottomRightRadius,
            54
          ],
          tl: /* [] */0
        }
      }
    });

var className$12 = Curry._1(Css.merge, {
      hd: resizeStyle$1,
      tl: {
        hd: css$5,
        tl: {
          hd: "MainNav",
          tl: /* [] */0
        }
      }
    });

var MainNav = {
  css: css$5,
  resizeStyle: resizeStyle$1,
  className: className$12
};

var iconStyle = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.primaryColor),
        tl: {
          hd: Css.hover({
                hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.hoverColor),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.activeColor),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var resizeIcon = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: {
        hd: [
          Css.height,
          40
        ],
        tl: {
          hd: [
            Css.marginTop,
            7
          ],
          tl: {
            hd: [
              Css.marginLeft,
              7
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$13 = Curry._1(Css.merge, {
      hd: iconStyle,
      tl: {
        hd: resizeIcon,
        tl: /* [] */0
      }
    });

var ArrowLeftIcon = {
  iconStyle: iconStyle,
  resizeIcon: resizeIcon,
  className: className$13
};

var K2BookQuestions = {
  Question: Question,
  QuestionContent: QuestionContent,
  AutoSizingBookPdf: AutoSizingBookPdf,
  BookContent: BookContent,
  BookPdf: BookPdf,
  BookSpinnerStyle: BookSpinnerStyle,
  QuestionContentStyling: QuestionContentStyling,
  BehaviourTabs: BehaviourTabs,
  MainNav: MainNav,
  ArrowLeftIcon: ArrowLeftIcon
};

var elementCss = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.selector(".K2Stem", Theme$LiteracyplanetClientFlags.resizeList({
                      hd: [
                        Css.marginTop,
                        20
                      ],
                      tl: {
                        hd: [
                          Css.fontSize,
                          36
                        ],
                        tl: /* [] */0
                      }
                    })),
            tl: {
              hd: Css.selector("img", Belt_List.concat({
                        hd: Css.maxWidth({
                              NAME: "percent",
                              VAL: 90.0
                            }),
                        tl: {
                          hd: Css.borderStyle(Css.solid),
                          tl: {
                            hd: Css.borderColor(Css.white),
                            tl: {
                              hd: Css.background(Css.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }, Theme$LiteracyplanetClientFlags.resizeList({
                            hd: [
                              Css.marginTop,
                              20
                            ],
                            tl: {
                              hd: [
                                Css.borderWidth,
                                10
                              ],
                              tl: /* [] */0
                            }
                          }))),
              tl: {
                hd: Css.selector(".AudioQueryComponentButtonContainer", Theme$LiteracyplanetClientFlags.resizeList({
                          hd: [
                            Css.marginTop,
                            5
                          ],
                          tl: {
                            hd: [
                              Css.height,
                              40
                            ],
                            tl: /* [] */0
                          }
                        })),
                tl: {
                  hd: Css.selector(".MultiChoiceInstruction", Theme$LiteracyplanetClientFlags.resizeList({
                            hd: [
                              Css.fontSize,
                              30
                            ],
                            tl: /* [] */0
                          })),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var resizedMargin = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        10
      ],
      tl: {
        hd: [
          Css.marginRight,
          10
        ],
        tl: /* [] */0
      }
    });

var css$6 = Curry._1(Css.merge, {
      hd: elementCss,
      tl: {
        hd: resizedMargin,
        tl: /* [] */0
      }
    });

var className$14 = Curry._1(Css.merge, {
      hd: css$6,
      tl: {
        hd: "StemHeading",
        tl: /* [] */0
      }
    });

var StemHeading = {
  elementCss: elementCss,
  resizedMargin: resizedMargin,
  css: css$6,
  className: className$14
};

var className$15 = Curry._1(Css.style, {
      hd: Css.cursor(Css.pointer),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.primaryColor),
        tl: {
          hd: Css.hover({
                hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.hoverColor),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.activeColor),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var AudioQueryComponent = {
  className: className$15
};

var container = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.left("zero"),
        tl: {
          hd: Css.right("zero"),
          tl: {
            hd: Css.marginLeft(Css.auto),
            tl: {
              hd: Css.marginRight(Css.auto),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.justifyContent(Css.center),
                  tl: {
                    hd: Css.cursor(Css.pointer),
                    tl: {
                      hd: Css.color(Css.white),
                      tl: {
                        hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.primaryColor),
                        tl: {
                          hd: Css.hover({
                                hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.hoverColor),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.active({
                                  hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.activeColor),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var pillResized = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        92
      ],
      tl: {
        hd: [
          Css.height,
          40
        ],
        tl: {
          hd: [
            Css.borderRadius,
            20
          ],
          tl: {
            hd: [
              Css.top,
              -25
            ],
            tl: /* [] */0
          }
        }
      }
    });

var className$16 = Curry._1(Css.merge, {
      hd: container,
      tl: {
        hd: pillResized,
        tl: {
          hd: "PillButton",
          tl: /* [] */0
        }
      }
    });

var PillButton = {
  container: container,
  pillResized: pillResized,
  className: className$16
};

var AudioPillButton = {
  PillButton: PillButton
};

var gridStyle = Curry._1(Css.style, {
      hd: Css.alignSelf("center"),
      tl: {
        hd: Css.justifySelf("center"),
        tl: /* [] */0
      }
    });

var elementStyle$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.alignItems(Css.center),
          tl: {
            hd: Css.gridColumnStart(2),
            tl: {
              hd: Css.gridColumnEnd(3),
              tl: {
                hd: Css.gridRowStart(2),
                tl: {
                  hd: Css.gridRowEnd(3),
                  tl: {
                    hd: Css.cursor(Css.pointer),
                    tl: {
                      hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.primaryColor),
                      tl: {
                        hd: Css.hover({
                              hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.hoverColor),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.active({
                                hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.activeColor),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var resizeStyle$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        138
      ],
      tl: {
        hd: [
          Css.height,
          60
        ],
        tl: {
          hd: [
            Css.borderRadius,
            30
          ],
          tl: /* [] */0
        }
      }
    });

var className$17 = Curry._1(Css.merge, {
      hd: resizeStyle$2,
      tl: {
        hd: elementStyle$1,
        tl: {
          hd: gridStyle,
          tl: {
            hd: "PlayBookAudioButton",
            tl: /* [] */0
          }
        }
      }
    });

var PlayBookAudioButton = {
  gridStyle: gridStyle,
  elementStyle: elementStyle$1,
  resizeStyle: resizeStyle$2,
  className: className$17
};

var className$18 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(Css.white),
      tl: {
        hd: Css.justifySelf(Css.center),
        tl: {
          hd: Css.alignSelf(Css.center),
          tl: {
            hd: Css.height({
                  NAME: "percent",
                  VAL: 90.0
                }),
            tl: /* [] */0
          }
        }
      }
    });

function withClassName(cn) {
  return Curry._1(Css.merge, {
              hd: className$18,
              tl: {
                hd: cn,
                tl: /* [] */0
              }
            });
}

var PlayAudioIcon = {
  className: className$18,
  withClassName: withClassName
};

var className$19 = Curry._1(Css.merge, {
      hd: K2Styles$LiteracyplanetTupperware.mainColumn,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.gridArea({
                    NAME: "ident",
                    VAL: "answers"
                  }),
              tl: {
                hd: Css.overflowX(Css.hidden),
                tl: {
                  hd: Css.gridTemplateRows({
                        hd: {
                          NAME: "fr",
                          VAL: 1.0
                        },
                        tl: {
                          hd: {
                            NAME: "fr",
                            VAL: 1.0
                          },
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.alignItems(Css.flexEnd),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: {
          hd: K2Styles$LiteracyplanetTupperware.scroll,
          tl: {
            hd: Curry._1(Css.style, {
                  hd: Css.selector(".correct .CorrectPanel", {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(".wrong .IncorrectPanel", {
                          hd: Css.display("flex"),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.selector(".correct .SubmitAnswerPanel, .wrong .SubmitAnswerPanel", {
                            hd: Css.justifyContent(Css.flexEnd),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Answers = {
  className: className$19
};

var elementCss$1 = Curry._1(Css.style, {
      hd: Css.alignItems(Css.center),
      tl: {
        hd: Css.justifyContent(Css.center),
        tl: {
          hd: Css.color(Css.white),
          tl: {
            hd: Css.display(Css.none),
            tl: /* [] */0
          }
        }
      }
    });

var resized = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        53
      ],
      tl: /* [] */0
    });

var css$7 = Curry._1(Css.merge, {
      hd: elementCss$1,
      tl: {
        hd: resized,
        tl: /* [] */0
      }
    });

function className$20(className$21) {
  return Curry._1(Css.merge, {
              hd: css$7,
              tl: {
                hd: className$21,
                tl: {
                  hd: "MarkStatusPanel",
                  tl: /* [] */0
                }
              }
            });
}

var MarkStatusPanel = {
  elementCss: elementCss$1,
  resized: resized,
  css: css$7,
  className: className$20
};

var iconElement = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 40.0
          }),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(Css.white),
        tl: /* [] */0
      }
    });

var resizedIcon = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.marginLeft,
        15
      ],
      tl: /* [] */0
    });

var iconCss = Curry._1(Css.merge, {
      hd: iconElement,
      tl: {
        hd: resizedIcon,
        tl: /* [] */0
      }
    });

var disabledCss = Curry._1(Css.style, {
      hd: Css.background(K2Styles$LiteracyplanetTupperware.disabledButtonColor),
      tl: {
        hd: Css.hover({
              hd: Css.background(K2Styles$LiteracyplanetTupperware.disabledButtonColor),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.active({
                hd: Css.background(K2Styles$LiteracyplanetTupperware.disabledButtonColor),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var buttonStyles = Curry._1(Css.style, {
      hd: Css.color(Css.white),
      tl: {
        hd: Css.borderStyle("none"),
        tl: {
          hd: Css.fontFamilies(K2Styles$LiteracyplanetTupperware.layoutFonts),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: {
                hd: Css.justifyContent(Css.center),
                tl: {
                  hd: Css.cursor(Css.pointer),
                  tl: {
                    hd: Css.userSelect(Css.none),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var buttonSize = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        209
      ],
      tl: {
        hd: [
          Css.height,
          64
        ],
        tl: {
          hd: [
            Css.fontSize,
            24
          ],
          tl: {
            hd: [
              Css.borderRadius,
              32
            ],
            tl: /* [] */0
          }
        }
      }
    });

function className$21(className$22) {
  return Curry._1(Css.merge, {
              hd: buttonStyles,
              tl: {
                hd: buttonSize,
                tl: {
                  hd: className$22,
                  tl: {
                    hd: "Button",
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Button = {
  buttonStyles: buttonStyles,
  buttonSize: buttonSize,
  className: className$21
};

function className$22(className$23) {
  return Curry._1(Css.merge, {
              hd: className$23,
              tl: {
                hd: "MarkButton",
                tl: /* [] */0
              }
            });
}

var MarkButton = {
  className: className$22
};

var enabledCss = Curry._1(Css.style, {
      hd: Css.userSelect(Css.none),
      tl: {
        hd: Css.background(Theme$LiteracyplanetClientFlags.defaultGreen),
        tl: {
          hd: Css.hover({
                hd: Css.background(Theme$LiteracyplanetClientFlags.hoverGreen),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css.background(Theme$LiteracyplanetClientFlags.activeGreen),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var BehaviourMarkButton = {
  enabledCss: enabledCss
};

function className$23(className$24) {
  return Curry._1(Css.merge, {
              hd: className$24,
              tl: {
                hd: "NextButton",
                tl: /* [] */0
              }
            });
}

var NextButton = {
  className: className$23
};

var enabledCss$1 = Curry._1(Css.style, {
      hd: Css.userSelect(Css.none),
      tl: {
        hd: Css.background(Theme$LiteracyplanetClientFlags.primaryColor),
        tl: {
          hd: Css.hover({
                hd: Css.background(Theme$LiteracyplanetClientFlags.hoverColor),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.active({
                  hd: Css.background(Theme$LiteracyplanetClientFlags.activeColor),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var BehaviourNextButton = {
  enabledCss: enabledCss$1
};

var markClassname = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "percent",
            VAL: 45.0
          }),
      tl: {
        hd: Css_Legacy_Core.SVG.fill(Css.white),
        tl: /* [] */0
      }
    });

var textLabelClassname = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.paddingLeft,
        10
      ],
      tl: {
        hd: [
          Css.fontSize,
          24
        ],
        tl: /* [] */0
      }
    });

var correctStatusPanel = Curry._1(Css.style, {
      hd: Css.background(K2Styles$LiteracyplanetTupperware.correctPanelBackgroundColor),
      tl: {
        hd: Css.userSelect(Css.none),
        tl: /* [] */0
      }
    });

var incorrectStatusPanel = Curry._1(Css.style, {
      hd: Css.background(K2Styles$LiteracyplanetTupperware.incorrectPanelBackgroundColor),
      tl: {
        hd: Css.userSelect(Css.none),
        tl: /* [] */0
      }
    });

var submitAnswerPanelElement = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100.0
          }),
      tl: {
        hd: Css.background(K2Styles$LiteracyplanetTupperware.sumbitPanelBackgroundColor),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.justifyContent(Css.center),
              tl: /* [] */0
            }
          }
        }
      }
    });

var resizedSubmit = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.height,
        100
      ],
      tl: /* [] */0
    });

var submitAnswerPanel = Curry._1(Css.merge, {
      hd: submitAnswerPanelElement,
      tl: {
        hd: resizedSubmit,
        tl: {
          hd: "SubmitAnswerPanel",
          tl: /* [] */0
        }
      }
    });

var correctClassName = Curry._1(Css.merge, {
      hd: correctStatusPanel,
      tl: {
        hd: "CorrectPanel",
        tl: /* [] */0
      }
    });

var incorrectClassName = Curry._1(Css.merge, {
      hd: incorrectStatusPanel,
      tl: {
        hd: "IncorrectPanel",
        tl: /* [] */0
      }
    });

var K2Answers = {
  Answers: Answers,
  MarkStatusPanel: MarkStatusPanel,
  iconElement: iconElement,
  resizedIcon: resizedIcon,
  iconCss: iconCss,
  disabledCss: disabledCss,
  Button: Button,
  MarkButton: MarkButton,
  BehaviourMarkButton: BehaviourMarkButton,
  NextButton: NextButton,
  BehaviourNextButton: BehaviourNextButton,
  markClassname: markClassname,
  textLabelClassname: textLabelClassname,
  correctStatusPanel: correctStatusPanel,
  incorrectStatusPanel: incorrectStatusPanel,
  submitAnswerPanelElement: submitAnswerPanelElement,
  resizedSubmit: resizedSubmit,
  submitAnswerPanel: submitAnswerPanel,
  correctClassName: correctClassName,
  incorrectClassName: incorrectClassName
};

function className$24(columns) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.display(Css.grid),
                    tl: {
                      hd: columns !== undefined ? Css.gridTemplateColumns(Belt_List.map(Belt_List.make(columns, 1.0), (function (size) {
                                    return {
                                            NAME: "fr",
                                            VAL: size
                                          };
                                  }))) : Css.gridTemplateColumns({
                              hd: {
                                NAME: "fr",
                                VAL: 1.0
                              },
                              tl: /* [] */0
                            }),
                      tl: {
                        hd: Css.gridAutoRows(Css.fr(1.0)),
                        tl: {
                          hd: Css.selector(".CorrectAnswer", {
                                hd: Css.visibility(Css.hidden),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.selector(".selected.correct .CorrectAnswer", {
                                  hd: Css.visibility(Css.visible),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(".WrongAnswer", {
                                    hd: Css.visibility(Css.hidden),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.selector(".selected.wrong .WrongAnswer", {
                                      hd: Css.visibility(Css.visible),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.selector(".K2AnswerWrap-Answer", Belt_List.concat({
                                            hd: Css.height({
                                                  NAME: "percent",
                                                  VAL: 100.0
                                                }),
                                            tl: {
                                              hd: Css.borderStyle(Css.solid),
                                              tl: {
                                                hd: Css.borderColor(Css.white),
                                                tl: /* [] */0
                                              }
                                            }
                                          }, Theme$LiteracyplanetClientFlags.resizeList({
                                                hd: [
                                                  Css.borderWidth,
                                                  7
                                                ],
                                                tl: /* [] */0
                                              }))),
                                  tl: {
                                    hd: Css.selector(".selected .K2AnswerWrap-Answer", Belt_List.concat({
                                              hd: Css.borderStyle(Css.solid),
                                              tl: {
                                                hd: Css.borderColor(Theme$LiteracyplanetClientFlags.primaryColor),
                                                tl: /* [] */0
                                              }
                                            }, Theme$LiteracyplanetClientFlags.resizeList({
                                                  hd: [
                                                    Css.borderWidth,
                                                    7
                                                  ],
                                                  tl: /* [] */0
                                                }))),
                                    tl: {
                                      hd: Css.selector(".unselected.wrong .CorrectAnswer", {
                                            hd: Css.visibility(Css.visible),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: Theme$LiteracyplanetClientFlags.resize({
                      hd: [
                        Css.gridColumnGap,
                        25
                      ],
                      tl: {
                        hd: [
                          Css.gridRowGap,
                          25
                        ],
                        tl: {
                          hd: [
                            Css.margin,
                            25
                          ],
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var MultiChoiceMarkStylingContainer = {
  className: className$24
};

var PieceOfCakeMultiChoiceContainer = {
  MultiChoiceMarkStylingContainer: MultiChoiceMarkStylingContainer
};

var className$25 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        40
      ],
      tl: {
        hd: [
          Css.marginTop,
          10
        ],
        tl: {
          hd: [
            Css.marginBottom,
            10
          ],
          tl: {
            hd: [
              Css.marginLeft,
              10
            ],
            tl: {
              hd: [
                Css.marginRight,
                10
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var Box = {
  className: className$25
};

var className$26 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.borderRadius,
        20
      ],
      tl: /* [] */0
    });

var Answer = {
  className: className$26
};

var AnswerBox = {
  Box: Box,
  Answer: Answer
};

var className$27 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        30
      ],
      tl: {
        hd: [
          Css.marginTop,
          10
        ],
        tl: {
          hd: [
            Css.marginBottom,
            10
          ],
          tl: {
            hd: [
              Css.marginLeft,
              10
            ],
            tl: {
              hd: [
                Css.marginRight,
                10
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var Box$1 = {
  className: className$27
};

var className$28 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.borderRadius,
        20
      ],
      tl: /* [] */0
    });

var Answer$1 = {
  className: className$28
};

var AnswerBoxMed = {
  Box: Box$1,
  Answer: Answer$1
};

var className$29 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.fontSize,
        24
      ],
      tl: {
        hd: [
          Css.marginTop,
          10
        ],
        tl: {
          hd: [
            Css.marginBottom,
            10
          ],
          tl: {
            hd: [
              Css.marginLeft,
              10
            ],
            tl: {
              hd: [
                Css.marginRight,
                10
              ],
              tl: /* [] */0
            }
          }
        }
      }
    });

var Box$2 = {
  className: className$29
};

var className$30 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.borderRadius,
        20
      ],
      tl: /* [] */0
    });

var Answer$2 = {
  className: className$30
};

var AnswerBoxSlim = {
  Box: Box$2,
  Answer: Answer$2
};

var css$8 = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: Css.fr(1.0),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.width({
                NAME: "percent",
                VAL: 100.0
              }),
          tl: {
            hd: Css.height({
                  NAME: "percent",
                  VAL: 100.0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var resized$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.paddingTop,
        24
      ],
      tl: /* [] */0
    });

var className$31 = Curry._1(Css.merge, {
      hd: css$8,
      tl: {
        hd: resized$1,
        tl: {
          hd: "ImageSoundQuestionGrid",
          tl: /* [] */0
        }
      }
    });

var ImageSoundQuestionGrid = {
  css: css$8,
  resized: resized$1,
  className: className$31
};

var imageDivCss = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems(Css.center),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.objectFit("scaleDown"),
            tl: /* [] */0
          }
        }
      }
    });

var imageDivClassName = Curry._1(Css.merge, {
      hd: imageDivCss,
      tl: {
        hd: "AnswerImage",
        tl: /* [] */0
      }
    });

var imageCss = Curry._1(Css.style, {
      hd: Css.maxWidth({
            NAME: "percent",
            VAL: 100.0
          }),
      tl: {
        hd: Css.height(Css.auto),
        tl: {
          hd: Css.background(Css.white),
          tl: /* [] */0
        }
      }
    });

var AnswerImage = {
  imageDivCss: imageDivCss,
  imageDivClassName: imageDivClassName,
  imageCss: imageCss
};

var textCss = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems(Css.flexEnd),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: /* [] */0
        }
      }
    });

var className$32 = Curry._1(Css.merge, {
      hd: textCss,
      tl: {
        hd: "AnswerText",
        tl: /* [] */0
      }
    });

var AnswerText = {
  textCss: textCss,
  className: className$32
};

var ImageSoundQuestion = {
  ImageSoundQuestionGrid: ImageSoundQuestionGrid,
  AnswerImage: AnswerImage,
  AnswerText: AnswerText
};

var gridStyle$1 = Curry._1(Css.style, {
      hd: Css.gridColumnStart(1),
      tl: {
        hd: Css.gridColumnEnd(2),
        tl: {
          hd: Css.gridRowStart(1),
          tl: {
            hd: Css.gridRowEnd(3),
            tl: {
              hd: Css.alignSelf("center"),
              tl: {
                hd: Css.justifySelf("selfEnd"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var elementStyle$2 = Curry._1(Css.style, {
      hd: Css.borderStyle(Css.solid),
      tl: {
        hd: Css.borderColor(Css.white),
        tl: {
          hd: Css.cursor(Css.pointer),
          tl: {
            hd: Css.zIndex(10000),
            tl: {
              hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.primaryColor),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.hoverColor),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.active({
                        hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.activeColor),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var resizeStyle$3 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        68
      ],
      tl: {
        hd: [
          Css.height,
          68
        ],
        tl: {
          hd: [
            Css.borderRadius,
            34
          ],
          tl: {
            hd: [
              Css.borderWidth,
              5
            ],
            tl: /* [] */0
          }
        }
      }
    });

var iconStyle$1 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(Css.white),
      tl: /* [] */0
    });

var className$33 = Curry._1(Css.merge, {
      hd: resizeStyle$3,
      tl: {
        hd: gridStyle$1,
        tl: {
          hd: elementStyle$2,
          tl: {
            hd: "CloseBookButton",
            tl: /* [] */0
          }
        }
      }
    });

var CloseBookButton = {
  gridStyle: gridStyle$1,
  elementStyle: elementStyle$2,
  resizeStyle: resizeStyle$3,
  iconStyle: iconStyle$1,
  className: className$33
};

var gridStyle$2 = Curry._1(Css.style, {
      hd: Css.gridColumnStart(1),
      tl: {
        hd: Css.gridColumnEnd(2),
        tl: {
          hd: Css.gridRowStart(1),
          tl: {
            hd: Css.gridRowEnd(2),
            tl: {
              hd: Css.alignSelf("center"),
              tl: {
                hd: Css.justifySelf("selfEnd"),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var elementStyle$3 = Curry._1(Css.style, {
      hd: Css.backgroundColor(Css.white),
      tl: {
        hd: Css.cursor(Css.pointer),
        tl: /* [] */0
      }
    });

var resizeStyle$4 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        60
      ],
      tl: {
        hd: [
          Css.height,
          60
        ],
        tl: {
          hd: [
            Css.borderRadius,
            30
          ],
          tl: /* [] */0
        }
      }
    });

var resizeIcon$1 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        30
      ],
      tl: {
        hd: [
          Css.height,
          30
        ],
        tl: /* [] */0
      }
    });

var iconStyle$2 = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.alignSelf(Css.center),
        tl: {
          hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.primaryColor),
          tl: {
            hd: Css.hover({
                  hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.hoverColor),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.active({
                    hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.activeColor),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var className$34 = Curry._1(Css.merge, {
      hd: resizeStyle$4,
      tl: {
        hd: gridStyle$2,
        tl: {
          hd: elementStyle$3,
          tl: {
            hd: "FullScreenButton",
            tl: /* [] */0
          }
        }
      }
    });

var className$35 = Curry._1(Css.merge, {
      hd: iconStyle$2,
      tl: {
        hd: resizeIcon$1,
        tl: /* [] */0
      }
    });

var FullScreenIcon = {
  className: className$35
};

var FullScreenButton = {
  gridStyle: gridStyle$2,
  elementStyle: elementStyle$3,
  resizeStyle: resizeStyle$4,
  resizeIcon: resizeIcon$1,
  iconStyle: iconStyle$2,
  className: className$34,
  FullScreenIcon: FullScreenIcon
};

var elementStyle$4 = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.background(Css.white),
        tl: {
          hd: Css.display("flex"),
          tl: /* [] */0
        }
      }
    });

var resized$2 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        40
      ],
      tl: {
        hd: [
          Css.height,
          40
        ],
        tl: {
          hd: [
            Css.right,
            -20
          ],
          tl: {
            hd: [
              Css.borderRadius,
              20
            ],
            tl: /* [] */0
          }
        }
      }
    });

var css$9 = Curry._1(Css.merge, {
      hd: elementStyle$4,
      tl: {
        hd: resized$2,
        tl: /* [] */0
      }
    });

function className$36(className$37) {
  return Curry._1(Css.merge, {
              hd: css$9,
              tl: {
                hd: className$37,
                tl: /* [] */0
              }
            });
}

var Background = {
  elementStyle: elementStyle$4,
  resized: resized$2,
  css: css$9,
  className: className$36
};

var className$37 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(K2Styles$LiteracyplanetTupperware.correctPanelBackgroundColor),
      tl: {
        hd: Css.height({
              NAME: "percent",
              VAL: 100.0
            }),
        tl: {
          hd: Css.justifySelf(Css.center),
          tl: {
            hd: Css.alignSelf(Css.center),
            tl: /* [] */0
          }
        }
      }
    });

var Correct = {
  className: className$37
};

var className$38 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(K2Styles$LiteracyplanetTupperware.incorrectPanelBackgroundColor),
      tl: {
        hd: Css.height({
              NAME: "percent",
              VAL: 100.0
            }),
        tl: {
          hd: Css.justifySelf(Css.center),
          tl: {
            hd: Css.alignSelf(Css.center),
            tl: /* [] */0
          }
        }
      }
    });

var Wrong = {
  className: className$38
};

var K2AnswerMarkIcon = {
  Background: Background,
  Correct: Correct,
  Wrong: Wrong
};

var css$10 = Curry._1(Css.style, {
      hd: Css.background(Css.white),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent(Css.center),
          tl: {
            hd: Css.alignItems(Css.center),
            tl: {
              hd: Css.boxSizing(Css.borderBox),
              tl: {
                hd: Css.userSelect(Css.none),
                tl: {
                  hd: Css.cursor(Css.pointer),
                  tl: {
                    hd: Css.position(Css.relative),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function className$39(className$40) {
  return Curry._1(Css.merge, {
              hd: css$10,
              tl: {
                hd: className$40,
                tl: {
                  hd: "K2AnswerWrap-Answer",
                  tl: /* [] */0
                }
              }
            });
}

var Answer$3 = {
  css: css$10,
  className: className$39
};

function className$40(className$41) {
  return Curry._1(Css.merge, {
              hd: className$41,
              tl: {
                hd: "K2AnswerWrap-Box",
                tl: /* [] */0
              }
            });
}

var Box$3 = {
  className: className$40
};

var K2AnswerWrap = {
  Answer: Answer$3,
  Box: Box$3
};

var gridStyle$3 = Curry._1(Css.style, {
      hd: Css.gridColumnStart(3),
      tl: {
        hd: Css.gridColumnEnd(4),
        tl: {
          hd: Css.gridRowStart(2),
          tl: {
            hd: Css.gridRowEnd(3),
            tl: {
              hd: Css.alignSelf("center"),
              tl: {
                hd: Css.justifySelf("selfEnd"),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var elementStyle$5 = Curry._1(Css.style, {
      hd: Css.backgroundColor(Css.white),
      tl: {
        hd: Css.cursor(Css.pointer),
        tl: /* [] */0
      }
    });

var resizeStyle$5 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        60
      ],
      tl: {
        hd: [
          Css.height,
          60
        ],
        tl: {
          hd: [
            Css.borderRadius,
            30
          ],
          tl: /* [] */0
        }
      }
    });

var iconStyle$3 = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.alignSelf(Css.center),
        tl: {
          hd: Css.height({
                NAME: "percent",
                VAL: 90.0
              }),
          tl: {
            hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.primaryColor),
            tl: {
              hd: Css.hover({
                    hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.hoverColor),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.active({
                      hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.activeColor),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$41 = Curry._1(Css.merge, {
      hd: resizeStyle$5,
      tl: {
        hd: gridStyle$3,
        tl: {
          hd: elementStyle$5,
          tl: {
            hd: "NextPageButton",
            tl: /* [] */0
          }
        }
      }
    });

var NextPageButton = {
  gridStyle: gridStyle$3,
  elementStyle: elementStyle$5,
  resizeStyle: resizeStyle$5,
  iconStyle: iconStyle$3,
  className: className$41
};

var gridStyle$4 = Curry._1(Css.style, {
      hd: Css.gridColumnStart(1),
      tl: {
        hd: Css.gridColumnEnd(2),
        tl: {
          hd: Css.gridRowStart(1),
          tl: {
            hd: Css.gridRowEnd(3),
            tl: {
              hd: Css.alignSelf("center"),
              tl: {
                hd: Css.justifySelf("selfStart"),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var elementStyle$6 = Curry._1(Css.style, {
      hd: Css.borderStyle(Css.solid),
      tl: {
        hd: Css.borderColor(Css.white),
        tl: {
          hd: Css.cursor(Css.pointer),
          tl: {
            hd: Css.zIndex(10000),
            tl: {
              hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.primaryColor),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.hoverColor),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.active({
                        hd: Css.backgroundColor(Theme$LiteracyplanetClientFlags.activeColor),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var resizeStyle$6 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        68
      ],
      tl: {
        hd: [
          Css.height,
          68
        ],
        tl: {
          hd: [
            Css.borderRadius,
            34
          ],
          tl: {
            hd: [
              Css.borderWidth,
              5
            ],
            tl: /* [] */0
          }
        }
      }
    });

var iconStyle$4 = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(Css.white),
      tl: {
        hd: Css.justifySelf(Css.center),
        tl: {
          hd: Css.alignSelf(Css.center),
          tl: {
            hd: Css.height({
                  NAME: "percent",
                  VAL: 70.0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var className$42 = Curry._1(Css.merge, {
      hd: resizeStyle$6,
      tl: {
        hd: gridStyle$4,
        tl: {
          hd: elementStyle$6,
          tl: {
            hd: "OpenBookButton",
            tl: /* [] */0
          }
        }
      }
    });

var OpenBookButton = {
  gridStyle: gridStyle$4,
  elementStyle: elementStyle$6,
  resizeStyle: resizeStyle$6,
  iconStyle: iconStyle$4,
  className: className$42
};

var className$43 = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.alignSelf(Css.center),
        tl: {
          hd: Css.height({
                NAME: "percent",
                VAL: 90.0
              }),
          tl: /* [] */0
        }
      }
    });

var iconStyleBlue = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.primaryColor),
      tl: /* [] */0
    });

var iconStyleWhite = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill(Css.white),
      tl: /* [] */0
    });

var CloseBookIcon = {
  className: className$43,
  iconStyleBlue: iconStyleBlue,
  iconStyleWhite: iconStyleWhite
};

var gridStyle$5 = Curry._1(Css.style, {
      hd: Css.gridColumnStart(1),
      tl: {
        hd: Css.gridColumnEnd(2),
        tl: {
          hd: Css.gridRowStart(2),
          tl: {
            hd: Css.gridRowEnd(3),
            tl: {
              hd: Css.alignSelf("center"),
              tl: {
                hd: Css.justifySelf("selfStart"),
                tl: {
                  hd: Css.display(Css.grid),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var elementStyle$7 = Curry._1(Css.style, {
      hd: Css.backgroundColor(Css.white),
      tl: {
        hd: Css.cursor(Css.pointer),
        tl: /* [] */0
      }
    });

var resizeStyle$7 = Theme$LiteracyplanetClientFlags.resize({
      hd: [
        Css.width,
        60
      ],
      tl: {
        hd: [
          Css.height,
          60
        ],
        tl: {
          hd: [
            Css.borderRadius,
            30
          ],
          tl: /* [] */0
        }
      }
    });

var iconStyle$5 = Curry._1(Css.style, {
      hd: Css.justifySelf(Css.center),
      tl: {
        hd: Css.alignSelf(Css.center),
        tl: {
          hd: Css.height({
                NAME: "percent",
                VAL: 90.0
              }),
          tl: {
            hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.primaryColor),
            tl: {
              hd: Css.hover({
                    hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.hoverColor),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.active({
                      hd: Css_Legacy_Core.SVG.fill(Theme$LiteracyplanetClientFlags.activeColor),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var className$44 = Curry._1(Css.merge, {
      hd: resizeStyle$7,
      tl: {
        hd: gridStyle$5,
        tl: {
          hd: elementStyle$7,
          tl: {
            hd: "PrevPageButton",
            tl: /* [] */0
          }
        }
      }
    });

var PrevPageButton = {
  gridStyle: gridStyle$5,
  elementStyle: elementStyle$7,
  resizeStyle: resizeStyle$7,
  iconStyle: iconStyle$5,
  className: className$44
};

var ratio = Theme$LiteracyplanetClientFlags.resize;

export {
  ratio ,
  backgroundSrc ,
  Layout ,
  StimulusLayout ,
  MinimizeBookIcon ,
  MinimizeBookButton ,
  K2BookQuestions ,
  StemHeading ,
  AudioQueryComponent ,
  AudioPillButton ,
  PlayBookAudioButton ,
  PlayAudioIcon ,
  K2Answers ,
  PieceOfCakeMultiChoiceContainer ,
  AnswerBox ,
  AnswerBoxMed ,
  AnswerBoxSlim ,
  ImageSoundQuestion ,
  CloseBookButton ,
  FullScreenButton ,
  K2AnswerMarkIcon ,
  K2AnswerWrap ,
  NextPageButton ,
  OpenBookButton ,
  CloseBookIcon ,
  PrevPageButton ,
  
}
/* backgroundSrc Not a pure module */
