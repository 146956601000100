// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PrevIcon(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 922 922",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M205.5 460.218L419 673.018C422.1 676.018 426.6 676.918 430.6 675.318C434.6 673.618 437.2 669.818 437.2 665.418V508.118H703.8C709.7 508.118 714.5 503.318 714.5 497.418V406.718C714.5 400.818 709.7 396.018 703.8 396.018L437.1 396.018V239.118C437.1 234.818 434.5 230.918 430.5 229.218C429.2 228.618 427.8 228.418 426.4 228.418C423.6 228.418 420.9 229.518 418.8 231.518L205.3 444.918C203.3 446.918 202.2 449.618 202.2 452.518C202.3 455.518 203.5 458.218 205.5 460.218Z"
                }));
}

var make = PrevIcon;

export {
  make ,
  
}
/* react Not a pure module */
