// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Wrap$LiteracyplanetClientFlags from "./Wrap.bs.js";

var span = {
  padding: "5px"
};

var noStyle = {};

var selected = {
  background: "white",
  color: "black"
};

function WrapSelectorComponent(Props) {
  var api = Wrap$LiteracyplanetClientFlags.useApi(undefined);
  var experienceLink = function (ex) {
    return React.createElement("a", {
                style: ex === api.whatExperience ? selected : noStyle,
                onClick: (function (param) {
                    return Curry._1(api.changeExperience, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, Wrap$LiteracyplanetClientFlags.experienceToString(ex)));
  };
  var navLink = function (ex) {
    return React.createElement("a", {
                style: ex === api.whatNavBar ? selected : noStyle,
                onClick: (function (param) {
                    return Curry._1(api.changeNavBar, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, Wrap$LiteracyplanetClientFlags.navBarToString(ex)));
  };
  var menuLink = function (ex) {
    return React.createElement("a", {
                style: ex === api.whatMenu ? selected : noStyle,
                onClick: (function (param) {
                    return Curry._1(api.changeMenu, ex);
                  })
              }, React.createElement("span", {
                    style: span
                  }, Wrap$LiteracyplanetClientFlags.menuToString(ex)));
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, experienceLink(/* Journey */1), experienceLink(/* Storyverse */2), experienceLink(/* Secondary */0)), React.createElement("div", undefined, menuLink(/* Menuless */2), menuLink(/* JourneyInitials */1), menuLink(/* Initials */0)), React.createElement("div", undefined, navLink(/* Barless */1), navLink(/* Bubble */2), navLink(/* Modern */0), navLink(/* Papyrus */3)));
}

var make = WrapSelectorComponent;

export {
  span ,
  noStyle ,
  selected ,
  make ,
  
}
/* react Not a pure module */
