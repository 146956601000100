// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import GetClickedWord from "./GetClickedWord";
import * as MemoPlay$LiteracyplanetClientFlags from "./MemoPlay.bs.js";
import * as FindWordsType$LiteracyplanetClientFlags from "./FindWordsType.bs.js";
import * as PlayPlaceholder$LiteracyplanetClientFlags from "./PlayPlaceholder.bs.js";

function getClickedWord(prim) {
  return GetClickedWord();
}

function ClickWordToHear$HighlightWord(Props) {
  var onClick = Props.onClick;
  var children = Props.children;
  var onClick$1 = Curry._1(React.useCallback(function (param) {
            return function (param) {
              return Curry._1(onClick, GetClickedWord());
            };
          }), [onClick]);
  return React.createElement("div", {
              style: {
                userSelect: "text"
              },
              onClick: onClick$1
            }, children);
}

var HighlightWord = {
  make: ClickWordToHear$HighlightWord
};

function ClickWordToHear$PlayAudioComponent(Props) {
  var wordName = Props.wordName;
  var onEnd = Props.onEnd;
  var useQuery = Props.useQuery;
  return Belt_Option.mapWithDefault(FindWordsType$LiteracyplanetClientFlags.hasWord(Curry._1(useQuery, [wordName])), React.createElement(PlayPlaceholder$LiteracyplanetClientFlags.make, {}), (function (word) {
                return React.createElement(MemoPlay$LiteracyplanetClientFlags.make, {
                            src: word.audioSrc,
                            onEnd: onEnd
                          });
              }));
}

var PlayAudioComponent = {
  make: ClickWordToHear$PlayAudioComponent
};

function ClickWordToHear(Props) {
  var useQuery = Props.useQuery;
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setWord = match[1];
  var wordName = match[0];
  var onClick = Curry._1(React.useCallback(function (param) {
            return function (word) {
              return Curry._1(setWord, (function (param) {
                            return word;
                          }));
            };
          }), []);
  var onEnd = Curry._1(React.useCallback(function (param) {
            return function (param) {
              return Curry._1(setWord, (function (param) {
                            
                          }));
            };
          }), []);
  var wordQuery = wordName !== undefined ? React.createElement(ClickWordToHear$PlayAudioComponent, {
          wordName: wordName,
          onEnd: onEnd,
          useQuery: useQuery
        }) : null;
  return React.createElement(React.Fragment, undefined, wordQuery, React.createElement(ClickWordToHear$HighlightWord, {
                  onClick: onClick,
                  children: children
                }));
}

var make = ClickWordToHear;

export {
  getClickedWord ,
  HighlightWord ,
  PlayAudioComponent ,
  make ,
  
}
/* react Not a pure module */
