/* eslint-disable new-cap, no-invalid-this, react/prop-types */
import React from 'react';

let toArray = (children) => {
  let newChildren = [];
  React.Children.forEach(children, (child) => {
    if (child) {
      newChildren.push(child);
    }
  });
  return newChildren;
};

let shuffleArray = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

class ShuffleChildren extends React.PureComponent {
  componentWillMount() {
    this.shuffledChildren = shuffleArray(toArray(this.props.children));
  }

  render() {
    return this.shuffledChildren;
  }
};

ShuffleChildren.displayName = 'ShuffleChildren';

export const shuffle = shuffleArray;

export default ShuffleChildren;
