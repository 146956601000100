// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as V2EventsContext$LiteracyplanetTupperware from "./V2EventsContext.bs.js";
import * as V2ExerciseStateStoreHooks$LiteracyplanetTupperware from "./V2ExerciseStateStoreHooks.bs.js";

function useCallbacks(questionIndex, persistedState) {
  var callbacks = V2EventsContext$LiteracyplanetTupperware.use(undefined);
  React.useEffect((function () {
          Curry._1(callbacks.onActivityStarted, undefined);
          Curry._2(callbacks.onQuestionStarted, questionIndex, Belt_Option.getWithDefault(persistedState, ""));
          
        }), []);
  
}

function BehaviourTestActivityPage(Props) {
  var persistedState = Props.persistedState;
  var assessment = Props.assessment;
  var questionIndex = Props.questionIndex;
  var children = Props.children;
  useCallbacks(questionIndex, persistedState);
  var ready = V2ExerciseStateStoreHooks$LiteracyplanetTupperware.useModeAndPersistedState(assessment, persistedState);
  if (ready) {
    return children;
  } else {
    return null;
  }
}

var make = BehaviourTestActivityPage;

export {
  useCallbacks ,
  make ,
  
}
/* react Not a pure module */
