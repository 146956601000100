// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Bucket$LiteracyplanetTupperware from "../Bucket.bs.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";

function SkilfulHighlightBucket(Props) {
  var group = Props.group;
  var colour = Props.colour;
  var children = Props.children;
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulHighlightBucket.className
            }, React.createElement(Bucket$LiteracyplanetTupperware.Button.make, {
                  group: group,
                  colour: colour,
                  children: children
                }));
}

var make = SkilfulHighlightBucket;

var $$default = SkilfulHighlightBucket;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
