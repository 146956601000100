// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as FireLater$LiteracyplanetClientSxActivity from "./FireLater.bs.js";
import * as FlipperStoreTypes$LiteracyplanetClientSxActivity from "./FlipperStoreTypes.bs.js";

var initialState = {
  backgroundColor: "#FFFFFF",
  loadResults: false,
  showResults: false,
  activityLoaded: false,
  numCorrect: 0,
  numComplete: 0,
  exited: false,
  exitedToMissions: false,
  exitedToCollections: false,
  percentCorrect: 0,
  initialised: false,
  needsToShowMissionComplete: false
};

function calcPercent(numerator, denominator) {
  return Math.ceil(numerator / denominator * 100) | 0;
}

function calculateResults(state) {
  return {
          backgroundColor: state.backgroundColor,
          loadResults: state.loadResults,
          showResults: state.showResults,
          activityLoaded: state.activityLoaded,
          numCorrect: state.numCorrect,
          numComplete: state.numComplete,
          exited: state.exited,
          exitedToMissions: state.exitedToMissions,
          exitedToCollections: state.exitedToCollections,
          percentCorrect: calcPercent(state.numCorrect, state.numComplete),
          initialised: state.initialised,
          needsToShowMissionComplete: state.needsToShowMissionComplete
        };
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* ActivityCompleted */0 :
          return calculateResults(state);
      case /* ActivityPaused */1 :
          return state;
      case /* ActivityReachedEnd */2 :
          return calculateResults({
                      backgroundColor: state.backgroundColor,
                      loadResults: true,
                      showResults: state.showResults,
                      activityLoaded: state.activityLoaded,
                      numCorrect: state.numCorrect,
                      numComplete: state.numComplete,
                      exited: state.exited,
                      exitedToMissions: state.exitedToMissions,
                      exitedToCollections: state.exitedToCollections,
                      percentCorrect: state.percentCorrect,
                      initialised: state.initialised,
                      needsToShowMissionComplete: state.needsToShowMissionComplete
                    });
      case /* ActivityReady */3 :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: true,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case /* Exit */6 :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: true,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case /* ExitToCollections */7 :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: true,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case /* ExitToMissions */8 :
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: true,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case /* ResultsReady */10 :
          return calculateResults({
                      backgroundColor: state.backgroundColor,
                      loadResults: state.loadResults,
                      showResults: true,
                      activityLoaded: state.activityLoaded,
                      numCorrect: state.numCorrect,
                      numComplete: state.numComplete,
                      exited: state.exited,
                      exitedToMissions: state.exitedToMissions,
                      exitedToCollections: state.exitedToCollections,
                      percentCorrect: state.percentCorrect,
                      initialised: state.initialised,
                      needsToShowMissionComplete: state.needsToShowMissionComplete
                    });
      default:
        return state;
    }
  } else {
    switch (action.TAG | 0) {
      case /* InitialiseStore */0 :
          return action._0;
      case /* QuestionFailed */1 :
          var question = action._0;
          var numComplete = question > state.numComplete ? question : state.numComplete;
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case /* QuestionPassed */2 :
          var question$1 = action._0;
          var numComplete$1 = question$1 > state.numComplete ? question$1 : state.numComplete;
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect + 1 | 0,
                  numComplete: numComplete$1,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case /* BulkQuestions */3 :
          var questions = action._0;
          return {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: Belt_Array.reduce(questions, 0, (function (total, correct) {
                          if (correct) {
                            return total + 1 | 0;
                          } else {
                            return total;
                          }
                        })),
                  numComplete: questions.length,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      case /* PreloaderReady */4 :
          return {
                  backgroundColor: action._0.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: state.numCorrect,
                  numComplete: state.numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: state.initialised,
                  needsToShowMissionComplete: state.needsToShowMissionComplete
                };
      
    }
  }
}

var Later = FireLater$LiteracyplanetClientSxActivity.Make({});

function useState(param) {
  return Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined)[0];
}

function initaliseState(needsToShowMissionComplete, numComplete, numCorrect) {
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var dispatch = match[1];
  var state = match[0];
  var fire = Curry._1(Later.use, undefined);
  React.useEffect((function () {
          Curry._1(dispatch, {
                TAG: /* InitialiseStore */0,
                _0: {
                  backgroundColor: state.backgroundColor,
                  loadResults: state.loadResults,
                  showResults: state.showResults,
                  activityLoaded: state.activityLoaded,
                  numCorrect: numCorrect,
                  numComplete: numComplete,
                  exited: state.exited,
                  exitedToMissions: state.exitedToMissions,
                  exitedToCollections: state.exitedToCollections,
                  percentCorrect: state.percentCorrect,
                  initialised: true,
                  needsToShowMissionComplete: needsToShowMissionComplete
                }
              });
          
        }), []);
  return [
          state,
          (function ($$event) {
              return Curry._1(fire, /* FireSoonish */{
                          _0: (function (param) {
                              return Curry._1(dispatch, $$event);
                            })
                        });
            })
        ];
}

var Context = {
  useState: useState,
  initaliseState: initaliseState
};

function FlipperStore$Provider(Props) {
  var children = Props.children;
  var match = React.useReducer(reducer, initialState);
  return React.createElement(Later.make, {
              children: React.createElement(FlipperStoreTypes$LiteracyplanetClientSxActivity.Provider.make, {
                    value: [
                      match[0],
                      match[1]
                    ],
                    children: children
                  })
            });
}

var Provider = {
  make: FlipperStore$Provider
};

function FlipperStore$ProviderWithExit$Exit(Props) {
  var onExit = Props.onExit;
  var children = Props.children;
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var state = match[0];
  React.useEffect((function () {
          if (state.exited) {
            Curry._1(onExit, undefined);
          }
          
        }), [state.exited]);
  return children;
}

var Exit = {
  make: FlipperStore$ProviderWithExit$Exit
};

function FlipperStore$ProviderWithExit(Props) {
  var onExit = Props.onExit;
  var children = Props.children;
  return React.createElement(FlipperStore$Provider, {
              children: React.createElement(FlipperStore$ProviderWithExit$Exit, {
                    onExit: onExit,
                    children: children
                  })
            });
}

var ProviderWithExit = {
  Exit: Exit,
  make: FlipperStore$ProviderWithExit
};

function FlipperStore$ProviderWithMissionExit$Exit(Props) {
  var onExit = Props.onExit;
  var onGoToMissions = Props.onGoToMissions;
  var onMaybeGotoCollections = Props.onMaybeGotoCollections;
  var children = Props.children;
  var match = Curry._1(FlipperStoreTypes$LiteracyplanetClientSxActivity.use, undefined);
  var state = match[0];
  React.useEffect((function () {
          if (state.exited) {
            Curry._1(onExit, undefined);
          }
          
        }), [state.exited]);
  React.useEffect((function () {
          if (state.exitedToMissions) {
            Curry._1(onGoToMissions, undefined);
          }
          
        }), [state.exitedToMissions]);
  React.useEffect((function () {
          if (state.exitedToCollections) {
            Curry._1(onMaybeGotoCollections, (function (param) {
                    
                  }));
          }
          
        }), [state.exitedToCollections]);
  return children;
}

var Exit$1 = {
  make: FlipperStore$ProviderWithMissionExit$Exit
};

function FlipperStore$ProviderWithMissionExit(Props) {
  var onExit = Props.onExit;
  var onGoToMissions = Props.onGoToMissions;
  var onMaybeGotoCollections = Props.onMaybeGotoCollections;
  var children = Props.children;
  return React.createElement(FlipperStore$Provider, {
              children: React.createElement(FlipperStore$ProviderWithMissionExit$Exit, {
                    onExit: onExit,
                    onGoToMissions: onGoToMissions,
                    onMaybeGotoCollections: onMaybeGotoCollections,
                    children: children
                  })
            });
}

var ProviderWithMissionExit = {
  Exit: Exit$1,
  make: FlipperStore$ProviderWithMissionExit
};

export {
  initialState ,
  calcPercent ,
  calculateResults ,
  reducer ,
  Later ,
  Context ,
  Provider ,
  ProviderWithExit ,
  ProviderWithMissionExit ,
  
}
/* Later Not a pure module */
