// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../node_modules/rescript/lib/es6/list.js";
import * as Belt_List from "../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Reducer$LiteracyplanetTupperware from "./Reducer.bs.js";

function getSelectedOption(questionState, options) {
  if (!questionState) {
    return ;
  }
  var responses = Reducer$LiteracyplanetTupperware.extractAnswers(questionState._0);
  return List.fold_left((function (acc, r) {
                if (acc !== undefined || !List.exists((function (o) {
                          return o === r;
                        }), options)) {
                  return acc;
                } else {
                  return r;
                }
              }), undefined, responses);
}

function getSelectedDropDownValue(step, opts) {
  var options = List.map((function (opt) {
          return opt.responseOptionId;
        }), opts);
  if (typeof step === "number") {
    return ;
  } else if (step.TAG === /* Attempted */0) {
    return getSelectedOption(step._0, options);
  } else {
    return getSelectedOption(step._1, options);
  }
}

function getMarkTypeForDropDown(value, step, opts) {
  if (value !== undefined) {
    return Reducer$LiteracyplanetTupperware.getMarkType(step, value);
  } else {
    return List.fold_left((function (acc, opt) {
                  var match = Reducer$LiteracyplanetTupperware.getMarkType(step, opt.responseOptionId);
                  switch (match) {
                    case /* Correct */0 :
                        if (acc !== 1) {
                          return /* Correct */0;
                        } else {
                          return acc;
                        }
                    case /* Wrong */1 :
                        return /* Wrong */1;
                    case /* Unanswered */2 :
                        return /* Unanswered */2;
                    
                  }
                }), /* Unanswered */2, opts);
  }
}

function find(opts, correctAnswer) {
  if (correctAnswer !== undefined) {
    return Belt_List.reduce(opts, undefined, (function (acc, opt) {
                  var exists = List.exists((function (answer) {
                          return answer === opt.responseOptionId;
                        }), correctAnswer);
                  if (exists) {
                    return opt.responseOptionId;
                  } else {
                    return acc;
                  }
                }));
  }
  
}

export {
  getSelectedOption ,
  getSelectedDropDownValue ,
  getMarkTypeForDropDown ,
  find ,
  
}
/* No side effect */
