// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Error$LiteracyplanetClientErrors from "../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";

function Make(S) {
  var use = function (defaultState, onLoading, onResult, onSetError, onError, variables) {
    var match = React.useState(function () {
          return Curry._1(defaultState, undefined);
        });
    var setState = match[1];
    var result = Curry._7(S.use, undefined, undefined, undefined, undefined, undefined, undefined, variables);
    var handleResponse = function (response) {
      var result = Curry._1(onResult, response);
      if (result.TAG !== /* Ok */0) {
        return Curry._1(onSetError, result._0);
      }
      var result$1 = result._0;
      return Curry._1(setState, (function (param) {
                    return result$1;
                  }));
    };
    React.useEffect((function () {
            if (result.loading) {
              Curry._1(setState, Curry.__1(onLoading));
            } else {
              var response = result.data;
              if (response !== undefined) {
                handleResponse(Caml_option.valFromOption(response));
              } else {
                var error = result.error;
                if (error !== undefined) {
                  Curry._1(onSetError, Curry._1(onError, error));
                } else {
                  throw {
                        RE_EXN_ID: "Match_failure",
                        _1: [
                          "SubscriptionHook.res",
                          46,
                          6
                        ],
                        Error: new Error()
                      };
                }
              }
            }
            
          }), [result]);
    return match[0];
  };
  var useWithError = function (defaultState, onLoading, onResult, onError, variables) {
    var match = Error$LiteracyplanetClientErrors.useErrorModal(undefined);
    return use(defaultState, onLoading, onResult, match.onSetError, onError, variables);
  };
  return {
          use: use,
          useWithError: useWithError
        };
}

export {
  Make ,
  
}
/* react Not a pure module */
