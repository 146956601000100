// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FindWordsQuery$LiteracyplanetClientFlags from "./FindWordsQuery.bs.js";
import * as ClickWordToHear$LiteracyplanetClientFlags from "./ClickWordToHear.bs.js";

function ClickWordToHearContainer(Props) {
  var children = Props.children;
  return React.createElement(ClickWordToHear$LiteracyplanetClientFlags.make, {
              useQuery: FindWordsQuery$LiteracyplanetClientFlags.useWithContext,
              children: children
            });
}

var make = ClickWordToHearContainer;

export {
  make ,
  
}
/* react Not a pure module */
