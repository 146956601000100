// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "../../../node_modules/@glennsl/bs-json/src/Json.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Error_modal from "./error_modal";
import * as Caml_js_exceptions from "../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as Sentry$LiteracyplanetClientErrors from "./Sentry.bs.js";

function stringifyArray(a) {
  return a.join(", ");
}

var exnToString = (function(exn) {
    return JSON.stringify(exn)
  });

function stringify(x) {
  if (typeof x === "number") {
    switch (x) {
      case /* JourneyMissionsError */0 :
          return "JourneyMissionsError";
      case /* JourneyActivitiesError */1 :
          return "JourneyActivitiesError";
      case /* JourneyMissionsSubscriptionsError */2 :
          return "JourneyMissionsSubscriptionsError";
      case /* RhetoricalMutationError */3 :
          return "RhetoricalMutationError";
      case /* RhetoricalQueryError */4 :
          return "RhetoricalQueryError";
      case /* PixiWebGLNotSupported */5 :
          return "PixiWebGLNotSupported";
      case /* WebpNotSupported */6 :
          return "WebpNotSupported";
      case /* SecondaryMissionError */7 :
          return "SecondaryMissionError";
      case /* TeacherError */8 :
          return "TeacherError";
      case /* CustomMissionAssignmentNotFoundTeacherError */9 :
          return "CustomMissionAssignmentNotFoundTeacherError";
      case /* UnknownError */10 :
          return "UnknownError";
      case /* GameTypeLookupFailed */11 :
          return "GameTypeLookupFailed";
      
    }
  } else {
    switch (x.TAG | 0) {
      case /* GraphqlError */0 :
          return "graphql error, " + (x._0 + (", [" + (x._1.join(", ") + "]") + Belt_Option.mapWithDefault(x._2, "", (function (err) {
                            return ", " + err;
                          }))));
      case /* PayloadDecodeError */1 :
          return "error payload decode error , " + x._0 + ", " + x._1;
      case /* AssetsMissing */2 :
          return "failed to load assets " + x._0.join(", ");
      case /* Exception */3 :
          return exnToString(x._0);
      case /* ErrorWithMessage */4 :
          var reason = Belt_Option.getWithDefault(x._1, "");
          return "error occurred , " + x._0 + ", " + reason;
      case /* CustomModalError */5 :
          return x._1;
      
    }
  }
}

function errToJs(param) {
  return {
          reason: param.reason,
          payload: param.payload
        };
}

function errFromJs(param) {
  return {
          reason: param.reason,
          payload: param.payload
        };
}

var emptyPayload = "";

function stringArray(param) {
  return Json_decode.array(Json_decode.string, param);
}

function parseReason(payload, reason, fallback) {
  switch (reason) {
    case "AssetsMissing" :
        return {
                TAG: /* AssetsMissing */2,
                _0: Json_decode.array(Json_decode.string, payload)
              };
    case "PixiWebGLNotSupported" :
        return /* PixiWebGLNotSupported */5;
    case "WebpNotSupported" :
        return /* WebpNotSupported */6;
    default:
      return fallback;
  }
}

function parseError(payload, reason, fallbackType) {
  var p;
  try {
    p = Json.parseOrRaise(payload);
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg.RE_EXN_ID === Json.ParseError) {
      return {
              TAG: /* PayloadDecodeError */1,
              _0: msg._1,
              _1: reason
            };
    }
    throw msg;
  }
  return parseReason(p, reason, fallbackType);
}

function makeError(fallback, error) {
  var err = errFromJs(error);
  var optPayload = err.payload;
  var optReason = err.reason;
  var fallbackType = Belt_Option.getWithDefault(fallback, /* UnknownError */10);
  if (optPayload == null) {
    if (optReason == null) {
      return fallbackType;
    } else {
      return parseReason(emptyPayload, optReason, fallbackType);
    }
  } else if (optReason == null) {
    return fallbackType;
  } else {
    return parseError(optPayload, optReason, fallbackType);
  }
}

var JsInterop = {
  errToJs: errToJs,
  errFromJs: errFromJs,
  emptyPayload: emptyPayload,
  stringArray: stringArray,
  parseReason: parseReason,
  parseError: parseError,
  makeError: makeError
};

function onSetErrorDefault(_error) {
  
}

function onSetErrorWithMessageDefault(modalMessage, sentryMessage, _error) {
  
}

function state_onClearError(param) {
  
}

var state = {
  onSetError: onSetErrorDefault,
  onSetErrorWithMessage: onSetErrorWithMessageDefault,
  onClearError: state_onClearError,
  error: undefined,
  modalMessage: undefined
};

var context = React.createContext(state);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  state: state,
  context: context,
  makeProps: makeProps,
  make: make
};

function MakeApp(Sentry) {
  var initialState_onClearError = function (param) {
    
  };
  var initialState = {
    onSetError: onSetErrorDefault,
    onSetErrorWithMessage: onSetErrorWithMessageDefault,
    onClearError: initialState_onClearError,
    error: undefined,
    modalMessage: undefined
  };
  var reducer = function (state, action) {
    if (action.TAG === /* SetError */0) {
      return {
              onSetError: state.onSetError,
              onSetErrorWithMessage: state.onSetErrorWithMessage,
              onClearError: state.onClearError,
              error: action._0,
              modalMessage: state.modalMessage
            };
    } else {
      return {
              onSetError: state.onSetError,
              onSetErrorWithMessage: state.onSetErrorWithMessage,
              onClearError: state.onClearError,
              error: action._0,
              modalMessage: action._1
            };
    }
  };
  var Error$MakeApp = function (Props) {
    var children = Props.children;
    var match = React.useReducer(reducer, initialState);
    var dispatch = match[1];
    var state = match[0];
    var onSetError = function (err) {
      if (typeof err !== "number" && err.TAG === /* CustomModalError */5) {
        var sentryMessage = err._0;
        Curry._1(Sentry.send, sentryMessage);
        return Curry._1(dispatch, {
                    TAG: /* SetErrorWithMessage */1,
                    _0: {
                      TAG: /* ErrorWithMessage */4,
                      _0: sentryMessage,
                      _1: undefined
                    },
                    _1: {
                      NAME: "String",
                      VAL: err._1
                    }
                  });
      }
      Curry._1(Sentry.send, stringify(err));
      return Curry._1(dispatch, {
                  TAG: /* SetError */0,
                  _0: err
                });
    };
    var onSetErrorWithMessage = function (modalMessage, sentryMessage, err) {
      Curry._1(Sentry.send, stringify(err) + Belt_Option.mapWithDefault(sentryMessage, "", (function (msg) {
                  return " ::: " + msg;
                })));
      return Curry._1(dispatch, {
                  TAG: /* SetErrorWithMessage */1,
                  _0: err,
                  _1: modalMessage
                });
    };
    var onClearError = function (param) {
      return Curry._1(dispatch, {
                  TAG: /* SetErrorWithMessage */1,
                  _0: undefined,
                  _1: undefined
                });
    };
    return React.createElement("div", undefined, React.createElement(make, makeProps({
                        onSetError: onSetError,
                        onSetErrorWithMessage: onSetErrorWithMessage,
                        onClearError: onClearError,
                        error: state.error,
                        modalMessage: state.modalMessage
                      }, children, undefined)));
  };
  return {
          initialState: initialState,
          reducer: reducer,
          make: Error$MakeApp
        };
}

function send(err) {
  Sentry$LiteracyplanetClientErrors.captureException(err);
  
}

function showReportDialog(param) {
  Sentry$LiteracyplanetClientErrors.showReportDialog(undefined);
  
}

var Sentry = {
  send: send,
  showReportDialog: showReportDialog
};

function initialState_onClearError(param) {
  
}

var initialState = {
  onSetError: onSetErrorDefault,
  onSetErrorWithMessage: onSetErrorWithMessageDefault,
  onClearError: initialState_onClearError,
  error: undefined,
  modalMessage: undefined
};

function reducer(state, action) {
  if (action.TAG === /* SetError */0) {
    return {
            onSetError: state.onSetError,
            onSetErrorWithMessage: state.onSetErrorWithMessage,
            onClearError: state.onClearError,
            error: action._0,
            modalMessage: state.modalMessage
          };
  } else {
    return {
            onSetError: state.onSetError,
            onSetErrorWithMessage: state.onSetErrorWithMessage,
            onClearError: state.onClearError,
            error: action._0,
            modalMessage: action._1
          };
  }
}

function Error$MakeApp(Props) {
  var children = Props.children;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var onSetError = function (err) {
    if (typeof err !== "number" && err.TAG === /* CustomModalError */5) {
      var sentryMessage = err._0;
      Sentry$LiteracyplanetClientErrors.captureException(sentryMessage);
      return Curry._1(dispatch, {
                  TAG: /* SetErrorWithMessage */1,
                  _0: {
                    TAG: /* ErrorWithMessage */4,
                    _0: sentryMessage,
                    _1: undefined
                  },
                  _1: {
                    NAME: "String",
                    VAL: err._1
                  }
                });
    }
    var err$1 = stringify(err);
    Sentry$LiteracyplanetClientErrors.captureException(err$1);
    return Curry._1(dispatch, {
                TAG: /* SetError */0,
                _0: err
              });
  };
  var onSetErrorWithMessage = function (modalMessage, sentryMessage, err) {
    var err$1 = stringify(err) + Belt_Option.mapWithDefault(sentryMessage, "", (function (msg) {
            return " ::: " + msg;
          }));
    Sentry$LiteracyplanetClientErrors.captureException(err$1);
    return Curry._1(dispatch, {
                TAG: /* SetErrorWithMessage */1,
                _0: err,
                _1: modalMessage
              });
  };
  var onClearError = function (param) {
    return Curry._1(dispatch, {
                TAG: /* SetErrorWithMessage */1,
                _0: undefined,
                _1: undefined
              });
  };
  return React.createElement("div", undefined, React.createElement(make, makeProps({
                      onSetError: onSetError,
                      onSetErrorWithMessage: onSetErrorWithMessage,
                      onClearError: onClearError,
                      error: state.error,
                      modalMessage: state.modalMessage
                    }, children, undefined)));
}

var App = {
  initialState: initialState,
  reducer: reducer,
  make: Error$MakeApp
};

function Error$PixiError(Props) {
  return React.createElement(React.Fragment, undefined, "Oh no! We need WebGL turned on.", React.createElement("br", undefined), "Learn how at our ", React.createElement("a", {
                  href: "https://hub.literacyplanet.com/s/article/Enable-WebGL"
                }, "Knowledge Hub."));
}

var PixiError = {
  make: Error$PixiError
};

function Error$WebpError(Props) {
  return React.createElement(React.Fragment, undefined, "Oh no! We need webp media support.", React.createElement("br", undefined), "Learn more at our ", React.createElement("a", {
                  href: "https://hub.literacyplanet.com/s/article/Minimum-Requirements"
                }, "Knowledge Hub."));
}

var WebpError = {
  make: Error$WebpError
};

var make$1 = Error_modal.Error;

var JsErrorModal = {
  make: make$1
};

function jsErrorModalCB(onClickHome, onClearError, message, param) {
  return React.createElement(make$1, {
              onClickHome: onClickHome,
              onClearError: onClearError,
              message: message
            });
}

function Error$Modal(Props) {
  var messageOpt = Props.message;
  var errorModalOpt = Props.errorModal;
  var onClickHome = Props.onClickHome;
  var children = Props.children;
  var message = messageOpt !== undefined ? messageOpt : "We seem to have encountered an error. Please retry or go back to the home page. If this problem persists, please contact our customer support team.";
  var errorModal = errorModalOpt !== undefined ? errorModalOpt : jsErrorModalCB;
  var state = React.useContext(context);
  var match = state.error;
  var match$1 = state.modalMessage;
  var errMessage;
  var exit = 0;
  if (match !== undefined && typeof match === "number") {
    if (match !== 5) {
      if (match !== 6 || match$1 !== undefined) {
        exit = 1;
      } else {
        errMessage = React.createElement(Error$WebpError, {});
      }
    } else if (match$1 !== undefined) {
      exit = 1;
    } else {
      errMessage = React.createElement(Error$PixiError, {});
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    errMessage = match$1 !== undefined ? match$1.VAL : message;
  }
  if (Belt_Option.isNone(state.error)) {
    return Curry._1(children, undefined);
  } else {
    return Curry._4(errorModal, onClickHome, state.onClearError, errMessage, undefined);
  }
}

var Modal = {
  JsErrorModal: JsErrorModal,
  jsErrorModalCB: jsErrorModalCB,
  make: Error$Modal
};

function useErrorModal(param) {
  return React.useContext(context);
}

function Error$Consumer(Props) {
  var children = Props.children;
  return Curry._1(children, React.useContext(context));
}

var Consumer = {
  use: useErrorModal,
  make: Error$Consumer
};

export {
  stringifyArray ,
  exnToString ,
  stringify ,
  JsInterop ,
  onSetErrorDefault ,
  onSetErrorWithMessageDefault ,
  Provider ,
  MakeApp ,
  Sentry ,
  App ,
  PixiError ,
  WebpError ,
  Modal ,
  useErrorModal ,
  Consumer ,
  
}
/* context Not a pure module */
