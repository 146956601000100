// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseMutation from "../../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";
import * as HasuraTypes$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/HasuraTypes.bs.js";
import * as MutationHook$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/MutationHook.bs.js";

var Raw = {};

var query = Client.gql(["mutation StudentResponseFunction($studentActivityAttemptId: uuid, $answer: String, $points: Int, $questionIndex: Int, $state: String, $contentMd5: String)  {\nresponse: student_response_function(args: {student_activity_attemptid: $studentActivityAttemptId, answer: $answer, points: $points, question_index: $questionIndex, state: $state, content_md_5: $contentMd5})  {\n__typename  \nid  \nstudentActivityAttemptId  \nquestionIndex  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.response;
  return {
          response: value$1.map(function (value) {
                return {
                        __typename: value.__typename,
                        id: value.id,
                        studentActivityAttemptId: value.studentActivityAttemptId,
                        questionIndex: value.questionIndex
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.response;
  var response = value$1.map(function (value) {
        var value$1 = value.questionIndex;
        var value$2 = value.studentActivityAttemptId;
        var value$3 = value.id;
        var value$4 = value.__typename;
        return {
                __typename: value$4,
                id: value$3,
                studentActivityAttemptId: value$2,
                questionIndex: value$1
              };
      });
  return {
          response: response
        };
}

function serializeVariables(inp) {
  var a = inp.studentActivityAttemptId;
  var a$1 = inp.answer;
  var a$2 = inp.points;
  var a$3 = inp.questionIndex;
  var a$4 = inp.state;
  var a$5 = inp.contentMd5;
  return {
          studentActivityAttemptId: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          answer: a$1 !== undefined ? a$1 : undefined,
          points: a$2 !== undefined ? a$2 : undefined,
          questionIndex: a$3 !== undefined ? a$3 : undefined,
          state: a$4 !== undefined ? a$4 : undefined,
          contentMd5: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables(studentActivityAttemptId, answer, points, questionIndex, state, contentMd5, param) {
  return {
          studentActivityAttemptId: studentActivityAttemptId,
          answer: answer,
          points: points,
          questionIndex: questionIndex,
          state: state,
          contentMd5: contentMd5
        };
}

function makeDefaultVariables(param) {
  return {
          studentActivityAttemptId: undefined,
          answer: undefined,
          points: undefined,
          questionIndex: undefined,
          state: undefined,
          contentMd5: undefined
        };
}

var StudentResponseFunction_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var StudentResponseFunction_useWithVariables = include.useWithVariables;

var StudentResponseFunction = {
  StudentResponseFunction_inner: StudentResponseFunction_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  use: use,
  useWithVariables: StudentResponseFunction_useWithVariables
};

var StudentResponse = MutationHook$LiteracyplanetClientFlags.Make({
      Raw: Raw,
      use: use
    });

function use$1(param) {
  var match = Curry._1(StudentResponse.useWithWordMonsterError, "student_response_function");
  var mutation = match[0];
  var insert = function (studentActivityAttemptId, answer, points, questionIndex, state, contentMd5) {
    Curry._8(mutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
          studentActivityAttemptId: Caml_option.some(HasuraTypes$LiteracyplanetClientFlags.encode(studentActivityAttemptId)),
          answer: answer,
          points: points,
          questionIndex: questionIndex,
          state: state,
          contentMd5: contentMd5
        });
    
  };
  return [
          insert,
          Belt_Option.flatMap(match[3], (function (param) {
                  return Belt_Option.map(Belt_Array.get(param.response, 0), (function (r) {
                                return HasuraTypes$LiteracyplanetClientFlags.decode(r.id);
                              }));
                }))
        ];
}

export {
  StudentResponseFunction ,
  StudentResponse ,
  use$1 as use,
  
}
/* query Not a pure module */
