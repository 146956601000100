// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PrevArrow(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              version: "1.1",
              viewBox: "0 0 18 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  id: "footer_game",
                  transform: "translate(-50.000000, -86.000000)"
                }, React.createElement("g", {
                      id: "Group-6",
                      transform: "translate(50.000000, 76.000000)"
                    }, React.createElement("g", {
                          id: "icon_leftarrow",
                          transform: "translate(9.000000, 18.000000) scale(-1, 1) translate(-9.000000, -18.000000) translate(0.000000, 10.000000)"
                        }, React.createElement("path", {
                              id: "Path",
                              d: "M17.8840523,7.71554139 L10.3825883,0.110136764 C10.2736678,0.00291771394 10.1155573,-0.0292480011 9.97501464,0.0279354923 C9.83447199,0.0886929541 9.74311927,0.224503751 9.74311927,0.381758358 L9.74311927,6.00361056 L0.375951591,6.00361056 C0.168651181,6.00361056 0,6.17516104 0,6.38602517 L0,9.62761445 C0,9.83847858 0.168651181,10.0100291 0.375951591,10.0100291 L9.74663283,10.0100291 L9.74663283,15.6175854 C9.74663283,15.771266 9.83798556,15.9106508 9.97852821,15.9714083 C10.0242046,15.9928521 10.0733945,16 10.1225844,16 C10.2209643,16 10.3158306,15.9606863 10.3896155,15.889207 L17.8910794,8.26235855 C17.9613508,8.19087918 18,8.09438203 18,7.99073695 C17.9964864,7.8835179 17.9543236,7.78702076 17.8840523,7.71554139 Z"
                            })))));
}

var make = PrevArrow;

export {
  make ,
  
}
/* react Not a pure module */
