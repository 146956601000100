// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context$LiteracyplanetClientLibsActivity from "./Context.bs.js";

function defaultDispatchables_onActivityCompleted(param) {
  
}

function defaultDispatchables_onActivityReachedEnd(param) {
  
}

function defaultDispatchables_onActivityStarted(param) {
  
}

function defaultDispatchables_onExit(param, param$1) {
  
}

function defaultDispatchables_onQuestionFailed(param, param$1) {
  
}

function defaultDispatchables_onQuestionPassed(param, param$1) {
  
}

function defaultDispatchables_onQuestionStarted(param, param$1) {
  
}

var defaultDispatchables = {
  onActivityCompleted: defaultDispatchables_onActivityCompleted,
  onActivityReachedEnd: defaultDispatchables_onActivityReachedEnd,
  onActivityStarted: defaultDispatchables_onActivityStarted,
  onExit: defaultDispatchables_onExit,
  onQuestionFailed: defaultDispatchables_onQuestionFailed,
  onQuestionPassed: defaultDispatchables_onQuestionPassed,
  onQuestionStarted: defaultDispatchables_onQuestionStarted
};

var Context = Context$LiteracyplanetClientLibsActivity.MakePair({
      name: "EventsContext",
      defaultValue: defaultDispatchables
    });

export {
  defaultDispatchables ,
  Context ,
  
}
/* Context Not a pure module */
