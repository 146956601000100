// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function MakePair(C) {
  var state = {
    contents: C.defaultValue
  };
  var subscriptions = {
    contents: []
  };
  var addSubscription = function (f) {
    subscriptions.contents = subscriptions.contents.concat([f]);
    return function (param) {
      subscriptions.contents = subscriptions.contents.filter(function (sub) {
            return sub !== f;
          });
      
    };
  };
  var updateState = function (newStateOpt) {
    var newState = newStateOpt !== undefined ? Caml_option.valFromOption(newStateOpt) : C.defaultValue;
    state.contents = newState;
    subscriptions.contents.forEach(function (f) {
          return Curry._1(f, newState);
        });
    
  };
  var Context$MakePair$Provider = function (Props) {
    var value = Props.value;
    var children = Props.children;
    React.useEffect((function () {
            updateState(value);
            
          }), [value]);
    return children;
  };
  var Provider = {
    make: Context$MakePair$Provider
  };
  var Context$MakePair$Consumer = function (Props) {
    var children = Props.children;
    var match = React.useReducer((function (param, action) {
            return action._0;
          }), state.contents);
    var send = match[1];
    React.useEffect((function () {
            return addSubscription(function (newState) {
                        return Curry._1(send, /* ChangeState */{
                                    _0: newState
                                  });
                      });
          }), []);
    return Curry._1(children, match[0]);
  };
  var Consumer = {
    make: Context$MakePair$Consumer
  };
  return {
          state: state,
          subscriptions: subscriptions,
          addSubscription: addSubscription,
          updateState: updateState,
          Provider: Provider,
          Consumer: Consumer
        };
}

export {
  MakePair ,
  
}
/* react Not a pure module */
