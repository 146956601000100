// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "../../../../../node_modules/rescript/lib/es6/belt_List.js";

var commonSyles = Curry._1(Css.style, {
      hd: Css.fontFamilies({
            hd: {
              NAME: "custom",
              VAL: "Quicksand"
            },
            tl: {
              hd: {
                NAME: "custom",
                VAL: "Lato"
              },
              tl: {
                hd: {
                  NAME: "custom",
                  VAL: "Arial"
                },
                tl: /* [] */0
              }
            }
          }),
      tl: /* [] */0
    });

function backgroundCss(dark, tiledOpt, mainBackground) {
  var tiled = tiledOpt !== undefined ? tiledOpt : false;
  return Curry._1(Css.merge, {
              hd: commonSyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position(Css.fixed),
                      tl: {
                        hd: Css.top(Css.zero),
                        tl: {
                          hd: Css.overflowY(Css.auto),
                          tl: {
                            hd: Css.overflowX(Css.hidden),
                            tl: {
                              hd: Css.paddingBottom(Css.px(0)),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: {
                                  hd: Css.height(Css.pct(100.0)),
                                  tl: {
                                    hd: Css.backgroundPositions({
                                          hd: Css.center,
                                          tl: {
                                            hd: Css.center,
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.backgrounds(Belt_List.concat(dark ? ({
                                                    hd: Css.linearGradient(Css.deg(0.0), {
                                                          hd: [
                                                            Css.pct(0.0),
                                                            Css.rgba(0, 0, 0, {
                                                                  NAME: "num",
                                                                  VAL: 0.8
                                                                })
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              Css.pct(50.0),
                                                              Css.rgba(0, 0, 0, {
                                                                    NAME: "num",
                                                                    VAL: 0.5
                                                                  })
                                                            ],
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: /* [] */0
                                                  }) : /* [] */0, {
                                                hd: Css.url(mainBackground),
                                                tl: /* [] */0
                                              })),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: Curry._1(Css.style, tiled ? ({
                            hd: Css.backgroundSize(Css.size(Css.px(80), Css.px(80))),
                            tl: {
                              hd: Css.backgroundRepeat(Css.repeat),
                              tl: /* [] */0
                            }
                          }) : ({
                            hd: Css.backgroundSize(Css.cover),
                            tl: {
                              hd: Css.backgroundRepeat(Css.noRepeat),
                              tl: {
                                hd: Css.backgroundAttachment(Css.fixed),
                                tl: /* [] */0
                              }
                            }
                          })),
                  tl: /* [] */0
                }
              }
            });
}

function backgroundColor(color) {
  return Curry._1(Css.merge, {
              hd: commonSyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.backgroundColor(color),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

function BackgroundComponent(Props) {
  var background = Props.background;
  var dark = Props.dark;
  var children = Props.children;
  if (typeof background === "number") {
    throw {
          RE_EXN_ID: "Match_failure",
          _1: [
            "BackgroundComponent.res",
            43,
            2
          ],
          Error: new Error()
        };
  }
  switch (background.TAG | 0) {
    case /* Src */0 :
        return React.createElement("div", {
                    className: backgroundCss(dark, undefined, background._0),
                    id: "backgroundComponentSrc"
                  }, children);
    case /* Color */1 :
        return React.createElement("div", {
                    className: backgroundColor({
                          NAME: "hex",
                          VAL: background._0
                        }),
                    id: "backgroundComponentColor"
                  }, children);
    case /* Style */2 :
        throw {
              RE_EXN_ID: "Match_failure",
              _1: [
                "BackgroundComponent.res",
                43,
                2
              ],
              Error: new Error()
            };
    case /* TiledSrc */3 :
        return React.createElement("div", {
                    className: backgroundCss(dark, true, background._0),
                    id: "backgroundComponentSrc"
                  }, children);
    
  }
}

var make = BackgroundComponent;

export {
  commonSyles ,
  backgroundCss ,
  backgroundColor ,
  make ,
  
}
/* commonSyles Not a pure module */
