// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.bs.js";
import * as SecondaryHeaderIcons$LiteracyplanetClientSxEntry from "./SecondaryHeaderIcons.bs.js";
import * as SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry from "./SecondaryHeaderStylesRatio.bs.js";

function InitialsMenuRatio$CloseIcon(Props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.CloseIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.SmallIcon.className
            });
}

var CloseIcon = {
  make: InitialsMenuRatio$CloseIcon
};

function InitialsMenuRatio$CloseBtnComponent(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.CloseBtn.className,
              onClick: onClick
            }, React.createElement(InitialsMenuRatio$CloseIcon, {}));
}

var CloseBtnComponent = {
  make: InitialsMenuRatio$CloseBtnComponent
};

function InitialsMenuRatio$IconAndText(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.IconAndText.className
            }, children);
}

var IconAndText = {
  make: InitialsMenuRatio$IconAndText
};

function InitialsMenuRatio$MouseLinkButton(Props) {
  var children = Props.children;
  var onClick = Props.onClick;
  var bgColor = Props.bgColor;
  var hoverColor = Props.hoverColor;
  var activeColor = Props.activeColor;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuLink.className(bgColor, hoverColor, activeColor),
              onClick: onClick
            }, React.createElement(InitialsMenuRatio$IconAndText, {
                  children: children
                }));
}

var MouseLinkButton = {
  make: InitialsMenuRatio$MouseLinkButton
};

function InitialsMenuRatio$MouseLinkButtonHref(Props) {
  var children = Props.children;
  var href = Props.href;
  var bgColor = Props.bgColor;
  var hoverColor = Props.hoverColor;
  var activeColor = Props.activeColor;
  return React.createElement("a", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuLink.className(bgColor, hoverColor, activeColor),
              href: href
            }, React.createElement(InitialsMenuRatio$IconAndText, {
                  children: children
                }));
}

var MouseLinkButtonHref = {
  make: InitialsMenuRatio$MouseLinkButtonHref
};

function InitialsMenuRatio$WordManiaLinkIcon(Props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.WordManiaIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.LinkIcon.className
            });
}

var WordManiaLinkIcon = {
  make: InitialsMenuRatio$WordManiaLinkIcon
};

function InitialsMenuRatio$LogOutLinkIcon(Props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.LogoutIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.LinkIcon.className
            });
}

var LogOutLinkIcon = {
  make: InitialsMenuRatio$LogOutLinkIcon
};

function InitialsMenuRatio$WordManiaLinkButton(Props) {
  var onClick = Props.onClick;
  return React.createElement(InitialsMenuRatio$MouseLinkButton, {
              children: null,
              onClick: onClick,
              bgColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.wordManiaColor,
              hoverColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.wordManiaHoverColor,
              activeColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.wordManiaActiveColor
            }, React.createElement(InitialsMenuRatio$WordManiaLinkIcon, {}), SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s("Word Mania"));
}

var WordManiaLinkButton = {
  make: InitialsMenuRatio$WordManiaLinkButton
};

function InitialsMenuRatio$LogOutLinkButton(Props) {
  var onClick = Props.onClick;
  return React.createElement(InitialsMenuRatio$MouseLinkButton, {
              children: null,
              onClick: onClick,
              bgColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.logOutColor,
              hoverColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.logOutHoverColor,
              activeColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.logOutActiveColor
            }, React.createElement(InitialsMenuRatio$LogOutLinkIcon, {}), SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s("Exit"));
}

var LogOutLinkButton = {
  make: InitialsMenuRatio$LogOutLinkButton
};

function InitialsMenuRatio$MenuIcon(Props) {
  var onClick = Props.onClick;
  var children = Props.children;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuIcon.className,
              onClick: onClick
            }, children);
}

var MenuIcon = {
  make: InitialsMenuRatio$MenuIcon
};

function InitialsMenuRatio$NavigationContainer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NavigationContainer.className
            }, children);
}

var NavigationContainer = {
  make: InitialsMenuRatio$NavigationContainer
};

function InitialsMenuRatio$NavigationName(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NavigationName.className
            }, children);
}

var NavigationName = {
  make: InitialsMenuRatio$NavigationName
};

function InitialsMenuRatio$NavigationLinksContainer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NavigationLinksContainer.className
            }, children);
}

var NavigationLinksContainer = {
  make: InitialsMenuRatio$NavigationLinksContainer
};

function InitialsMenuRatio$NotificationIcon(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.NotificationIcon.className
            }, children);
}

var NotificationIcon = {
  make: InitialsMenuRatio$NotificationIcon
};

function InitialsMenuRatio$MenuOpenIcon(Props) {
  return React.createElement(Icons$LiteracyplanetClientSxEntry.MenuOpenIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.MenuOpenIcon.className
            });
}

var MenuOpenIcon = {
  make: InitialsMenuRatio$MenuOpenIcon
};

function InitialsMenuRatio$LoginLinkIcon(Props) {
  return React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.LoginIcon.make, {
              className: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.LinkIcon.className
            });
}

var LoginLinkIcon = {
  make: InitialsMenuRatio$LoginLinkIcon
};

function InitialsMenuRatio$TeacherModeButton(Props) {
  var href = Props.href;
  return React.createElement(InitialsMenuRatio$MouseLinkButtonHref, {
              children: null,
              href: href,
              bgColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.missionsColor,
              hoverColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.missionsHoverColor,
              activeColor: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.missionsActiveColor
            }, React.createElement(InitialsMenuRatio$LoginLinkIcon, {}), SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s("Teacher Mode"));
}

var TeacherModeButton = {
  make: InitialsMenuRatio$TeacherModeButton
};

function InitialsMenuRatio(Props) {
  var collectionNotificationCount = Props.collectionNotificationCount;
  var userName = Props.userName;
  var userInitials = Props.userInitials;
  var showWordManiaLink = Props.showWordManiaLink;
  var showTeacherModeWidget = Props.showTeacherModeWidget;
  var teacherModeLink = Props.teacherModeLink;
  var onClickWordMania = Props.onClickWordMania;
  var onClickLogout = Props.onClickLogout;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  return React.createElement(React.Fragment, undefined, match[0] ? React.createElement(InitialsMenuRatio$NavigationContainer, {
                    children: null
                  }, React.createElement(InitialsMenuRatio$NavigationName, {
                        children: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s(userName)
                      }), React.createElement(InitialsMenuRatio$NavigationLinksContainer, {
                        children: null
                      }, showWordManiaLink ? React.createElement(InitialsMenuRatio$WordManiaLinkButton, {
                              onClick: (function (param) {
                                  return Curry._1(onClickWordMania, undefined);
                                })
                            }) : null, showTeacherModeWidget ? React.createElement(InitialsMenuRatio$TeacherModeButton, {
                              href: teacherModeLink
                            }) : null, React.createElement(InitialsMenuRatio$LogOutLinkButton, {
                            onClick: (function (param) {
                                return Curry._1(onClickLogout, undefined);
                              })
                          })), React.createElement(InitialsMenuRatio$CloseBtnComponent, {
                        onClick: (function (param) {
                            return Curry._1(setIsOpen, (function (previousValue) {
                                          return !previousValue;
                                        }));
                          })
                      })) : React.createElement(React.Fragment, undefined, React.createElement(InitialsMenuRatio$MenuIcon, {
                        onClick: (function (param) {
                            return Curry._1(setIsOpen, (function (previousValue) {
                                          return !previousValue;
                                        }));
                          }),
                        children: null
                      }, SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s(userInitials), React.createElement(InitialsMenuRatio$MenuOpenIcon, {})), collectionNotificationCount > 0 ? React.createElement(InitialsMenuRatio$NotificationIcon, {
                          children: SecondaryHeaderStylesRatio$LiteracyplanetClientSxEntry.s(String(collectionNotificationCount))
                        }) : null));
}

var make = InitialsMenuRatio;

export {
  CloseIcon ,
  CloseBtnComponent ,
  IconAndText ,
  MouseLinkButton ,
  MouseLinkButtonHref ,
  WordManiaLinkIcon ,
  LogOutLinkIcon ,
  WordManiaLinkButton ,
  LogOutLinkButton ,
  MenuIcon ,
  NavigationContainer ,
  NavigationName ,
  NavigationLinksContainer ,
  NotificationIcon ,
  MenuOpenIcon ,
  LoginLinkIcon ,
  TeacherModeButton ,
  make ,
  
}
/* react Not a pure module */
