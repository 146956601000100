// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";

function make(prim) {
  return Uuid.v4();
}

export {
  make ,
  
}
/* uuid Not a pure module */
