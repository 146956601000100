// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "../../../node_modules/rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as QueryHook$LiteracyplanetClientFlags from "./QueryHook.bs.js";
import * as LocaleContext$LiteracyplanetClientFlags from "./LocaleContext.bs.js";

var Raw = {};

var query = Client.gql(["fragment ContentAudioFragment on ContentAudio   {\n__typename  \nsource  \n}\n"]);

function parse(value) {
  var value$1 = value.source;
  return {
          __typename: value.__typename,
          source: !(value$1 == null) ? value$1 : undefined
        };
}

function serialize(value) {
  var value$1 = value.source;
  var source = value$1 !== undefined ? value$1 : null;
  var value$2 = value.__typename;
  return {
          __typename: value$2,
          source: source
        };
}

function verifyArgsAndParse(_ContentAudioFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ContentAudioFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = Client.gql([
      "query AudiosQuery($locale: Locale!, $accent: Accent!, $ids: [ID!]!)  {\nfindAudios(ids: $ids, locale: $locale, accent: $accent)  {\n...ContentAudioFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$1(value) {
  var value$1 = value.findAudios;
  return {
          findAudios: value$1.map(function (value) {
                if (!(value == null)) {
                  return parse(value);
                }
                
              })
        };
}

function serialize$1(value) {
  var value$1 = value.findAudios;
  var findAudios = value$1.map(function (value) {
        if (value !== undefined) {
          return serialize(value);
        } else {
          return null;
        }
      });
  return {
          findAudios: findAudios
        };
}

function serializeVariables(inp) {
  var a = inp.locale;
  var a$1 = inp.accent;
  var a$2 = inp.ids;
  return {
          locale: a === "en_BZ" ? "en_BZ" : (
              a === "en_CA" ? "en_CA" : (
                  a === "en_CB" ? "en_CB" : (
                      a === "en_GB" ? "en_GB" : (
                          a === "en_IE" ? "en_IE" : (
                              a === "en_IN" ? "en_IN" : (
                                  a === "en_JM" ? "en_JM" : (
                                      a === "en_NZ" ? "en_NZ" : (
                                          a === "en_PH" ? "en_PH" : (
                                              a === "en_TT" ? "en_TT" : (
                                                  a === "en_US" ? "en_US" : (
                                                      a === "en_ZA" ? "en_ZA" : (
                                                          a === "en_ZW" ? "en_ZW" : "en_AU"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          accent: a$1 === "American" ? "American" : (
              a$1 === "British" ? "British" : (
                  a$1 === "Texan" ? "Texan" : "Australian"
                )
            ),
          ids: a$2.map(function (b) {
                return b;
              })
        };
}

function makeVariables(locale, accent, ids, param) {
  return {
          locale: locale,
          accent: accent,
          ids: ids
        };
}

var GetAudios_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables
    });

var use = include.use;

var GetAudios_refetchQueryDescription = include.refetchQueryDescription;

var GetAudios_useLazy = include.useLazy;

var GetAudios_useLazyWithVariables = include.useLazyWithVariables;

var GetAudios = {
  GetAudios_inner: GetAudios_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: GetAudios_refetchQueryDescription,
  use: use,
  useLazy: GetAudios_useLazy,
  useLazyWithVariables: GetAudios_useLazyWithVariables
};

function makeAudio(audios, ids) {
  return Belt_Array.map(Belt_Array.zip(ids, audios), (function (param) {
                return [
                        param[0],
                        Belt_Option.flatMap(param[1], (function (a) {
                                return a.source;
                              }))
                      ];
              }));
}

function handleResponse(r, ids) {
  return /* Audios */{
          _0: makeAudio(r.findAudios, ids)
        };
}

function onResult(ids, query) {
  return Belt_Result.map(query, (function (res) {
                return handleResponse(res, ids);
              }));
}

function onLoading(param) {
  return /* LoadingAudios */0;
}

var Q = QueryHook$LiteracyplanetClientFlags.Make({
      Raw: Raw$1,
      use: use
    });

function useQuery(ids) {
  var api = LocaleContext$LiteracyplanetClientFlags.use(undefined);
  var locale = api.locale;
  var accent = api.accent;
  return Curry._4(Q.useWithErrorModal, onLoading, onLoading, (function (param) {
                return onResult(ids, param);
              }), {
              locale: locale,
              accent: accent,
              ids: ids
            });
}

export {
  ContentAudioFragment ,
  GetAudios ,
  makeAudio ,
  handleResponse ,
  onResult ,
  onLoading ,
  Q ,
  useQuery ,
  
}
/* query Not a pure module */
