// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Flavor$LiteracyplanetTupperware from "./Flavor.bs.js";

function K2AnswerMarkIcon$AnswerCorrect(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M255.3,0.5C114.3,0.5,0,114.8,0,255.8s114.3,255.3,255.3,255.3s255.3-114.3,255.3-255.3S396.4,0.5,255.3,0.5z\n        M225.7,382.1l-115.1-106l40-43.5l69,63.5l136-166.2l45.7,37.4L225.7,382.1z"
                }));
}

var AnswerCorrect = {
  make: K2AnswerMarkIcon$AnswerCorrect
};

function K2AnswerMarkIcon$AnswerWrong(Props) {
  var className = Props.className;
  return React.createElement("svg", {
              className: className,
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M255.3,0.5C114.3,0.5,0,114.8,0,255.8s114.3,255.3,255.3,255.3s255.3-114.3,255.3-255.3S396.3,0.5,255.3,0.5z\n        M377.2,336.3l-40.9,40.9L256,296.9l-80.3,80.3l-40.9-40.9l80.3-80.3l-80.3-80.3l40.9-40.9l80.3,80.3l80.3-80.3l40.9,40.9\n        L296.9,256L377.2,336.3z"
                }));
}

var AnswerWrong = {
  make: K2AnswerMarkIcon$AnswerWrong
};

function K2AnswerMarkIcon$Background(Props) {
  var additionalClasses = Props.additionalClasses;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Flavor$LiteracyplanetTupperware.K2AnswerMarkIcon.Background.className, additionalClasses)
            }, children);
}

var Background = {
  make: K2AnswerMarkIcon$Background
};

function K2AnswerMarkIcon$Correct(Props) {
  return React.createElement(K2AnswerMarkIcon$Background, {
              additionalClasses: "CorrectAnswer",
              children: React.createElement(K2AnswerMarkIcon$AnswerCorrect, {
                    className: Flavor$LiteracyplanetTupperware.K2AnswerMarkIcon.Correct.className
                  })
            });
}

var Correct = {
  make: K2AnswerMarkIcon$Correct
};

function K2AnswerMarkIcon$Wrong(Props) {
  return React.createElement(K2AnswerMarkIcon$Background, {
              additionalClasses: "WrongAnswer",
              children: React.createElement(K2AnswerMarkIcon$AnswerWrong, {
                    className: Flavor$LiteracyplanetTupperware.K2AnswerMarkIcon.Wrong.className
                  })
            });
}

var Wrong = {
  make: K2AnswerMarkIcon$Wrong
};

export {
  AnswerCorrect ,
  AnswerWrong ,
  Background ,
  Correct ,
  Wrong ,
  
}
/* react Not a pure module */
