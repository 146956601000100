// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as BehaviourMultiChoiceGroupedOptionContainer$LiteracyplanetTupperware from "./BehaviourMultiChoiceGroupedOptionContainer.bs.js";

function PieceOfCakeMultiChoiceSingleResponseContainer(Props) {
  var questionIndex = Props.questionIndex;
  var responseOptionId = Props.responseOptionId;
  var children = Props.children;
  return React.createElement(BehaviourMultiChoiceGroupedOptionContainer$LiteracyplanetTupperware.make, {
              questionIndex: questionIndex,
              group: "SingleResponse",
              responseOptionId: responseOptionId,
              children: children
            });
}

var make = PieceOfCakeMultiChoiceSingleResponseContainer;

var $$default = PieceOfCakeMultiChoiceSingleResponseContainer;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
