import React from 'react';
import PropTypes from 'prop-types';

class WithOauth extends React.Component {
  render() {
    return (
      this.props.children
    );
  }
};

WithOauth.propTypes = {
  oidcPrompt: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};


export default WithOauth;
