// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BehaviourQuestionVisible$LiteracyplanetTupperware from "./BehaviourQuestionVisible.bs.js";

function makeProps(prim0, prim1, prim2, prim3) {
  var tmp = {
    questionIndex: prim0,
    children: prim1
  };
  if (prim2 !== undefined) {
    tmp.key = prim2;
  }
  return tmp;
}

var make = BehaviourQuestionVisible$LiteracyplanetTupperware.make;

var $$default = BehaviourQuestionVisible$LiteracyplanetTupperware.make;

export {
  make ,
  makeProps ,
  $$default ,
  $$default as default,
  
}
/* BehaviourQuestionVisible-LiteracyplanetTupperware Not a pure module */
