import {USER_FOUND} from '@literacyplanet/redux-oidc';
import {openIdUserManager, openIdSilentRenew} from './open_id_connect';
import {ApolloClient, InMemoryCache, HttpLink, ApolloLink, split} from '@apollo/client';
import ReduxLink from '../apollo_link_redux';
import {getMainDefinition} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';
import {SubscriptionClient} from 'subscriptions-transport-ws/dist/client';
import MessageTypes from 'subscriptions-transport-ws/dist/message-types';

// The apollo WebSocketLink package causes the following error.
// https://github.com/apollographql/subscriptions-transport-ws/issues/56
// It is because they are importing the subscriptions-transport-ws normally
// instead of from dist as above.
export class WebSocketLink extends ApolloLink {
  constructor(client) {
    super();
    this.subscriptionClient = client;
  }
  request(operation) {
    return this.subscriptionClient.request(operation);
  }
}

export const dataIdFromObject = o => {
  if (o.id) {
    return `${o.__typename}__${o.id}`;
  }
};

export const noCacheOption = {
  options: {
    fetchPolicy: 'network-only'
  }
};

export const createApolloAuthMiddleware = (reconnect = true, disableWebsockets = false) => {
  let idToken = null;
  let accessToken = null;
  let wsClient = null;

  const wsGqlURL = `${process.env.WS_GQL_URL}${process.env.WS_GQL_PATH}`;

  if (!disableWebsockets) {
    // Create WebSocket client
    wsClient = new SubscriptionClient(wsGqlURL, {
      reconnect,
      connectionParams: {
        // Pass any arguments you want for initialization
        // NOTE: THIS IS ONLY EVER EVALUATED ONCE, IN ORDER TO SEND A DIFFERNT
        // TOKEN YOU MUST DISCONNECT AND RECONNECT THE WEBSOCKET CLIENT.

        // __APOLLO_CLIENT__.resetWebsocket()

        get accessToken() {
          return accessToken;
        },
        get idToken() {
          return idToken;
        }
      }
    });
  }

  // https://github.com/apollographql/subscriptions-transport-ws/issues/171
  // https://github.com/coralproject/talk/blob/32da779ac155af2547f411a81ce5d93aecdcdd5e/\
  // client/coral-framework/services/client.js#L9
  const resetWebsocket = () => {
    if (wsClient === null) {
      // Nothing to reset!
      return;
    }

    // Close socket connection which will also unregister subscriptions on the server-side.
    if (wsClient.status === 1) {
      // OPEN
      wsClient.close();
    }

    // Reconnect to the server.
    wsClient.connect();

    // Reregister all subscriptions (uses non public api).
    // See: https://github.com/apollographql/subscriptions-transport-ws/issues/171
    Object.keys(wsClient.operations).forEach((id) => {
      wsClient.sendMessage(id, MessageTypes.GQL_START, wsClient.operations[id].options);
    });
  };

  const apolloMiddlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      'headers': {
        'authorization': accessToken,
        'X-IdToken': idToken
      }
    });
    return forward(operation);
  });

  const reduxApolloAuthMiddleware = (store) => (next) => (action) => {
    const {openId: {user}} = store.getState();
    accessToken = user ? user.access_token : null;
    idToken = user ? user.id_token : null;

    if (action.type === USER_FOUND) {
      accessToken = action.payload.access_token;
      idToken = action.payload.id_token;
    }

    // TODO - need to add all open id actions
    if (action.type === USER_FOUND) {
      resetWebsocket();
    }

    next(action);
  };

  return {apolloMiddlewareLink, reduxApolloAuthMiddleware, wsClient};
};

const signIn = () => {
  // iOS
  if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.signInMessageHandler.postMessage({
      param1: 'Signing in',
      param2: '1000'
    });
  }

  // Android
  if (typeof bridge !== 'undefined') {
    bridge.signInMessageHandler('Signing in');
  }
};

export const createApolloClientWithAuth = ({
  apolloMiddlewareLink,
  store,
  wsClient,
  serverUri,
  possibleTypes
}) => {
  const errorLink = onError(({graphQLErrors, networkError, operation}) => {
    if (networkError) {
      console.log(`createApolloClientWithAuth: [Network error]:`);
      try {
        console.log('networkError.name', networkError.name);
        console.log('networkError.statusCode', networkError.statusCode);
        console.log('networkError.result', networkError.result);
        if (operation.operationName) {
          console.log('operationName', operation.operationName);
        }
        if (operation.variables) {
          console.log('variables', JSON.stringify(operation.variables));
        }
      } catch (e) {
        console.log('couldn\'t JSONify networkError');
      }
      return;
    }
    const unauthorised = graphQLErrors
      .find(({message}) => message.includes('Unauthorised'));
    if (unauthorised) {
      // if mobile or message includes a reason sign in again
      if (process.env.MOBILE) {
        openIdUserManager.removeUser().then(() =>
          signIn()
        );
      } else {
        unauthorised.message.includes('expired')
        ? openIdSilentRenew(store, openIdUserManager)
        : openIdUserManager.removeUser();
      }
    }
  });

  const httpLink = new HttpLink({
    uri: serverUri || process.env.GRAPHQL_SERVER + process.env.GRAPHQL_PATH
  });

  let requestLink = httpLink;

  if (wsClient !== null) {
    const wsLink = new WebSocketLink(wsClient);

    requestLink = split(
      ({query}) => {
        const {kind, operation} = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    );
  }

  const filterNulls = (obj) => {
    return Object.keys(obj).reduce((body, key) => {
      const value = obj[key];
      if (value !== null) {
        if (value instanceof Array) {
          body[key] = value;
        } else if (typeof value === 'object') {
          body[key] = filterNulls(value);
        } else {
          body[key] = value;
        }
      }
      return body;
    }, {});
  };

  const isMutation = (operation) =>
    (operation.query.definitions
      .find(({kind}) => kind === 'OperationDefinition') || {})
      .operation === 'mutation';

  const middlewareLink = new ApolloLink((operation, forward) => {
    const variables = isMutation(operation)
      ? filterNulls(operation.variables)
      : operation.variables;
    operation.variables = variables;
    return forward(operation);
  });

  const reduxLink = new ReduxLink(store);
  const link = ApolloLink.from([
    apolloMiddlewareLink,
    reduxLink,
    errorLink,
    middlewareLink,
    requestLink
  ]);
  const cacheOptions = {};
  if (possibleTypes) {
    cacheOptions.possibleTypes = possibleTypes;
  }

  // cacheOptions.typePolicies = {
  //   Student: {
  //     keyFields: ['userId']
  //   }
  // };

  // cacheOptions.typePolicies = {
  //   Teacher: {
  //     keyFields: ['userId']
  //   },
  //   TeacherDashboardMissionsReport: {
  //     keyFields: ['userId']
  //   },
  //   StudentProfile: {
  //     keyFields: ['login']
  //   },
  //   Student: {
  //     keyFields: ['userId']
  //   },
  //   TeachingStaff: {
  //     keyFields: ['schoolYearCommencesDate']
  //   },
  // };

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  };

  const cache = new InMemoryCache(cacheOptions);
  const apolloClient = new ApolloClient({link, cache, defaultOptions, dataIdFromObject});

  return apolloClient;
};
