// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import ToHHMMSS from "./toHHMMSS";

var missionsColour = "#0C9CE5";

function VideoControls$PlayIcon(Props) {
  return React.createElement("svg", {
              className: "VPIcons-play",
              fill: "currentColor",
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("ellipse", {
                  cx: "258.5",
                  cy: "257",
                  fill: missionsColour,
                  rx: "205",
                  ry: "205"
                }), React.createElement("path", {
                  d: "M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"
                }));
}

var PlayIcon = {
  make: VideoControls$PlayIcon
};

function VideoControls$PauseIcon(Props) {
  return React.createElement("svg", {
              className: "VPIcons-pause",
              fill: "currentColor",
              viewBox: "0 0 512 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("ellipse", {
                  cx: "258.5",
                  cy: "257",
                  fill: missionsColour,
                  rx: "205",
                  ry: "205"
                }), React.createElement("path", {
                  d: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm96-280v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16zm-112 0v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16z"
                }));
}

var PauseIcon = {
  make: VideoControls$PauseIcon
};

function VideoControls$VolumeDown(Props) {
  return React.createElement("svg", {
              className: "VPIcons-volumeDown",
              fill: "currentColor",
              viewBox: "0 0 640 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M247,71.06l-89,88.94H56a24,24,0,0,0-24,24V328a24,24,0,0,0,24,24H158.06L247,441c15,15,41,4.46,41-17V88c0-21.32-25.86-32.08-41-17Zm-7,295-55-55a24,24,0,0,0-17-7H80V208h88a24,24,0,0,0,17-7l55-55ZM416,256a88.07,88.07,0,0,1-37.9,72.35,24,24,0,0,1-27.34-39.44,40,40,0,0,0-1.94-67.07,24,24,0,0,1,25-41A87.45,87.45,0,0,1,416,256Z"
                }));
}

var VolumeDown = {
  make: VideoControls$VolumeDown
};

function VideoControls$VolumeCenter(Props) {
  return React.createElement("svg", {
              className: "VPIcons-volumeDown",
              fill: "currentColor",
              viewBox: "0 0 640 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M247,71.06l-89,88.94H56a24,24,0,0,0-24,24V328a24,24,0,0,0,24,24H158.06L247,441c15,15,41,4.46,41-17V88c0-21.32-25.86-32.08-41-17Zm-7,295-55-55a24,24,0,0,0-17-7H80V208h88a24,24,0,0,0,17-7l55-55Zm192.76,41.23a24,24,0,0,1-27.36-39.44,136,136,0,0,0-6.57-228,24,24,0,1,1,25-40.95,184,184,0,0,1,8.89,308.37ZM416,256a88.07,88.07,0,0,1-37.9,72.35,24,24,0,0,1-27.34-39.44,40,40,0,0,0-1.94-67.07,24,24,0,0,1,25-41A87.45,87.45,0,0,1,416,256Z"
                }));
}

var VolumeCenter = {
  make: VideoControls$VolumeCenter
};

function VideoControls$VolumeUp(Props) {
  return React.createElement("svg", {
              className: "VPIcons-volumeUp",
              fill: "currentColor",
              viewBox: "0 0 640 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M247,71.06l-89,88.94H56a24,24,0,0,0-24,24V328a24,24,0,0,0,24,24H158.06L247,441c15,15,41,4.46,41-17V88c0-21.32-25.86-32.08-41-17Zm-7,295-55-55a24,24,0,0,0-17-7H80V208h88a24,24,0,0,0,17-7l55-55ZM608,256A280.3,280.3,0,0,1,487.41,486.22a24,24,0,0,1-27.36-39.44A232.07,232.07,0,0,0,448.84,57.91a24,24,0,0,1,25-40.95A280,280,0,0,1,608,256ZM432.76,407.29a24,24,0,0,1-27.36-39.44,136,136,0,0,0-6.57-228,24,24,0,1,1,25-40.95,184,184,0,0,1,8.89,308.37ZM416,256a88.07,88.07,0,0,1-37.9,72.35,24,24,0,0,1-27.34-39.44,40,40,0,0,0-1.94-67.07,24,24,0,0,1,25-41A87.45,87.45,0,0,1,416,256Z"
                }));
}

var VolumeUp = {
  make: VideoControls$VolumeUp
};

function VideoControls$VolumeMute(Props) {
  return React.createElement("svg", {
              className: "VPIcons-volumeMute",
              fill: "currentColor",
              viewBox: "0 0 640 512",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M606.05,509.51,4.68,40.53A12,12,0,0,1,2.49,23.71l14.63-19A12,12,0,0,1,34,2.49l601.37,469a12,12,0,0,1,2.19,16.82l-14.63,19a12,12,0,0,1-16.83,2.19ZM415.91,259.78c0-1.26.09-2.52.09-3.78a87.45,87.45,0,0,0-42.14-75.12,24,24,0,0,0-36.37,17.75Zm-17.08-119.9a135.94,135.94,0,0,1,60,153.34l40,31.18A183.93,183.93,0,0,0,423.87,98.92a24,24,0,0,0-25,41Zm50-82a231.9,231.9,0,0,1,89,296.94L576.48,385A280,280,0,0,0,473.88,17a24,24,0,0,0-25,41ZM395.16,385.63a24,24,0,0,0,31.63,24.67Zm71.9,56.07q-3.45,2.61-7,5.08a24,24,0,0,0,27.36,39.44Q497,479.54,506,472.1l-39-30.4ZM240,264.63V366.06l-55-55a24,24,0,0,0-17-7H80V208h87.39l-61.55-48H56a24,24,0,0,0-24,24V328a24,24,0,0,0,24,24H158.06L247,441c15,15,41,4.46,41-17V302.06l-48-37.43ZM288,160V88c0-21.32-25.86-32.08-41-17l-32,32Z"
                }));
}

var VolumeMute = {
  make: VideoControls$VolumeMute
};

function VideoControls$VolumeIcon(Props) {
  var muted = Props.muted;
  var volume = Props.volume;
  if (muted) {
    return React.createElement(VideoControls$VolumeMute, {});
  } else if (volume < 0.33) {
    return React.createElement(VideoControls$VolumeDown, {});
  } else if (volume > 0.66) {
    return React.createElement(VideoControls$VolumeUp, {});
  } else {
    return React.createElement(VideoControls$VolumeCenter, {});
  }
}

var VolumeIcon = {
  make: VideoControls$VolumeIcon
};

function toHHMMSS(prim) {
  return ToHHMMSS(prim);
}

function VideoControls(Props) {
  var currentTime = Props.currentTime;
  var duration = Props.duration;
  var paused = Props.paused;
  var volume = Props.volume;
  var muted = Props.muted;
  var onVolumeChange = Props.onVolumeChange;
  var onVolumeEnd = Props.onVolumeEnd;
  var onVolumeTouchEnd = Props.onVolumeTouchEnd;
  var onPlayClick = Props.onPlayClick;
  var onScrubStart = Props.onScrubStart;
  var onTouchScrubStart = Props.onTouchScrubStart;
  var onScrubEnd = Props.onScrubEnd;
  var onScrubChange = Props.onScrubChange;
  var onTouchScrubEnd = Props.onTouchScrubEnd;
  var onMuteClick = Props.onMuteClick;
  var displayVolume = muted ? 0.0 : volume;
  return React.createElement("div", {
              className: "VPPlayer-controls VPControls"
            }, React.createElement("div", {
                  className: "VPControls-progress"
                }, React.createElement("div", {
                      className: "VPControls-time"
                    }, React.createElement("span", undefined, ToHHMMSS(currentTime)), React.createElement("span", undefined, " / "), React.createElement("span", undefined, React.createElement("span", {
                              style: {
                                visibility: duration > 0.0 ? "visible" : "hidden"
                              }
                            }, ToHHMMSS(duration)))), React.createElement("div", {
                      className: "VPControls-progressBar"
                    }, React.createElement("input", {
                          className: "VPControls-rangeInput",
                          max: duration.toString(),
                          min: "0",
                          step: 0.1,
                          type: "range",
                          value: currentTime.toString(),
                          onChange: onScrubChange,
                          onMouseDown: onScrubStart,
                          onMouseUp: onScrubEnd,
                          onTouchEnd: onTouchScrubEnd,
                          onTouchStart: onTouchScrubStart
                        }))), React.createElement("div", {
                  className: "VPControls-controls"
                }, React.createElement("div", {
                      className: "VPControls-buttons"
                    }, React.createElement("div", {
                          className: "VPControls-playPause",
                          onClick: onPlayClick
                        }, paused ? React.createElement(VideoControls$PlayIcon, {}) : React.createElement(VideoControls$PauseIcon, {}))), React.createElement("div", {
                      className: "VPControls-volume"
                    }, React.createElement("div", {
                          className: "VPControls-volumeIcon canMute",
                          onClick: onMuteClick
                        }, React.createElement(VideoControls$VolumeIcon, {
                              muted: muted,
                              volume: volume
                            })), React.createElement("input", {
                          className: "VPControls-rangeInput",
                          max: "1",
                          min: "0",
                          step: 0.05,
                          type: "range",
                          value: displayVolume.toString(),
                          onChange: onVolumeChange,
                          onMouseUp: onVolumeEnd,
                          onTouchEnd: onVolumeTouchEnd
                        }))));
}

var make = VideoControls;

export {
  missionsColour ,
  PlayIcon ,
  PauseIcon ,
  VolumeDown ,
  VolumeCenter ,
  VolumeUp ,
  VolumeMute ,
  VolumeIcon ,
  toHHMMSS ,
  make ,
  
}
/* react Not a pure module */
