import {React, PropTypes, WithOauth, connect} from '@literacyplanet/client_core';

const OauthContainerInterop = ({dispatch, user, oidcPrompt, children}) =>
  <WithOauth dispatch={dispatch} oidcPrompt={oidcPrompt} user={user}>
    {user && children({
      name: user.profile.name,
      familyName: `${user.profile.family_name || ''}`,
      givenName: `${user.profile.given_name || ''}`,
      accountName: user.profile.org,
      userRoles: user.profile.roles,
      userId: user.profile.uuid,
      idp: user.profile.idp,
      idpAccountId: `${user.profile.idp_account_id}`,
      classicFlags: user.profile.features,
      startingPoint: user.profile.extensions?.startingPoint
        || (user.profile.extensions && user.profile.extensions[0]?.startingPoint),
      yearLevel: user.profile.extensions?.yearLevel
        || (user.profile.extensions && user.profile.extensions[0]?.yearLevel),
      locale: user.profile.locale,
      email: user.profile.email,
      userType: user.profile.user_type
    })}
    {!user && null}
  </WithOauth>;

OauthContainerInterop.propTypes = {
  oidcPrompt: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  children: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {dispatch};
}

function mapStateToProps(state, {userFound}) {
  const {openId: {user}, oidcPrompt} = state;
  return {user, oidcPrompt, userFound};
}

export default connect(mapStateToProps, mapDispatchToProps)(OauthContainerInterop);
