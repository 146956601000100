// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icons$LiteracyplanetClientSxEntry from "./Icons.bs.js";
import * as JourneyHeaderStyles$LiteracyplanetClientSxEntry from "./JourneyHeaderStyles.bs.js";
import * as SecondaryHeaderIcons$LiteracyplanetClientSxEntry from "./SecondaryHeaderIcons.bs.js";
import * as SecondaryHeaderStyles$LiteracyplanetClientSxEntry from "./SecondaryHeaderStyles.bs.js";

function JourneyInitialsMenu$CloseBtnComponent(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.closeBtn,
              onClick: onClick
            }, React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.CloseIcon.make, {
                  className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.closeIcon
                }));
}

var CloseBtnComponent = {
  make: JourneyInitialsMenu$CloseBtnComponent
};

function JourneyInitialsMenu$MouseLinkButton(Props) {
  var children = Props.children;
  var onClick = Props.onClick;
  var bgColor = Props.bgColor;
  var hoverColor = Props.hoverColor;
  var activeColor = Props.activeColor;
  return React.createElement("div", {
              className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.menuLink(bgColor, hoverColor, activeColor),
              onClick: onClick
            }, React.createElement("div", {
                  className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.iconAndText
                }, children));
}

var MouseLinkButton = {
  make: JourneyInitialsMenu$MouseLinkButton
};

function JourneyInitialsMenu(Props) {
  var userName = Props.userName;
  var userInitials = Props.userInitials;
  var showWordManiaLink = Props.showWordManiaLink;
  var onClickWordMania = Props.onClickWordMania;
  var onClickLogout = Props.onClickLogout;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  return React.createElement(React.Fragment, undefined, match[0] ? React.createElement("div", {
                    className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.navigationContainer
                  }, React.createElement("div", {
                        className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.navigationName
                      }, SecondaryHeaderStyles$LiteracyplanetClientSxEntry.s(userName)), React.createElement("div", {
                        className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.navigationLinksContainer
                      }, showWordManiaLink ? React.createElement(JourneyInitialsMenu$MouseLinkButton, {
                              children: null,
                              onClick: (function (param) {
                                  return Curry._1(onClickWordMania, undefined);
                                }),
                              bgColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.wordManiaColor,
                              hoverColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.wordManiaHoverColor,
                              activeColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.wordManiaActiveColor
                            }, React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.WordManiaIcon.make, {
                                  className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.linkIcon
                                }), SecondaryHeaderStyles$LiteracyplanetClientSxEntry.s("Word Mania")) : null, React.createElement(JourneyInitialsMenu$MouseLinkButton, {
                            children: null,
                            onClick: (function (param) {
                                return Curry._1(onClickLogout, undefined);
                              }),
                            bgColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.logOutColor,
                            hoverColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.logOutHoverColor,
                            activeColor: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.logOutActiveColor
                          }, React.createElement(SecondaryHeaderIcons$LiteracyplanetClientSxEntry.LogoutIcon.make, {
                                className: SecondaryHeaderStyles$LiteracyplanetClientSxEntry.linkIcon
                              }), SecondaryHeaderStyles$LiteracyplanetClientSxEntry.s("Exit"))), React.createElement(JourneyInitialsMenu$CloseBtnComponent, {
                        onClick: (function (param) {
                            return Curry._1(setIsOpen, (function (previousValue) {
                                          return !previousValue;
                                        }));
                          })
                      })) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.menuPanel
                      }, React.createElement("div", {
                            className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.menuIcon,
                            onClick: (function (param) {
                                return Curry._1(setIsOpen, (function (previousValue) {
                                              return !previousValue;
                                            }));
                              })
                          }, userInitials, React.createElement(Icons$LiteracyplanetClientSxEntry.MenuOpenIcon.make, {
                                className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.menuOpenIcon
                              })))));
}

var make = JourneyInitialsMenu;

export {
  CloseBtnComponent ,
  MouseLinkButton ,
  make ,
  
}
/* react Not a pure module */
