// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Make(C) {
  var context = React.createContext(C.$$default);
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var make = context.Provider;
  var use = function (param) {
    return React.useContext(context);
  };
  return {
          context: context,
          makeProps: makeProps,
          make: make,
          use: use
        };
}

export {
  Make ,
  
}
/* react Not a pure module */
