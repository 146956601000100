// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Render$LiteracyplanetTupperware from "./Render.bs.js";
import * as Skillet$LiteracyplanetTupperware from "./Skillet.bs.js";
import * as ExpandingInput$LiteracyplanetTupperware from "./ExpandingInput.bs.js";
import * as V2TextInputHook$LiteracyplanetTupperware from "./V2TextInputHook.bs.js";
import * as V2ExerciseContext$LiteracyplanetTupperware from "./V2ExerciseContext.bs.js";
import * as SkilfulResponseMark$LiteracyplanetTupperware from "./SkilfulResponseMark.bs.js";
import * as V2TextAnswerStatesHook$LiteracyplanetTupperware from "./V2TextAnswerStatesHook.bs.js";

function useLabelTransition(value) {
  var checkInputState = function (param) {
    if (value === "") {
      return Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.LabelLarge.className;
    } else {
      return Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.LabelSmall.className;
    }
  };
  var match = React.useState(function () {
        return checkInputState(undefined);
      });
  var setClassName = match[1];
  var onFocus = function (param) {
    return Curry._1(setClassName, (function (param) {
                  return Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.LabelSmall.className;
                }));
  };
  var onBlur = function (param) {
    return Curry._1(setClassName, (function (param) {
                  return checkInputState(undefined);
                }));
  };
  return [
          match[0],
          onFocus,
          onBlur
        ];
}

function SkilfulTextInputContainer$InputWithLabel(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var label = Props.label;
  var largeField = Props.largeField;
  var match = useLabelTransition(value);
  var labelClassName = match[0];
  return React.createElement(React.Fragment, undefined, Render$LiteracyplanetTupperware.mapWithNull(label, (function (label) {
                    return React.createElement("span", {
                                className: labelClassName
                              }, label);
                  })), React.createElement(ExpandingInput$LiteracyplanetTupperware.make, {
                  value: value,
                  className: Curry._1(Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.Input.className, largeField),
                  onChange: onChange,
                  onFocus: match[1],
                  onBlur: match[2]
                }));
}

var InputWithLabel = {
  make: SkilfulTextInputContainer$InputWithLabel
};

function SkilfulTextInputContainer(Props) {
  var questionIndex = Props.questionIndex;
  var responseOptionId = Props.responseOptionId;
  var label = Props.label;
  var largeFieldOpt = Props.largeField;
  var largeField = largeFieldOpt !== undefined ? largeFieldOpt : false;
  var match = V2TextAnswerStatesHook$LiteracyplanetTupperware.use(questionIndex, responseOptionId);
  var marked = match[1];
  var match$1 = V2ExerciseContext$LiteracyplanetTupperware.use(undefined);
  var answerVisible = Curry._1(match$1.isCorrectAnswerVisible, questionIndex);
  var match$2 = V2TextInputHook$LiteracyplanetTupperware.use(questionIndex, responseOptionId);
  var value = match$2[1];
  var tmp;
  var exit = 0;
  if (marked >= 2) {
    tmp = React.createElement(SkilfulTextInputContainer$InputWithLabel, {
          value: value,
          onChange: match$2[0],
          label: label,
          largeField: largeField
        });
  } else if (answerVisible !== undefined && answerVisible) {
    tmp = React.createElement(SkilfulResponseMark$LiteracyplanetTupperware.make, {
          inputClassName: Skillet$LiteracyplanetTupperware.inputSizeClass(largeField),
          marked: marked,
          answerVisible: answerVisible,
          value: Belt_Option.getWithDefault(match[2], "")
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(SkilfulResponseMark$LiteracyplanetTupperware.make, {
          inputClassName: Skillet$LiteracyplanetTupperware.inputSizeClass(largeField),
          marked: marked,
          answerVisible: answerVisible,
          value: value
        });
  }
  return React.createElement("div", {
              className: Skillet$LiteracyplanetTupperware.SkilfulTextInputContainer.className(largeField)
            }, tmp);
}

var make = SkilfulTextInputContainer;

var $$default = SkilfulTextInputContainer;

export {
  useLabelTransition ,
  InputWithLabel ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
