// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "../../../../../node_modules/bs-css-emotion/src/Css.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ContainerDimensions$LiteracyplanetClientFlags from "../../../../../node_modules/@literacyplanet/client-flags/src/ContainerDimensions.bs.js";

function replaceSpaces(__x) {
  return __x.replace(/ /g, "&nbsp;");
}

var hiddenSpanClass = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.position(Css.absolute),
            tl: {
              hd: Css.left({
                    NAME: "px",
                    VAL: -10000
                  }),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: "ExpandingInput-HiddenSpanClass",
        tl: /* [] */0
      }
    });

function makeWidth(px) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.width({
                          NAME: "px",
                          VAL: px
                        }),
                    tl: /* [] */0
                  }),
              tl: {
                hd: "ExpandingInput-makeWidth",
                tl: /* [] */0
              }
            });
}

function useExpandingInput(className, value) {
  var match = ContainerDimensions$LiteracyplanetClientFlags.useDimsWithContent(value);
  var inputWidthCss = Belt_Option.mapWithDefault(match[1], "", (function (d) {
          return makeWidth((d.width | 0) + 1 | 0);
        }));
  return [
          inputWidthCss,
          React.createElement("span", {
                ref: match[0],
                className: Curry._1(Css.merge, {
                      hd: hiddenSpanClass,
                      tl: {
                        hd: className,
                        tl: /* [] */0
                      }
                    }),
                dangerouslySetInnerHTML: {
                  __html: replaceSpaces(value)
                }
              })
        ];
}

var setInputProps = (function(input) {
    input.autocapitalize = 'off';
    input.autocorrect = false;
    input.autocomplete = 'off';
    input.spellcheck = false;
  });

function ExpandingInput(Props) {
  var value = Props.value;
  var className = Props.className;
  var onChange = Props.onChange;
  var disabledOpt = Props.disabled;
  var spellCheckOpt = Props.spellCheck;
  var autoCompleteOpt = Props.autoComplete;
  var autoCapitalizeOpt = Props.autoCapitalize;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var spellCheck = spellCheckOpt !== undefined ? spellCheckOpt : false;
  var autoComplete = autoCompleteOpt !== undefined ? autoCompleteOpt : "off";
  var autoCapitalize = autoCapitalizeOpt !== undefined ? autoCapitalizeOpt : "off";
  var match = useExpandingInput(className, value);
  var onFocus$1 = Belt_Option.getWithDefault(onFocus, (function (param) {
          
        }));
  var onBlur$1 = Belt_Option.getWithDefault(onBlur, (function (param) {
          
        }));
  var input = React.useRef(null);
  React.useLayoutEffect((function () {
          Belt_Option.map(Caml_option.nullable_to_opt(input.current), setInputProps);
          
        }), [Css.content]);
  return React.createElement(React.Fragment, undefined, match[1], React.createElement("input", {
                  ref: input,
                  className: Curry._1(Css.merge, {
                        hd: className,
                        tl: {
                          hd: match[0],
                          tl: /* [] */0
                        }
                      }),
                  spellCheck: spellCheck,
                  autoComplete: autoComplete,
                  autoCapitalize: autoCapitalize,
                  disabled: disabled,
                  type: "text",
                  value: value,
                  onFocus: onFocus$1,
                  onBlur: onBlur$1,
                  onChange: onChange
                }));
}

var cursorWidth = 1;

var make = ExpandingInput;

export {
  replaceSpaces ,
  hiddenSpanClass ,
  makeWidth ,
  cursorWidth ,
  useExpandingInput ,
  setInputProps ,
  make ,
  
}
/* hiddenSpanClass Not a pure module */
