// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_json from "../../../node_modules/rescript/lib/es6/js_json.js";
import * as Caml_obj from "../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";

function sortBy(x, y, order) {
  if (Caml_obj.caml_equal(x, y)) {
    return order;
  }
  
}

function decode(json) {
  return Belt_Option.getWithDefault(Js_json.decodeString(json), "");
}

function encode(prim) {
  return prim;
}

function decodeJsonbStringArray(a) {
  return Belt_Array.map(Belt_Option.getWithDefault(Js_json.decodeArray(a), []), decode);
}

function encodeJsonbFromArray(a) {
  return Belt_Array.map(a, (function (prim) {
                return prim;
              }));
}

function makeOrderBy(f, orderByFields) {
  return Belt_Array.map(orderByFields, (function (param) {
                var order = param[1];
                var field = param[0];
                return Curry._1(f, (function (x) {
                              return sortBy(field, x, order);
                            }));
              }));
}

function MakeOrderBy(Config) {
  var param = Belt_Array.getExn(Config.humanReadable, 0);
  var defaultHumanReadable = param[0];
  var sortByArray = Belt_Array.map(Config.humanReadable, (function (param) {
          return param[0];
        }));
  var makeOrderByFromStringArray = function (hrs) {
    return Belt_Array.keepMap(Config.humanReadable, (function (param) {
                  var s = param[0];
                  if (Belt_Array.some(hrs, (function (hr) {
                            return hr === s;
                          }))) {
                    return param[1];
                  }
                  
                }));
  };
  return {
          defaultHumanReadable: defaultHumanReadable,
          sortByArray: sortByArray,
          makeOrderByFromStringArray: makeOrderByFromStringArray,
          make: Config.make
        };
}

function Make(Config) {
  var aggregate = function (total) {
    return total.aggregate;
  };
  var count = function (total) {
    return Belt_Option.getWithDefault(Belt_Option.map(total.aggregate, (function (a) {
                      return a.count;
                    })), 0);
  };
  return {
          aggregate: aggregate,
          count: count
        };
}

var Aggregate = {
  Make: Make
};

export {
  sortBy ,
  decode ,
  encode ,
  decodeJsonbStringArray ,
  encodeJsonbFromArray ,
  makeOrderBy ,
  MakeOrderBy ,
  Aggregate ,
  
}
/* No side effect */
