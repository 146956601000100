// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_List from "../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../node_modules/rescript/lib/es6/belt_Result.js";

function join(a, b, cb) {
  return Belt_Result.flatMap(a, (function (a) {
                return Belt_Result.flatMap(b, (function (b) {
                              return Curry._2(cb, a, b);
                            }));
              }));
}

function allOk(__x) {
  return Belt_List.reduce(__x, {
              TAG: /* Ok */0,
              _0: /* [] */0
            }, (function (acc, el) {
                return Belt_Result.flatMap(acc, (function (list) {
                              return Belt_Result.map(el, (function (el) {
                                            return Belt_List.concat(list, {
                                                        hd: el,
                                                        tl: /* [] */0
                                                      });
                                          }));
                            }));
              }));
}

function combine(a, b) {
  if (a.TAG === /* Ok */0) {
    if (b.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: [
                a._0,
                b._0
              ]
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: b._0
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: a._0
          };
  }
}

function combine3(a, b, c) {
  return Belt_Result.flatMap(combine(combine(a, b), c), (function (param) {
                var match = param[0];
                return {
                        TAG: /* Ok */0,
                        _0: [
                          match[0],
                          match[1],
                          param[1]
                        ]
                      };
              }));
}

function toResult(a, err) {
  return Belt_Option.mapWithDefault(a, {
              TAG: /* Error */1,
              _0: err
            }, (function (x) {
                return {
                        TAG: /* Ok */0,
                        _0: x
                      };
              }));
}

function map(a, f, err) {
  return Belt_Option.mapWithDefault(a, {
              TAG: /* Error */1,
              _0: err
            }, (function (x) {
                return {
                        TAG: /* Ok */0,
                        _0: Curry._1(f, x)
                      };
              }));
}

function flatMap(a, f, err) {
  return Belt_Option.mapWithDefault(a, {
              TAG: /* Error */1,
              _0: err
            }, f);
}

var Opt = {
  toResult: toResult,
  map: map,
  flatMap: flatMap
};

function allOk$1(__x) {
  return Belt_Array.reduce(__x, {
              TAG: /* Ok */0,
              _0: []
            }, (function (acc, el) {
                return Belt_Result.flatMap(acc, (function (list) {
                              return Belt_Result.map(el, (function (el) {
                                            return Belt_Array.concat(list, [el]);
                                          }));
                            }));
              }));
}

function append(result, row) {
  return Belt_Result.map(result, (function (rows) {
                return Belt_Array.concat(rows, [row]);
              }));
}

function mapWithIndex(rows, f) {
  return Belt_Array.reduceWithIndex(rows, {
              TAG: /* Ok */0,
              _0: []
            }, (function (rows, curr, i) {
                return join(rows, Curry._3(f, curr, i, rows), (function (rows, row) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: Belt_Array.concat(rows, [row])
                                    };
                            }));
              }));
}

function forEachL(arr, f, cb) {
  return mapWithIndex(arr, (function (row, i, param) {
                var l = Curry._2(f, row, i);
                return Belt_Result.map(allOk$1(l), cb);
              }));
}

function forEach2(arr, f, cb) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(match[0], match[1]), (function (param) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: Curry._2(cb, param[0], param[1])
                                    };
                            }));
              }));
}

function forEach3(arr, f, cb) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine3(match[0], match[1], match[2]), (function (param) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: Curry._3(cb, param[0], param[1], param[2])
                                    };
                            }));
              }));
}

function forEach4(arr, f, cb) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(combine(combine(match[0], match[1]), match[2]), match[3]), (function (param) {
                              var match = param[0];
                              var match$1 = match[0];
                              return {
                                      TAG: /* Ok */0,
                                      _0: Curry._4(cb, match$1[0], match$1[1], match[1], param[1])
                                    };
                            }));
              }));
}

function forEach5(arr, f, cb) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(combine(combine(combine(match[0], match[1]), match[2]), match[3]), match[4]), (function (param) {
                              var match = param[0];
                              var match$1 = match[0];
                              var match$2 = match$1[0];
                              return {
                                      TAG: /* Ok */0,
                                      _0: Curry._5(cb, match$2[0], match$2[1], match$1[1], match[1], param[1])
                                    };
                            }));
              }));
}

function forEach6(arr, f, cb) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(combine(combine(combine(combine(match[0], match[1]), match[2]), match[3]), match[4]), match[5]), (function (param) {
                              var match = param[0];
                              var match$1 = match[0];
                              var match$2 = match$1[0];
                              var match$3 = match$2[0];
                              return {
                                      TAG: /* Ok */0,
                                      _0: Curry._6(cb, match$3[0], match$3[1], match$2[1], match$1[1], match[1], param[1])
                                    };
                            }));
              }));
}

function forEach7(arr, f, cb) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(combine(combine(combine(combine(combine(match[0], match[1]), match[2]), match[3]), match[4]), match[5]), match[6]), (function (param) {
                              var match = param[0];
                              var match$1 = match[0];
                              var match$2 = match$1[0];
                              var match$3 = match$2[0];
                              var match$4 = match$3[0];
                              return {
                                      TAG: /* Ok */0,
                                      _0: Curry._7(cb, match$4[0], match$4[1], match$3[1], match$2[1], match$1[1], match[1], param[1])
                                    };
                            }));
              }));
}

function map7(arr, f) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(combine(combine(combine(combine(combine(match[0], match[1]), match[2]), match[3]), match[4]), match[5]), match[6]), (function (param) {
                              var match = param[0];
                              var match$1 = match[0];
                              var match$2 = match$1[0];
                              var match$3 = match$2[0];
                              var match$4 = match$3[0];
                              return {
                                      TAG: /* Ok */0,
                                      _0: [
                                        match$4[0],
                                        match$4[1],
                                        match$3[1],
                                        match$2[1],
                                        match$1[1],
                                        match[1],
                                        param[1]
                                      ]
                                    };
                            }));
              }));
}

function map8(arr, f) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(combine(combine(combine(combine(combine(combine(match[0], match[1]), match[2]), match[3]), match[4]), match[5]), match[6]), match[7]), (function (param) {
                              var match = param[0];
                              var match$1 = match[0];
                              var match$2 = match$1[0];
                              var match$3 = match$2[0];
                              var match$4 = match$3[0];
                              var match$5 = match$4[0];
                              return {
                                      TAG: /* Ok */0,
                                      _0: [
                                        match$5[0],
                                        match$5[1],
                                        match$4[1],
                                        match$3[1],
                                        match$2[1],
                                        match$1[1],
                                        match[1],
                                        param[1]
                                      ]
                                    };
                            }));
              }));
}

function map9(arr, f) {
  return mapWithIndex(arr, (function (row, i, param) {
                var match = Curry._2(f, row, i);
                return Belt_Result.flatMap(combine(combine(combine(combine(combine(combine(combine(combine(match[0], match[1]), match[2]), match[3]), match[4]), match[5]), match[6]), match[7]), match[8]), (function (param) {
                              var match = param[0];
                              var match$1 = match[0];
                              var match$2 = match$1[0];
                              var match$3 = match$2[0];
                              var match$4 = match$3[0];
                              var match$5 = match$4[0];
                              var match$6 = match$5[0];
                              return {
                                      TAG: /* Ok */0,
                                      _0: [
                                        match$6[0],
                                        match$6[1],
                                        match$5[1],
                                        match$4[1],
                                        match$3[1],
                                        match$2[1],
                                        match$1[1],
                                        match[1],
                                        param[1]
                                      ]
                                    };
                            }));
              }));
}

var Arr = {
  allOk: allOk$1,
  append: append,
  mapWithIndex: mapWithIndex,
  forEachL: forEachL,
  forEach2: forEach2,
  forEach3: forEach3,
  forEach4: forEach4,
  forEach5: forEach5,
  forEach6: forEach6,
  forEach7: forEach7,
  map7: map7,
  map8: map8,
  map9: map9
};

export {
  join ,
  allOk ,
  combine ,
  combine3 ,
  Opt ,
  Arr ,
  
}
/* No side effect */
